import React from "react";
import { Layout, Row, Col } from "antd";
import { useNavigate } from "react-router-dom";

const { Footer } = Layout;

const CustomFooter = () => {
  return (
    <Footer
      style={{
        textAlign: "center",
        background: "#1dbf73",
        color: "white",
        marginTop: "20px",
        width: "100%",
      }}
    >
      <Row gutter={[16, 16]} justify="center">
        <Col span={24}>
          <strong>
            <a href="terminos-condiciones">
              Términos y condiciones de uso del Sitio
            </a>
          </strong>
          <span style={{ margin: "0 10px" }}>|</span>
          <strong>
            <a href="declaracion-privacidad">Privacidad y confidencialidad</a>
          </strong>
          <span style={{ margin: "0 10px" }}>|</span>
          <strong>
            <a href="terminos-marketplace">Información del marketplace</a>
          </strong>
        </Col>
        <Col span={24}>
          <p>
            Av. Juan Rulfo 1851 – 69 Fracc. Sonterra, C.P 76235, Querétaro,
            México.
          </p>
        </Col>
        <Col span={24}>
        <p>
        CONTACTO
          </p>
          <p>
           ayuda@blizu.com.mx
           +52 1 442 345 9936
          </p>
        </Col>
        <Col span={24}>
          <p>
            Copyright © 2024 la presente plataforma es operada por la marca comercial "Blizu".
          </p>
        </Col>
      </Row>
    </Footer>
  );
};

export default CustomFooter;
