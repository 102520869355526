import React, { useState } from "react";
import { Upload, Button, Form, Input, message, Checkbox } from "antd";
import {
  UserOutlined,
  LockOutlined,
  MailOutlined,
  UploadOutlined,
  LoadingOutlined,
  PlusOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import "./Registro.scss";
import { auth, createUserWithEmailAndPassword } from "../../../firebaseConfig";
import { getFirestore, collection, doc, setDoc } from "firebase/firestore";
import { Toaster, toast } from "sonner";
import { createCustomer } from "../../../Api/Openpay/OpenPayApi";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../../context/UserContext";
const { geolocation } = navigator;

const Registro = () => {
  // Navegación
  const navigate = useNavigate();
  // Se inicializa el FirebaseStorage para subir la imagen
  const storage = getStorage();
  // States
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const { setUser } = useUser();
  // Al pulsar registrarse
  const onFinish = async (values) => {
    setLoading(true);
    try {
      // Creación de usuario en FirebaseAuth
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        values.email,
        values.password
      );

      // Se obtiene el identificador del usuario
      const userId = userCredential.user.uid;
      // const verificationId = await sendVerificationCode(values.phone);
      // Obtención de la ubicación del usuario
      let userLocation = null;
      if (geolocation) {
        // Obtener la posición del usuario
        const position = await new Promise((resolve, reject) => {
          geolocation.getCurrentPosition(resolve, reject);
        });

        // Extraer la latitud y longitud de la posición
        const { latitude, longitude } = position.coords;
        userLocation = { latitude, longitude };
      }

      var avatarLink = "";
      if (imageUrl) {
        const avatarRef = ref(storage, `avatars/${userId}`);
        await uploadBytes(avatarRef, values.photo[0].originFileObj);
        avatarLink = await getDownloadURL(avatarRef);
      }

      // Obtener una instancia de Firestore
      const db = getFirestore();
      // Se crea el documento del usuario
      const usersCollection = collection(db, "roles");
      const userDocRef = doc(usersCollection, userId);
      // Se crea el usuario en OpenPay

    //  console.log(values);
      const userOpenpay = await createCustomer({
        name: values.username,
        email: values.email,
        phone_number: values.phone,
      });
      // Se sube la colección de datos del usuario
      await setDoc(userDocRef, {
        username: values.username,
        email: values.email,
        phone: values.phone,
        role: 0,
        openpayId: userOpenpay.id,
        photo: avatarLink,
        uid: userId,
        activado: true,
        location: userLocation,
      });

      toast.success("Registro exitoso");
      setUser({
        username: values.username,
        email: values.email,
        phone: values.phone,
        role: 0,
        openpayId: userOpenpay.id,
        photo: avatarLink,
        uid: userId,
        activado: true,
        location: userLocation,
      });
      setLoading(false);
      message.success("Inicio de sesión exitoso");
      navigate("/");
    } catch (error) {
      setLoading(false);
      if(error.message === "Firebase: Error (auth/email-already-in-use)."){
        toast.error("El correo ya tiene una cuenta existente en el sistema.");
      }else{
        toast.error("Error en el registro. Por favor, inténtalo de nuevo." + error.message);
      }
      console.error(error);
    }
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("Solo puedes subir archivos JPG/PNG");
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("La imagen debe ser menor a 2MB");
      return false;
    }
    getBase64(file, (imageUrl) => {
      setLoading(false);
      setImageUrl(imageUrl);
    });

    return false; // Evitar que el componente Upload realice la carga
  };

  const handleChange = (info) => {
   // console.log("Contenido de info:", info);
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (imageUrl) => {
        setLoading(false);
        setImageUrl(imageUrl);
      });
    } else if (info.file.status === "error") {
      setLoading(false);
      message.error(`${info.file.name} falló al subir.`);
    }
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Subir Foto</div>
    </div>
  );

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return (
    <div className="registration-container">
      <Toaster richColors position="top-center" />
      <div id="recaptcha-container"></div>

      <Form
        name="register"
        onFinish={onFinish}
        scrollToFirstError
        className="registration-form"
        layout="vertical"
      >
        <h1 className="registration-title">Registro de cliente</h1>
        <Form.Item
          name="photo"
          valuePropName="fileList"
          getValueFromEvent={(e) => normFile(e, setImageUrl)}
          extra="Sube una foto de perfil"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <Upload
            name="avatar"
            listType="picture-circle"
            className="avatar-uploader"
            showUploadList={false}
            beforeUpload={beforeUpload}
            onChange={handleChange}
            accept="image/*" // Specify that only image files are allowed
          >
            {imageUrl ? (
              <img
                src={imageUrl}
                alt="avatar"
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "50%",
                  objectFit: "cover",
                }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>

        <Form.Item
          name="username"
          label="Nombre"
          rules={[
            {
              required: true,
              message: "Por favor, ingresa tu nombre de usuario",
            },
          ]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Nombre de Usuario"
          />
        </Form.Item>

        <Form.Item
          name="email"
          label="Correo electrónico"
          rules={[
            {
              required: true,
              message: "Por favor, ingresa tu dirección de correo electrónico",
            },
            {
              type: "email",
              message: "Ingresa un correo electrónico válido",
            },
          ]}
        >
          <Input
            prefix={<MailOutlined className="site-form-item-icon" />}
            placeholder="Correo Electrónico"
          />
        </Form.Item>
        <Form.Item
          name="phone"
          label="Teléfono"
          rules={[
            {
              required: true,
              message: "Por favor, ingresa tu número de teléfono",
            },
          ]}
        >
          <Input
            prefix={<PhoneOutlined className="site-form-item-icon" />}
            placeholder="Número de Teléfono"
          />
        </Form.Item>

        <Form.Item
          name="password"
          label="Contraseña"
          rules={[
            {
              required: true,
              message: "Por favor, ingresa tu contraseña",
            },
            {
              min: 8,
              message: "La contraseña debe tener al menos 8 caracteres",
            },
          ]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            placeholder="Contraseña"
          />
        </Form.Item>

        <Form.Item
          name="confirm"
          label="Confirmar contraseña"
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Por favor, confirma tu contraseña",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject("Las contraseñas no coinciden");
              },
            }),
          ]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            placeholder="Confirmar Contraseña"
          />
        </Form.Item>
        <Form.Item
          name="agreeTerms"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject("Debes aceptar los términos y condiciones"),
            },
          ]}
        >
          <Checkbox>
            He leído y acepto los{" "}
            <a
              href="/terminos-condiciones"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "blue", cursor: "pointer" }}
            >
              términos y condiciones
            </a>
          </Checkbox>
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            className="registration-form-button"
          >
            Registrarse
          </Button>
        </Form.Item>

        <Form.Item style={{ textAlign: "center" }}>
          ¿Ya tienes una cuenta? <a href="/login">Inicia Sesión</a>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Registro;
