import React from "react";
import { List, Typography, Button, Space, DatePicker } from "antd";
const { Text } = Typography;

const ListaItemProductosSubpedidos = ({
  item,
  onAssignDeliveryDate,
  entregado,
  recuperado
}) => {
  return (
    <List.Item key={item.id}>
      {item.tipo === "producto" ? (
        <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
          <div style={{ marginRight: 16 }}></div>
          <div style={{ flex: 1 }}>
            <List.Item.Meta
              title={item.nombre}
              description={`Cantidad: ${item.cantidad}`}
            />
            {/* Contenido específico para productos */}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p>${item.total}</p>
              <Text type="success">
                <strong> ${item.total * item.cantidad}</strong>
              </Text>
            </div>
            <p>Estatus: {item.estatus}</p>
            <p>
              Fecha de entrega:{" "}
              {item.devolucion > 0
                ? "Fecha de entrega cancelada"
                : item.entregaProgramada
                ? item.entregaProgramada.toDate().toLocaleString("es-ES", {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                  })
                : "Aún no se ha asignado una fecha de entrega"}
            </p>

            {/* Botones de acción */}
            <Space>
              {item.entregaProgramada &&
              item.estatus !== "Entrega realizada" &&
              (!item.devolucion || item.devolucion === 0) ? (
                <div>
                  <Button
                    danger
                    style={{ marginRight: "5px" }}
                    type="default"
                    onClick={() => onAssignDeliveryDate(item)}
                  >
                    Cambiar fecha de entrega o devolución
                  </Button>
                  <Button danger type="primary" onClick={() => entregado(item)}>
                    Marcar como entregado
                  </Button>
                </div>
              ) : (
                item.estatus !== "Entrega realizada" &&
                (!item.devolucion || item.devolucion === 0) && (
                  <Button
                    type="primary"
                    onClick={() => onAssignDeliveryDate(item)}
                  >
                    Confirmar entrega
                  </Button>
                )
              )}
            </Space>
          </div>
        </div>
      ) : (
        <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
          <div style={{ marginRight: 16 }}></div>
          <div style={{ flex: 1 }}>
            <List.Item.Meta
              title={item.nombre}
              description={item.stock && `Cantidad: ${item.cantidad}`}
            />

            {/* Contenido específico para productos */}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p>${item.total}</p>
              <p>{item.duracion}</p>
              <p>
                <p>
                  <strong>{item.horaInicioSeleccionada}</strong>
                </p>
                <strong>{item.diaSeleccionado}</strong>
              </p>
              {item.duracion !== "unico" && (
                <p>Tiempo: {item.cantidadDuracion}</p>
              )}
              {item.stock ? (
                item.duracion === "unico" ? (
                  <Text type="success">
                    <strong> ${parseFloat(item.total * 1).toFixed(2)}</strong>
                  </Text>
                ) : (
                  <Text type="success">
                    <strong>
                      {parseFloat(
                        item.total * item.cantidad * item.cantidadDuracion
                      ).toFixed(2)}
                    </strong>
                  </Text>
                )
              ) : item.duracion === "unico" ? (
                <Text type="success">
                  <strong> ${parseFloat(item.total * 1).toFixed(2)}</strong>
                </Text>
              ) : (
                <Text type="success">
                  <strong>
                    {parseFloat(item.total * item.cantidadDuracion).toFixed(2)}
                  </strong>
                </Text>
              )}
            </div>
            <p>Estatus: {item.estatus}</p>
            <p>
              Fecha de entrega:{" "}
              {item.entregaProgramada
                ? item.entregaProgramada.toDate().toLocaleString("es-ES", {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                  })
                : "Aún no se ha asignado una fecha de entrega"}
            </p>

            <Space>
              {item.entregaProgramada &&
              item.estatus !== "Entrega realizada"  &&  item.estatus !== "Producto de renta recuperado"  ? (
                <div>
                  <Button
                    danger
                    style={{ marginRight: "5px" }}
                    type="default"
                    onClick={() => onAssignDeliveryDate(item)}
                  >
                    Cambiar fecha de entrega o devolución
                  </Button>
                  <Button danger type="primary" onClick={() => entregado(item)}>
                    Marcar como entregado
                  </Button>
                </div>
              ) : (
                item.estatus !== "Entrega realizada" &&  item.estatus !== "Producto de renta recuperado" && (
                  <Button
                    type="primary"
                    onClick={() => onAssignDeliveryDate(item)}
                  >
                   Confirmar entrega
                  </Button>
                )
              )}
              {/* Agregar el botón para marcar como recuperado si la entrega fue realizada */}
              {item.estatus === "Entrega realizada" && item.tipoServicio === "renta" && (
                <Button type="primary"    style={{ backgroundColor: 'green', borderColor: 'green' }} onClick={() => recuperado(item)}>
                  Marcar como recuperación realizada
                </Button>
              )}
            </Space>
          </div>
        </div>
      )}
    </List.Item>
  );
};

export default ListaItemProductosSubpedidos;
