import React, { useEffect, useState } from "react";
import { Row, Col, Card, Typography } from "antd";
import {
  getFirestore,
  collection,
  getDocs,
  where,
  query,
} from "firebase/firestore";
import { Link } from "react-router-dom"; // Importa Link desde react-router-dom

const { Meta } = Card;

const Categories = () => {
  const [categoriesData, setCategoriesData] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const db = getFirestore();
        const categoriesCollection = collection(db, "categorias");
        
        // Filtra las categorías por tipo (producto o servicio)
        const q = query(categoriesCollection);
        
        const categoriesSnapshot = await getDocs(q);

        const categories = categoriesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setCategoriesData(categories);
        console.log(categories);
      } catch (error) {
        console.error("Error al obtener categorías:", error);
      }
    };

    fetchCategories();
  }, []);

  return (

    <div style={{ margin: "10px" }}>
    <Row gutter={[16, 16]}>
      {categoriesData.map((category) => (
        <Col key={category.id} xs={12} sm={12} md={8} lg={6} xl={4}>
          {/* Utiliza Link en lugar de Card y Meta para enlazar a la categoría */}
          <Link to={`/buscador?tipo=${category.tipo}&categoria=${category.id}`}>
            <Card hoverable>
              <img alt={category.nombre} src={category.photo} style={{ objectFit: 'cover', width: '100%', height: 'auto' }} />
              <Meta title={category.nombre} />
            </Card>
          </Link>
        </Col>
      ))}
    </Row>
  </div>

  );
};

export default Categories;
