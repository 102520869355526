import {
  collection,
  getDocs,
  query,
  where,
  startAt,
  orderBy,
} from "firebase/firestore";
import { db } from "../../firebaseConfig";
import {
  obteneProductosFavoritos,
  obteneServiciosFavoritos,
} from "../favoritos/favoritosUtils";

export const getArticulosCerca = async (ubicacionUsuario, filters, userData) => {
  try {
    const tiendasCercanas = await obtenerTiendasCercanas(ubicacionUsuario);
    const articulos = [];
    for (const tienda of tiendasCercanas) {
      const articulosTienda = await obtenerProductosServiciosDeTienda(
        tienda.vendedorUid,
        ubicacionUsuario,
        filters,
        userData
      );
      // Limitar a 60 artículos por tienda
      articulos.push(...articulosTienda.slice(0, 60));
    }

    return articulos; // Devuelve los artículos encontrados
  } catch (error) {
    console.error("Error al obtener artículos cercanos:", error.message);
    return []; // Retorna un array vacío en caso de error
  }
};

export const obtenerProductosServiciosDeTienda = async (
  tiendaId,
  ubicacionUsuario,
  filters,
  userData
) => {
  if (!filters) {
    // Si los filtros están vacíos, obtener solo los resultados de búsqueda
    const productos = await obtenerProductosConFiltros(tiendaId, ubicacionUsuario, {});
    const servicios = await obtenerServiciosConFiltros(tiendaId, ubicacionUsuario, {});
    return [...productos, ...servicios];
  } else if (filters.tipo === "producto") {
    return obtenerProductosConFiltros(tiendaId, ubicacionUsuario, filters);
  } else if (filters.tipo === "servicio") {
    return obtenerServiciosConFiltros(tiendaId, ubicacionUsuario, filters);
  } else {
    // En caso de que el tipo sea "todos", obtenemos productos y servicios
    const productos = await obtenerProductosConFiltros(
      tiendaId,
      ubicacionUsuario,
      filters,
      userData
    );
    const servicios = await obtenerServiciosConFiltros(
      tiendaId,
      ubicacionUsuario,
      filters,
      userData
    );
    return [...productos, ...servicios];
  }
};

const obtenerTiendasCercanas = async (ubicacionUsuario) => {
  const tiendasCollection = collection(db, "tiendas");
  const tiendasSnapshot = await getDocs(
    query(tiendasCollection, where("activado", "==", true))
  );

  const tiendasData = [];
  tiendasSnapshot.forEach((doc) => {
    const tienda = doc.data();
    tienda.vendedorUid = doc.id;
    // Calcula la distancia entre la ubicación del usuario y la ubicación de la tienda
    const distancia = calcularDistancia(ubicacionUsuario, tienda.ubicacion);
    // Agrega la distancia a la información de la tienda
    tienda.distancia = distancia;
    tiendasData.push(tienda);
  });

  // Ordena las tiendas por distancia de forma ascendente
  tiendasData.sort((a, b) => a.distancia - b.distancia);

  return tiendasData;
};

// Función para calcular la distancia entre dos ubicaciones geográficas
const calcularDistancia = (ubicacionUsuario, ubicacionTienda) => {
  const lat1 = ubicacionUsuario.latitude;
  const lon1 = ubicacionUsuario.longitude;
  const lat2 = ubicacionTienda.latitud;
  const lon2 = ubicacionTienda.longitud;

  const R = 6371; // Radio de la Tierra en kilómetros
  const dLat = (lat2 - lat1) * (Math.PI / 180);
  const dLon = (lon2 - lon1) * (Math.PI / 180);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * (Math.PI / 180)) *
      Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distancia = R * c; // Distancia en kilómetros
  return distancia;
};

export const obtenerProductosConFiltros = async (
  tiendaId,
  ubicacionUsuario,
  filters,
  userData
) => {
  try {
    // Aplicar filtros
    let queryRef = collection(db, "productos");

    // Agregar todas las cláusulas where
    if (filters) {
      if (
        filters.categoria &&
        filters.categoria !== "" &&
        filters.categoria !== "todos"
      ) {
        queryRef = query(queryRef, where("categoria", "==", filters.categoria));
      }

      if (
        filters.subcategoria &&
        filters.subcategoria !== "" &&
        filters.subcategoria !== "todos"
      ) {
        queryRef = query(
          queryRef,
          where("subcategoria", "==", parseInt(filters.subcategoria))
        );
      }

      if (filters.mesesSinIntereses) {
        queryRef = query(queryRef, where("mesesSinIntereses", "==", true));
      }

      if (
        filters.precioMin !== "" &&
        filters.precioMin !== undefined &&
        filters.precioMin !== null
      ) {
        queryRef = query(queryRef, where("total", ">=", filters.precioMin));
      }

      if (
        filters.precioMax !== "" &&
        filters.precioMax !== undefined &&
        filters.precioMax !== null
      ) {
        queryRef = query(queryRef, where("total", "<=", filters.precioMax));
      }
    }
    // Agregar filtro por vendedorUid
    queryRef = query(queryRef, where("vendedorUid", "==", tiendaId));

    // Filtrar por nombre
    if (filters.search) {
      queryRef = query(queryRef, orderBy("nombre"), startAt(filters.search));
    }

    const productosSnapshot = await getDocs(queryRef);

     const favoritos = await obteneProductosFavoritos(userData);
    
    const productosData = [];
    productosSnapshot.forEach((doc) => {
      const producto = doc.data();
      producto.id = doc.id;
      producto.tipo = "producto"; // Agregar el tipo producto
      // Verifica si el producto está habilitado
      if (!producto.pausa && producto.stock > 0) {
        // Comprueba si el producto está en favoritos
          const isFavorite = favoritos.includes(producto.id);
          producto.isFavorite = isFavorite;

        productosData.push(producto);
      }
    });

    return productosData;
  } catch (error) {
    console.error("Error en obtenerProductosConFiltros:", error.message);
    throw error;
  }
};

export const obtenerServiciosConFiltros = async (
  tiendaId,
  ubicacionUsuario,
  filters,
  userData
) => {
  let queryRef = collection(db, "servicios");
  if (filters) {
    if (
      filters.categoria &&
      filters.categoria !== "" &&
      filters.categoria !== "todos"
    ) {
      queryRef = query(queryRef, where("categoria", "==", filters.categoria));
    }

    if (
      filters.subcategoria &&
      filters.subcategoria !== "" &&
      filters.subcategoria !== "todos"
    ) {
      queryRef = query(
        queryRef,
        where("subcategoria", "==", parseInt(filters.subcategoria))
      );
    }

    if (filters.mesesSinIntereses) {
      queryRef = query(queryRef, where("mesesSinIntereses", "==", true));
    }

    if (
      filters.precioMin !== "" &&
      filters.precioMin !== undefined &&
      filters.precioMin !== null
    ) {
      queryRef = query(queryRef, where("total", ">=", filters.precioMin));
    }

    if (
      filters.precioMax !== "" &&
      filters.precioMax !== undefined &&
      filters.precioMax !== null
    ) {
      queryRef = query(queryRef, where("total", "<=", filters.precioMax));
    }

    if (filters.pagoPor) {
      queryRef = query(queryRef, where("tipoServicio", "==", filters.pagoPor));
    }
    if (filters.diasDisponibles) {
      queryRef = query(
        queryRef,
        where("diasDisponibles", "array-contains-any", filters.diasDisponibles)
      );
    }
  }
  // Agregar filtro por vendedorUid
  queryRef = query(queryRef, where("vendedorUid", "==", tiendaId));

  // Filtrar por nombre
  if (filters.search) {
    queryRef = query(queryRef, orderBy("nombre"), startAt(filters.search));
  }

  const serviciosSnapshot = await getDocs(queryRef);

  const favoritos = await obteneServiciosFavoritos(userData);
  
  const serviciosData = [];
  serviciosSnapshot.forEach((doc) => {
    const servicio = doc.data();
    servicio.id = doc.id;
    servicio.tipo = "servicio"; // Agregar el tipo servicio
    // Verifica si el servicio está habilitado
    if (!servicio.pausa) {
      if (
        servicio.tipoServicio !== "renta" ||
        (servicio.tipoServicio === "renta" && servicio.stock > 0)
      ) {
        // Comprueba si el servicio está en favoritos
          const isFavorite = favoritos.includes(servicio.id);
          servicio.isFavorite = isFavorite;
        

        serviciosData.push(servicio);
      }
    }
  });

  return serviciosData;
};
