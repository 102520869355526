import React, { useState, useEffect } from "react";
import { Form, Input, Button, Modal, Upload, message, Card } from "antd";
import {
  UserOutlined,
  LockOutlined,
  MailOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import "./EditarPerfilVendedor.scss";
import { auth } from "../../../firebaseConfig";
import {
  getFirestore,
  collection,
  doc,
  getDoc,
  setDoc,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { useNavigate } from "react-router-dom";
import { Toaster, toast } from "sonner";
import { useUser } from "../../../context/UserContext";
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
} from "firebase/auth";
import PhotoUploader from "../../../components/PhotoUploader/PhotoUploader";

const EditarPerfil = () => {
  const storage = getStorage();
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const { userData } = useUser();
  const [userProfile, setUserProfile] = useState({});
  const [form] = Form.useForm();
  const [editingFields, setEditingFields] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isPasswordModalVisible, setIsPasswordModalVisible] = useState(false);
  const [userInfo, setUserInfo] = useState([]);

  useEffect(() => {
    // Referencia a la colección de pedidos
    if(userData){
      setUserInfo(userData);
    }
   
}, [userData]);

  const showPasswordModal = () => {
    setIsPasswordModalVisible(true);
  };

  const handlePasswordModalCancel = () => {
    setIsPasswordModalVisible(false);
  };

  const handlePasswordModalFinish = async (values) => {
    const { currentPassword, newPassword, confirmNewPassword } = values;

    try {
      const user = auth.currentUser;
      const credential = EmailAuthProvider.credential(
        user.email,
        currentPassword
      );

      await reauthenticateWithCredential(user, credential);

      if (newPassword !== confirmNewPassword) {
        throw new Error("Las nuevas contraseñas no coinciden");
      }

      await updatePassword(user, newPassword);
      toast.success("Contraseña actualizada con éxito", { duration: 5000 });
    } catch (error) {
      if (
        error.message === "Firebase: Error (auth/invalid-login-credentials)."
      ) {
        toast.error(`Error, la contraseña anterior no coincide`);
      } else {
        toast.error(`Error al actualizar la contraseña: ${error.message}`);
      }
    } finally {
      setIsPasswordModalVisible(false);
    }
  };

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const db = getFirestore();
        const usersCollection = collection(db, "roles");
        const userDocRef = doc(usersCollection, userData.uid);
        const docSnapshot = await getDoc(userDocRef);

        if (docSnapshot.exists()) {
          const userProfileData = docSnapshot.data();

          form.setFieldsValue({
            username: userProfileData.username,
            email: userProfileData.email,
          });
          setUserProfile(userProfileData);
          setImageUrl(userProfileData.photo);
        }
      } catch (error) {
        console.error("Error al obtener datos del usuario:", error.message);
      }
    };

    fetchUserProfile();
  }, [userData, form]);

  const showModal = (field) => {
    setEditingFields([field]);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setEditingFields([]);
  };

  const handleModalFinish = async (values) => {
    // Lógica para actualizar el campo específico en el perfil
    try {
      const db = getFirestore();
      const usersCollection = collection(db, "roles");
      const userDocRef = doc(usersCollection, userData.uid);

      const updatedFields = {};
      editingFields.forEach((field) => {
        updatedFields[field] = values[field];
      });

      await setDoc(userDocRef, updatedFields, { merge: true });

      toast.success("Campo actualizado con éxito", { duration: 5000 });

      // Actualizar el valor del campo "username" en el estado del formulario
      form.setFieldsValue({
        username: values["username"],
      });
    } catch (error) {
      toast.error(
        "Error al actualizar el campo. Por favor, inténtalo de nuevo."
      );
      console.error(error.message);
    } finally {
      setIsModalVisible(false);
      setEditingFields([]);
    }
  };

  const renderEditableField = (field, label, icon) => {
    const isEditing = editingFields.includes(field);

    return (
      <Form.Item
      style={{width:'100%'}}
        key={field}
        name={field}
        label={label}
        initialValue={userProfile[field] || ""}
        rules={[
          {
            required: true,
            message: `Por favor, ingresa tu ${label.toLowerCase()}`,
          },
        ]}
      >
        {setUserInfo.google && field === "email" ? (
          <Input
          
            prefix={icon}
            placeholder={label}
            disabled
            readOnly
            style={{width:'100%'}}
          />
        ) : isEditing ? (
          <Input prefix={icon} placeholder={`Nuevo ${label}`} />
        ) : (
          <Input
            prefix={icon}
            placeholder={label}
            readOnly
            style={{width:'100%'}}
            onClick={() => showModal(field)}
          />
        )}
      </Form.Item>
    );
  };

  const actualizarFoto = async (values) => {
    try {
      setLoading(true);
      // Se obtiene el identificador del usuario
      const userId = userData.uid;
    //  console.log(values.photo[0].originFileObj);
      var avatarLink = "";
      if (imageUrl) {
        const avatarRef = ref(storage, `avatars/${userId}`);
        await uploadBytes(avatarRef, values.photo[0].originFileObj);
        avatarLink = await getDownloadURL(avatarRef);
        const db = getFirestore();
        const usersCollection = collection(db, "roles");
        const userDocRef = doc(usersCollection, userId);

        // Actualizar el campo 'photo' con el nuevo URL de la foto
        await setDoc(userDocRef, { photo: avatarLink }, { merge: true });
        setLoading(false);
        toast.success("Foto actualizada correctamente", {
          duration: 5000,
        });
      }
    } catch (error) {
      setLoading(false);
      toast.error(
        "Error al actualizar la foto. Por favor, inténtalo de nuevo."
      );
      console.error(error.message);
    }
  };

  return (
    <div >
      <div className="editar-perfil-container" style={{ maxWidth: 800, marginTop: '10px' }}>
        <Toaster richColors position="top-center" />
        <Form
          name="editarPerfil"
          scrollToFirstError
          className="editar-perfil-form"
          form={form}
          onFinish={actualizarFoto}
          layout="vertical"
        >
          <h1 className="editar-perfil-title">Editar perfil</h1>
          <PhotoUploader imageUrl={imageUrl} setImageUrl={setImageUrl} />
          <Button type="primary" htmlType="submit"   style={{ marginBottom: 16 , backgroundColor: "#1dbe72" }} loading={loading}>
            Cambiar foto
          </Button>
          {renderEditableField(
            "username",
            "Nombre de usuario",
            <UserOutlined className="site-form-item-icon" />
          )}
          {renderEditableField(
            "email",
            "Correo electrónico",
            <MailOutlined className="site-form-item-icon" />
          )}
          {/* Add more editable fields if needed */}

          <Form.Item>
            {setUserInfo.google ? (
              <Button
                type="primary"
                style={{ marginBottom: 16 }}
                disabled
                className="editar-perfil-form-button"
              >
                Google
              </Button>
            ) : (
              <Button
                type="primary"
                onClick={showPasswordModal}
                style={{ marginBottom: 16 , backgroundColor: "#1dbe72" }}
                className="editar-perfil-form-button"
              >
                Cambiar Contraseña
              </Button>
            )}
          </Form.Item>
        </Form>

        <Modal
          title={`Modificar campo`}
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}
        >
          <Form onFinish={handleModalFinish}>
            {editingFields.map((field) => (
              <Form.Item
                key={field}
                name={field}
                label={field.charAt(0).toUpperCase() + field.slice(1)}
                initialValue={userProfile[field] || ""}
                rules={[
                  {
                    required: true,
                    message: `Por favor, ingresa tu ${field.toLowerCase()}`,
                  },
                ]}
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder={field}
                />
              </Form.Item>
            ))}
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Actualizar
              </Button>
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          title="Cambiar Contraseña"
          open={isPasswordModalVisible}
          onCancel={handlePasswordModalCancel}
          footer={null}
        >
          <Form onFinish={handlePasswordModalFinish}>
            <Form.Item
              name="currentPassword"
              rules={[
                {
                  required: true,
                  message: "Por favor, ingresa tu contraseña actual",
                },
              ]}
            >
              <Input.Password placeholder="Contraseña Actual" />
            </Form.Item>

            <Form.Item
              name="newPassword"
              rules={[
                {
                  min: 8,
                  message:
                    "La nueva contraseña debe tener al menos 8 caracteres",
                },
              ]}
            >
              <Input.Password placeholder="Nueva Contraseña" />
            </Form.Item>

            <Form.Item
              name="confirmNewPassword"
              dependencies={["newPassword"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Por favor, confirma tu nueva contraseña",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || value === getFieldValue("newPassword")) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      "Las nuevas contraseñas no coinciden"
                    );
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Confirmar Nueva Contraseña" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Actualizar Contraseña
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </div>
  );
};

export default EditarPerfil;
