import React, { useEffect, useRef, useState } from "react";
import { Card, Row, Col } from "antd";
import {
  collection,
  query,
  where,
  getDocs,
  Timestamp,
} from "firebase/firestore";
import { db } from "../../../firebaseConfig"; // Importa tu configuración de Firebase aquí
import GraficoVentasPorHora from "../../../components/Graficas/GraficoVentasPorHora";
import GraficoVentasPorDiaMesActual from "../../../components/Graficas/GraficoVentasPorDiaMesActual";
import GraficoVentasPorMesAnioActual from "../../../components/Graficas/GraficoVentasPorMesAnioActual";
import { useUser } from "../../../context/UserContext";

const EstadisticasVendedor = () => {
  const { userData } = useUser();
  const [totalDineroGeneradoHoy, setTotalDineroGeneradoHoy] = useState(0);
  const [totalGananciaGeneradoHoy, setTotalGananciaGeneradoHoy] = useState(0);
  const [totalComisionesPlataformaHoy, setTotalComisionesPlataformaHoy] =
    useState(0);

  const [totalDineroGeneradoMes, setTotalDineroGeneradoMes] = useState(0);
  const [totalGananciaGeneradoMes, setTotalGananciaGeneradoMes] = useState(0);
  const [totalComisionesPlataformaMes, setTotalComisionesPlataformaMes] =
    useState(0);

  const [totalDineroGeneradoAnio, setTotalDineroGeneradoAnio] = useState(0);
  const [totalGananciaGeneradoAnio, setTotalGananciaGeneradoAnio] = useState(0);
  const [totalComisionesPlataformaAnio, setTotalComisionesPlataformaAnio] =
    useState(0);

  const [ventasPorHora, setVentasPorHora] = useState(null);
  const [ventasPorDia, setVentasPorDia] = useState(null);
  const [ventasPorMes, setVentasPorMes] = useState(null);

  const obtenerVentasPorHoraHoy = async (uid) => {
    const hoy = new Date();
    const inicioDia = Timestamp.fromDate(
      new Date(hoy.getFullYear(), hoy.getMonth(), hoy.getDate(), 0, 0, 0)
    );
    const finDia = Timestamp.fromDate(
      new Date(hoy.getFullYear(), hoy.getMonth(), hoy.getDate(), 23, 59, 59)
    );
  
    const querySnapshot = await getDocs(
      query(
        collection(db, "subpedidos"),
        where("vendedorUid", "==", uid),
        where("fechaCreacion", ">=", inicioDia),
        where("fechaCreacion", "<=", finDia)
      )
    );
  
    const ventasPorHora = {};
  
    querySnapshot.forEach((doc) => {
      const pedido = doc.data();
      const fechaPedido = pedido.fechaCreacion.toDate();
      const horaPedido = fechaPedido.getHours();
  
      // Guardar el total de ventas correspondiente a la hora del pedido
      if (!ventasPorHora[horaPedido]) {
        ventasPorHora[horaPedido] = {
          totalVentas: 0,
          totalComisionesPlataforma: 0,
          totalDevoluciones: 0,
        };
      }
  
      const devolucion = pedido.devolucion || 0;
      const totalSinDevolucion = pedido.total - devolucion;
  
      const comisionPlataforma = Number(pedido.comisiones || 0);
      const comisionPlataformaProporcional = (comisionPlataforma * totalSinDevolucion) / pedido.total;
  
      ventasPorHora[horaPedido].totalVentas += totalSinDevolucion;
      ventasPorHora[horaPedido].totalDevoluciones += devolucion;
      ventasPorHora[horaPedido].totalComisionesPlataforma += comisionPlataformaProporcional;
    });
  
    // Redondear a dos decimales para mantener consistencia
    Object.keys(ventasPorHora).forEach((hora) => {
      ventasPorHora[hora].totalVentas = ventasPorHora[hora].totalVentas.toFixed(2);
      ventasPorHora[hora].totalComisionesPlataforma = ventasPorHora[hora].totalComisionesPlataforma.toFixed(2);
      ventasPorHora[hora].totalDevoluciones = ventasPorHora[hora].totalDevoluciones.toFixed(2);
    });
  
    let totalHoy = 0;
    let totalComisionesPlataforma = 0;
    let totalGanancia = 0;
  
    querySnapshot.forEach((doc) => {
      const pedido = doc.data();
     
      const devolucion = pedido.devolucion || 0;
      const totalSinDevolucion = pedido.total - devolucion;
  
      const comisionPlataforma = Number(pedido.comisiones || 0);
      const comisionPlataformaProporcional = (comisionPlataforma * totalSinDevolucion) / pedido.total;

      totalHoy += totalSinDevolucion;
      totalComisionesPlataforma += comisionPlataformaProporcional;
      totalGanancia += totalSinDevolucion - comisionPlataformaProporcional;
    });
  
    setTotalDineroGeneradoHoy(totalHoy.toFixed(2));
    setTotalComisionesPlataformaHoy(totalComisionesPlataforma.toFixed(2));
    setTotalGananciaGeneradoHoy(totalGanancia.toFixed(2));
  
    return ventasPorHora;
  };
  

  const obtenerVentasPorDiaMesActual = async (uid) => {
    const hoy = new Date();
    const primerDiaMes = new Date(hoy.getFullYear(), hoy.getMonth(), 1);
    const ultimoDiaMes = new Date(hoy.getFullYear(), hoy.getMonth() + 1, 0);

    const querySnapshot = await getDocs(
      query(
        collection(db, "subpedidos"),
        where("vendedorUid", "==", uid),
        where("fechaCreacion", ">=", Timestamp.fromDate(primerDiaMes)),
        where("fechaCreacion", "<=", Timestamp.fromDate(ultimoDiaMes))
      )
    );

    const ventasPorDia = {};

    querySnapshot.forEach((doc) => {
      const pedido = doc.data();
      const fechaPedido = pedido.fechaCreacion.toDate();
      const diaPedido = fechaPedido.getDate();

      // Guardar el total de ventas correspondiente al día del pedido
      if (!ventasPorDia[diaPedido]) {
        ventasPorDia[diaPedido] = {
          totalVentas: 0,
          totalComisionesPlataforma: 0,
          totalDevoluciones: 0,
        };
      }

      const devolucion = pedido.devolucion || 0;
      const totalSinDevolucion = pedido.total - devolucion;

      const comisionPlataforma = Number(pedido.comisiones || 0);
      const comisionPlataformaProporcional =
        comisionPlataforma * (totalSinDevolucion / pedido.total);

      ventasPorDia[diaPedido].totalVentas += totalSinDevolucion;
      ventasPorDia[diaPedido].totalDevoluciones += devolucion;
      ventasPorDia[diaPedido].totalComisionesPlataforma +=
        comisionPlataformaProporcional;
    });

    // Redondear a dos decimales para mantener consistencia
    Object.keys(ventasPorDia).forEach((dia) => {
      ventasPorDia[dia].totalVentas = ventasPorDia[dia].totalVentas.toFixed(2);
      ventasPorDia[dia].totalComisionesPlataforma =
        ventasPorDia[dia].totalComisionesPlataforma.toFixed(2);
      ventasPorDia[dia].totalDevoluciones =
        ventasPorDia[dia].totalDevoluciones.toFixed(2);
    });

    let totalMes = 0;
    let totalComisionesPlataforma = 0;
    let totalGanancia = 0;

    querySnapshot.forEach((doc) => {
      const pedido = doc.data();

      const devolucion = pedido.devolucion || 0;
      const totalSinDevolucion = pedido.total - devolucion;

      const comisionPlataforma = Number(pedido.comisiones || 0);
      const comisionPlataformaProporcional =
        comisionPlataforma * (totalSinDevolucion / pedido.total);

       // console.log(comisionPlataformaProporcional);
      totalMes += totalSinDevolucion;
      totalComisionesPlataforma += comisionPlataformaProporcional;
      totalGanancia += totalSinDevolucion - comisionPlataformaProporcional;
    });

    setTotalDineroGeneradoMes(totalMes.toFixed(2));
    setTotalComisionesPlataformaMes(totalComisionesPlataforma.toFixed(2));
    setTotalGananciaGeneradoMes(totalGanancia.toFixed(2));

 //   console.log(ventasPorDia);
    return ventasPorDia;
  };

  const obtenerVentasPorMesAñoActual = async (uid) => {
    const hoy = new Date();

    const primerDiaAño = new Date(hoy.getFullYear(), 0, 1);
    const ultimoDiaAño = new Date(hoy.getFullYear(), 11, 31);

    const querySnapshot = await getDocs(
      query(
        collection(db, "subpedidos"),
        where("vendedorUid", "==", uid),
        where("fechaCreacion", ">=", Timestamp.fromDate(primerDiaAño)),
        where("fechaCreacion", "<=", Timestamp.fromDate(ultimoDiaAño))
      )
    );

    const ventasPorMes = {};

    querySnapshot.forEach((doc) => {
      const pedido = doc.data();
      const fechaPedido = pedido.fechaCreacion.toDate();
      const mesPedido = fechaPedido.getMonth() + 1; // Ni idea da un mes antes
     // console.log(fechaPedido);
     // console.log(mesPedido);
      // Guardar el total de ventas correspondiente al mes del pedido
      if (!ventasPorMes[mesPedido]) {
        ventasPorMes[mesPedido] = {
          totalVentas: 0,
          totalComisionesPlataforma: 0,
          totalDevoluciones: 0,
        };
      }

      const devolucion = pedido.devolucion || 0;
      const totalSinDevolucion = pedido.total - devolucion;

      const comisionPlataforma = Number(
        pedido.comisiones || 0
      );
      const comisionPlataformaProporcional =
        comisionPlataforma * (totalSinDevolucion / pedido.total);

      ventasPorMes[mesPedido].totalVentas += totalSinDevolucion;
      ventasPorMes[mesPedido].totalDevoluciones += devolucion;
      ventasPorMes[mesPedido].totalComisionesPlataforma +=
        comisionPlataformaProporcional;
    });

    // Redondear a dos decimales para mantener consistencia
    Object.keys(ventasPorMes).forEach((mes) => {
      ventasPorMes[mes].totalVentas = ventasPorMes[mes].totalVentas.toFixed(2);
      ventasPorMes[mes].totalComisionesPlataforma =
        ventasPorMes[mes].totalComisionesPlataforma.toFixed(2);
      ventasPorMes[mes].totalDevoluciones =
        ventasPorMes[mes].totalDevoluciones.toFixed(2);
    });

    let totalAnio = 0;
    let totalComisionesPlataforma = 0;
    let totalGanancia = 0;

    querySnapshot.forEach((doc) => {
      const pedido = doc.data();

      const devolucion = pedido.devolucion || 0;
      const totalSinDevolucion = pedido.total - devolucion;

      const comisionPlataforma = Number(pedido.comisiones || 0);
      const comisionPlataformaProporcional =
        comisionPlataforma * (totalSinDevolucion / pedido.total);

      totalAnio += totalSinDevolucion;
      totalComisionesPlataforma += comisionPlataformaProporcional;
      totalGanancia += totalSinDevolucion - comisionPlataformaProporcional;
    });

    setTotalDineroGeneradoAnio(totalAnio.toFixed(2));
    setTotalComisionesPlataformaAnio(totalComisionesPlataforma.toFixed(2));
    setTotalGananciaGeneradoAnio(totalGanancia.toFixed(2));

    return ventasPorMes;
  };

  useEffect(() => {
    const obtenerDatos = async () => {
      if (userData) {
        try {
          const ventasHora = await obtenerVentasPorHoraHoy(userData.uid);
          setVentasPorHora(ventasHora);

          const ventasDia = await obtenerVentasPorDiaMesActual(userData.uid);
          setVentasPorDia(ventasDia);

          const ventasMes = await obtenerVentasPorMesAñoActual(userData.uid);
          setVentasPorMes(ventasMes);
        } catch (error) {
          console.error("Error al obtener datos de ventas:", error);
        }
      }
    };

    obtenerDatos();
  }, [userData]);

  return (
    <div>
      <h1>Dashboard</h1>
      <Row gutter={16}>
        <Col span={8}>
          <Card title="Ventas hoy">{totalDineroGeneradoHoy} MXN</Card>
        </Col>
        <Col span={8}>
          <Card title="Ganancias hoy">{totalGananciaGeneradoHoy} MXN</Card>
        </Col>
        <Col span={8}>
          <Card title="Pago de comisión a plataforma Blizu">
            {totalComisionesPlataformaHoy} MXN
          </Card>
        </Col>

        <GraficoVentasPorHora datos={ventasPorHora} />
        <Col span={8}>
          <Card title="Ventas este mes">{totalDineroGeneradoMes} MXN</Card>
        </Col>
        <Col span={8}>
          <Card title="Ganancias  mes">{totalGananciaGeneradoMes} MXN</Card>
        </Col>
        <Col span={8}>
          <Card title="Pago de comisión a plataforma Blizu">
            {totalComisionesPlataformaMes} MXN
          </Card>
        </Col>
        <GraficoVentasPorDiaMesActual datos={ventasPorDia} />

        <Col span={8}>
          <Card title="Ventas este año">{totalDineroGeneradoAnio} MXN</Card>
        </Col>
        <Col span={8}>
          <Card title="Ganancia comisiones año">
            {totalGananciaGeneradoAnio} MXN
          </Card>
        </Col>
        <Col span={8}>
          <Card title="Pago de comisión a plataforma Blizu">
            {totalComisionesPlataformaAnio} MXN
          </Card>
        </Col>

        <GraficoVentasPorMesAnioActual datos={ventasPorMes} />
      </Row>
    </div>
  );
};

export default EstadisticasVendedor;
