import { ListaProductoItemBuscador } from "../ProductosBuscador/ListaProductoItemBuscador";
import React, { useEffect, useState } from "react";
import { Flex, Modal, Card, Typography } from "antd";
import { useNavigate, Link } from "react-router-dom";
import {
  collection,
  getDocs,
  getDoc,
  doc,
  updateDoc,
  setDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { Toaster, toast } from "sonner";
import { useUser } from "../../context/UserContext";
import { db } from "../../firebaseConfig";
import {
  addToCart,
} from "../../utils/productos/productoUtils";
import { getProductosCerca } from "../../utils/productos/productosCercaUtils";
import { handleFavoriteProductoAction } from "../../utils/favoritos/favoritosUtils";
const ProductosCerca = () => {
  const [products, setProducts] = useState([]);
  const { userLocation , userData, userFavorites } = useUser();

  useEffect(() => {
    if (userLocation && userFavorites) {
      getProductosCerca(userLocation, userFavorites, setProducts);
    }
  }, [userLocation, userFavorites]);

  const handleFavoriteToggle = async (productId, isFavorite) => {
    // Invierte el estado local de isFavorite para el producto
    const updatedProducts = products.map((producto) => {
      if (producto.id === productId) {
        return { ...producto, isFavorite: !isFavorite };
      }
      return producto;
    });
    setProducts(updatedProducts);
  
    // Llama a la función handleFavoriteAction con el ID del producto y la acción determinada
    // Determina la acción según si el producto ya está en favoritos o no
    const action = isFavorite ? "remove" : "add";
    handleFavoriteProductoAction(productId, action, userData);
  };
  

  return (
    <Card style={{ margin: "20px", textAlign: "center" }}>
      <Typography.Title>Productos cerca de ti</Typography.Title>
      <Flex wrap="wrap" gap="large" justify="center">
        {products.slice(0, 5).map((producto) => (
          <ListaProductoItemBuscador
            producto={producto}
            addToCart={() => addToCart(producto,userData)}
            favorite={() =>
              handleFavoriteToggle(producto.id, producto.isFavorite)
            } // Nueva llamada a la función
            isFavorite={producto.isFavorite}
            key={producto.id}
          />
        ))}
      </Flex>
    </Card>
  );
};

export default ProductosCerca;
