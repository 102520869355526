import React, { useState, useEffect } from "react";
import { Form, InputNumber, Button, message, Card } from "antd";
import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore";

const ComisionesAdmin = () => {
  const [comisionMesesSinIntereses, setComisionMesesSinIntereses] = useState(0);
  const [comisionPlataforma, setComisionPlataforma] = useState(0);
  const [form] = Form.useForm();
  useEffect(() => {
    const fetchConfiguraciones = async () => {
      try {
        const db = getFirestore();
        const configuracionesDocRef = doc(db, "configuraciones", "comisiones");
        const configuracionesDoc = await getDoc(configuracionesDocRef);

        if (configuracionesDoc.exists()) {
          const configuracionesData = configuracionesDoc.data();
          setComisionMesesSinIntereses(
            configuracionesData.comisionMesesSinIntereses
          );
          setComisionPlataforma(configuracionesData.comisionPlataforma);

          form.setFieldsValue({
            comisionMesesSinIntereses: comisionMesesSinIntereses,
            comisionPlataforma: comisionPlataforma,
          });
        }
      } catch (error) {
        console.error("Error al obtener configuraciones:", error);
      }
    };

    fetchConfiguraciones();
  }, [comisionMesesSinIntereses, comisionPlataforma]);

//  console.log(comisionMesesSinIntereses);
  const handleFormSubmit = async (values) => {
    try {
      const db = getFirestore();
      const configuracionesDocRef = doc(db, "configuraciones", "comisiones");
      await updateDoc(configuracionesDocRef, {
        comisionMesesSinIntereses: values.comisionMesesSinIntereses,
        comisionPlataforma: values.comisionPlataforma,
      });

      message.success("Comisiones actualizadas correctamente");
    } catch (error) {
      console.error("Error al actualizar configuraciones:", error);
    }
  };

  return (
    <div style={{ maxWidth: 800, margin: "auto" }}>
     
      <Card>
      <h1 >Porcentaje de comisiones globales</h1>
      <Form
        labelCol={{ span: 16 }}
        wrapperCol={{ span: 16 }}
        form={form}
        onFinish={handleFormSubmit}
      >
        <Form.Item
          label="Comisión meses sin intereses"
          name="comisionMesesSinIntereses" // Asegúrate de que los nombres coincidan aquí
          rules={[{ required: true, type: "number", min: 0, max: 100 }]}
        >
          <InputNumber addonAfter="%" />
        </Form.Item>

        <Form.Item
          label="Comisión plataforma"
          name="comisionPlataforma" // Asegúrate de que los nombres coincidan aquí
          rules={[{ required: true, type: "number", min: 0, max: 100 }]}
        >
          <InputNumber addonAfter="%" />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Actualizar comisiones
          </Button>
        </Form.Item>
      </Form>
      </Card>
     
    </div>
  );
};

export default ComisionesAdmin;
