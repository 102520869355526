import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  orderBy,
  onSnapshot,
  addDoc,
  serverTimestamp,
  updateDoc,
  doc,
  getDoc,
} from "firebase/firestore";
import { db } from "../../../firebaseConfig";
import {
  Input,
  Button,
  List,
  Typography,
  Avatar,
  Spin,
  Card,
  Empty,
  Alert,
} from "antd";
import { useUser } from "../../../context/UserContext";
import { useParams, useNavigate } from "react-router-dom";
import {
  UserOutlined,
  ArrowRightOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import moment from "moment";

const { Text } = Typography;

const VendedorChat = () => {
  const { userData } = useUser();
  const { subpedidoId } = useParams();
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [store, setStore] = useState({}); // Información de la tienda
  const [comprador, setComprador] = useState({}); // Información del comprador

  useEffect(() => {
    const fetchStoreAndComprador = async () => {
      try {
        if (subpedidoId && userData) {
          // Obtén el subpedido
          const subpedidoDocRef = doc(db, "subpedidos", subpedidoId);
          const subpedidoDocSnapshot = await getDoc(subpedidoDocRef);
          if (subpedidoDocSnapshot.exists()) {
            const subpedidoData = subpedidoDocSnapshot.data();

            // Obtén la información del comprador
            const compradorUid = subpedidoData.usuarioUid;
            const compradorDocRef = doc(db, "roles", compradorUid);
            const compradorDocSnapshot = await getDoc(compradorDocRef);
            if (compradorDocSnapshot.exists()) {
              setComprador(compradorDocSnapshot.data());
            }

            // Obtén la tienda usando el vendedorUid
            const vendedorUid = subpedidoData.vendedorUid;
            const tiendaDocRef = doc(db, "tiendas", vendedorUid);
            const tiendaDocSnapshot = await getDoc(tiendaDocRef);
            if (tiendaDocSnapshot.exists()) {
              setStore(tiendaDocSnapshot.data());
            }
          }
        }
      } catch (error) {
        console.error("Error al obtener la tienda o comprador:", error);
      }
    };

    fetchStoreAndComprador();
  }, [subpedidoId, userData]);

  useEffect(() => {
    if (subpedidoId && userData) {
      const messagesCollection = collection(
        db,
        "subpedidos",
        subpedidoId,
        "messages"
      );
      const messagesQuery = query(
        messagesCollection,
        orderBy("timestamp", "asc")
      );

      const unsubscribe = onSnapshot(messagesQuery, (snapshot) => {
        const fetchedMessages = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Filtra los mensajes para mostrar solo los aprobados y enviados por el comprador
        const filteredMessages = fetchedMessages.filter(
          (message) =>
            message.status === "approved" || message.senderId === userData.uid
        );

        // Actualiza el estado de lectura para los mensajes recibidos
        filteredMessages.forEach(async (message) => {
          if (message.senderId !== userData.uid && !message.read) {
            const messageDoc = doc(
              db,
              "subpedidos",
              subpedidoId,
              "messages",
              message.id
            );
            await updateDoc(messageDoc, { read: true });
          }
        });

        setMessages(filteredMessages);
        setLoading(false);
      });

      return () => unsubscribe();
    }
  }, [subpedidoId, userData]);

  const isMessageValid = (message) => {
    const phoneRegex = /\b\d{10}\b/;
    const badWords = ["badword1", "badword2"];

    if (phoneRegex.test(message)) return false;
    for (let word of badWords) {
      if (message.includes(word)) return false;
    }
    return true;
  };

  const sendMessage = async () => {
    if (newMessage.trim() !== "" && isMessageValid(newMessage)) {
      try {
        if (userData && userData.uid) {
          await addDoc(collection(db, "subpedidos", subpedidoId, "messages"), {
            content: newMessage,
            senderId: userData.uid,
            timestamp: serverTimestamp(),
            status: "pending",
            read: false,
          });
          setNewMessage("");
        }
      } catch (error) {
        console.error("Error adding document: ", error);
      }
    }
  };

  return (
    <Card
      style={{
        padding: "20px",
        maxWidth: "800px",
        margin: "0 auto",
        marginTop: "10px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <h2>Chat con el comprador</h2>
        <Button
          onClick={() => navigate("/dashboard/mispedidos/subpedidos/" +subpedidoId)}
          icon={<ArrowLeftOutlined />} // Añade el ícono de flecha izquierda
        >
          Volver al pedido
        </Button>
      </div>
      <Alert
        message="Aviso"
        description="Todo mensaje enviado por este chat será validado por un administrador antes de llegar al comprador. Si el mensaje no cumple con las políticas, no le llegará al comprador y en el mensaje aparecerá la leyenda 'No aprobado'. Recuerda que no se permite enviar números de teléfono."
        type="info"
        showIcon
      />

      <div
        style={{
          height: "400px",
          overflowY: "scroll",
          border: "1px solid #d9d9d9",
          borderRadius: "8px",
          padding: "10px",
          marginTop: "10px",
          backgroundColor: "#fafafa",
        }}
      >
        {loading && userData ? (
          <Spin />
        ) : (
          <div>
            {messages.length > 0 ? (
              <List
                dataSource={messages}
                renderItem={(message) => (
                  <List.Item
                    key={message.id}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "10px",
                      backgroundColor:
                        message.senderId === userData?.uid ? "#e6f7ff" : "#fff",
                      padding: "10px",
                      borderRadius: "8px",
                    }}
                  >
                    <Avatar
                      style={{ marginRight: "10px" }}
                      src={
                        message.senderId === userData?.uid
                          ? store.foto
                          : comprador.photo || <UserOutlined />
                      }
                    />
                    <div style={{ flex: 1 }}>
                      <Text style={{ display: "block" }} strong>
                        {message.senderId === userData?.uid
                          ? "Tú"
                          : comprador.username || "Desconocido"}
                      </Text>
                      <Text>{message.content}</Text>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginTop: "5px",
                        }}
                      >
                        <Text type="secondary" style={{ marginRight: "10px" }}>
                          {moment(message.timestamp?.toDate()).format(
                            "YYYY-MM-DD HH:mm"
                          )}
                        </Text>
                        {message.senderId === userData?.uid && (
                          <Text type="secondary">
                            {message.read ? "Leído" : "No leído"}
                          </Text>
                        )}
                        {message.senderId === userData?.uid &&
                          message.status === "rejected" && (
                            <Text type="danger">No aprobado</Text>
                          )}
                      </div>
                    </div>
                  </List.Item>
                )}
              />
            ) : (
              <Empty description="Aún no se ha enviado ningún mensaje" />
            )}
          </div>
        )}
      </div>
      <div style={{ marginTop: "10px", display: "flex", alignItems: "center" }}>
        <Input
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Escribe un mensaje..."
          style={{ marginRight: "10px", flex: 1 }}
        />
        <Button
          type="primary"
          onClick={sendMessage}
          icon={<ArrowRightOutlined />} // Añade el ícono de flecha
        >
          Enviar
        </Button>
      </div>
    </Card>
  );
};

export default VendedorChat;
