import React from "react";
import { Card, Space, Typography, Button, InputNumber, Input } from "antd";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";
import { useUser } from "../context/UserContext";
import { Link } from "react-router-dom";

const { Text } = Typography;

const CartItem = ({ item }) => {
  const { userData } = useUser();

  const updateCartQuantity = async (productId, newQuantity) => {
    try {
      const userCartRef = doc(db, "carritos", userData.uid);
      const userCartDoc = await getDoc(userCartRef);

      if (userCartDoc.exists()) {
        const updatedProducts = userCartDoc.data().productos.map((product) => {
          if (product.id === productId) {
            return {
              ...product,
              cantidad: newQuantity,
            };
          }
          return product;
        });

        await updateDoc(userCartRef, { productos: updatedProducts });
        // console.log('Cantidad actualizada en el carrito.');
      } else {
        console.error("El carrito no existe.");
      }
    } catch (error) {
      console.error(
        "Error al actualizar la cantidad en el carrito:",
        error.message
      );
    }
  };

  const handleQuantityChange = async (newQuantity) => {
    updateCartQuantity(item.id, newQuantity);
  };

  const handleRemoveFromCart = async () => {
    try {
      const userCartRef = doc(db, "carritos", userData.uid);
      const userCartDoc = await getDoc(userCartRef);

      if (userCartDoc.exists()) {
        const updatedProducts = userCartDoc
          .data()
          .productos.filter((product) => product.id !== item.id);

        await updateDoc(userCartRef, { productos: updatedProducts });
        // console.log('Producto eliminado del carrito.');
      } else {
        console.error("El carrito no existe.");
      }
    } catch (error) {
      console.error(
        "Error al eliminar el producto del carrito:",
        error.message
      );
    }
  };

  const handleRemoveFromCartService = async () => {
    try {
      const userCartRef = doc(db, "carritos", userData.uid);
      const userCartDoc = await getDoc(userCartRef);

      if (userCartDoc.exists()) {
        const updatedProducts = userCartDoc
          .data()
          .servicios.filter((product) => product.id !== item.id);

        await updateDoc(userCartRef, { servicios: updatedProducts });
        // console.log('Producto eliminado del carrito.');
      } else {
        console.error("El carrito no existe.");
      }
    } catch (error) {
      console.error(
        "Error al eliminar el producto del carrito:",
        error.message
      );
    }
  };

  // Si el tipo de elemento es "servicio", mostramos la vista específica para servicios
  if (item.tipo === "servicio") {
    return (
      <Card style={{ marginBottom: "16px" }}>
        <Space direction="vertical" size="small">
          <Link to={`/servicio/${item.id}`}>
            <h3>{item.nombre}</h3>
          </Link>
          <Text>Precio: ${parseFloat(item.total).toFixed(2)}</Text>
          <Text>
            Horario: {item.horaInicio} - {item.horaFin}
          </Text>
          <Text>Duración : {item.duracion}</Text>

          {item.stock && (
            <Text>
              Cantidad:
              <InputNumber
                min={1}
                max={item.stock}
                value={item.cantidad}
                disabled
              />
            </Text>
          )}

          <Text>
            Hora Inicio seleccionada:
            <Input value={item.horaInicioSeleccionada} disabled />
          </Text>

          <Text>
            Dia seleccionado:
            <Input value={item.diaSeleccionado} disabled />
          </Text>

          {item.cantidadDuracion && (
            <Text>
              Cantidad de duración:
              <InputNumber value={item.cantidadDuracion} disabled />
            </Text>
          )}

          {item.stock ? (
            item.duracion === "unico" ? (
              <Text type="secondary">
                Subtotal: ${parseFloat(item.total * 1).toFixed(2)}
              </Text>
            ) : (
              <Text type="secondary">
                Subtotal: $
                {parseFloat(
                  item.total * item.cantidad * item.cantidadDuracion
                ).toFixed(2)}
              </Text>
            )
          ) : item.duracion === "unico" ? (
            <Text type="secondary">
              Subtotal: ${parseFloat(item.total * 1).toFixed(2)}
            </Text>
          ) : (
            <Text type="secondary">
              Subtotal: $
              {parseFloat(item.total * item.cantidadDuracion).toFixed(2)}
            </Text>
          )}

          <Button type="danger" onClick={handleRemoveFromCartService}>
            Eliminar del carrito
          </Button>
        </Space>
      </Card>
    );
  }

  // Si el tipo de elemento no es "servicio", mostramos la vista genérica para productos
  return (
    <Card style={{ marginBottom: "16px" }}>
      <Space direction="vertical" size="small">
        <Link to={`/producto/${item.id}`}>
          <h3>{item.nombre}</h3>
        </Link>
        <Text>Precio: ${item.total}</Text>
        <Text>
          Cantidad:
          <InputNumber
            min={1}
            max={item.stock}
            value={item.cantidad}
            onChange={handleQuantityChange}
          />
        </Text>
        <Text type="secondary">Subtotal: ${item.total * item.cantidad}</Text>
        <Button type="danger" onClick={handleRemoveFromCart}>
          Eliminar del carrito
        </Button>
      </Space>
    </Card>
  );
};

export default CartItem;
