import React, { useState, useEffect } from "react";
import { Form, Input, Button, message, Modal, Upload, Card,Typography } from "antd";
import { EditOutlined, LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {
  getFirestore,
  collection,
  doc,
  getDoc,
  setDoc,
} from "firebase/firestore";
import { useUser } from "../../../context/UserContext";

const EditarTienda = () => {
  const [loading, setLoading] = useState(false);
  const [logoUrl, setLogoUrl] = useState(null); // URL de la imagen de la tienda
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editField, setEditField] = useState(null);
  const { Title, Paragraph } = Typography;
  const { userData } = useUser();

  const [tiendaData, setTiendaData] = useState({});
  const [form] = Form.useForm();

  const validateImageSquare = async (file) => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = URL.createObjectURL(file);
      image.onload = () => {
        const width = image.width;
        const height = image.height;
        if (width === height) {
          resolve();
        } else {
          reject(new Error("La imagen debe ser cuadrada."));
        }
      };
      image.onerror = () => {
        reject(new Error("Error al cargar la imagen."));
      };
    });
  };
  

  useEffect(() => {
    const fetchTiendaData = async () => {
      try {
        const db = getFirestore();
        const tiendasCollection = collection(db, "tiendas");
        const tiendaDocRef = doc(tiendasCollection, userData.uid);
        const docSnapshot = await getDoc(tiendaDocRef);

        if (docSnapshot.exists()) {
          const tiendaData = docSnapshot.data();
          // Llena el formulario con los datos de la tienda
          form.setFieldsValue({
            nombre: tiendaData.nombre || "",
            descripcion: tiendaData.descripcion || "",
            // Agrega otros campos según sea necesario
          });
          setLogoUrl(tiendaData.foto);
          setTiendaData(tiendaData);
        }
      } catch (error) {
        console.error("Error al obtener datos de la tienda:", error.message);
      }
    };

    fetchTiendaData();
  }, [userData, form]);

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handleLogoChange = async (info) => {
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
      const storage = getStorage();
      const logoRef = ref(storage, `tiendas/${userData.uid}/fotoTienda`);
      const url = await getDownloadURL(logoRef);
      setLogoUrl(url);

      // También puedes actualizar otros elementos que puedan depender de la URL de la imagen
      // Por ejemplo, podrías tener un estado de avatar y actualizarlo aquí
      // setAvatarUrl(url);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const handleEditClick = (field) => {
    setEditField(field);
    setIsEditModalVisible(true);
  };

  const handleEditModalCancel = () => {
    setIsEditModalVisible(false);
  };

  const handleEditModalFinish = async (value) => {
    setLoading(true);
    // Actualizar el campo editado en la base de datos
    try {
      const db = getFirestore();
      const tiendasCollection = collection(db, "tiendas");
      const tiendaDocRef = doc(tiendasCollection, userData.uid);

      await setDoc(
        tiendaDocRef,
        { [editField]: value[editField] },
        { merge: true }
      );

      message.success(`¡${editField} actualizado con éxito!`);
    } catch (error) {
      console.error("Error al actualizar el campo:", error.message);
      message.error("Error al actualizar. Por favor, inténtalo de nuevo.");
    } finally {
      setIsEditModalVisible(false);
    }
  };

  const renderEditableField = (field) => {
    return (
      <div>
        {tiendaData[field]}
        <Button icon={<EditOutlined />} onClick={() => handleEditClick(field)}>
          Editar
        </Button>
      </div>
    );
  };
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Subir Foto</div>
    </div>
  );
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card className="container" style={{ margin: "20px", maxWidth: "600px", minWidth:"500px" }}>
      <Title level={2}>Editar tienda</Title>
      <Form
        name="editar-tienda-form"
        initialValues={{ logo: [] }}
        form={form}
        layout="vertical"
      >
        <Form.Item label="Nombre de la Tienda" name="nombre">
          {renderEditableField("nombre")}
        </Form.Item>

        <Form.Item label="Descripción" name="descripcion">
          {renderEditableField("descripcion")}
        </Form.Item>

        <Form.Item
          name="logo"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          extra="Sube una foto de perfil"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <Upload
            name="logo"
            listType="picture-circle"
            className="avatar-uploader"
            extra="Sube una foto de perfil"
            accept="image/*"
            showUploadList={false}
            customRequest={async ({ file, onSuccess, onError }) => {
              try {
                // Validar si la imagen es cuadrada
                await validateImageSquare(file);

                // Subir la imagen del logo a Firebase Storage
                const storage = getStorage();
                const logoRef = ref(
                  storage,
                  `tiendas/${userData.uid}/fotoTienda`
                );
                await uploadBytes(logoRef, file);

                // Indicar a Ant Design que la carga fue exitosa
                onSuccess();

                // Aquí puedes realizar otras acciones después de la carga exitosa
             //   console.log("La carga se ha completado con éxito");
              } catch (error) {
                message.error('La imagen debe ser cuadrada');
                // Manejar errores en caso de que ocurra algún problema durante la carga
                console.error("Error durante la carga:", error);
                onError(error);
              }
            }}
            onChange={handleLogoChange}
          >
            {logoUrl ? (
              <img
                src={logoUrl}
                alt="avatar"
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "50%",
                  objectFit: "cover",
                }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>
      </Form>
      </Card>
  
      {/* Modal para editar campos */}
      <Modal
        title={`Editar ${editField}`}
        visible={isEditModalVisible}
        onCancel={handleEditModalCancel}
        footer={null}
      >
        <Form
          onFinish={handleEditModalFinish}
          initialValues={{ [editField]: tiendaData[editField] }}
        >
          <Form.Item
            name={editField}
            rules={[
              {
                required: true,
                message: `Por favor, ingresa el nuevo valor para ${editField}`,
              },
            ]}
          >
            <Input placeholder={`Nuevo ${editField}`} />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Actualizar
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
    
  );
};

export default EditarTienda;
