import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { collection, doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../../../firebaseConfig";
import { useUser } from "../../../context/UserContext";
import { Form, Input, Button, Card, Typography, message, Alert } from "antd";

const { Title, Paragraph } = Typography;

const DatosDePagoAVendedor = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [datosPago, setDatosPago] = useState(null);
  const [estado, setEstado] = useState("pendientecreacion");
  const { userData } = useUser();

  useEffect(() => {
    const fetchDatosPago = async () => {
      try {
        if (userData) {
          const docRef = doc(db, "datosPagoVendedor", userData.uid);
          const docSnapshot = await getDoc(docRef);
          if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            setDatosPago(data);
            setEstado(data.estado || "pendientecreacion");
            form.setFieldsValue(data);
          }
        }
      } catch (error) {
        console.error("Error al obtener los datos de pago:", error);
      }
    };

    fetchDatosPago();
  }, [userData, form]);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      await setDoc(doc(db, "datosPagoVendedor", userData.uid), {
        ...values,
        estado: "pendiente",
        comentariosRechazo: "",
      });
      message.success("Datos de pago enviados para validación");
      setEstado("pendiente");
    } catch (error) {
      console.error("Error al guardar los datos de pago:", error);
      message.error("Hubo un error al registrar los datos de pago");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card className="container" style={{ margin: "20px", maxWidth: "600px" }}>
        <Title level={2}>Registrar datos de pago</Title>
        <Paragraph>
          Para que se depositen los pagos de tus ventas es necesario, que nos
          proporciones la siguiente información
        </Paragraph>
        {estado === "rechazado" && datosPago && (
          <Alert
            message="Datos de pago rechazados"
            description={datosPago.comentariosRechazo}
            type="error"
            showIcon
          />
        )}
        {estado === "aprobado" && (
          <Alert
            message="Datos de pago aprobados"
            description="Sus datos de pago han sido aprobados. Puede editar los datos, pero estarán sujetos a una nueva validación."
            type="success"
            showIcon
          />
        )}
        {estado === "pendiente" && (
          <Alert
            message="Datos de pago pendientes de validación"
            description="Sus datos de pago están pendientes de validación. No puede modificar los datos hasta que se complete la validación."
            type="warning"
            showIcon
          />
        )}
        {estado === "pendientecreacion" && (
          <Alert
            message="Datos de pago pendientes de creación"
            description="Sus datos de pago están pendientes de creacion."
            type="warning"
            showIcon
          />
        )}
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <Form.Item
            label="Nombre"
            name="nombre"
            rules={[
              {
                required: true,
                message: "Por favor ingrese el nombre del titular de la cuenta",
              },
            ]}
          >
            <Input placeholder="Nombre " disabled={estado === "pendiente"} />
          </Form.Item>
          <Form.Item
            label="CLABE o tarjeta"
            name="clabe"
            rules={[{ required: true, message: "Por favor ingrese la CLABE" }]}
          >
            <Input placeholder="CLABE" disabled={estado === "pendiente"} />
          </Form.Item>
          <Form.Item
            label="Banco"
            name="banco"
            rules={[{ required: true, message: "Por favor ingrese el banco" }]}
          >
            <Input placeholder="Banco" disabled={estado === "pendiente"} />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              disabled={estado === "pendiente"}
            >
              {estado === "rechazado"
                ? "Reenviar para aprobación"
                : estado === "aprobado"
                ? "Actualizar"
                : "Registrar"}
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default DatosDePagoAVendedor;
