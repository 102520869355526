import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import "./Producto.css";
import {
  Avatar,
  Breadcrumb,
  Button,
  Card,
  Carousel,
  Col,
  Flex,
  List,
  Row,
  Rate,
  Input,
  Typography,
  Modal,
  InputNumber,
} from "antd";
import { db } from "../../../firebaseConfig";
import {
  doc,
  addDoc,
  collection,
  getDoc,
  getDocs,
  query,
  where,
  updateDoc,
  setDoc,
  arrayUnion,
} from "firebase/firestore";
import { useUser } from "../../../context/UserContext";
import { Toaster, toast } from "sonner";
import {
  HeartOutlined,
  HeartFilled,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import { addToCartProductoDetalle } from "../../../utils/productos/productoUtils";
import { handleFavoriteProductoAction } from "../../../utils/favoritos/favoritosUtils";
export default function Producto() {
  const { id } = useParams();
  const [producto, setProducto] = useState(null);
  const [cantidad, setCantidad] = useState(1);
  const [calificacion, setCalificacion] = useState(0);
  const [resena, setResena] = useState("");
  const [mostrarFormulario, setMostrarFormulario] = useState(false);
  const listaResenasRef = useRef(null);
  const [reseñas, setReseñas] = useState([]);
  const [calificacionPromedio, setCalificacionPromedio] = useState(0);
  const { userData, userFavorites } = useUser();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [tienda, setTienda] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    // Verificar si userFavorites no está vacío y tiene la propiedad productos definida
    if (
      userFavorites &&
      userFavorites.productos &&
      userFavorites.productos.length > 0
    ) {
      setIsFavorite(userFavorites.productos.includes(id));
    }
    if(userData){
      setMostrarFormulario(true);
    }else{
      setMostrarFormulario(false);
      setButtonDisabled(true);
    }
  }, [id, userFavorites]);

  useEffect(() => {
    const getProduct = async () => {
      const docRef = doc(db, "productos", id);
      const docSnap = await getDoc(docRef);
      const product = docSnap.data();
      product.id = docSnap.id;
      await getTienda(product.vendedorUid);
      setProducto(product);
    };
    const getTienda = async (vendedorUid) => {
      try {
        const docRef = doc(db, "tiendas", vendedorUid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const tiendaData = docSnap.data();
          tiendaData.id = docSnap.id;
          setTienda(tiendaData);
        } else {
          console.error("No se encontró la tienda");
        }
      } catch (error) {
        console.error("Error al obtener la tienda:", error);
      }
    };
    getProduct();
  }, [id]);

  const handleToggleFavorite = () => {
    if (!buttonDisabled) {
      // Si el botón no está deshabilitado
      setIsFavorite(!isFavorite); // Actualizar el estado local
      setButtonDisabled(true); // Deshabilitar el botón
      const action = isFavorite ? "remove" : "add";
      handleFavoriteProductoAction(id, action, userData);

      setTimeout(() => {
        // Habilitar el botón después de 1 segundo
        setButtonDisabled(false);
      }, 1000);
    }
  };

  const handleModalOk = () => {
    setIsModalVisible(false);
    navigate("/login");
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const handleCalificar = async () => {
    if (mostrarFormulario) {
      const nuevaResena = {
        titulo: "Nueva",
        descripcion: resena,
        calificacion,
        idProducto: id,
        idUser: userData.uid,
        nombre: userData.email,
        vendedorUid: producto.vendedorUid,
        photo: userData.photo,
      };

      // Guardar reseña en Firebase
      const reseñasCollection = collection(db, "resenas");
      await addDoc(reseñasCollection, nuevaResena);

      // Actualizar el estado de las reseñas
      setMostrarFormulario(false);
      obtenerReseñas();
    }
  };

  const obtenerReseñas = async () => {
    try {
      // Realiza una consulta para obtener solo las reseñas del producto actual
      const resenasCollection = collection(db, "resenas");
      const querySnapshot = await getDocs(
        query(resenasCollection, where("idProducto", "==", id))
      );

      // Mapea los documentos de reseñas y actualiza el estado
      const resenasData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setReseñas(resenasData);

      // Calcula el promedio de las calificaciones
      const totalCalificaciones = resenasData.reduce(
        (total, reseña) => total + reseña.calificacion,
        0
      );
      const promedio = totalCalificaciones / resenasData.length || 0;
      setCalificacionPromedio(promedio);
    } catch (error) {
      console.error("Error al obtener reseñas:", error);
    }
  };

  useEffect(() => {
    obtenerReseñas();
  }, []);

  return (
    <>
      <Toaster richColors position="top-center" />
      <Flex justify="center" vertical style={{ padding: 10 }}>
        {producto == null ? null : (
          <>
            <br />
            <Card>
              <Row>
                <Col xs={24} md={8} xl={8}>
                  <Carousel autoplay>
                    {producto.imagen.map((imagen, index) => (
                      <div className="img-container" key={index}>
                        <img alt="" src={imagen.url} width={200} />
                      </div>
                    ))}
                  </Carousel>
                  <button
                    onClick={() => handleToggleFavorite()}
                    style={{
                      position: "absolute",
                      top: 10,
                      right: 10,
                      border: "none",
                      background: "transparent",
                      cursor: "pointer",
                    }}
                    disabled={buttonDisabled} // Deshabilitar el botón si está en cooldown
                  >
                    {isFavorite ? (
                      <HeartFilled style={{ color: "red", fontSize: "26px" }} />
                    ) : (
                      <HeartOutlined
                        style={{ color: "red", fontSize: "26px" }}
                      />
                    )}
                  </button>
                </Col>
                <Col xs={24} md={16} xl={16}>
                  <Typography.Title>{producto.nombre}</Typography.Title>
                  <Rate
                    disabled
                    allowHalf
                    defaultValue={calificacionPromedio}
                  />
                  <Row>
                    <Col xs={24} md={24} lg={18}>
                      <Flex
                        justify="space-between"
                        align="center"
                        style={{ marginRight: 50 }}
                      >
                        <Typography.Paragraph strong>
                          Disponible(s): {producto.stock}
                        </Typography.Paragraph>
                        <Typography.Title level={3}>
                          ${producto.total} MXN
                        </Typography.Title>
                      </Flex>
                      <Typography.Paragraph>Acerca de:</Typography.Paragraph>
                      <Typography.Paragraph>
                        {producto.descripcion}
                      </Typography.Paragraph>
                    </Col>
                    <Col xs={24} md={24} lg={6}>
                      <Flex
                        vertical
                        justify="space-evenly"
                        align="center"
                        style={{
                          height: "100%",
                          padding: 10,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <InputNumber
                            min={1} // Valor mínimo permitido
                            max={producto.stock} // Valor máximo permitido
                            defaultValue={1} // Valor inicial predeterminado
                            style={{ width: 100 }} // Ancho del campo de entrada
                            onChange={(value) => setCantidad(value)} // Función para manejar cambios en la cantidad
                          />
                          <Button
                            type="primary"
                            onClick={() =>
                              addToCartProductoDetalle(
                                producto,
                                userData,
                                cantidad
                              )
                            } // Función para agregar al carrito con la cantidad seleccionada
                            icon={<ShoppingCartOutlined />} // Icono del botón
                          >
                            Agregar al carrito
                          </Button>
                        </div>
                      </Flex>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Typography.Title level={3} strong>
                <Link to={`/reseñas/${tienda.id}`}>
                  {tienda.nombre} <Rate disabled value={tienda.calificacion} />
                </Link>
              </Typography.Title>
            </Card>
            <Flex vertical style={{ padding: 50 }}>
              <Typography.Title level={2}>Reseñas</Typography.Title>
              {/* Formulario de calificación y reseña */}
              {mostrarFormulario && (
                <>
                  <Rate
                    value={calificacion}
                    onChange={(value) => setCalificacion(value)}
                  />
                  <Input
                    type="textarea"
                    rows={4}
                    value={resena}
                    onChange={(e) => setResena(e.target.value)}
                  />
                  <Button type="primary" onClick={handleCalificar}>
                    Calificar y dejar reseña
                  </Button>
                </>
              )}
              {/* Lista de reseñas */}
              <List
                itemLayout="horizontal"
                dataSource={reseñas}
                renderItem={(item, index) => (
                  <List.Item key={index}>
                    <List.Item.Meta
                      avatar={<Avatar src={item.photo} />}
                      title={
                        <>
                          <a href="https://ant.design">{item.nombre}</a>
                          {/* Muestra la calificación como estrellas */}
                          <Rate disabled defaultValue={item.calificacion} />
                        </>
                      }
                      description={item.descripcion}
                    />
                  </List.Item>
                )}
                ref={listaResenasRef}
              />
              {/* Muestra la calificación promedio */}
            </Flex>
          </>
        )}
      </Flex>
      <Modal
        title="Autenticación Requerida"
        open={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        okText="Iniciar Sesión"
        cancelText="Cancelar"
      >
        <p>Debes iniciar sesión para agregar productos al carrito.</p>
      </Modal>
    </>
  );
}
