import React, { useEffect, useState } from "react";
import { Table, Button, Space, message, Popconfirm, Input } from "antd";
import {
  updateDoc,
  doc,
  getFirestore,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { FrownOutlined, SmileOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const UsuariosVendedorAdmin = ({ activado }) => {
  const [usuarios, setUsuarios] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  // Filtrar categorías según el término de búsqueda
  const filteredUsuarios = usuarios.filter((usuario) =>
    usuario.username.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const fetchUsuarios = async () => {
    try {
      const db = getFirestore();
      const usuariosCollection = collection(db, "roles");
      const querySnapshot = await getDocs(
        query(
          usuariosCollection,
          where("role", "==", 1),
          where("activado", "==", activado)
        )
      );

      const usuariosData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setUsuarios(usuariosData);
    } catch (error) {
      console.error("Error al obtener usuarios:", error.message);
    }
  };

  useEffect(() => {
    fetchUsuarios();
  }, [activado]);

  const handleActivadoChange = async (userId, activado) => {
    try {
      const db = getFirestore();
      const userRef = doc(db, "roles", userId);
      await updateDoc(userRef, { activado: !activado });
      fetchUsuarios();
      message.success("Estado de activación cambiado correctamente");
    } catch (error) {
      console.error("Error al cambiar el estado de activación:", error.message);
      message.error("Error al cambiar el estado de activación");
    }
  };

  const columns = [
    {
      title: "Foto",
      dataIndex: "photo",
      key: "photo",
      render: (photoUrl) => (
        <img src={photoUrl} alt="Foto" style={{ maxWidth: 100 }} />
      ),
    },
    {
      title: "Usuario",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Telefono",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "INE frontal",
      dataIndex: "ine",
      key: "ine",
      render: (ineUrl) => (
        <a href={ineUrl} target="_blank" rel="noopener noreferrer">
           Ver archivo
        </a>
      ),
    },
    {
      title: "INE trasera",
      dataIndex: "ineTrasera",
      key: "ineTrasera",
      render: (ineTraseraUrl) => (
        <a href={ineTraseraUrl} target="_blank" rel="noopener noreferrer">
          Ver archivo
        </a>
      ),
    },
    {
      title: "Constancia de situación fiscal",
      dataIndex: "constancia",
      key: "constancia",
      render: (constanciaUrl) => (
        <a href={constanciaUrl} target="_blank" rel="noopener noreferrer">
        Ver archivo
        </a>
      ),
    },
    {
      title: "Activado",
      dataIndex: "activado",
      key: "activado",
      render: (activado, record) => (
        <span>
          {activado ? "Activo" : "Inactivo"}
          <Popconfirm
            title={`¿Estás seguro de ${activado ? "desactivar" : "activar"}?`}
            onConfirm={() => handleActivadoChange(record.id, activado)}
            okText="Sí"
            cancelText="No"
          >
            <Button type="link">{activado ? "Desactivar" : "Activar"}</Button>
          </Popconfirm>
        </span>
      ),
    },
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <h1>Vendedores</h1>
        <Input.Search
          placeholder="Buscar usuarios"
          onSearch={handleSearch}
          style={{ width: 400, marginBottom: 16 }}
        />

        <Link to={`/admin/vendedores/${activado ? "blacklist" : ""}`}>
          <Button
            type="primary"
            danger={activado}
            icon={activado ? <FrownOutlined /> : <SmileOutlined />}
            style={{ marginBottom: 16 }}
          >
            {activado ? "Ver blacklist" : "Ir a whitelist"}
          </Button>
        </Link>
      </div>

      <Table
        dataSource={filteredUsuarios}
        columns={columns}
        rowKey="id"
        pagination={{ pageSize: 10 }}
      />
    </div>
  );
};

export default UsuariosVendedorAdmin;
