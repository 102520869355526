import React, { useEffect, useRef, useState } from "react";
import { Card, Row, Col } from "antd";
import Chart from "chart.js/auto";
import { collection, getDocs, Timestamp, query, where } from "firebase/firestore";
import { db } from "../../../firebaseConfig"; // Importa tu configuración de Firebase aquí
import GraficoVentasPorHora from "../../../components/Graficas/GraficoVentasPorHora";
import GraficoVentasPorDiaMesActual from "../../../components/Graficas/GraficoVentasPorDiaMesActual";
import GraficoVentasPorMesAnioActual from "../../../components/Graficas/GraficoVentasPorMesAnioActual";

const EstadisticasAdmin = () => {
  const [totalDineroGeneradoHoy, setTotalDineroGeneradoHoy] = useState(0);
  const [totalComisionesPlataformaHoy, setTotalComisionesPlataformaHoy] =
    useState(0);
  const [
    totalComisionOpenpayMesesSinInteresesHoy,
    setTotalComisionOpenpayMesesSinInteresesHoy,
  ] = useState(0);
  const [totalDineroGeneradoMes, setTotalDineroGeneradoMes] = useState(0);
  const [totalComisionesPlataformaMes, setTotalComisionesPlataformaMes] =
    useState(0);
  const [
    totalComisionOpenpayMesesSinInteresesMes,
    setTotalComisionOpenpayMesesSinInteresesMes,
  ] = useState(0);
  const [totalDineroGeneradoAnio, setTotalDineroGeneradoAnio] = useState(0);
  const [totalComisionesPlataformaAnio, setTotalComisionesPlataformaAnio] =
    useState(0);
  const [
    totalComisionOpenpayMesesSinInteresesAnio,
    setTotalComisionOpenpayMesesSinInteresesAnio,
  ] = useState(0);


  const [ventasPorHora, setVentasPorHora] = useState(null);
  const [ventasPorDia, setVentasPorDia] = useState(null);
  const [ventasPorMes, setVentasPorMes] = useState(null);

  const obtenerVentasPorHoraHoy = async () => {
    const hoy = new Date();
    const inicioDia = new Date(hoy.getFullYear(), hoy.getMonth(), hoy.getDate(), 0, 0, 0);
    const finDia = new Date(hoy.getFullYear(), hoy.getMonth(), hoy.getDate(), 23, 59, 59);
  
    const querySnapshot = await getDocs(
      query(
        collection(db, "pedidos"),
        where("fechaCreacion", ">=", inicioDia),
        where("fechaCreacion", "<=", finDia)
      )
    );
  
    const ventasPorHora = {};
  
    querySnapshot.forEach((doc) => {
      const pedido = doc.data();
      const fechaPedido = pedido.fechaCreacion.toDate();
      const horaPedido = fechaPedido.getHours();
  
      // Guardar el total de ventas correspondiente a la hora del pedido
      if (!ventasPorHora[horaPedido]) {
        ventasPorHora[horaPedido] = {
          totalVentas: 0,
          totalComisionesPlataforma: 0,
          totalComisionesOpenPay: 0
        };
      }
  
      ventasPorHora[horaPedido].totalVentas += pedido.totalGeneral;
  
      if (pedido.totalPagoOpenpayMeses !== 0) {
        ventasPorHora[horaPedido].totalComisionesPlataforma -= Number(pedido.totalPagoOpenpayMeses);
      }
  
      ventasPorHora[horaPedido].totalComisionesPlataforma = (Number(ventasPorHora[horaPedido].totalComisionesPlataforma) + Number(pedido.totalComisiones.impuestoMesesSinIntereses) + Number(pedido.totalComisiones.impuestoPlataforma)).toFixed(2);
  
      ventasPorHora[horaPedido].totalComisionesOpenPay = (Number(ventasPorHora[horaPedido].totalComisionesOpenPay) + Number(pedido.totalPagoOpenpayMeses)).toFixed(2);
    });

    return ventasPorHora;
  };
  
  
  const obtenerVentasPorDiaMesActual = async () => {
    const hoy = new Date();
    const primerDiaMes = new Date(hoy.getFullYear(), hoy.getMonth(), 1);
    const ultimoDiaMes = new Date(hoy.getFullYear(), hoy.getMonth() + 1, 0);
  
    const querySnapshot = await getDocs(
      query(
        collection(db, "pedidos"),
        where("fechaCreacion", ">=", primerDiaMes),
        where("fechaCreacion", "<=", ultimoDiaMes)
      )
    );
  
    const ventasPorDia = {};
  
    querySnapshot.forEach((doc) => {
      const pedido = doc.data();
      const fechaPedido = pedido.fechaCreacion.toDate();
      const diaPedido = fechaPedido.getDate();
  
      // Guardar el total de ventas correspondiente al día del pedido
      if (!ventasPorDia[diaPedido]) {
        ventasPorDia[diaPedido] = {
          totalVentas: 0,
          totalComisionesPlataforma: 0,
          totalComisionesOpenPay: 0
        };
      }
  
      ventasPorDia[diaPedido].totalVentas += pedido.totalGeneral;
  
      if (pedido.totalPagoOpenpayMeses !== 0) {
        ventasPorDia[diaPedido].totalComisionesPlataforma -= Number(pedido.totalPagoOpenpayMeses);
      }
  
      ventasPorDia[diaPedido].totalComisionesPlataforma = (Number(ventasPorDia[diaPedido].totalComisionesPlataforma) + Number(pedido.totalComisiones.impuestoMesesSinIntereses) + Number(pedido.totalComisiones.impuestoPlataforma)).toFixed(2);
  
      ventasPorDia[diaPedido].totalComisionesOpenPay = (Number(ventasPorDia[diaPedido].totalComisionesOpenPay) + Number(pedido.totalPagoOpenpayMeses)).toFixed(2);
    });

    return ventasPorDia;
  };

  const obtenerVentasPorMesAñoActual = async () => {
    const hoy = new Date();
    const primerDiaAño = new Date(hoy.getFullYear(), 0, 1);
    const ultimoDiaAño = new Date(hoy.getFullYear(), 11, 31);

    const querySnapshot = await getDocs(
        query(
            collection(db, "pedidos"),
            where("fechaCreacion", ">=", primerDiaAño),
            where("fechaCreacion", "<=", ultimoDiaAño)
        )
    );

    const ventasPorMes = {};

    querySnapshot.forEach((doc) => {
        const pedido = doc.data();
        const fechaPedido = pedido.fechaCreacion.toDate();
        const mesPedido = fechaPedido.getMonth();

        // Guardar el total de ventas correspondiente al mes del pedido
        if (!ventasPorMes[mesPedido]) {
            ventasPorMes[mesPedido] = {
                totalVentas: 0,
                totalComisionesPlataforma: 0,
                totalComisionesOpenPay: 0
            };
        }

        ventasPorMes[mesPedido].totalVentas += pedido.totalGeneral;

        if (pedido.totalPagoOpenpayMeses !== 0) {
            ventasPorMes[mesPedido].totalComisionesPlataforma -= Number(pedido.totalPagoOpenpayMeses);
        }

        ventasPorMes[mesPedido].totalComisionesPlataforma = (Number(ventasPorMes[mesPedido].totalComisionesPlataforma) + Number(pedido.totalComisiones.impuestoMesesSinIntereses) + Number(pedido.totalComisiones.impuestoPlataforma)).toFixed(2);

        ventasPorMes[mesPedido].totalComisionesOpenPay = (Number(ventasPorMes[mesPedido].totalComisionesOpenPay) + Number(pedido.totalPagoOpenpayMeses)).toFixed(2);
    });

    return ventasPorMes;
};

  
  useEffect(() => {
    const obtenerDatos = async () => {
      const ventasHora = await obtenerVentasPorHoraHoy();
      setVentasPorHora(ventasHora);

      const ventasDia = await obtenerVentasPorDiaMesActual();
      setVentasPorDia(ventasDia);

      const ventasMes = await      obtenerVentasPorMesAñoActual();
      setVentasPorMes(ventasMes);
 

     // console.log(ventasMes);
    };
    obtenerDatos();
  }, []);


  useEffect(() => {
    // Función para obtener los pedidos de hoy
    const obtenerPedidosHoy = async () => {
      const querySnapshot = await getDocs(collection(db, "pedidos"));
      let totalHoy = 0;
      let totalComisionesPlataforma = 0;
      let totalComisionOpenpayMesesSinIntereses = 0;
      querySnapshot.forEach((doc) => {
        const pedido = doc.data();
       
        // Convertir el Timestamp a un objeto de fecha
        const fechaPedido = pedido.fechaCreacion.toDate();
        const hoy = new Date();
        // Si el pedido fue realizado hoy, sumar el total al total de dinero generado hoy
        if (
          fechaPedido.getDate() === hoy.getDate() &&
          fechaPedido.getMonth() === hoy.getMonth() &&
          fechaPedido.getFullYear() === hoy.getFullYear()
        ) {
          totalHoy += pedido.totalGeneral;
          // Si totalPagoOpenpay es distinto de 0, descontar su valor de totalComisiones
          if (pedido.totalPagoOpenpayMeses !== 0) {
            totalComisionesPlataforma -= pedido.totalPagoOpenpayMeses;
          }

          // Sumar totalComisionesPlataforma y totalComisionOpenpayMesesSinIntereses
          totalComisionesPlataforma +=
            pedido.totalComisiones["impuestoMesesSinIntereses"] +
            pedido.totalComisiones["impuestoPlataforma"];

          totalComisionOpenpayMesesSinIntereses +=
            pedido.totalPagoOpenpayMeses;
        }
      });

      setTotalDineroGeneradoHoy(totalHoy);
      setTotalComisionesPlataformaHoy(totalComisionesPlataforma);
      setTotalComisionOpenpayMesesSinInteresesHoy(
        totalComisionOpenpayMesesSinIntereses
      );
    };

    obtenerPedidosHoy();
  }, []);

  // Función para obtener los pedidos del mes actual
  useEffect(() => {
    const obtenerPedidosMes = async () => {
      // Obtener la fecha de inicio y fin del mes actual
      const hoy = new Date();
      const primerDiaMes = new Date(hoy.getFullYear(), hoy.getMonth(), 1);
      const ultimoDiaMes = new Date(hoy.getFullYear(), hoy.getMonth() + 1, 0);

      const querySnapshot = await getDocs(collection(db, "pedidos"));
      let totalMes = 0;
      let totalComisionesPlataforma = 0;
      let totalComisionOpenpayMesesSinIntereses = 0;

      querySnapshot.forEach((doc) => {
        const pedido = doc.data();
        // Convertir el Timestamp a un objeto de fecha
        const fechaPedido = pedido.fechaCreacion.toDate();
        // Si el pedido fue realizado este mes, sumar el total al total de dinero generado este mes
        if (fechaPedido >= primerDiaMes && fechaPedido <= ultimoDiaMes) {
          totalMes += pedido.totalGeneral;
          // Si totalPagoOpenpay es distinto de 0, descontar su valor de totalComisiones
          if (pedido.totalPagoOpenpay !== 0) {
            totalComisionesPlataforma -= pedido.totalPagoOpenpayMeses;
          }

          // Sumar totalComisionesPlataforma y totalComisionOpenpayMesesSinIntereses
          totalComisionesPlataforma +=
            pedido.totalComisiones["impuestoMesesSinIntereses"] +
            pedido.totalComisiones["impuestoPlataforma"];
          totalComisionOpenpayMesesSinIntereses +=
            pedido.totalPagoOpenpayMeses;
        }
      });

      setTotalDineroGeneradoMes(totalMes);
      setTotalComisionesPlataformaMes(totalComisionesPlataforma);
      setTotalComisionOpenpayMesesSinInteresesMes(
        totalComisionOpenpayMesesSinIntereses
      );
    };

    obtenerPedidosMes();
  }, []);

  useEffect(() => {
    const obtenerPedidosAnio = async () => {
      // Obtener la fecha de inicio y fin del año actual
      const hoy = new Date();
      const primerDiaAnio = new Date(hoy.getFullYear(), 0, 1);
      const ultimoDiaAnio = new Date(hoy.getFullYear(), 11, 31);

      const querySnapshot = await getDocs(collection(db, "pedidos"));
      let totalAnio = 0;
      let totalComisionesPlataforma = 0;
      let totalComisionOpenpayMesesSinIntereses = 0;

      querySnapshot.forEach((doc) => {
        const pedido = doc.data();
        // Convertir el Timestamp a un objeto de fecha
        const fechaPedido = pedido.fechaCreacion.toDate();
        // Si el pedido fue realizado este año, sumar el total al total de dinero generado este año
        if (fechaPedido >= primerDiaAnio && fechaPedido <= ultimoDiaAnio) {
          totalAnio += pedido.totalGeneral;

          // Si totalPagoOpenpay es distinto de 0, descontar su valor de totalComisiones
          if (pedido.totalPagoOpenpayMeses !== 0) {
            totalComisionesPlataforma -= pedido.totalPagoOpenpayMeses;
          }

          // Sumar totalComisionesPlataforma y totalComisionOpenpayMesesSinIntereses
          totalComisionesPlataforma +=
            pedido.totalComisiones["impuestoMesesSinIntereses"] +
            pedido.totalComisiones["impuestoPlataforma"];
          totalComisionOpenpayMesesSinIntereses +=
            pedido.totalPagoOpenpayMeses;
        }
      });

      setTotalDineroGeneradoAnio(totalAnio);
      setTotalComisionesPlataformaAnio(totalComisionesPlataforma);
      setTotalComisionOpenpayMesesSinInteresesAnio(
        totalComisionOpenpayMesesSinIntereses
      );
    };

    obtenerPedidosAnio();
  }, []);

  return (
    <div>
      <h1>Dashboard</h1>
      <Row gutter={16}>
        <Col span={8}>
          <Card title="Ventas hoy">
            {totalDineroGeneradoHoy.toFixed(2)} MXN
          </Card>
        </Col>
        <Col span={8}>
          <Card title="Ganancia comisiones hoy">
            {totalComisionesPlataformaHoy.toFixed(2)} MXN
          </Card>
        </Col>
        <Col span={8}>
          <Card title="Pago comisión OpenPay meses sin intereses hoy">
            {totalComisionOpenpayMesesSinInteresesHoy.toFixed(2)} MXN
          </Card>
        </Col>

        <GraficoVentasPorHora datos={ventasPorHora} />
        <Col span={8}>
          <Card title="Ventas este mes">
            {totalDineroGeneradoMes.toFixed(2)} MXN
          </Card>
        </Col>
        <Col span={8}>
          <Card title="Ganancia comisiones mes">
            {totalComisionesPlataformaMes.toFixed(2)} MXN
          </Card>
        </Col>
        <Col span={8}>
          <Card title="Pago comisión OpenPay meses sin intereses mes">
            {totalComisionOpenpayMesesSinInteresesMes.toFixed(2)} MXN
          </Card>
        </Col>
        <GraficoVentasPorDiaMesActual datos={ventasPorDia} />

        <Col span={8}>
          <Card title="Ventas este año">
            {totalDineroGeneradoAnio.toFixed(2)} MXN
          </Card>
        </Col>
        <Col span={8}>
          <Card title="Ganancia comisiones año">
            {totalComisionesPlataformaAnio.toFixed(2)} MXN
          </Card>
        </Col>
        <Col span={8}>
          <Card title="Pago comisión OpenPay meses sin intereses año">
            {totalComisionOpenpayMesesSinInteresesAnio.toFixed(2)} MXN
          </Card>
        </Col>

        <GraficoVentasPorMesAnioActual datos={ventasPorMes} />
      </Row>
    </div>
  );
};

export default EstadisticasAdmin;
