import React from "react";
import { List, Typography, Row, Col, Button } from "antd";
import { useNavigate } from "react-router-dom";
const { Text } = Typography;

const ListaItemCheckout = ({ item }) => {
  const navigate = useNavigate();
  const handleNavigateToService = () => {
    navigate(`/servicio/${item.id}`);
  };
  return (
    <List.Item key={item.id}>
      {item.tipo === "producto" ? (
        <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
          <div style={{ marginRight: 16 }}>
            <img
              src={item.imagen[0].url}
              alt={item.nombre}
              style={{ width: 50, height: 50, borderRadius: "50%" }}
            />
          </div>
          <div style={{ flex: 1 }}>
            <List.Item.Meta
              title={item.nombre}
              description={`Cantidad: ${item.cantidad}`}
            />
            {/* Contenido específico para productos */}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p>${item.total}</p>
              <Text type="success">
                <strong> ${item.total * item.cantidad}</strong>
              </Text>
            </div>
            {/* Agrega más detalles o componentes específicos para productos aquí */}
          </div>
        </div>
      ) : (
        <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
          <div style={{ marginRight: 16 }}>
            <img
              src={item.imagen[0].url}
              alt={item.nombre}
              style={{ width: 50, height: 50, borderRadius: "50%" }}
            />
          </div>
          <div style={{ flex: 1 }}>
            <List.Item.Meta
              title={item.nombre}
              description={item.stock && `Cantidad: ${item.cantidad}`}
            />

            {/* Contenido específico para productos */}
            <Row gutter={[16, 16]}>
              <Col xs={12} sm={12} md={12} lg={12} xl={4}>
                <p>${item.total}</p>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={4}>
                <p>{item.duracion}</p>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={4}>
                <p>
                  <strong>{item.diaSeleccionado}</strong>
                </p>
              </Col>
              {item.duracion !== "unico" && (
                <Col xs={12} sm={24} md={12} lg={12} xl={4}>
                  <p>Tiempo: {item.cantidadDuracion}</p>
                </Col>
              )}
              <Col xs={24} sm={24} md={24} lg={24} xl={4}>
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={24}>
                    {item.stock ? (
                      item.duracion === "unico" ? (
                        <Text type="success">
                          <strong>
                            {" "}
                            ${parseFloat(item.total * 1).toFixed(2)}
                          </strong>
                        </Text>
                      ) : (
                        <Text type="success">
                          <strong>
                            {parseFloat(
                              item.total * item.cantidad * item.cantidadDuracion
                            ).toFixed(2)}
                          </strong>
                        </Text>
                      )
                    ) : item.duracion === "unico" ? (
                      <Text type="success">
                        <strong>
                          {" "}
                          ${parseFloat(item.total * 1).toFixed(2)}
                        </strong>
                      </Text>
                    ) : (
                      <Text type="success">
                        <strong>
                          {parseFloat(
                            item.total * item.cantidadDuracion
                          ).toFixed(2)}
                        </strong>
                      </Text>
                    )}
                  </Col>
                </Row>
              </Col>

              <Button
  type="primary"
  style={{
    backgroundColor: "#f3ff10",
    color: "#111",
    alignSelf: "flex-end",
  }}
  onClick={handleNavigateToService}
>
  Editar servicio
</Button>
            </Row>
          </div>
        </div>
      )}
    </List.Item>
  );
};

export default ListaItemCheckout;
