import React from "react";
import { Modal, Form, Input, Button, Space } from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import PhotoUploader from "../PhotoUploader/PhotoUploader";
import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore";

export const AddCategoriaModal = ({
  visible,
  onCancel,
  onOk,
  imageUrl,
  form,
  setImageUrl,
}) => {
  return (
    <Modal
      title="Agregar Categoría"
      open={visible}
      onOk={onOk}
      onCancel={onCancel}
    >
      <Form form={form} layout="vertical" name="categoria_form">
        <Form.Item
          label="Nombre"
          name="nombre"
          rules={[
            {
              required: true,
              message: "Por favor, ingresa el nombre de la categoría",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <PhotoUploader setImageUrl={setImageUrl} imageUrl={imageUrl} />

        <Form.List name="subcategorias">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <Space key={key} style={{ display: "flex", marginBottom: 8 }}>
                  <Form.Item
                    {...restField}
                    name={[name, "subcategoria"]}
                    fieldKey={[fieldKey, "subcategoria"]}
                    label={key === 0 ? "Subcategorías" : ""}
                    rules={[
                      { required: true, message: "Ingresa la subcategoría" },
                    ]}
                    style={{ marginBottom: 0, marginRight: 8, flex: 1 }}
                  >
                    <Input />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                >
                  Agregar Subcategoría
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  );
};

const eliminarSubcategoria = async (categoriaId, subcategoriaIndex) => {
    try {
      const db = getFirestore();
      const categoriaDocRef = doc(db, "categorias", categoriaId);
  
      // Obtiene los datos actuales de la categoría
      const categoriaDoc = await getDoc(categoriaDocRef);
      const categoriaData = categoriaDoc.data();
  
      // Elimina la subcategoría por su índice
      categoriaData.subcategorias.splice(subcategoriaIndex, 1);
  
      // Actualiza los datos de la categoría en Firestore
      await updateDoc(categoriaDocRef, categoriaData);
    } catch (error) {
      console.error("Error al eliminar subcategoría:", error);
    }
  };
  

export const EditCategoriaModal = ({
  visible,
  onCancel,
  onOk,
  form,
  imageUrl,
  setImageUrl,
  categoriaSeleccionada,
}) => {
  return (
    <Modal
      title="Editar Categoría"
      open={visible}
      onOk={onOk}
      onCancel={onCancel}
    >
      <Form form={form} layout="vertical" name="categoria_form">
        <Form.Item
          label="Nombre"
          name="nombre"
          rules={[
            {
              required: true,
              message: "Por favor, ingresa el nombre de la categoría",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <PhotoUploader setImageUrl={setImageUrl} imageUrl={imageUrl} />

        <Form.List
          name="subcategorias"
          initialValue={categoriaSeleccionada.subcategorias || []}
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <Space key={key} style={{ display: "flex", marginBottom: 8 }}>
                  <Form.Item
                    {...restField}
                    name={[name, "subcategoria"]}
                    fieldKey={[fieldKey, "subcategoria"]}
                    label={key === 0 ? "Subcategorías" : ""}
                    rules={[
                      { required: true, message: "Ingresa la subcategoría" },
                    ]}
                    style={{ marginBottom: 0, marginRight: 8, flex: 1 }}
                  >
                    <Input />
                  </Form.Item>
                  <MinusCircleOutlined
                    onClick={() => {
                      const subcategoriaIndex = key;
                      console.log(
                        "Índice de subcategoría a eliminar:",
                        subcategoriaIndex
                      );
                      eliminarSubcategoria(
                        categoriaSeleccionada.id,
                        subcategoriaIndex
                      );
                      remove(name);
                    }}
                  />
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                >
                  Agregar Subcategoría
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  );
};
