import React from 'react';

import { Button } from 'antd';
import { useNavigate } from 'react-router-dom/dist';

const ButtonVolverACheckout = () => {
    const navigation = useNavigate();

  // Función para manejar el clic en el botón de volver
  const handleGoBack = () => {
    navigation('/checkout'); // Navega hacia atrás en el historial de navegación
  };

  return (
    <Button type="default" onClick={handleGoBack}>
      Volver a finalizar pedido
    </Button>
  );
};

export default ButtonVolverACheckout;
