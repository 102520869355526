import React, { useState } from "react";
import { Form, Input, Button, message, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import {
  getFirestore,
  collection,
  doc,
  setDoc,
  getDoc,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { Link, useNavigate } from "react-router-dom";
import { useUser } from "../../../context/UserContext";
import "./CrearTienda.scss";
import MapGoogle from "../../../components/MapGoogle/MapGoogle";
import PhotoUploader from "../../../components/PhotoUploader/PhotoUploader";

const CrearTienda = () => {
  const [loading, setLoading] = useState(false);
  const { userData } = useUser();
  const [imageUrl, setImageUrl] = useState(null);
  const [markerPosition, setMarkerPosition] = useState(null);

  if (navigator.geolocation && markerPosition === null) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setMarkerPosition({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      },
      (error) => {
        console.error(error.message);
      }
    );
  }

  const navigate = useNavigate();

  

  
  

  const onFinish = async (values) => {
    setLoading(true);
   // console.log(imageUrl);
    try {
      // Obtener una instancia de Firestore
      const db = getFirestore();
      // Obtener el ID de usuario del usuario autenticado
      const userId = userData.uid;

      // Crear la tienda en la colección 'tiendas'
      const tiendasCollection = collection(db, "tiendas");
      const tiendaDocRef = doc(tiendasCollection, userId);

      // Subir la foto de la tienda a Firebase Storage
      const storage = getStorage();
      const fotoTiendaRef = ref(storage, `tiendas/${userId}/fotoTienda`);
      await uploadBytes(fotoTiendaRef, values.photo[0].originFileObj);

      // Obtener el enlace de descarga de la foto de la tienda
      const fotoTiendaURL = await getDownloadURL(fotoTiendaRef);

      const comisionesDoc = await getDoc(
        doc(db, "configuraciones", "comisiones")
      );
      const comisionesData = comisionesDoc.data();

      // Puedes personalizar los datos de la tienda según tus necesidades
      if (
        values.nombre &&
        values.descripcion &&
        fotoTiendaURL &&
        markerPosition &&
        comisionesData &&
        comisionesData.comisionPlataforma
      ) {
        // Todos los datos están cargados, proceder con setDoc
        await setDoc(tiendaDocRef, {
          nombre: values.nombre,
          descripcion: values.descripcion,
          foto: fotoTiendaURL,
          ubicacion: markerPosition,
          comisionPlataforma: comisionesData.comisionPlataforma,
          activado: true,
        });
        message.success("Tienda creada exitosamente");
      } else {
        message.error("La tienda no se pudo crear faltaron cargar datos");
      }

      setLoading(false);

      navigate("/dashboard"); // Redirigir a la página de dashboard u otra después de crear la tienda
    } catch (error) {
      setLoading(false);
      message.error("Error al crear la tienda. Por favor, inténtalo de nuevo.");
      console.error(error.message);
    }
  };

  const handleMapClick = (event) => {
    setMarkerPosition({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
   // console.log("afuera" + event.latLng.lat());
  };



  return (
    <div className="crear-tienda-container">
      <Form
        name="crear-tienda"
        onFinish={onFinish}
        scrollToFirstError
        className="crear-tienda-form"
        layout="vertical"
      >
        <h1 className="crear-tienda-title">Crear tienda</h1>
        <label>
          Bienvenido nuevo vendedor, por favor sube los datos de tu tienda para
          que tus clientes te puedan contactar, ¡gracias por unirte!
        </label>

        <PhotoUploader  setImageUrl={setImageUrl} imageUrl={imageUrl}/>

        <Form.Item
          name="nombre"
          label="Nombre"
          rules={[
            {
              required: true,
              message: "Por favor, ingresa el nombre de tu tienda",
            },
          ]}
        >
          <Input placeholder="Nombre de la Tienda" />
        </Form.Item>

        <Form.Item
          name="descripcion"
          label="Descripción"
          rules={[
            {
              required: true,
              message: "Por favor, ingresa una descripción de tu tienda",
            },
          ]}
        >
          <Input.TextArea placeholder="Descripción de la Tienda" />
        </Form.Item>

        <MapGoogle
          onMapClick={handleMapClick}
          markerPosition={markerPosition}
        />
        <Form.Item>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              className="crear-tienda-form-button"
              style={{ margin: "5px" }}
            >
              Crear Tienda
            </Button>
            <Link to={`/`}>
              <Button type="link">Volver a inicio</Button>
            </Link>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CrearTienda;
