import React from "react";
import { Badge, Button } from "antd";
import { HeartOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const BtnFavoritos = ({ favoriteItems, disabled, text, onClick}) => {
    // Verifica si favoriteItems es un objeto y tiene las propiedades productos y servicios
    const countProductosFavoritos = favoriteItems && favoriteItems.productos ? favoriteItems.productos.length : 0;
    const countServiciosFavoritos = favoriteItems && favoriteItems.servicios ? favoriteItems.servicios.length : 0;

  return (
    <Badge count={countProductosFavoritos + countServiciosFavoritos} style={{marginLeft: "10px"}}>
      <Link to="/favoritos">
        <Button onClick={onClick} icon={<HeartOutlined />} disabled={disabled} >{text === false ? text : "Ir a ver mis favoritos"}</Button>
      </Link>
    </Badge>
  );
};

export default BtnFavoritos;
