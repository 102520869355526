import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  message,
  Space,
  Popconfirm,
  Modal,
  Descriptions,
  Image,
} from "antd";
import {
  getFirestore,
  collection,
  getDocs,
  query,
  where,
  doc,
  updateDoc,
} from "firebase/firestore";

const VendedoresAdmin = () => {
  const [vendedores, setVendedores] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedVendedor, setSelectedVendedor] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    const db = getFirestore();
    const vendedoresCollection = collection(db, "roles"); // Asegúrate de tener la colección correcta
    const q = query(vendedoresCollection, where("role", "==", 1), where("activado", "==", false));
    const vendedoresSnapshot = await getDocs(q);
    const vendedoresData = vendedoresSnapshot.docs.map((doc) => ({
      key: doc.id,
      ...doc.data(),
    }));

  //  console.log(vendedoresData);
    const vendedoresFiltrados = vendedoresData.filter((vendedor) => {
      return vendedor.aprobado === undefined;
    });
    setVendedores(vendedoresFiltrados);
    setLoading(false);
  };

  const handleAprobarVendedor = async (record) => {
    try {
      const db = getFirestore();
      const vendedorDocRef = doc(db, "roles", record.key);
      await updateDoc(vendedorDocRef, { aprobado: true, activado: true});
      setModalVisible(false);
      message.success("Vendedor aprobado correctamente");
      fetchData();
    } catch (error) {
      console.error("Error al aprobar vendedor:", error);
    }
  };

  const handleDenegarVendedor = async (record) => {
    try {
      const db = getFirestore();
      const vendedorDocRef = doc(db, "roles", record.key);
      await updateDoc(vendedorDocRef, { aprobado: false });
      setModalVisible(false);
      message.success("Vendedor denegado correctamente");
      fetchData();
    } catch (error) {
      console.error("Error al denegar vendedor:", error);
    }
  };

  const handleVerDetalles = (record) => {
    setSelectedVendedor(record);
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setSelectedVendedor(null);
    setModalVisible(false);
  };

  const constanciaSitFiscalUrl = selectedVendedor?.constancia || "";
  const ineUrl = selectedVendedor?.ine || "";
  const ineTraseraUrl = selectedVendedor?.ineTrasera || "";
  return (
    <div>
      <h1 style={{textAlign:"center"}}>Listado de vendedores pendientes</h1>
      <Table
        dataSource={vendedores}
        columns={[
          {
            title: "Foto",
            dataIndex: "photo",
            key: "photo",
            render: (photo) => (
              <img
                src={photo}
                alt="photo"
                style={{ width: "50px", height: "50px", objectFit: "cover" }}
              />
            ),
          },
          {
            title: "Email",
            dataIndex: "email",
            key: "email",
          },
          {
            title: "Nombre",
            dataIndex: "username",
            key: "username",
          },

          {
            title: "Acciones",
            dataIndex: "acciones",
            key: "acciones",
            render: (_, record) => (
              <Space>
                <Button
                  type="primary"
                  onClick={() => handleVerDetalles(record)}
                >
                  Aprobar o denegar
                </Button>
              </Space>
            ),
          },
        ]}
        loading={loading}
      />

      {/* Modal para ver detalles del vendedor */}
      <Modal
        title="Detalles del Vendedor"
        visible={modalVisible}
        onCancel={handleModalClose}
        footer={[
          <Button
            key="aprobar"
            type="primary"
            onClick={() => handleAprobarVendedor(selectedVendedor)}
          >
            Aprobar
          </Button>,
          <Button
            key="denegar"
            danger
            onClick={() => handleDenegarVendedor(selectedVendedor)}
          >
            Denegar
          </Button>,
          <Button key="close" onClick={handleModalClose}>
            Cerrar
          </Button>,
        ]}
      >
        {selectedVendedor && (
          <Descriptions column={1}>
            <Descriptions.Item label="Avatar">
              <Image
                src={selectedVendedor.photo}
                alt="Avatar"
                width={80}
                height={80}
              />
            </Descriptions.Item>
            <Descriptions.Item label="Email">
              {selectedVendedor.email}
            </Descriptions.Item>
            <Descriptions.Item label="Nombre">
              {selectedVendedor.username}
            </Descriptions.Item>
            {/* Otras descripciones según tus campos */}
            <Descriptions.Item label="Constancia de situación fiscal">
              <a
                href={constanciaSitFiscalUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                Ver constancia
              </a>
            </Descriptions.Item>
            <Descriptions.Item label="INE frontal">
              <a href={ineUrl} target="_blank" rel="noopener noreferrer">
                Ver INE
              </a>
            </Descriptions.Item>
            <Descriptions.Item label="INE trasera">
              <a href={ineTraseraUrl} target="_blank" rel="noopener noreferrer">
                Ver INE
              </a>
            </Descriptions.Item>
          </Descriptions>
        )}
      </Modal>
    </div>
  );
};

export default VendedoresAdmin;
