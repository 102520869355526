// servicioUtils.js
import {
  getDoc,
  doc,
  updateDoc,
  setDoc,
  arrayUnion
} from "firebase/firestore";
import { db } from "../../firebaseConfig";
import { toast } from "sonner";

export const addToCartService = async (servicio, userData) => {
  try {
    if (!userData) {
      toast.error("Inicia sesión para crear un carrito.");
      return;
    }

    // Obtener el carrito del usuario desde la base de datos
    const userCartRef = doc(db, "carritos", userData.uid);
    const userCartDoc = await getDoc(userCartRef);
    console.log(userCartDoc);
    if (userCartDoc.exists()) {
      // Si el carrito ya existe, verificar si el servicio está presente
      const userCartData = userCartDoc.data();
      let existingProductIndex = -1;

      if (userCartData.servicios) {
        existingProductIndex = userCartData.servicios.findIndex(
          (cartProduct) => cartProduct.id === servicio.id
        );
      } else {
        // Si no hay ningún servicio en el carrito, crear el array de servicios
        await userCartDoc.ref.update({ servicios: [] });
      }

      if (existingProductIndex === -1) {
        // El servicio no está presente en el carrito, agregarlo
        await userCartDoc.ref.update({
          servicios: [...userCartData.servicios, servicio],
        });
      } else {
        // El servicio ya está en el carrito, puedes manejar esta situación según tus necesidades
        console.log("El servicio ya está en el carrito.");
      }

      if (existingProductIndex !== -1) {
        // Si el servicio ya está en el carrito, incrementar la cantidad en uno
        const updatedCartItems = [...userCartDoc.data().servicios];
        updatedCartItems[existingProductIndex].cantidad += 1;
        // Actualizar el carrito en la base de datos
        await updateDoc(userCartRef, {
          servicios: updatedCartItems,
        });
      } else {
        // Si el servicio no está en el carrito, agregar uno nuevo
        const updatedCartItems = arrayUnion({
          id: servicio.id,
          cantidad: 1,
        });
        // Actualizar el carrito en la base de datos
        await updateDoc(userCartRef, {
          servicios: updatedCartItems,
        });
      }
    } else {
      // Si el carrito no existe, crear uno nuevo con el servicio
      await setDoc(userCartRef, {
        servicios: [{ id: servicio.id, cantidad: 1 }],
      });
    }
  } catch (error) {
    console.error("Error al agregar el servicio al carrito:", error.message);
  }
};

export const addToCartServicioDetalle = async (
  servicio,
  userData,
  cantidad,
  cantidadDuracion,
  diaSeleccionado,
  horaInicioSeleccionada
) => {
  try {
    if (!userData) {
      toast.error("Inicia sesión para crear un carrito.");
      return;
    }
   // Verificar si los campos obligatorios están presentes
   if (!servicio || !cantidad || !cantidadDuracion || !diaSeleccionado || !horaInicioSeleccionada) {
    toast.error("Por favor, completa todos los campos obligatorios.");
    return;
  }


    const userCartRef = doc(db, "carritos", userData.uid);
    const userCartDoc = await getDoc(userCartRef);

    if (userCartDoc.exists()) {
      const existingProductIndex = userCartDoc
        .data()
        .servicios.findIndex((cartProduct) => cartProduct.id === servicio.id);

      let updatedCartItems;

      if (existingProductIndex !== -1) {
        updatedCartItems = [...userCartDoc.data().servicios];
        updatedCartItems[existingProductIndex].cantidad = cantidad;

        if (servicio.stock) {
          if (servicio.duracion === "unico") {
            updatedCartItems[existingProductIndex] = {
              ...updatedCartItems[existingProductIndex],
              cantidad: cantidad,
              diaSeleccionado: diaSeleccionado,
              horaInicioSeleccionada: horaInicioSeleccionada,
            };
          } else {
            updatedCartItems[existingProductIndex] = {
              ...updatedCartItems[existingProductIndex],
              cantidad: cantidad,
              cantidadDuracion: cantidadDuracion,
              diaSeleccionado: diaSeleccionado,
              horaInicioSeleccionada: horaInicioSeleccionada,
            };
          }
        } else {
          if (servicio.duracion === "unico") {
            updatedCartItems[existingProductIndex] = {
              ...updatedCartItems[existingProductIndex],
              diaSeleccionado: diaSeleccionado,
              horaInicioSeleccionada: horaInicioSeleccionada,
            };
          } else {
            updatedCartItems[existingProductIndex] = {
              ...updatedCartItems[existingProductIndex],
              cantidadDuracion: cantidadDuracion,
              diaSeleccionado: diaSeleccionado,
              horaInicioSeleccionada: horaInicioSeleccionada,
            };
          }
        }

        await updateDoc(userCartRef, {
          servicios: updatedCartItems,
        });
        toast.success("Servicio actualizado correctamente.");
      } else {
        if (servicio.stock) {
          if (servicio.duracion === "unico") {
            updatedCartItems = arrayUnion({
              id: servicio.id,
              cantidad: cantidad,
              diaSeleccionado: diaSeleccionado,
              horaInicioSeleccionada: horaInicioSeleccionada,
            });
          } else {
            updatedCartItems = arrayUnion({
              id: servicio.id,
              cantidad: cantidad,
              cantidadDuracion: cantidadDuracion,
              diaSeleccionado: diaSeleccionado,
              horaInicioSeleccionada: horaInicioSeleccionada,
            });
          }
        } else {
          if (servicio.duracion === "unico") {
            updatedCartItems = arrayUnion({
              id: servicio.id,
              diaSeleccionado: diaSeleccionado,
              horaInicioSeleccionada: horaInicioSeleccionada,
            });
          } else {
            updatedCartItems = arrayUnion({
              id: servicio.id,
              cantidadDuracion: cantidadDuracion,
              diaSeleccionado: diaSeleccionado,
              horaInicioSeleccionada: horaInicioSeleccionada,
            });
          }
        }


        await updateDoc(userCartRef, {
          servicios: updatedCartItems,
        });
        toast.success("Servicio agregado al carrito correctamente.");
      }
    } else {
      let cartItems;

      if (servicio.stock) {
        if (servicio.stock && servicio.duracion === "unico") {
          cartItems = {
            id: servicio.id,
            cantidad: cantidad,
            diaSeleccionado: diaSeleccionado,
            horaInicioSeleccionada: horaInicioSeleccionada,
          };
        } else {
          cartItems = {
            id: servicio.id,
            cantidad: cantidad,
            cantidadDuracion: cantidadDuracion,
            diaSeleccionado: diaSeleccionado,
            horaInicioSeleccionada: horaInicioSeleccionada,
          };
        }
      } else {
        if (servicio.stock && servicio.duracion === "unico") {
          cartItems = {
            id: servicio.id,
            diaSeleccionado: diaSeleccionado,
            horaInicioSeleccionada: horaInicioSeleccionada,
          };
        } else {
          cartItems = {
            id: servicio.id,
            cantidadDuracion: cantidadDuracion,
            diaSeleccionado: diaSeleccionado,
            horaInicioSeleccionada: horaInicioSeleccionada,
          };
        }
      }
        
      await setDoc(userCartRef, {
        servicios: [cartItems],
        productos: []
      });
      toast.success("Servicio agregado al carrito correctamente.");
    }
  } catch (error) {
    console.error("Error al agregar el servicio al carrito:", error.message);
  }
};

export const eliminarServicioCarrito = async (userData, servicioId) => {
  try {
    if (!userData) {
      toast.error("Inicia sesión para acceder al carrito.");
      return;
    }
    
    // Obtener la referencia del carrito del usuario
    const userCartRef = doc(db, "carritos", userData.uid);
    const userCartDoc = await getDoc(userCartRef);

    if (userCartDoc.exists()) {
      // Si el carrito existe, eliminar el servicio con el servicioId proporcionado
      const updatedCartItems = userCartDoc
        .data()
        .servicios.filter((cartItem) => cartItem.id !== servicioId);
      
      // Actualizar el carrito en la base de datos sin el servicio eliminado
      await updateDoc(userCartRef, {
        servicios: updatedCartItems,
      });

      // Mostrar un mensaje de éxito
      toast.success("Servicio eliminado del carrito correctamente.");
    } else {
      toast.error("El carrito no existe.");
    }
  } catch (error) {
    console.error("Error al eliminar el servicio del carrito:", error.message);
    toast.error("Ocurrió un error al eliminar el servicio del carrito.");
  }
};



// Función para obtener los datos de un producto por su ID
export const getServicioPorId = async (id) => {
  try {
    // Obtener el documento del producto con el ID proporcionado
    const infoProductDoc = doc(db, "servicios", id);
    const infoProductSnapshot = await getDoc(infoProductDoc);

    // Verificar si el documento existe
    if (infoProductSnapshot.exists()) {
      // Obtener los datos del producto y devolverlos
      const infoProductData = infoProductSnapshot.data();
      return { id: infoProductSnapshot.id, ...infoProductData };
    } else {
      // Si el documento no existe, devolver un objeto vacío
      console.warn("El servicio con el ID proporcionado no existe.");
      return null;
    }
  } catch (error) {
    console.error("Error al obtener los datos del servicio:", error.message);
    return null;
  }
}