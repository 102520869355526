import React, { useEffect, useState } from "react";
import { Flex, Modal, Card} from "antd";
import { useNavigate} from "react-router-dom";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import { Toaster } from "sonner";
import CarouselAntd from "../../../components/Carousel/CarouselAntd";
import Beneficios from "../../../components/Beneficios/Beneficios";
import CustomFooter from "../../../components/Footer/CustomFooter";
import Categories from "../../../components/Categorias/Categories";
import ProductosCerca from "../../../components/ProductosCerca/ProductosCerca";
import ServiciosCerca from "../../../components/ServiciosCerca/ServiciosCerca";

export default function Productos() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [imagenes, setImagenes] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    obtenerImagenesCarrusel();
  }, []);

  const obtenerImagenesCarrusel = async () => {
    try {
      const storage = getStorage();
      const imagenesRef = ref(storage, "carrusel");
      const imagenesLista = await listAll(imagenesRef);
      const urlsPromises = imagenesLista.items.map(async (item) => {
        const url = await getDownloadURL(item);
        return { url, nombre: item.name };
      });
      const urls = await Promise.all(urlsPromises);
      setImagenes(urls);
    } catch (error) {
      console.error("Error al obtener las imágenes del carrusel:", error);
    }
  };

  const handleModalOk = () => {
    setIsModalVisible(false);
    navigate("/login");
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Flex justify="center" align="center" vertical>
      <CarouselAntd images={imagenes} />
   
   
      <Toaster richColors position="top-center" />
      <Beneficios />
      {/* <Recomendaciones /> */}
      <ProductosCerca />
      <ServiciosCerca />
      <Modal
        title="Autenticación Requerida"
        open={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        okText="Iniciar Sesión"
        cancelText="Cancelar"
      >
        <p>Debes iniciar sesión para agregar productos al carrito</p>
      </Modal>
      <Card style={{marginTop:"20px", textAlign: "center"}}>
        <h1>Categorías de productos y servicios</h1>
        <Categories/>
      </Card>

      <CustomFooter />
    </Flex>
  );
}
