import React, { useState, useEffect } from "react";
import { Upload, message, Button, Space } from "antd";
import {
  getStorage,
  ref,
  listAll,
  deleteObject,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import CarouselAntd from "../../../components/Carousel/CarouselAntd";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";

const CarruselImagenes = () => {
  const [imagenes, setImagenes] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    obtenerImagenesCarrusel();
  }, []);

  const obtenerImagenesCarrusel = async () => {
    try {
      const storage = getStorage();
      const imagenesRef = ref(storage, "carrusel");
      const imagenesLista = await listAll(imagenesRef);
      const urlsPromises = imagenesLista.items.map(async (item) => {
        const url = await getDownloadURL(item);
        return { url, nombre: item.name };
      });
      const urls = await Promise.all(urlsPromises);
      setImagenes(urls);
    } catch (error) {
      console.error("Error al obtener las imágenes del carrusel:", error);
    }
  };

  const eliminarImagen = async (nombre) => {
    try {
      const storage = getStorage();
      const imagenRef = ref(storage, `carrusel/${nombre}`);
      await deleteObject(imagenRef);
      message.success("Imagen eliminada correctamente");
      obtenerImagenesCarrusel();
    } catch (error) {
      console.error("Error al eliminar la imagen:", error);
    }
  };

  const handleUploadFile = async (file) => {
    try {
      const storage = getStorage();
      const imagenRef = ref(storage, `carrusel/${file.name}`);
      await uploadBytes(imagenRef, file);
      message.success(`${file.name} archivo subido correctamente.`);
      obtenerImagenesCarrusel(); // Asegúrate de definir esta función
    } catch (error) {
      console.error("Error al subir la imagen:", error);
      message.error(`${file.name} archivo no se pudo subir.`);
    }
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("Solo puedes subir archivos JPG/PNG");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("La imagen debe ser menor a 2MB");
    }

    return true;
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ marginBottom: "20px" }}>
      <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px",
            marginBottom: "10px"
          }}
        >
          <h1>
           Carrusel 
          </h1>
          </div>
        <CarouselAntd images={imagenes} />
      </div>
      <div style={{ padding: "20px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <h1>
            Selecciona y administra las imágenes para el carrusel de inicio
          </h1>
          <Space direction="vertical" style={{ marginBottom: "20px" }}>
            <Upload
              beforeUpload={beforeUpload}
              accept="image/*"
              showUploadList={false}
              name="file"
              headers={{
                authorization: "authorization-text",
              }}
              onChange={(info) => {
                if (info.file.status !== "uploading") {
                  handleUploadFile(info.file.originFileObj);
                }
              }}
            >
              <Button type="primary">
                {" "}
                {loading ? <LoadingOutlined /> : <PlusOutlined />}Subir Imágenes
              </Button>
            </Upload>
          </Space>
        </div>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {imagenes.map((imagen) => (
            <div
              key={imagen.nombre}
              style={{
                marginBottom: "10px",
                marginRight: "10px",
                height: "200px",
                maxWidth: "300px",
                flex: "1 0 200px",
                position: "relative",
              }}
            >
              <img
                src={imagen.url}
                alt={imagen.nombre}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
              <Button
                type="primary"
                danger
                onClick={() => eliminarImagen(imagen.nombre)}
                style={{ position: "absolute", bottom: "5px", right: "5px" }}
              >
                Eliminar
              </Button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CarruselImagenes;
