import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Form,
  InputNumber,
  message,
  Table,
  Image,
  Descriptions,
  Rate,
  Input,
} from "antd";
import {
  getFirestore,
  collection,
  getDocs,
  doc,
  updateDoc,
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  query,
  where,
} from "firebase/firestore";
import { Link } from "react-router-dom";
import { FrownOutlined, SmileOutlined } from "@ant-design/icons";

const TiendasAdmin = ({ activado }) => {
  const [tiendas, setTiendas] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedTienda, setSelectedTienda] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [form] = Form.useForm();

  const fetchTiendas = async () => {
    try {
      const db = getFirestore();
      const tiendasCollection = collection(db, "tiendas");
      const tiendasSnapshot = await getDocs(
        query(tiendasCollection, where("activado", "==", activado))
      );

      const tiendasData = [];

      for (const doc of tiendasSnapshot.docs) {
        const tiendaData = doc.data();

        // Obtener reseñas para cada tienda
        const resenasCollection = collection(db, "resenas");
        const querySnapshot = await getDocs(
          query(resenasCollection, where("vendedorUid", "==", doc.id))
        );

        const resenasData = querySnapshot.docs.map((resenaDoc) =>
          resenaDoc.data()
        );
        tiendaData.calificacionPromedio =
          calcularCalificacionPromedio(resenasData);

        tiendasData.push({
          key: doc.id,
          ...tiendaData,
        });
      }

      setTiendas(tiendasData);
    } catch (error) {
      console.error("Error al obtener tiendas:", error);
    }
  };

  useEffect(() => {
    fetchTiendas();
  }, [activado]);

  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  // Filtrar categorías según el término de búsqueda
  const filteredTiendas = tiendas.filter((tienda) =>
    tienda.nombre.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleDesactivarTienda = async () => {
    try {
      const db = getFirestore();
      const tiendaDocRef = doc(db, "tiendas", selectedTienda.key);
      await updateDoc(tiendaDocRef, {
        activado: !activado, // Cambiar a la propiedad adecuada de activación
      });

      message.success("Estado de tienda actualizado correctamente");
      handleModalClose();
      fetchTiendas();
    } catch (error) {
      console.error("Error al desactivar la tienda:", error);
    }
  };

  const calcularCalificacionPromedio = (resenas) => {
    const totalCalificaciones = resenas.reduce(
      (total, resena) => total + resena.calificacion,
      0
    );
    return resenas.length > 0 ? totalCalificaciones / resenas.length : 0;
  };

  const handleModalOpen = async (tienda) => {
    setSelectedTienda(tienda);
    form.setFieldsValue({
      comisionPlataforma: tienda.comisionPlataforma,
    });
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setSelectedTienda(null);
    setModalVisible(false);
  };

  const handleFormSubmit = async (values) => {
    try {
      const db = getFirestore();
      const tiendaDocRef = doc(db, "tiendas", selectedTienda.key);
      await updateDoc(tiendaDocRef, {
        comisionPlataforma: values.comisionPlataforma,
      });

      message.success("Comisión actualizada correctamente");
      handleModalClose();
      fetchTiendas();
    } catch (error) {
      console.error("Error al actualizar comisión:", error);
    }
  };

  const columns = [
    {
      title: "Imagen",
      dataIndex: "foto",
      key: "foto",
      render: (foto) => (
        <Image src={foto} alt="Foto de la Tienda" width={50} height={50} />
      ),
    },
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
    },
    {
      title: "Comisión plataforma",
      dataIndex: "comisionPlataforma",
      key: "comisionPlataforma",
    },
    {
      title: "Calificación promedio",
      dataIndex: "calificacionPromedio",
      key: "calificacionPromedio",
      render: (calificacionPromedio) =>
        calificacionPromedio ? (
          <Rate allowHalf defaultValue={calificacionPromedio} disabled />
        ) : (
          "Sin calificación"
        ),
    },
    {
      title: "Acciones",
      dataIndex: "acciones",
      key: "acciones",
      render: (_, tienda) => (
        <Button onClick={() => handleModalOpen(tienda)}>Ver Detalles</Button>
      ),
    },
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <h1>Tiendas</h1>
        <Input.Search
          placeholder="Buscar tienda"
          onSearch={handleSearch}
          style={{ width: 400, marginBottom: 16 }}
        />
        <Link to={`/admin/tiendas/${activado ? "blacklist" : ""}`}>
          {" "}
          <Button
            type="primary"
            danger={activado}
            icon={activado ? <FrownOutlined /> : <SmileOutlined />}
            style={{ marginBottom: 16 }}
          >
            {activado ? "Ver blacklist" : "Ir a whitelist"}
          </Button>
        </Link>
      </div>
      <Table dataSource={filteredTiendas} columns={columns} />

      <Modal
        title="Detalles de la Tienda"
        visible={modalVisible}
        onCancel={handleModalClose}
        footer={[
          <Button key="desactivar" onClick={handleDesactivarTienda} danger>
             {activado ? "Desactivar Tienda" : "Activar Tienda"}
          </Button>,
          <Button key="close" onClick={handleModalClose}>
            Cerrar
          </Button>,
        ]}
      >
        {selectedTienda && (
          <Descriptions column={1}>
            <Descriptions.Item label="Nombre">
              {selectedTienda.nombre}
            </Descriptions.Item>
            <Descriptions.Item label="Descripción">
              {selectedTienda.descripcion}
            </Descriptions.Item>
            <Descriptions.Item label="Ubicación">
              {JSON.stringify(selectedTienda.ubicacion)}
            </Descriptions.Item>
            <Descriptions.Item label="Foto">
              <Image
                src={selectedTienda.foto}
                alt="Foto de la Tienda"
                width={80}
                height={80}
              />
            </Descriptions.Item>
            <Descriptions.Item label="Calificacion promedio">
              <p>
                {selectedTienda.calificacionPromedio ? (
                  <Rate
                    allowHalf
                    defaultValue={selectedTienda.calificacionPromedio}
                    disabled
                  />
                ) : (
                  "Sin calificación"
                )}
              </p>
            </Descriptions.Item>

            <Descriptions.Item label="Comisión Plataforma">
              <Form onFinish={handleFormSubmit} form={form}>
                <Form.Item
                  name="comisionPlataforma"
                  rules={[{ required: true, type: "number", min: 0, max: 100 }]}
                >
                  <InputNumber addonAfter="%" />
                </Form.Item>
                <Button type="primary" htmlType="submit">
                  Actualizar Comisión
                </Button>
              </Form>
            </Descriptions.Item>
          </Descriptions>
        )}
      </Modal>
    </div>
  );
};

export default TiendasAdmin;
