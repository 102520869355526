// TerminosCondiciones.jsx

import React from "react";
import { Typography } from "antd";
import './TerminosCondiciones.css';
const { Title, Paragraph } = Typography;

const TerminosCondiciones = () => {
  return (
    <div className="terminos-condiciones-container">
      <Title level={2}>Términos y Condiciones de uso del Sitio</Title>
      <Paragraph strong>Versión vigente: 21 de Diciembre, 2023</Paragraph>
      <Paragraph>
        Blizu es una compañía de tecnología que ofrece servicios vinculados
        principalmente al comercio electrónico y a los pagos digitales. • El
        Marketplace es una plataforma de comercio electrónico donde las Personas
        Usuarias pueden vender y comprar productos usando distintas soluciones
        de pago y envío. • En Marketplace, conectamos a las personas interesadas
        en realizar transacciones con productos y servicios con posibles
        vendedores, con el fin de democratizar la compra, venta y alquiler de
        productos y servicios en la región. Para poder operar en la plataforma
        todas las Personas Usuarias deberán aceptar los Términos y Condiciones,
        los anexos y la Declaración de Privacidad. Cada Persona Usuaria es
        responsable de los datos personales que brinda al momento de registrarse
        y se obliga a mantenerlos actualizados. Además, es el único responsable
        del uso y resguardo de su contraseña. En algunos casos, podremos cobrar
        una tarifa por el uso de los servicios que integran el ecosistema de
        Blizu, que la Persona Usuaria se compromete a pagar.
      </Paragraph>

      <Title level={3}>1- Blizu</Title>
      <Paragraph>
        Blizu es una compañía de tecnología que ofrece servicios vinculados
        principalmente al comercio electrónico y a los pagos digitales. Los
        servicios que ofrece Blizu en el sitio www.blizu.com.mx y sus
        aplicaciones móviles (de ahora en más: “Sitio”) están diseñados para
        formar un ecosistema que permita a las personas vender, comprar, pagar y
        realizar otras actividades comerciales con tecnología aplicada.
      </Paragraph>

      <Title level={3}>2- Términos y Condiciones</Title>
      <Paragraph>
        Estos términos y condiciones y los anexos que explican los servicios del
        Ecosistema Blizu (de ahora en más: “Términos y Condiciones”) regulan la
        relación entre Blizu y las personas que usan sus servicios (“Personas
        Usuarias”). Las Personas Usuarias aceptan estos Términos y Condiciones
        desde el momento en que se registran en el Sitio y usan el Ecosistema
        Blizu. Cuando debamos hacer cambios importantes en nuestros servicios,
        publicaremos las modificaciones con 10 días corridos de anticipación
        para que las Personas Usuarias puedan revisarlas y seguir usando el
        Ecosistema Blizu. En ningún caso afectarán las operaciones que ya hayan
        finalizado. Las Personas Usuarias que no tengan obligaciones pendientes
        con Blizu o con otras Personas Usuarias, podrán finalizar la relación
        con Blizu cancelando su cuenta.
      </Paragraph>
      <Title level={4}>3- Capacidad</Title>
      <Paragraph>
        Podrán usar nuestros servicios las personas mayores de edad que tengan
        capacidad legal para contratar. Quien use el Ecosistema Blizu en
        representación de una empresa deberá tener capacidad para contratar a
        nombre de ella. Además, para poder usar la cuenta, la Persona Usuaria
        debe encontrarse activa.
      </Paragraph>

      <Title level={4}>4- Registro y Cuenta</Title>
      <Paragraph>
        Quien quiera usar nuestros servicios, deberá completar el formulario de
        registro con los datos que le sean requeridos. Al completarlo, se
        compromete a hacerlo de manera exacta, precisa y verdadera y a mantener
        sus datos siempre actualizados. La Persona Usuaria será la única
        responsable de la certeza de sus datos de registro. Sin perjuicio de la
        información brindada en el formulario, podremos solicitar y/o consultar
        información adicional para corroborar la identidad de la Persona
        Usuaria. La cuenta es personal, única e intransferible, es decir que
        bajo ningún concepto se podrá vender o ceder a otra persona. Se accede a
        ella con la clave personal de seguridad que haya elegido y que deberá
        mantener bajo estricta confidencialidad. La Persona Usuaria podrá crear
        Cuentas Colaboradoras y definir sus permisos de acceso. En cualquier
        caso, la Persona Usuaria será la única responsable por las operaciones
        que se realicen en su cuenta. En caso de detectar un uso no autorizado
        de su cuenta, deberá notificar de forma inmediata y fehaciente a Blizu.
        Podremos rechazar una solicitud de registro o bien cancelar un registro
        ya aceptado, sin que esto genere derecho a un resarcimiento. No podrán
        registrarse nuevamente en el Sitio las Personas Usuarias que hayan sido
        inhabilitadas previamente. Tampoco podrán registrarse quienes estén
        incluidos o relacionados a personas incluidas en listas nacionales o
        internacionales de sanciones. Además, en caso de detectar el uso de más
        de una cuenta, podremos aplicar retenciones, débitos y/o cualquier otra
        medida si consideramos que ese accionar puede perjudicar al resto de las
        personas que usan el Sitio o a Blizu, más allá de las sanciones que
        pudieran corresponder.
      </Paragraph>

      <Title level={4}>5- Privacidad de datos</Title>
      <Paragraph>
        En Blizu hacemos un uso responsable de la información personal,
        protegiendo la privacidad de las Personas Usuarias que nos confiaron sus
        datos y tomando las medidas necesarias para garantizar la seguridad en
        nuestro Ecosistema Blizu
      </Paragraph>

      <Title level={4}>6- Acceso a Otra Información Comercial</Title>
      <Paragraph>
        Además de lo previsto en las secciones anteriores, Blizu proporciona
        herramientas, aplicaciones y servicios basados en otra información
        comercial provista por las Personas Usuarias y/o generada al utilizar
        los servicios del Ecosistema Blizu, para ayudar a los Vendedores a
        optimizar sus ventas, aumentar su volumen y gestionar su negocio.
        También podremos usarla para ofrecer a las Personas Usuarias
        promociones, productos y servicios de otras empresas o de marca
        compartida, también podrá utilizarse en programas de prevención de
        fraude y otras medidas destinadas a aumentar la seguridad del
        Marketplace, de las Personas Usuarias y el cumplimiento de los Términos
        y Condiciones.
      </Paragraph>

      <Title level={4}>7- Sanciones</Title>
      <Paragraph>
        En caso que la Persona Usuaria incumpliera una ley o los Términos y
        Condiciones, podremos advertir, suspender, restringir o inhabilitar
        temporal o definitivamente su cuenta, sin perjuicio de otras sanciones
        que se establezcan en las reglas de uso particulares de los servicios de
        Blizu.
      </Paragraph>

      <Title level={4}>8- Responsabilidad</Title>
      <Paragraph>
        Blizu será responsable por cualquier defecto en la prestación de su
        servicio, en la medida en que le sea imputable y con el alcance previsto
        en las leyes vigentes.
      </Paragraph>

      <Title level={4}>9- Tarifas</Title>
      <Paragraph>
        Blizu podrá cobrar por sus servicios y la Persona Usuaria se compromete
        a pagarlos a tiempo. Podremos modificar o eliminar las tarifas en
        cualquier momento con el debido preaviso establecido en la cláusula 2 de
        estos Términos y Condiciones. De la misma manera, podremos modificar las
        tarifas temporalmente por promociones en favor de las Personas Usuarias.
        La Persona Usuaria autoriza a Blizu a retener y/o debitar los fondos
        existentes y/o futuros de las cuentas bancarias que haya registrado en
        ella, para saldar las tarifas impagas o cualquier otra deuda que pudiera
        tener. Para conocer el detalle de las tarifas de cada servicio, las
        Personas Usuarias deberán consultar los términos y condiciones
        correspondientes. En todos los casos se emitirá la factura de
        conformidad con los datos fiscales que las personas tengan cargados en
        su cuenta.
      </Paragraph>

      <Title level={4}>10- Propiedad Intelectual</Title>
      <Paragraph>
        Blizu y/o sus sociedades relacionadas son propietarias de todos los
        derechos de propiedad intelectual sobre sus sitios, todo su contenido,
        servicios, productos, marcas, nombres comerciales, logos, diseños,
        imágenes, frases publicitarias, derechos de autor, dominios, programas
        de computación, códigos, desarrollos, software, bases de datos,
        información, tecnología, patentes y modelos de utilidad, diseños y
        modelos industriales, secretos comerciales, entre otros (“Propiedad
        Intelectual”) y se encuentran protegidos por leyes nacionales e
        internacionales. Aunque Blizu otorga permiso para usar sus productos y
        servicios conforme a lo previsto en los Términos y Condiciones, esto no
        implica una autorización para usar su Propiedad Intelectual, excepto
        consentimiento previo y expreso de Blizu y/o sus sociedades vinculadas.
        En cualquier caso, los usuarios vendedores que usen dichos productos y
        servicios no podrán utilizar la Propiedad Intelectual de Blizu de una
        manera que cause confusión en el público y deberán llevar a cabo su
        actividad comercial bajo una marca o nombre comercial propio y
        distintivo, que no resulte confundible con la marca Blizu. Está
        prohibido usar nuestros productos o servicios para fines ilegales,
        realizar cualquier tipo de ingeniería inversa u obras derivadas,
        utilizar herramientas de búsqueda o de extracción de datos y contenidos
        de nuestra plataforma para su reutilización y/o crear bases de datos
        propias que incluyan en todo o en parte nuestro contenido sin nuestra
        expresa autorización. Está también prohibido el uso indebido, sin
        autorización y/o contrario a la normativa vigente y/o que genere
        confusión o implique uso denigratorio y/o que le cause perjuicio, daños
        o pérdidas a Blizu y/o a sus sociedades relacionadas. La utilización de
        los productos y servicios de Blizu tampoco implica la autorización para
        usar propiedad intelectual de terceros que pueda estar involucrada, cuyo
        uso quedará bajo exclusiva responsabilidad del usuario. En caso que una
        Persona Usuaria o cualquier publicación infrinja la Propiedad
        Intelectual de Blizu o de terceros, Blizu podrá remover dicha
        publicación total o parcialmente), sancionar al usuario conforme a lo
        previsto en estos Términos y Condiciones y ejercer las acciones
        extrajudiciales y/o judiciales correspondientes.
      </Paragraph>

      <Title level={4}>11- Indemnidad</Title>
      <Paragraph>
        La Persona Usuaria mantendrá indemne a Blizu y sus sociedades
        relacionadas, así como a quienes la dirigen, suceden, administran,
        representan y/o trabajan en ellas, por cualquier reclamo administrativo
        o judicial iniciado por otras Personas Usuarias, terceros o por
        cualquier Organismo, relacionado con sus actividades en el Ecosistema
        Blizu. En virtud de esa responsabilidad, podrán realizar compensaciones,
        retenciones u otras medidas necesarias para la reparación de pérdidas,
        daños y perjuicios, cualquiera sea su naturaleza.
      </Paragraph>

      <Title level={4}>12- Anexos</Title>
      <Paragraph>
        Además de estos Términos y Condiciones, cada servicio del Ecosistema
        Blizu tiene sus propias reglas de uso: • Marketplace {/*Cambiar */}
      </Paragraph>

      <Title level={4}>13- Jurisdicción y Ley Aplicable</Title>
      <Paragraph>
        Estos Términos y Condiciones se rigen por la ley local. Toda
        controversia derivada de su aplicación, interpretación, ejecución o
        validez será resuelta por los tribunales nacionales ordinarios
        competentes, con asiento en la capital, salvo disposición específica de
        normas de orden público, como por ejemplo, legislación relativa al
        Consumidor. Para todos los efectos relacionados con estos Términos y
        Condiciones y con el uso del sitio, Blizu Shop SAPI de C.V. con RFC
        TEAC881007UP7, establece como domicilio Av. Juan Rulfo 1851 – 69 Fracc.
        Sonterra, C.P 76235, Querétaro, México.
      </Paragraph>

      <Title level={4}>14- Metodo de pagos</Title>
      <Paragraph>
      Se hace uso de Openpay como procesador de pago.
      </Paragraph>
    </div>
  );
};

export default TerminosCondiciones;
