import React, { useState, useEffect } from "react";
import { Checkbox, InputNumber, Select, Button, Card } from "antd";
import { db } from "../../firebaseConfig";
import { collection, getDocs, query } from "firebase/firestore";
const { Option } = Select;

const FiltroProductos = ({inicialFilters, applyFilters }) => {
  const [categorias, setCategorias] = useState([]);
  const [subcategorias, setSubcategorias] = useState([]);
  const [allCategorias, setAllCategorias] = useState([]);
  const [search, setSearch] = useState(null);
  const [categoria, setCategoria] = useState("");
  const [subcategoria, setSubcategoria] = useState("");
  const [precioMin, setPrecioMin] = useState(null);
  const [precioMax, setPrecioMax] = useState(null);
  const [mesesSinIntereses, setMesesSinIntereses] = useState(false);
  const [tipo, setTipo] = useState("todos");
  const [diasDisponibles, setDiasDisponibles] = useState("");
  const [pagoPor, setPagoPor] = useState("");

  
  useEffect(() => {
    const fetchCategorias = async () => {
      try {
        const categoriasCollection = collection(db, "categorias");
        const categoriasSnapshot = await getDocs(categoriasCollection);
        const categoriasData = categoriasSnapshot.docs.map((doc) => ({
          key: doc.id,
          ...doc.data(),
        }));
        setAllCategorias(categoriasData);
      } catch (error) {
        console.error("Error al obtener categorías:", error.message);
      }
    };
  
    fetchCategorias();
  }, []);
  
  useEffect(() => {
    const asignarValoresPreCargados = (filters) => {
      if (filters) {
        setPrecioMin(filters.precioMin || null);
        setPrecioMax(filters.precioMax || null);
        setMesesSinIntereses(filters.mesesSinIntereses || false);
        setTipo(filters.tipo || "todos");
        setDiasDisponibles(filters.diasDisponibles || "");
        setPagoPor(filters.pagoPor || "");
        if (allCategorias.length > 0) {
          changeCategoria(filters.categoria);
        }
        if(filters.search){
          setSearch(filters.search);
        }
      }
    };
    asignarValoresPreCargados(inicialFilters);
  }, [allCategorias, inicialFilters]);
  


  const handleApplyFilters = () => {
    const filters = {
      categoria,
      subcategoria,
      precioMin,
      precioMax,
      mesesSinIntereses,
      tipo,
      diasDisponibles,
      pagoPor,
      search
    };
    applyFilters(filters);
  };

  const changeCategoria = (categoriaId) => {
    if (categoriaId === "todos") {
      setCategoria("todos");
      setSubcategoria("todos");
    } else {
      if(subcategoria === ""){
        setSubcategoria("todos");
      }
    
      const categoriaSeleccionadaData = allCategorias.find(
        (categoria) => categoria.key === categoriaId
      );
      if (
        categoriaSeleccionadaData &&
        categoriaSeleccionadaData.subcategorias
      ) {
        const subcategoriasWithKeys =
          categoriaSeleccionadaData.subcategorias.map(
            (subcategoria, index) => ({
              ...subcategoria,
              id: index.toString(),
            })
          );
        setCategoria(categoriaSeleccionadaData.key);
        setSubcategorias(subcategoriasWithKeys);
      } else {
        setSubcategorias([]);
      }
    }
  };

  const handleChangeCategoria = (categoriaId) => {
   
    if (categoriaId === "todos") {
      setCategoria("todos");
      setSubcategoria("todos");
    } else {
        setSubcategoria("todos");
      const categoriaSeleccionadaData = allCategorias.find(
        (categoria) => categoria.key === categoriaId
      );
      if (
        categoriaSeleccionadaData &&
        categoriaSeleccionadaData.subcategorias
      ) {
        const subcategoriasWithKeys =
          categoriaSeleccionadaData.subcategorias.map(
            (subcategoria, index) => ({
              ...subcategoria,
              id: index.toString(),
            })
          );
         
        setCategoria(categoriaSeleccionadaData.key);
        setSubcategorias(subcategoriasWithKeys);
      } else {
        setSubcategorias([]);
      }
    }
  };

  useEffect(() => {
    if (tipo !== "todos") {
      // Filtrar las categorías según el tipo seleccionado
      const categoriasFiltradas = allCategorias.filter(
        (categoria) => categoria.tipo === tipo
      );
      setCategorias(categoriasFiltradas);
  
      // Verificar si alguna categoría actual tiene un tipo diferente al tipo seleccionado
      const categoriaActualInvalida = categorias.find(cat => cat.tipo !== tipo);
      if (categoriaActualInvalida) {
        setCategoria("todos"); // Establecer la categoría en "todos"
      }
    } else {
      if(categoria === "") {
        setCategoria("todos");
      }
    }
  }, [allCategorias, tipo]); // Asegúrate de incluir 'categorias' en las dependencias
  
  

  return (
    <Card style={{ width: "270px", alignContent: "center", marginTop: "20px" }}>
      <h2>Filtrar Resultados</h2>
      <p>Rango de precio:</p>
      <InputNumber
        value={precioMin}
        onChange={setPrecioMin}
        placeholder="Precio mínimo"
        style={{ width: 200, marginBottom: 10 }}
      />
      <InputNumber
        value={precioMax}
        onChange={setPrecioMax}
        placeholder="Precio máximo"
        style={{ width: 200, marginBottom: 10 }}
      />
      <Checkbox
        checked={mesesSinIntereses}
        onChange={(e) => setMesesSinIntereses(e.target.checked)}
      >
        Solo meses sin intereses
      </Checkbox>

      <p>Producto o servicio:</p>
      <Select
  value={tipo}
  onChange={(value) => {
   // console.log(value); // Verificar el valor seleccionado
    setTipo(value); // Actualizar el estado 'tipo' con el nuevo valor
  }}
  style={{ width: 200, marginBottom: 10 }}
>
  <Option value="todos">Todos</Option>
  <Option value="producto">Producto</Option>
  <Option value="servicio">Servicio</Option>
</Select>

      {tipo !== "todos" && categorias.length > 0 && (
        <div>
          <p>categoria:</p>
          <Select
            onChange={handleChangeCategoria}
            value={categoria}
            placeholder="Selecciona una categoría"
            style={{ width: 200, marginBottom: 10 }}
          >
            <Option value="todos">Todos</Option>
            {categorias.map((categoria) => (
              <Option key={categoria.key} value={categoria.key}>
                {categoria.nombre}
              </Option>
            ))}
          </Select>
        </div>
      )}
      {subcategorias.length > 0 &&
        tipo !== "todos" &&
        categoria !== "todos"  &&  categoria !== "" && (
          <div>
            <p>subcategoria:</p>
            <Select
              value={subcategoria}
              onChange={setSubcategoria}
              placeholder="Selecciona una subcategoría"
              style={{ width: 200, marginBottom: 10 }}
            >
              <Option value="todos">Todos</Option>
              {subcategorias.map((subcategoria) => (
                <Option key={subcategoria.id} value={subcategoria.id}>
                  {subcategoria.subcategoria}
                </Option>
              ))}
            </Select>
          </div>
        )}
      {tipo === "servicio" && (
        <>
        <p>Tipo de pago:</p>
          <Select
            value={pagoPor}
            onChange={setPagoPor}
            style={{ width: 200, marginBottom: 10 }}
          >
            <Option value="">Pago por</Option>
            <Option value="hora">Hora</Option>
            <Option value="dia">Día</Option>
            <Option value="unico">Único</Option>
          </Select>
          <p>Dias disponibles:</p>
          <Select
            value={diasDisponibles}
            onChange={setDiasDisponibles}
            mode="multiple"
            placeholder="Días disponibles"
            style={{ width: 200, height: 120, marginBottom: 10 }} // Ajusta la altura aquí
          >
            <Option value="lunes">Lunes</Option>
            <Option value="martes">Martes</Option>
            <Option value="miércoles">Miércoles</Option>
            <Option value="jueves">Jueves</Option>
            <Option value="viernes">Viernes</Option>
            <Option value="sábado">Sábado</Option>
            <Option value="domingo">Domingo</Option>
          </Select>

          
        </>
      )}
      <Button onClick={handleApplyFilters}>Aplicar Filtros</Button>
    </Card>
  );
};

export default FiltroProductos;
