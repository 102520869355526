import React, { useState, useEffect } from "react";
import { Form, Input, Button, Card, message } from "antd";
import { UserOutlined, LockOutlined, GoogleOutlined } from "@ant-design/icons";
import "./Login.scss";
import { auth, signInWithEmailAndPassword } from "../../../firebaseConfig";
import { Link, useNavigate } from "react-router-dom";
import { Toaster, toast } from "sonner";
import { useUser } from "../../../context/UserContext";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { createCustomer } from "../../../Api/Openpay/OpenPayApi";
import {
  getFirestore,
  collection,
  doc,
  setDoc,
  getDoc,
} from "firebase/firestore";
import logo from "../../../assets/logo.png";
const { geolocation } = navigator;

const Login = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { setUser, userData } = useUser();

  useEffect(() => {
    // console.log('userData after setUser:', userData);
  }, [userData]);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        values.email,
        values.password
      );

      // console.log(userCredential)

      // Obtener una instancia de Firestore
      const db = getFirestore();
      const userId = userCredential.user.uid;

      // Obtener el documento de roles del usuario
      const userDoc = await getDoc(doc(db, "roles", userId));
      const userData = userDoc.data();
    //  console.log(userData);

      if (!userData.activado && userData.role != 1) {
        setLoading(false);
        toast.error(
          "Tu cuenta se encuentra deshabilitada. Favor de contactar a un administrador"
        );
        return;
      } else if (!userData.activado && userData.role == 1) {
        if (userData.aprobado == false) {
          setLoading(false);
          toast.error("Tu cuenta de vendedor no ha sido aprobada");
        } else {
          setLoading(false);
          toast.error("Tu cuenta de vendedor aun no se ha revisado espera de 24 a 48 horas y vuelve a intentarlo");
        }

        return;
      }

      setUser(userData);

      setLoading(false);

      if (userData && userData.role === 1) {
        const tiendasCollection = collection(db, "tiendas");
        const tiendaDocRef = doc(tiendasCollection, userId);

        // Verificar si el documento de la tienda ya existe
        const tiendaDocSnapshot = await getDoc(tiendaDocRef);

        if (tiendaDocSnapshot.exists()) {
          // Si la tienda ya existe, redirigir a la página del vendedor
          navigate("/dashboard");

          toast.success("Inicio de sesión exitoso", {
            duration: 5000,
          });
        } else {
          // Si la tienda no existe, redirigir a la creación de tienda
          navigate("/creartienda");

          toast.success("Inicio de sesión exitoso. ¡Crea tu tienda ahora!", {
            duration: 5000,
          });
        }
      } else if (userData && userData.role === 2) {
        message.success("Inicio de sesión exitoso");
        navigate("/admin");
      } else {
        message.success("Inicio de sesión exitoso");
        navigate("/");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Credenciales incorrectas. Por favor, inténtalo de nuevo.");
      console.error(error.message);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);

      // console.log(result);
      const userCredential = result.user;

      // Aquí puedes realizar acciones adicionales después del inicio de sesión exitoso.

      const userId = userCredential.uid;

      // Obtener una instancia de Firestore
      const db = getFirestore();
      // Se crea el documento del usuario
      const usersCollection = collection(db, "roles");

      // Consultar si el usuario ya existe en la base de datos
      const userDocRef = doc(usersCollection, userId);
      const userDocSnapshot = await getDoc(userDocRef);

      if (userDocSnapshot.exists()) {
        // El usuario ya está registrado, realiza acciones adicionales si es necesario

        const userData = userDocSnapshot.data();
        if (!userData.activado && userData.role != 1) {
          setLoading(false);
          toast.error(
            "Tu cuenta se encuentra deshabilitada. Favor de contactar a un administrador"
          );
          return;
        } else if (!userData.activado && userData.role == 1) {
          if (userData.aprobado == false) {
            setLoading(false);
            toast.error("Tu cuenta de vendedor no ha sido aprobada");
          } else {
            setLoading(false);
            toast.error("Tu cuenta de vendedor aun no se ha revisado");
          }

          return;
        }
        setUser(userData);

        setLoading(false);
        message.success("Inicio de sesión exitoso");
        navigate("/");
      } else {
        // El usuario no está registrado, procede a crear el registro
        const userOpenpay = await createCustomer({
          name: userCredential.displayName,
          email: userCredential.email,
          phone_number: userCredential.phoneNumber,
          requires_account: false,
        });
        let userLocation = null;
        if (geolocation) {
          // Obtener la posición del usuario
          const position = await new Promise((resolve, reject) => {
            geolocation.getCurrentPosition(resolve, reject);
          });

          // Extraer la latitud y longitud de la posición
          const { latitude, longitude } = position.coords;
          userLocation = { latitude, longitude };
        }
        // Se sube la colección de datos del usuario
        await setDoc(userDocRef, {
          username: userCredential.displayName,
          email: userCredential.email,
          role: 0,
          openpayId: userOpenpay.id,
          photo: userCredential.photoURL,
          uid: userId,
          location: userLocation,
          activado: true,
          google: true,
        });

        setUser({
          username: userCredential.displayName,
          email: userCredential.email,
          role: 0,
          openpayId: userOpenpay.id,
          photo: userCredential.photoURL,
          uid: userId,
          location: userLocation,
          activado: true,
        });

        toast.success("Registro exitoso", {
          duration: 5000,
        });
        navigate("/");
      }
    } catch (error) {
      console.error("Error al iniciar sesión con Google:", error.message);
      // Puedes mostrar un mensaje de error al usuario si lo deseas.
    }
  };

  return (
    <Card className="login-container">
      <Toaster richColors position="top-center" />

      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        className="login-form"
        layout="vertical"
      >
        <h1 className="login-title">Bienvenido a </h1>
        <div style={{ textAlign: "center" }}>
          {" "}
          {/* Añade un div para centrar la imagen */}
          <img
            src={logo}
            style={{ width: "100px", margin: "auto", marginBottom: "10px" }}
            alt="blizu"
          />
        </div>

        <Form.Item
          name="email"
          label="Correo electronico"
          rules={[
            {
              required: true,
              message: "Por favor, ingresa tu correo",
            },
          ]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder=""
          />
        </Form.Item>

        <Form.Item
          name="password"
          label="Contraseña"
          rules={[
            {
              required: true,
              message: "Por favor, ingresa tu contraseña",
            },
          ]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            placeholder="*****"
          />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            className="login-form-button"
          >
            Iniciar sesión
          </Button>
        </Form.Item>
        <div style={{ textAlign: "center", marginBottom: "20px" }}> {/* Agrega un div para el separador */}
      <span> o </span>
    </div>
        <Form.Item>
          <Button
            icon={<GoogleOutlined />}
            onClick={handleGoogleLogin}
            className="registration-form-button"
          >
            Iniciar sesión con Google
          </Button>
        </Form.Item>
        <Form.Item>
          ¿No tienes una cuenta?
          <Link to={`/tipo`}> Regístrate aquí</Link>
        </Form.Item>
        <Form.Item>
          ¿Olvidaste tu contraseña?
          <Link to={`/olvide`}> la olvide</Link>
        </Form.Item>

        <Link to={`/`} style={{ display: "flex", justifyContent: "center" }}>
          <Button type="link">Volver a inicio</Button>
        </Link>
      </Form>
    </Card>
  );
};

export default Login;
