import React from "react";
import { Avatar, Menu, Modal, Typography, message } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useUser } from "../../context/UserContext";
import {
  HomeOutlined,
  ShopOutlined,
  AreaChartOutlined,
  DollarOutlined,
  EditOutlined,
  CodeSandboxOutlined,
  HourglassOutlined,
  LogoutOutlined,
  WarningOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import "./MenuList.scss";
import logo from "../../assets/logo.png";
const { Title } = Typography;

export default function MenuList({ darkTheme, datosDePago, foto, nombre }) {
  const navigate = useNavigate();
  const { clearUser } = useUser();

  const handleLogout = () => {
    Modal.confirm({
      title: "Cerrar sesión",
      content: "¿Estás seguro de que deseas cerrar sesión?",
      okText: "Sí",
      okType: "danger",
      cancelText: "Cancelar",
      async onOk() {
        try {
          clearUser();
          message.success("Se ha cerrado la sesión exitosamente");
          navigate("/login");
        } catch (error) {
          message.error("Error al cerrar la sesión");
        }
      },
    });
  };

  // Determinar el ícono basado en el estado de los datos de pago
  const getPaymentStatusIcon = (estado) => {
    switch (estado) {
      case "sin datos":
        return <WarningOutlined style={{ color: "orange" }} />;
      case "pendiente":
        return <HourglassOutlined style={{ color: "blue" }} />;
      case "rechazado":
        return <CloseCircleOutlined style={{ color: "red" }} />;
      case "aprobado":
      default:
        return null;
    }
  };

  return (
    <Menu
      theme={darkTheme ? "dark" : "light"}
      mode="inline"
      className="menu-bar"
    >
           <div style={{ textAlign: "center", marginTop: "20px" }}>
        <img src={logo} className="logo-menu" alt="blizu" />
      </div>
      <Menu.Item key="dashboard" icon={<AreaChartOutlined />}>
        <Link to="/dashboard">Dashboard</Link>
      </Menu.Item>
      <Menu.Item key="misproductos" icon={<ShopOutlined />}>
        <Link to="/dashboard/misproductos">Mis artículos</Link>
      </Menu.Item>
      <Menu.Item key="misservicios" icon={<HourglassOutlined />}>
        <Link to="/dashboard/misservicios">Mis servicios</Link>
      </Menu.Item>
      <Menu.Item key="mispedidos" icon={<CodeSandboxOutlined />}>
        <Link to="/dashboard/mispedidos">Mis pedidos</Link>
      </Menu.Item>
      <Menu.Item key="editarperfil" icon={<EditOutlined />}>
        <Link to="/dashboard/tienda/editar">Editar Tienda</Link>
      </Menu.Item>
      <Menu.Item key="datospay" icon={<DollarOutlined />}>
        <Link to="/dashboard/datosdepago">
          Mis datos de pago {getPaymentStatusIcon(datosDePago)}
        </Link>
      </Menu.Item>
      <Menu.Item key="cerrar" icon={<LogoutOutlined />}>
        <Link onClick={handleLogout}>Cerrar sesión</Link>
      </Menu.Item>
      <Menu.Item key="inicio" icon={<HomeOutlined />}>
        <Link to="/">Volver a inicio</Link>
      </Menu.Item>
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <Avatar size={50} src={foto} alt="Logo de la tienda" />
        <p style={{ marginTop: '20px'}}>
         <strong>{nombre}</strong> 
        </p>
      </div>
    </Menu>
  );
}
