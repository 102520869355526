import React, { useState, useEffect } from "react";
import { Modal, Button } from "antd";
import MapGoogle from "../MapGoogle/MapGoogle";

const ModalUbicacion = ({ visible, onOk, onCancel }) => {
  const [markerPosition, setMarkerPosition] = useState(null);
  const [locationAllowed, setLocationAllowed] = useState(false);

  useEffect(() => {
    // Verificar si el navegador admite geolocalización y si el usuario ha dado permiso
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocationAllowed(true);
          setMarkerPosition({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (error) => {
          setLocationAllowed(false);
        }
      );
    } else {
      setLocationAllowed(false);
    }
  }, []);

  const handleMapClick = ({ latLng }) => {
    setMarkerPosition({
      lat: latLng.lat(),
      lng: latLng.lng(),
    });
  };

  return (
    <Modal
      title="Ubicación de productos y servicios"
      visible={visible}
      onOk={() => onOk(markerPosition)}
      okButtonProps={{ disabled: !markerPosition }}
      maskClosable={false}
      cancelButtonProps={{ disabled: true }}
      closable={false}
    >
      {locationAllowed ? (
        <MapGoogle onMapClick={handleMapClick} markerPosition={markerPosition} />
      ) : (
        <p>Para utilizar la página web de forma correcta, es necesario que habilite la geolocalización en su navegador.</p>
      )}
      <p>Selecciona una ubicación en el mapa o utiliza el campo de búsqueda para encontrar una ubicación específica para encontrar productos o servicios.</p>
    </Modal>
  );
};

export default ModalUbicacion;
