import React, { createContext, useContext, useEffect, useState } from "react";
import { onSnapshot, doc, getFirestore } from "firebase/firestore";
import { db } from "../firebaseConfig";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userData, setUserDataState] = useState();
  const [userFavorites, setUserFavorites] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [userLocation, setUserLocation] = useState(null); // Nuevo estado para la ubicación del usuario
  const [userDataAvailable, setUserDataAvailable] = useState(null); // Nueva variable de estado

  useEffect(() => {
    const storedUserData = localStorage.getItem("userData");
    const initialUserData = storedUserData ? JSON.parse(storedUserData) : null;
    setUserDataState(initialUserData);

    const unsubscribeUserData = listenToUserData(initialUserData?.uid, setUserDataState);
    return () => {
      unsubscribeUserData();
    };
  }, []);

  useEffect(() => {
    // Cuando userData esté disponible y no sea null, establecer userDataAvailable en true
    if (userData !== undefined) {
      if(userData !== null){
        setUserDataAvailable(true);
      }else{
        setUserDataAvailable(false);
      }
   
    }
  }, [userData]);


  useEffect(() => {
    const unsubscribeUserFavorites = listenToUserFavorites(userData?.uid, setUserFavorites);
    return () => {
      unsubscribeUserFavorites();
    };
  }, [userData?.uid]);

  useEffect(() => {
    const unsubscribeCart = listenToUserCart(userData?.uid, setCartItems);
    return () => {
      unsubscribeCart();
    };
  }, [userData?.uid]);

  useEffect(() => {
    const storedUserLocation = localStorage.getItem("userLocation");
    const initialUserLocation = storedUserLocation ? JSON.parse(storedUserLocation) : null;
    setUserLocation(initialUserLocation);
  }, []);


  const setUser = (newUserData) => {
    localStorage.setItem("userData", JSON.stringify(newUserData));
    setUserDataState(newUserData);
  };

  const clearUser = () => {
    localStorage.removeItem("userData");
    setUserDataState(null);
  };

  const updateUserFavoritesLocal = (newFavorites) => {
    setUserFavorites(newFavorites);
  };

  const setUserLocationCurrent = (newLocation) => {
    localStorage.setItem("userLocation", JSON.stringify(newLocation));
    setUserLocation(newLocation); // Actualiza el estado localmente
  };

  const listenToUserData = (uid, setUserData) => {
    if (!uid) return () => {};

    const userRef = doc(db, "roles", uid);
    return onSnapshot(userRef, (snapshot) => {
      if (snapshot.exists()) {
        const userData = snapshot.data();
        setUserData(userData);
        if (userData.activado === false) {
          clearUser();
        }
      } else {
        clearUser();
      }
    });
  };

  const listenToUserFavorites = (uid, setUserFavorites) => {
    if (!uid) return () => {};

    const userFavoritesRef = doc(db, "favoritos", uid);
    return onSnapshot(userFavoritesRef, (snapshot) => {
      if (snapshot.exists()) {
        const userFavoritesData = snapshot.data();
        setUserFavorites(userFavoritesData);
      } else {
        setUserFavorites([]);
      }
    });
  };

  const listenToUserCart = (uid, setCartItems) => {
    if (!uid) return () => {};

    const userCartRef = doc(db, "carritos", uid);
    return onSnapshot(userCartRef, (snapshot) => {
      if (snapshot.exists()) {
        setCartItems(snapshot.data()); // Suponiendo que 'servicios' es la propiedad que contiene los elementos del carrito
      } else {
        setCartItems([]);
      }
    });
  };

  return (
    <UserContext.Provider
      value={{
        userData,
        userFavorites,
        userLocation, // Agregar userLocation al contexto
        userDataAvailable, // Agregar userDataAvailable al contexto
        setUser,
        clearUser,
        updateUserFavoritesLocal,
        setUserLocationCurrent, // Agregar updateUserLocation al contexto
        cartItems,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContext);
};
