import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { db } from "../../../firebaseConfig";
import { Card, List, Button, Badge, Typography } from "antd";
import { Link } from "react-router-dom";
import { useUser } from "../../../context/UserContext";

const ListaSubpedidos = () => {
  const { userData } = useUser();
  const [subpedidos, setSubpedidos] = useState([]);
  const [loading, setLoading] = useState(true);

  // Función para obtener el conteo de mensajes pendientes para un subpedido
  const getPendingMessagesCount = async (subpedidoId) => {
    const messagesCollection = collection(db, "subpedidos", subpedidoId, "messages");
    const messagesQuery = query(
      messagesCollection,
      where("status", "==", "pending"),
      where("read", "==", false)
    );
    const messagesSnapshot = await getDocs(messagesQuery);
    return messagesSnapshot.size;
  };

  const fetchSubpedidos = async () => {
    try {
      // Consultar subpedidos del vendedor
      const subpedidosQuery = query(
        collection(db, "subpedidos"),
        where("vendedorUid", "==", userData.uid)
      );

      const subpedidosSnapshot = await getDocs(subpedidosQuery);
      const subpedidosData = [];

      for (const docData of subpedidosSnapshot.docs) {
        const subpedido = docData.data();
        subpedido.id = docData.id;

        // Obtener la fecha de creación del pedido asociado al subpedido
        const pedidoDocRef = doc(db, "pedidos", subpedido.pedidoId);
        const pedidoDocSnapshot = await getDoc(pedidoDocRef);

        if (pedidoDocSnapshot.exists()) {
          const pedidoData = pedidoDocSnapshot.data();
          subpedido.fechaCreacionPedido = pedidoData.fechaCreacion.toDate();

          // Verificar si el total del subpedido es igual a la devolución
          if (subpedido.total !== subpedido.devolucion) {
            // Obtener el conteo de mensajes pendientes
            subpedido.pendingMessagesCount = await getPendingMessagesCount(subpedido.id);
            subpedidosData.push(subpedido);
          }
        }
      }

      // Ordenar los subpedidos por fecha de creación del pedido
      subpedidosData.sort(
        (a, b) => b.fechaCreacionPedido - a.fechaCreacionPedido
      );

      setSubpedidos(subpedidosData);
      setLoading(false);
    } catch (error) {
      console.error("Error al obtener los subpedidos del vendedor:", error);
    }
  };

  useEffect(() => {
    if (userData) {
      fetchSubpedidos();
    }
  }, [userData]);

  return (
    <Card className="container" style={{ padding: 50, margin: "10px" }}>
      <Typography.Title level={2}>Lista de Subpedidos</Typography.Title>
      {loading ? (
        <p>Cargando subpedidos...</p>
      ) : (
        <List
          itemLayout="horizontal"
          dataSource={subpedidos}
          renderItem={(subpedido) => (
            <List.Item
              actions={[
                <Link to={`/dashboard/mispedidos/subpedidos/${subpedido.id}`}>
                  <Button  style={{ display: 'flex', alignItems: 'center' }}>
                    Ver información
                    {subpedido.pendingMessagesCount > 0 && (
                      <Badge
                        count={subpedido.pendingMessagesCount}
                        style={{
                          marginLeft: '10px',
                          backgroundColor: "#f5222d", // Rojo para los mensajes pendientes
                        }}
                      />
                    )}
                  </Button>
                </Link>,
              ]}
            >
              <List.Item.Meta
                title={`Subpedido ID: ${subpedido.id}`}
                description={`Total del subpedido: $${subpedido.total}`}
              />
              <div>{`${subpedido.fechaCreacionPedido.toLocaleString()}`}</div>
            </List.Item>
          )}
        />
      )}
    </Card>
  );
};

export default ListaSubpedidos;
