import {
  Modal,
  Button,
  Form,
  Input,
  Space,
  Table,
  Tag,
  Popconfirm,
  message,
  Upload,
} from "antd";
import { useState, useEffect } from "react";
import {
  getFirestore,
  collection,
  addDoc,
  getDocs,
  deleteDoc,
  doc,
  updateDoc,
  query,
  where
} from "firebase/firestore";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import PhotoUploader from "../../../components/PhotoUploader/PhotoUploader";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {
  AddCategoriaModal,
  EditCategoriaModal,
} from "../../../components/CategoriaModal/Modals";
const CategoriasTipo= ({tipo}) => {
  const [categorias, setCategorias] = useState([]);
  const [imageUrl, setImageUrl] = useState(null);
  const [categoriaSeleccionada, setCategoriaSeleccionada] = useState({});
  const [formAdd] = Form.useForm();
  const [formEdit] = Form.useForm();
  const storage = getStorage();
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [imageUrlAdd, setImageUrlAdd] = useState(null);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");


  
  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  // Filtrar categorías según el término de búsqueda
  const filteredCategorias = categorias.filter((categoria) =>
    categoria.nombre.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const fetchData = async () => {
    const db = getFirestore();
    const categoriasCollection = collection(db, "categorias");
    const categoriasSnapshot = await getDocs(query(categoriasCollection, where("tipo", "==", tipo)));
    const categoriasData = categoriasSnapshot.docs.map((doc) => ({
      key: doc.id,
      ...doc.data(),
    }));
    setCategorias(categoriasData);
  };
  

  useEffect(() => {
    fetchData();
  }, []);

  const showModal = (type, categoria) => {
    if (type === "editar") {
      setEditModalVisible(true);
      setCategoriaSeleccionada(categoria || {});
      setImageUrl(categoria?.photo || "");

      formEdit.setFieldsValue({
        nombre: categoria.nombre,
        subcategorias: categoria.subcategorias.map((subcat, index) => ({
          subcategoria: subcat.subcategoria,
          id: index,
        })),
      });
    } else {
      setAddModalVisible(true);
    }
  };

  const handleOkAdd = async () => {
    try {
      const values = await formAdd.validateFields();
   //   console.log(values);
      let photoLink = "";
  
      if (values.photo && values.photo.length > 0 && setImageUrlAdd) {
        const avatarRef = ref(
          storage,
          `categorias/${values.photo[0].originFileObj.name}`
        );
        await uploadBytes(avatarRef, values.photo[0].originFileObj);
        photoLink = await getDownloadURL(avatarRef);
        setImageUrlAdd("");
      }
  
      // Modificar la estructura de las subcategorías
      const subcategorias = values.subcategorias.map((subcategoria, index) => ({
        id: index,
        subcategoria: subcategoria.subcategoria,
      }));
  
      const db = getFirestore();
      const categoriasCollection = collection(db, "categorias");
      
      // Actualizar el objeto values con las subcategorías modificadas
      const updatedValues = {
        ...values,
        photo: photoLink,
        subcategorias: subcategorias,
        tipo: tipo,
      };
  
      await addDoc(categoriasCollection, updatedValues);
      setAddModalVisible(false);
  
      message.success("Operación exitosa");
  
      fetchData();
      // Restablecer el formulario después de la operación exitosa
      formAdd.resetFields();
    } catch (error) {
      console.error(error);
    }
  };
  

  const handleOkEdit = async () => {
    try {
      const values = await formEdit.validateFields();
      let photoLink = "";

      if (
        values.photo &&
        values.photo.length > 0 &&
        values.photo[0].originFileObj &&
        values.photo[0].originFileObj.name !== undefined &&
        imageUrl
      ) {
        const avatarRef = ref(
          storage,
          `categorias/${values.photo[0].originFileObj.name}`
        );
        await uploadBytes(avatarRef, values.photo[0].originFileObj);
        photoLink = await getDownloadURL(avatarRef);
        setImageUrl("");
      } else if (imageUrl) {
        photoLink = imageUrl;
        setImageUrl("");
      }

      const db = getFirestore();
      const categoriaDocRef = doc(db, "categorias", categoriaSeleccionada.key);
      await updateDoc(categoriaDocRef, { ...values, photo: photoLink });
      setEditModalVisible(false);

      message.success("Operación exitosa");

      fetchData();
      // Restablecer el formulario después de la operación exitosa
      formEdit.resetFields();
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async (record) => {
    // Aquí debes manejar la lógica para eliminar el registro
    // Puedes actualizar tu lista de datos (data) después de eliminar
    //console.log(record);
    const db = getFirestore();
    const categoriaDocRef = doc(db, "categorias", record.key); // Asegúrate de usar el ID correcto del registro
    await deleteDoc(categoriaDocRef);
    fetchData();
    message.success("Se ha eliminado correctamente una categoria");
  };

  const columns = [
    {
      title: "Imagen",
      dataIndex: "photo",
      key: "photo",
      render: (photo) =>
        photo ? (
          <img
            src={photo}
            alt="Foto de la Categoría"
            style={{ width: "50px", height: "50px", objectFit: "cover" }}
          />
        ) : null,
    },
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
    },
    {
      title: "Subcategorías",
      dataIndex: "subcategorias",
      key: "subcategorias",
      render: (subcategorias) =>
        subcategorias.map((subcategoria) => (
          <Tag key={subcategoria.id} color="blue">
            {subcategoria.subcategoria}
          </Tag>
        )),
    },

    {
      title: "Acciones",
      dataIndex: "acciones",
      key: "acciones",
      render: (_, record) => (
        <Space>
          <Button
            icon={<EditOutlined />}
            onClick={() => showModal("editar", record)}
          >
            Editar
          </Button>
          <Popconfirm
            title="¿Estás seguro de eliminar esta categoría?"
            onConfirm={() => handleDelete(record)}
            okText="Sí"
            cancelText="No"
          >
            <Button danger icon={<DeleteOutlined />}>
              Eliminar
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <h1>Categorias</h1>
        <Input.Search
          placeholder="Buscar categoría"
          onSearch={handleSearch}
          style={{ width: 400, marginBottom: 16 }}
        />
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => showModal("agregar", null)}
          style={{ marginBottom: 16 }}
        >
          Agregar Categoría
        </Button>
      </div>

      <Table dataSource={filteredCategorias} columns={columns} />

      <AddCategoriaModal
        visible={addModalVisible}
        onCancel={() => setAddModalVisible(false)}
        onOk={handleOkAdd}
        form={formAdd}
        imageUrl={imageUrlAdd}
        setImageUrl={setImageUrlAdd}
      />

      <EditCategoriaModal
        visible={editModalVisible}
        onCancel={() => setEditModalVisible(false)}
        onOk={handleOkEdit}
        form={formEdit}
        imageUrl={imageUrl}
        setImageUrl={setImageUrl}
        categoriaSeleccionada={categoriaSeleccionada}
      />
    </div>
  );
};

export default CategoriasTipo;
