import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  orderBy,
  onSnapshot,
  updateDoc,
  doc,
  getDoc,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../../../firebaseConfig";
import {
  List,
  Typography,
  Button,
  Spin,
  Card,
  Avatar,
  Empty,
  Alert,
} from "antd";
import { useParams } from "react-router-dom";
import { UserOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import moment from "moment";

const { Text } = Typography;

const AdminChatApproval = () => {
  const { subpedidoId } = useParams();
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [usuario, setUsuario] = useState({});
  const [vendedor, setVendedor] = useState({});
  const [notification, setNotification] = useState("");

  useEffect(() => {
    const fetchUsers = async () => {
      if (subpedidoId) {
        const subpedidoDocRef = doc(db, "subpedidos", subpedidoId);
        const subpedidoDocSnapshot = await getDoc(subpedidoDocRef);

        if (subpedidoDocSnapshot.exists()) {
          const subpedidoData = subpedidoDocSnapshot.data();
          const usuarioUid = subpedidoData.usuarioUid;
          const vendedorUid = subpedidoData.vendedorUid;

          // Obtener información del usuario
          const usuarioDocRef = doc(db, "roles", usuarioUid);
          const usuarioDocSnapshot = await getDoc(usuarioDocRef);
          if (usuarioDocSnapshot.exists()) {
            setUsuario(usuarioDocSnapshot.data());
          }

          // Obtener información del vendedor
          const vendedorDocRef = doc(db, "tiendas", vendedorUid);
          const vendedorDocSnapshot = await getDoc(vendedorDocRef);
          if (vendedorDocSnapshot.exists()) {
            setVendedor(vendedorDocSnapshot.data());
          }
        }
      }
    };

    fetchUsers();
  }, [subpedidoId]);

  useEffect(() => {
    if (subpedidoId) {
      const messagesCollection = collection(
        db,
        "subpedidos",
        subpedidoId,
        "messages"
      );
      const messagesQuery = query(
        messagesCollection,
        orderBy("timestamp", "asc")
      );

      const unsubscribe = onSnapshot(messagesQuery, (snapshot) => {
        const fetchedMessages = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setMessages(fetchedMessages);
        setLoading(false);
      });

      return () => unsubscribe();
    }
  }, [subpedidoId]);

  const approveMessage = async (messageId) => {
    try {
      const messageDoc = doc(
        db,
        "subpedidos",
        subpedidoId,
        "messages",
        messageId
      );
      await updateDoc(messageDoc, {
        status: "approved",
        timestamp: serverTimestamp(),
      });
      setNotification("Mensaje aprobado");
    } catch (error) {
      console.error("Error updating document: ", error);
      setNotification("Error al aprobar el mensaje");
    }
  };

  const rejectMessage = async (messageId) => {
    try {
      const messageDoc = doc(
        db,
        "subpedidos",
        subpedidoId,
        "messages",
        messageId
      );
      await updateDoc(messageDoc, {
        status: "rejected",
        timestamp: serverTimestamp(),
      });
      setNotification("Mensaje rechazado");
    } catch (error) {
      console.error("Error updating document: ", error);
      setNotification("Error al rechazar el mensaje");
    }
  };

  return (
    <Card
      style={{
        padding: "20px",
        maxWidth: "800px",
        margin: "0 auto",
        marginTop: "10px",
      }}
    >
      <h2>Aprobar mensajes</h2>
      <div
        style={{
          border: "1px solid #d9d9d9",
          borderRadius: "8px",
          padding: "10px",
          backgroundColor: "#fafafa",
        }}
      >
        {loading ? (
          <Spin />
        ) : (
          <div>
            {messages.length > 0 ? (
              <List
                dataSource={messages}
                renderItem={(message) => (
                  <List.Item
                    key={message.id}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "10px",
                      backgroundColor:
                        message.status === "pending" ? "#fff3f3" : "#e6f7ff",
                      padding: "10px",
                      borderRadius: "8px",
                    }}
                  >
                    <Avatar
                      style={{ marginRight: "10px" }}
                      src={
                        message.senderId === usuario.uid
                          ? usuario.photo || <UserOutlined />
                          : vendedor.foto || <UserOutlined />
                      }
                    />
                    <div style={{ flex: 1 }}>
                      <div>
                        <Text strong>
                          {message.senderId === usuario.uid
                            ? usuario.username
                            : vendedor.nombre}
                        </Text>
                      </div>
                      <Text>{message.content}</Text>
                      {message.status === "rejected" && (
                        <Text
                          type="danger"
                          style={{ display: "block", marginTop: "5px" }}
                        >
                          No aprobado
                        </Text>
                      )}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginTop: "5px",
                        }}
                      >
                        <Text type="secondary" style={{ marginRight: "10px" }}>
                          {moment(message.timestamp?.toDate()).format(
                            "YYYY-MM-DD HH:mm"
                          )}
                        </Text>
                      </div>
                    </div>
                    {message.status === "pending" && (
                      <div>
                        <Button
                          type="primary"
                          onClick={() => approveMessage(message.id)}
                          icon={<CheckOutlined />}
                          style={{ marginRight: "10px" }}
                        >
                          Aprobar
                        </Button>
                        <Button
                          type="danger"
                          onClick={() => rejectMessage(message.id)}
                          icon={<CloseOutlined />}
                        >
                          Rechazar
                        </Button>
                      </div>
                    )}
                  </List.Item>
                )}
              />
            ) : (
              <Empty description="Aún no se ha enviado ningún mensaje" />
            )}
          </div>
        )}
      </div>
    </Card>
  );
};

export default AdminChatApproval;
