import React, { useState, useEffect } from "react";
import {
  Typography,
  List,
  Button,
  Row,
  Col,
  Card,
  Select,
  Modal,
  Form,
  message,
  Input,
  Image,
} from "antd";
import {
  ShoppingCartOutlined,
  PlusOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import {
  eliminarCarritoUsuario,
  getUserCart,
  obtenerDetallesProductos,
  obtenerDetallesServicio,
} from "../../../utils/carrito/carritoUtils";
import { useUser } from "../../../context/UserContext";
import ListaItemCheckout from "../../../components/ListaCheckout/ListaItemCheckout";
import {
  addCardToCustomer,
  createCharge,
  getCardToCustomer,
} from "../../../Api/Openpay/OpenPayApi";
import {
  getFirestore,
  collection,
  doc,
  addDoc,
  getDoc,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import openpayimg from "../../../assets/openpay.png";
import tarjetasaceptadas from "../../../assets/tarjetasaceptadas.jpg";
import visaymaster from "../../../assets/visa-mastercard.png";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { v4 as uuidv4 } from "uuid";
const { Title, Text } = Typography;

const Checkout = () => {
  const { userData } = useUser();
  const [form] = Form.useForm();
  const [formAdd] = Form.useForm();
  const [productoDetalles, setProductoDetalles] = useState([]);
  const [creditCards, setCreditCards] = useState([]);
  const [creditCardsParticipantes, setCreditCardsParticipantes] = useState([
    "BANAMEX",
    "BANCOMER",
    "BANCO SANTANDER SERFIN",
    "SCOTIABANK",
    "AMERICAN EXPRESS",
    "BANCO MERCANTIL DEL NORTE",
    "HSBC",
    "INBURSA",
    "MONEX",
    "AFIRME",
  ]);
  const [tarjeta, setTarjeta] = useState("");
  const [selectedMonths, setSelectedMonths] = useState(1);
  const [selectedCard, setSelectedCard] = useState("");
  const [direcciones, setDirecciones] = useState([]);
  const [selectedDireccion, setSelectedDireccion] = useState("");
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { confirm } = Modal;

  const irADirecciones = () => {
    navigate("/perfil/direcciones", { state: { desdeCheckout: true } });
  };

  const irAInicio = () => {
    navigate("/");
  };

  const getTotalPrice = (productoDetalles) => {
    return productoDetalles.reduce((total, item) => {
      let subtotal = 0;

      if (item.tipo === "producto") {
        subtotal = item.total * item.cantidad;
      } else {
        if (item.stock) {
          if (item.duracion === "unico") {
            subtotal = item.total * 1;
          } else {
            subtotal = item.total * item.cantidad * item.cantidadDuracion;
          }
        } else {
          if (item.duracion === "unico") {
            subtotal = item.total * 1;
          } else {
            subtotal = item.total * item.cantidadDuracion;
          }
        }
      }
      return total + subtotal;
    }, 0);
  };

  const getPlataformaImpuesto = (productoDetalles) => {
    // Calcular el impuesto de plataforma
    const impuestoPlataforma = productoDetalles.reduce((total, item) => {
      let subtotal = 0;
      if (item.tipo === "producto") {
        subtotal = item.total * item.cantidad;
      } else {
        if (item.stock) {
          if (item.duracion === "unico") {
            subtotal = item.total * 1;
          } else {
            subtotal = item.total * item.cantidad * item.cantidadDuracion;
          }
        } else {
          if (item.duracion === "unico") {
            subtotal = item.total * 1;
          } else {
            subtotal = item.total * item.cantidadDuracion;
          }
        }
      }
      const comisionPlataforma = subtotal * (item.comisionPlataforma / 100);
      return total + comisionPlataforma;
    }, 0);

    // Calcular el impuesto de meses sin intereses
    const impuestoMesesSinIntereses = productoDetalles.reduce((total, item) => {
      let subtotal = 0;
      if (item.tipo === "producto") {
        subtotal = item.total * item.cantidad;
      } else {
        if (item.stock) {
          if (item.duracion === "unico") {
            subtotal = item.total * 1;
          } else {
            subtotal = item.total * item.cantidad * item.cantidadDuracion;
          }
        } else {
          if (item.duracion === "unico") {
            subtotal = item.total * 1;
          } else {
            subtotal = item.total * item.cantidadDuracion;
          }
        }
      }
      const comisionMesesSinIntereses =
        subtotal * (item.comisionMesesSinIntereses / 100);
      return total + comisionMesesSinIntereses;
    }, 0);

    return { impuestoPlataforma, impuestoMesesSinIntereses };
  };

  const cargarTarjetas = async () => {
    try {
      if (userData) {
        const response = await getCardToCustomer(userData.openpayId);
        if (response.length !== 0) {
          // Verifica si la respuesta no está vacía
          setCreditCards(response);
        }
      }
    } catch (error) {
      console.error("Error al cargar tarjetas:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchDirecciones = async () => {
    try {
      if (userData) {
        const db = getFirestore();
        const userId = userData.uid;
        const direccionDocRef = doc(db, "direcciones", userId);

        const direccionDoc = await getDoc(direccionDocRef);

        if (direccionDoc.exists()) {
          const direccionesData = direccionDoc.data().direcciones || {};
          const direccionesArray = Object.values(direccionesData).map(
            (direccion, index) => {
              // Puedes usar el índice como identificador o generar un identificador único aquí
              const identificador = `${index}`; // Cambia esto según tus necesidades
              return { ...direccion, id: identificador };
            }
          );
          setDirecciones(direccionesArray);
        }
      }
    } catch (error) {
      console.error("Error al obtener direcciones:", error.message);
    }
  };

  useEffect(() => {
    fetchDirecciones();
  }, [userData]);

  const mostrarModal = () => {
    setVisible(true);
  };
  const handleCancel = () => {
    setVisible(false);
  };

  const handleSubmit = async (values) => {
    try {
      if (userData) {
        setLoading(true);
        await addCardToCustomer(values, userData.openpayId);
        message.success("Tarjeta agregada con éxito");
        cargarTarjetas();
        formAdd.resetFields();
        setLoading(false);
        setVisible(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error al agregar tarjeta:", error);
      message.error("Error al agregar tarjeta");
    }
  };

  useEffect(() => {
    cargarTarjetas();
  }, [userData]); // El efecto se ejecuta solo una vez al montar el componente

  const total =
    Array.isArray(productoDetalles) && productoDetalles.length > 0
      ? getTotalPrice(productoDetalles)
      : 0;

  const totalImpuesto =
    Array.isArray(productoDetalles) && productoDetalles.length > 0
      ? getPlataformaImpuesto(productoDetalles)
      : 0;

  useEffect(() => {
    const fetchCartDetails = async () => {
      try {
        if (userData) {
          const userCart = await getUserCart(userData.uid);

          if (userCart) {
            const detallesProductos = await obtenerDetallesProductos(userCart);
            const detallesServicios = await obtenerDetallesServicio(userCart);
            const detallesCombinados = [
              ...detallesProductos,
              ...detallesServicios,
            ];
            setProductoDetalles(detallesCombinados);
          }
        }
      } catch (error) {
        console.error("Error al obtener detalles del carrito:", error);
      }
    };

    fetchCartDetails();
  }, [userData]);

  const crearPedido = async () => {
    try {
      const db = getFirestore();

      const pedidoUid = uuidv4();

      const data = {
        source_id: selectedCard,
        method: "card",
        amount: total.toFixed(2),
        currency: "MXN",
        description: "Pago en plataforma blizu",
        order_id: pedidoUid,
        device_session_id: userData.uid,
      };

      if (selectedMonths > 1) {
        data.payment_plan = {
          payments: selectedMonths,
        };
      }

      //   createCharge(data, userData.openpayId);

      let totalPagoOpenpay = 0;
      if (selectedMonths > 1) {
        // Calcular la comisión de OpenPay para meses sin intereses
        totalPagoOpenpay += totalImpuesto["impuestoMesesSinIntereses"];
      }
      // Encontrar la dirección seleccionada
      const direccion = direcciones.find(
        (direccion) => direccion.id === selectedDireccion
      );

      if (!selectedDireccion) {
        console.error("No se encontró la dirección seleccionada");
        return null;
      }

      const direccionEntrega = `${direccion.calle} ${direccion.numeroExterior}, ${direccion.numeroInterior}, ${direccion.colonia}, ${direccion.codigoPostal}, ${direccion.estado}, ${direccion.municipio}`;

      const pedidoRef = await addDoc(collection(db, "pedidos"), {
        direccionEntrega: direccionEntrega,
        tarjetaBanco: tarjeta.bank_name,
        tarjetaNumero: tarjeta.card_number,
        tarjetaTipo: tarjeta.type,
        totalGeneral: total,
        meses: parseInt(selectedMonths),
        totalComisiones: totalImpuesto,
        totalPagoOpenpayMeses: totalPagoOpenpay,
        fechaCreacion: serverTimestamp(),
        usuarioUid: userData.uid,
        uid: pedidoUid,
        devolucion: 0,
        coordenadas: direccion.coordenadas,
      });

      // Objeto para almacenar los subpedidos por vendedorUid
      const subpedidosPorVendedor = {};

      // Calcular el total por vendedor y almacenar los productos en subpedidos
      productoDetalles.forEach((product) => {
        const vendedorUid = product.vendedorUid;
        let costoProducto = 0;
        if (product.tipo === "producto") {
          costoProducto = product.total * product.cantidad;
        } else {
          if (product.stock) {
            if (product.duracion === "unico") {
              costoProducto = product.total * 1;
            } else {
              costoProducto =
                product.total * product.cantidad * product.cantidadDuracion;
            }
          } else {
            if (product.duracion === "unico") {
              costoProducto = product.total * 1;
            } else {
              costoProducto = product.total * product.cantidadDuracion;
            }
          }
        }

        let costoImpuestos = 0;
        product.estatus = "Pendiente de revisión del vendedor";
        product.pagadoVendedor = false;
        // Calcular el costo de los impuestos
        if (product.comisionMesesSinIntereses) {
          costoImpuestos +=
            (product.comisionMesesSinIntereses / 100) * costoProducto;
        }
        if (product.comisionPlataforma) {
          costoImpuestos += (product.comisionPlataforma / 100) * costoProducto;
        }

        // Calcular el total por vendedor
        if (!subpedidosPorVendedor[vendedorUid]) {
          subpedidosPorVendedor[vendedorUid] = {
            total: costoProducto, // Inicialmente solo el costo del producto
            comisiones: costoImpuestos, // Costo total de impuestos para este subpedido
            productos: [{ ...product }], // Agregar producto al subpedido
          };
        } else {
          subpedidosPorVendedor[vendedorUid].total += costoProducto; // Agregar costo del producto al total del subpedido
          subpedidosPorVendedor[vendedorUid].comisiones += costoImpuestos; // Agregar costo de impuestos al total del subpedido
          subpedidosPorVendedor[vendedorUid].productos.push({ ...product }); // Agregar producto al subpedido
        }
      });

      // Buscar el producto o servicio y restar el stock vendido
      productoDetalles.forEach(async (product) => {
        if (product.stock && product.tipo === "producto") {
          const productoRef = doc(db, "productos", product.id);
          const productoSnapshot = await getDoc(productoRef);
          if (productoSnapshot.exists()) {
            const productoData = productoSnapshot.data();
            const nuevoStock = productoData.stock - product.cantidad;
            // Verificar que el stock no sea negativo
            if (nuevoStock >= 0) {
              // Actualizar el stock en la base de datos
              await updateDoc(productoRef, { stock: nuevoStock });
           //   console.log(`Stock actualizado para ${product.nombre}`);
            } else {
              console.error(`Stock insuficiente para ${product.nombre}`);
              throw new Error(`Stock insuficiente para ${product.nombre}`);
            }
          } else {
            console.error(`No se encontró el producto ${product.nombre}`);
            throw new Error(`No se encontró el producto ${product.nombre}`);
          }
        } else if (product.stock && product.tipo === "servicio") {
          const servicioRef = doc(db, "servicios", product.id);
          const servicioSnapshot = await getDoc(servicioRef);
          if (servicioSnapshot.exists()) {
            const servicioData = servicioSnapshot.data();
            const nuevoStock = servicioData.stock - product.cantidad;
            // Verificar que el stock no sea negativo
            if (nuevoStock >= 0) {
              // Actualizar el stock en la base de datos
              await updateDoc(servicioRef, { stock: nuevoStock });
           //   console.log(`Stock actualizado para ${product.nombre}`);
            } else {
              console.error(`Stock insuficiente para ${product.nombre}`);
              throw new Error(`Stock insuficiente para ${product.nombre}`);
            }
          } else {
            console.error(`No se encontró el servicio ${product.nombre}`);
            throw new Error(`No se encontró el servicio ${product.nombre}`);
          }
        }
      });

      // Agregar los subpedidos asociados al pedido por vendedorUid
      for (const vendedorUid of Object.keys(subpedidosPorVendedor)) {
        const subpedidoPorVendedor = subpedidosPorVendedor[vendedorUid];
        const subpedido = {
          pedidoId: pedidoRef.id,
          vendedorUid: vendedorUid,
          comisiones: subpedidoPorVendedor.comisiones.toFixed(2),
          total: subpedidoPorVendedor.total.toFixed(2),
          fechaCreacion: serverTimestamp(),
          usuarioUid: userData.uid,
        };

        try {
          // Agregar el subpedido a la colección de subpedidos
          const subpedidoref = await addDoc(
            collection(db, "subpedidos"),
            subpedido
          );

          // Para cada producto en el subpedido, agregar el ID del subpedido y guardarlo en la tabla "Productos Pedidos"
          for (const producto of subpedidoPorVendedor.productos) {
            if (producto.cantidad === undefined) {
              delete producto.cantidad;
            }

            producto.subpedidoId = subpedidoref.id; // Agregar el ID del subpedido al producto
            await addDoc(collection(db, "productosPedidos"), producto);
          //  console.log("Producto pedido creado:", producto.id);
          }
        } catch (error) {
          console.error("Error al agregar subpedido:", error);
          throw new Error("Error al agregar subpedido");
        }
      }

      await eliminarCarritoUsuario(userData.uid);
      message.success("El pago de su pedido a sido aprobado");
      navigate("/pedidos");
      return pedidoRef.id; // Retornar el ID del pedido creado
    } catch (error) {
      console.error("Error al crear el pedido:", error);
      throw new Error("Error al crear el pedido");
    }
  };

  const handleConfirmPedido = () => {
    confirm({
      title: "Confirmar pedido",
      content: "¿Estás seguro de que deseas realizar este pedido?",
      onOk() {
        // Aquí puedes colocar la lógica para realizar el pedido
        form.submit();
      },
      onCancel() {},
    });
  };

  const handleCardChange = (value) => {
    setSelectedCard(value);
    const foundCard = creditCards.find((card) => card.id === value);
    if (foundCard != undefined) {
      setTarjeta(foundCard);
    }

    setSelectedMonths(1); // Reiniciar la selección de meses al cambiar la tarjeta
  };

  return (
    <div style={{ margin: "10px" }}>
      <Row gutter={[16, 16]}>
        {/* Sección de detalles del carrito */}
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <Card>
            <Typography.Title level={3}>Resumen de pedido</Typography.Title>
            <List
              dataSource={productoDetalles}
              renderItem={(item) => <ListaItemCheckout item={item} />}
            />
          </Card>
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <div>
            <Card>
              <Button
                type="primary"
                onClick={irAInicio}
                style={{ marginBottom: "10px", backgroundColor: "#111" }}
              >
                <ArrowLeftOutlined /> Volver a inicio
              </Button>
              <Title level={3}>Información del pedido</Title>
              <Title style={{ color: "#1dbf73" }} level={2}>
                {" "}
                ${total.toFixed(2)}
              </Title>

              <Title level={4}>Información de entrega</Title>
              <Form onFinish={crearPedido} form={form} layout="vertical">
                <Form.Item
                  label="Dirección de entrega"
                  name="selectedDireccion"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, seleccione una dirección de entrega",
                    },
                  ]}
                >
                  <Select
                    value={selectedDireccion}
                    onChange={(value) => setSelectedDireccion(value)}
                    style={{ width: "100%" }}
                  >
                    <option value="">Seleccione una dirección</option>
                    {direcciones.map((direccion) => (
                      <option key={direccion.id} value={direccion.id}>
                        {direccion.alias} - {direccion.calle}{" "}
                        {direccion.numeroExterior}, {direccion.numeroInterior},{" "}
                        {direccion.colonia}, {direccion.codigoPostal},{" "}
                        {direccion.estado}, {direccion.municipio}
                      </option>
                    ))}
                  </Select>
                </Form.Item>
                <Button
                  type="primary"
                  onClick={irADirecciones}
                  style={{ marginBottom: "10px" }}
                >
                  Ir a direcciones
                </Button>

                <Title level={4}>Información de pago</Title>
                <Text>
                  Por el momento el unico medio de pago es con tarjeta
                </Text>
                <Form.Item
                  label="Tarjeta"
                  name="selectedCard"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, seleccione una tarjeta para pagar",
                    },
                  ]}
                >
                  <Select
                    value={selectedCard}
                    onChange={handleCardChange}
                    style={{ width: "100%" }}
                  >
                    <option value="">Seleccione una tarjeta</option>
                    {creditCards.map((card) => (
                      <option key={card.id} value={card.id}>
                        {card.card_number}
                      </option>
                    ))}
                  </Select>
                </Form.Item>

                {creditCardsParticipantes.includes(tarjeta.bank_name) &&
                  tarjeta.type === "credit" && (
                    <Form.Item
                      label="Número de meses sin intereses"
                      name="selectedMonths"
                      rules={[
                        {
                          required: true,
                          message:
                            "Por favor, seleccione la cantidad de meses para pagar",
                        },
                      ]}
                    >
                      <Select
                        value={selectedMonths}
                        onChange={(value) => setSelectedMonths(value)}
                        style={{ width: "300px" }}
                      >
                        <option value="">
                          Seleccione la cantidad de meses
                        </option>
                        <option value="1">
                          1 mes (1 x {total.toFixed(2)})
                        </option>
                        <option value="3">
                          3 meses (3 x {(total / 3).toFixed(2)})
                        </option>
                        <option value="6">
                          6 meses (6 x {(total / 6).toFixed(2)})
                        </option>
                        <option value="9">
                          9 meses (9 x {(total / 9).toFixed(2)})
                        </option>
                        <option value="12">
                          12 meses (12 x {(total / 12).toFixed(2)})
                        </option>
                      </Select>
                    </Form.Item>
                  )}
                <div style={{ marginBottom: "16px" }}>
                  <Button
                    type="primary"
                    onClick={mostrarModal}
                    icon={<PlusOutlined />}
                    style={{ backgroundColor: "#1dbe72" }}
                  >
                    Agregar tarjeta
                  </Button>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Button
                    size="large"
                    style={{ backgroundColor: "black", color: "white" }}
                    icon={<ShoppingCartOutlined />}
                    onClick={handleConfirmPedido}
                  >
                    Realizar pedido
                  </Button>
                </div>
              </Form>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Image
                  style={{ maxWidth: "40%" }}
                  src={openpayimg}
                  alt="openpay"
                  preview={false}
                />
                <Image
                  style={{ maxWidth: "50%" }}
                  src={visaymaster}
                  alt="visa y mastercard"
                  preview={false}
                />
              </div>

              <Image
                style={{ maxWidth: "100%" }} // Establece el ancho máximo al 100% del contenedor
                src={tarjetasaceptadas}
                alt="tarjetas aceptadas"
                preview={false}
              />
            </Card>
          </div>
        </Col>
      </Row>

      <Modal
        title="Agregar Tarjeta"
        visible={visible}
        onCancel={handleCancel}
        footer={null}
      >
        <div style={{ textAlign: "center" }}>
          <Image width={150} src={openpayimg} alt="openpay" preview={false} />
          <Image width={150} src={visaymaster} alt="openpay" preview={false} />
          <Form onFinish={handleSubmit} layout="vertical" form={formAdd}>
            {/* Campos del formulario para la nueva tarjeta */}
            {/* Ajusta según tus necesidades */}
            <Form.Item
              label="Número de Tarjeta"
              name="card_number"
              rules={[
                {
                  required: true,
                  message: "Por favor, ingrese el número de tarjeta",
                },
                {
                  pattern: /^[0-9]{16}$/,
                  message: "El número de tarjeta debe tener 16 dígitos",
                },
              ]}
            >
              <Input maxLength={16} />
            </Form.Item>

            <Form.Item
              label="Nombre en la Tarjeta"
              name="holder_name"
              rules={[
                {
                  required: true,
                  message: "Por favor, ingrese el nombre en la tarjeta",
                },
                {
                  max: 50,
                  message: "El nombre debe tener menos de 50 caracteres",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Mes de Expiración"
              name="expiration_month"
              rules={[
                {
                  required: true,
                  message: "Por favor, ingrese el mes de expiración",
                },
                {
                  pattern: /^(0[1-9]|1[0-2])$/,
                  message: "Ingrese un mes válido (01-12)",
                },
              ]}
            >
              <Input maxLength={2} />
            </Form.Item>

            <Form.Item
              label="Año de Expiración"
              name="expiration_year"
              rules={[
                {
                  required: true,
                  message: "Por favor, ingrese el año de expiración",
                },
                {
                  pattern: /^(0[1-9]|1[0-9]|2[0-9]|3[0-9])$/,
                  message: "Ingrese un año válido (ej. 25)",
                },
              ]}
            >
              <Input maxLength={2} />
            </Form.Item>

            <Form.Item
              label="CVV"
              name="cvv2"
              rules={[
                { required: true, message: "Por favor, ingrese el CVV" },
                {
                  pattern: /^[0-9]{3,4}$/,
                  message: "El CVV debe tener 3 o 4 dígitos",
                },
              ]}
            >
              <Input maxLength={4} />
            </Form.Item>

            <Button type="primary" htmlType="submit" loading={loading}>
              Agregar Tarjeta
            </Button>
          </Form>

          <Image
            width={400}
            src={tarjetasaceptadas}
            alt="tarjetas aceptadas"
            preview={false}
          />
        </div>
      </Modal>
    </div>
  );
};

export default Checkout;
