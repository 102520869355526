import React, { useEffect, useState } from "react";
import { Flex, Button, Modal } from "antd";
import { useNavigate, useParams } from "react-router-dom";

import FiltroProductos from "../../../components/FiltroProductos/FiltroProductos";
import { useUser } from "../../../context/UserContext";
import { ListaProductoItemBuscador } from "../../../components/ProductosBuscador/ListaProductoItemBuscador";
import { addToCart } from "../../../utils/productos/productoUtils";
import { Toaster } from "sonner";
import { getArticulosCerca } from "../../../utils/buscador/buscadorUtils";
import { useSearchParams } from "react-router-dom";
import { handleFavoriteProductoAction } from "../../../utils/favoritos/favoritosUtils";
import "./Buscador.scss";

export default function BuscadorProductos() {
  const [articulos, setArticulos] = useState([]);
  const [filters, setFilters] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { userLocation, userData } = useUser();
  const [filtroVisible, setFiltroVisible] = useState(false);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [search, setSearch] = useState();

  const toggleFiltroVisibility = () => {
    setFiltroVisible(!filtroVisible);
  };

  const applyFilters = async (filters) => {
    try {
      // Aplica los filtros y obtiene los artículos correspondientes
      const articulosEncontrados = await getArticulosCerca(
        userLocation,
        filters,
        userData
      );
      // Actualiza el estado de los artículos con los encontrados
      setArticulos(articulosEncontrados);
      setFilters(filters);
    } catch (error) {
      console.error("Error al aplicar filtros y obtener artículos:", error);
    }
  };

  useEffect(() => {
    try {
      // Crear un nuevo objeto de filtros
      const newFilters = {};
      // Obtiene los valores de los parámetros de consulta
      const tipo = searchParams.get("tipo");
      const categoria = searchParams.get("categoria");
      const search = searchParams.get("search");
      // Verifica si los parámetros están presentes y válidos
      if (userLocation) {
        // Agrega el tipo al filtro si está presente y válido
        if (tipo) {
          newFilters.tipo = tipo;
        }

        // Agrega la categoría al filtro si está presente y válida
        if (categoria) {
          newFilters.categoria = categoria;
        }

        // Agrega el término de búsqueda al filtro si está presente y válido
        if (search) {
          newFilters.search = search;
          setSearch(search);
        }

        // Actualiza el estado de los filtros con el nuevo objeto

        applyFilters(newFilters);
        setFilters(newFilters);
      }
    } catch (error) {
      console.error("Error al aplicar filtros y obtener artículos:", error);
    }
  }, [searchParams, userLocation]);

  const handleFavoriteToggle = async (productId, isFavorite) => {
    // Invierte el estado local de isFavorite para el producto
    const updatedProducts = articulos.map((producto) => {
      if (producto.id === productId) {
        return { ...producto, isFavorite: !isFavorite };
      }
      return producto;
    });
    setArticulos(updatedProducts);

    // Llama a la función handleFavoriteAction con el ID del producto y la acción determinada
    // Determina la acción según si el producto ya está en favoritos o no
    const action = isFavorite ? "remove" : "add";
    handleFavoriteProductoAction(productId, action, userData);
  };

  const handleModalOk = () => {
    setIsModalVisible(false);
    navigate("/login");
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Flex justify="center" vertical>
      <Toaster richColors position="top-center" />

      <Flex direction="column">
        <Flex vertical style={{ marginLeft: "20px" }} className="web-opcional">
          <FiltroProductos
            inicialFilters={filters}
            applyFilters={applyFilters}
            style={{
              position: "fixed",
              left: 0,
              height: "100%",
            }}
          />
        </Flex>

        <Flex
          justify="center"
          vertical
          style={{ margin: "10px", height: "100%" }}
        >
          <Button onClick={toggleFiltroVisibility} className="button-movil">
            {filtroVisible ? "Ocultar filtros" : "Mostrar filtros"}
          </Button>
          {filtroVisible && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FiltroProductos
                inicialFilters={filters}
                applyFilters={applyFilters}
              />
            </div>
          )}
          {search &&
            search.trim() !== "" && ( // Verifica si search no está vacío ni compuesto solo de espacios en blanco
              <h1 style={{ margin: 0, marginRight: "20px" }}>
                Resultado de búsqueda de: "{search}"
              </h1>
            )}

          <p style={{ fontSize: "18px" }}>
            {articulos.length} item(s) encontrados
          </p>
          <div className="center-flex">
          <Flex wrap="wrap" gap="large" justify="center">
            {articulos.map((producto, index) => (
              <ListaProductoItemBuscador
                producto={producto}
                addToCart={() => addToCart(producto)}
                key={`${producto.id}-${index}`}
                favorite={() =>
                  handleFavoriteToggle(producto.id, producto.isFavorite)
                } // Nueva llamada a la función
                isFavorite={producto.isFavorite}
              />
            ))}
            
          </Flex>
          </div>
        </Flex>
      </Flex>

      <Modal
        title="Autenticación Requerida"
        visible={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        okText="Iniciar Sesión"
        cancelText="Cancelar"
      >
        <p>Debes iniciar sesión para agregar productos al carrito.</p>
      </Modal>
    </Flex>
  );
}
