import React, { useEffect, useState } from "react";
import { Flex, Card, Typography, Empty } from "antd";
import {
  collection,
  getDocs,
  getDoc,
  doc,
  updateDoc,
  setDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { db } from "../../../firebaseConfig";
import { useUser } from "../../../context/UserContext";
import { ListaProductoItemBuscador } from "../../../components/ProductosBuscador/ListaProductoItemBuscador";

import { addToCart } from "../../../utils/productos/productoUtils";
import {
  handleFavoriteProductoAction,
  handleFavoriteServicioAction,
  obtenerFavoritosConDetallesUsuario,
} from "../../../utils/favoritos/favoritosUtils";
import { ListaServiciosItemBuscador } from "../../../components/ServiciosBuscador/ListaServiciosItemBuscador";

const { Meta } = Card;
const Favoritos = () => {
  const [productsDetails, setProductsDetails] = useState({});

  const { userData, userFavorites } = useUser();

  const handleAddToCart = async (producto) => {
    await addToCart(producto, userData);
  };

  const handleFavoriteToggleProduct = async (productId, action, favorite) => {
    //console.log(productId, action, favorite);
    // Llama a la función handleFavoriteAction con el ID del producto y la acción determinada
    await handleFavoriteProductoAction(productId, action, userData);
  };

  const handleFavoriteToggleService = async (productId, action) => {
    // Llama a la función handleFavoriteAction con el ID del producto y la acción determinada
    await handleFavoriteServicioAction(productId, action, userData);
  };

  useEffect(() => {
    if (userData && userFavorites) {
      obtenerFavoritosConDetallesUsuario(userFavorites, setProductsDetails);
    }
  }, [userData, userFavorites]);

  return (
    <Flex
      justify="center"
      align="center"
      style={{ marginTop: "20px" }}
      vertical
    >
      <h1>Mis Productos favoritos</h1>
      <Flex wrap="wrap" gap="large" justify="center">
        {Array.isArray(productsDetails) && productsDetails.length > 0 ? (
          productsDetails.map((product, index) => (
            <React.Fragment key={index}>
              {product.tipoServicio ? (
                <ListaServiciosItemBuscador
                  servicio={product}
                  addToCart={() => handleAddToCart(product)}
                  favorite={() => {
                    if (product.isFavorite) {
                      handleFavoriteToggleService(product.id, "remove");
                    } else {
                      handleFavoriteToggleService(product.id, "add");
                    }
                  }}
                  isFavorite={product.isFavorite}
                  removeFavorite={() =>
                    handleFavoriteToggleService(product.id, "remove")
                  }
                />
              ) : (
                <ListaProductoItemBuscador
                  producto={product}
                  addToCart={() => handleAddToCart(product)}
                  favorite={() => {
                    if (product.isFavorite) {
                      handleFavoriteToggleProduct(product.id, "remove");
                    } else {
                      handleFavoriteToggleProduct(
                        product.id,
                        "add",
                        product.isFavorite
                      );
                    }
                  }}
                  isFavorite={product.isFavorite}
                  removeFavorite={() =>
                    handleFavoriteToggleProduct(product.id, "remove")
                  }
                />
              )}
            </React.Fragment>
          ))
        ) : (
          <Empty description="No hay productos agregados a favoritos" />
        )}
      </Flex>
    </Flex>
  );
};

export default Favoritos;
