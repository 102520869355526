import { Menu, Badge, Modal, message } from "antd";
import React from "react";
import {
  ShopOutlined,
  AreaChartOutlined,
  DollarOutlined,
  InsertRowBelowOutlined,
  UserOutlined,
  IdcardOutlined,
  PercentageOutlined,
  MenuOutlined,
  CrownOutlined,
  LogoutOutlined,
  HomeOutlined,
  SketchOutlined,
} from "@ant-design/icons";
import "./MenuList.scss";
import { Link, useNavigate } from "react-router-dom";
import { useUser } from "../../context/UserContext";
import logo from "../../assets/logo.png";
const { Item } = Menu;

export default function MenuListAdmin({
  vendedoresPendientes,
  pagoAVendedores,
  datosPendientes
}) {
  const navigate = useNavigate();
  const { clearUser } = useUser();

  const renderPendientesItem = () => {
    if (datosPendientes === 0) {
      return null;
    }
    const badgeCount = datosPendientes > 99 ? "99+" : datosPendientes;
    return (
      <Badge count={badgeCount} style={{ backgroundColor: "red" }}></Badge>
    );
  };
  const renderVendedoresItem = () => {
    if (vendedoresPendientes === 0) {
      return null;
    }
    const badgeCount = vendedoresPendientes > 99 ? "99+" : vendedoresPendientes;
    return (
      <Badge count={badgeCount} style={{ backgroundColor: "red" }}></Badge>
    );
  };


  const renderPagoAVendedoresItem = () => {
    if (pagoAVendedores === 0) {
      return null;
    }
    const badgeCount = pagoAVendedores > 99 ? "99+" : pagoAVendedores;
    return (
      <Badge count={badgeCount} style={{ backgroundColor: "red" }}></Badge>
    );
  };

  const handleLogout = () => {
    Modal.confirm({
      title: "Cerrar sesión",
      content: "¿Estás seguro de que deseas cerrar sesión?",
      okText: "Sí",
      okType: "danger",
      cancelText: "Cancelar",
      async onOk() {
        try {
          // Lógica para cerrar sesión
          clearUser();
          message.success("Se ha cerrado la sesión exitosamente");
          navigate("/login");
        } catch (error) {
          message.error("Error al cerrar la sesión");
        }
      },
    });
  };

  return (
    <Menu theme="light" mode="inline" className="menu-bar">
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <img src={logo} className="logo-menu" alt="blizu" />
      </div>
      <Item key="dashboard" icon={<AreaChartOutlined />}>
        <Link to="/admin">Dashboard</Link>
      </Item>
      <Item key="usuarios" icon={<UserOutlined />}>
        <Link to="/admin/usuarios">Clientes</Link>
      </Item>
      <Item key="vendedores" icon={<IdcardOutlined />}>
        <Link to="/admin/vendedores">Vendedores</Link>
      </Item>
      <Item key="admins" icon={<CrownOutlined />}>
        <Link to="/admin/admins">Administradores</Link>
      </Item>
      <Item key="vendedorespendientes" icon={<IdcardOutlined />}>
        <Link to="/admin/vendedores/pendientes">
          Vendedores pendientes
          {renderVendedoresItem()}
        </Link>
      </Item>
      <Item key="tiendas" icon={<ShopOutlined />}>
        <Link to="/admin/tiendas">Tiendas</Link>
      </Item>
      <Item key="pedidos" icon={<DollarOutlined />}>
        <Link to="/admin/pedidos">Transacciones</Link>
      </Item>
      <Item key="mensajes" icon={<MenuOutlined />}>
        <Link to="/admin/revicionchats">Chats pendientes</Link>
      </Item>
      <Menu.Item key="datospay" icon={<DollarOutlined />}>
        <Link to="/admin/aprobardatospago">Datos de pago {renderPendientesItem()}</Link>
      </Menu.Item>
      <Item key="pagos a vendedores" icon={<SketchOutlined />}>
        <Link to="/admin/pagosavendedores">Pagos a vendedores      {renderPagoAVendedoresItem()}</Link>
      </Item>
      <Item key="comisiones" icon={<PercentageOutlined />}>
        <Link to="/admin/comisiones" style={{ maxWidth: "150px" }}>
          Comisiones generales
        </Link>
      </Item>
      <Item key="categorias" icon={<MenuOutlined />}>
        <Link to="/admin/categorias">Categorias</Link>
      </Item>
      <Item key="carrusel" icon={<InsertRowBelowOutlined />}>
        <Link to="/admin/carrusel">Carrusel</Link>
      </Item>
      <Item key="cerrar" icon={<LogoutOutlined />}>
        <Link onClick={handleLogout}>Cerrar sesión</Link>
      </Item>
      <Item key="inicio" icon={<HomeOutlined />}>
        <Link to="/">Volver a inicio</Link>
      </Item>
    </Menu>
  );
}
