import React, { useState, useEffect } from "react";
import CartItem from "../../../components/CartItem";
import { Button, Empty, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { Toaster } from "sonner";
import "./CartView.css";
import { getProductoPorId } from "../../../utils/productos/productoUtils";
import { getServicioPorId } from "../../../utils/servicios/serviciosUtils";

const { Text } = Typography;

const CartView = ({ cartItems, onClose }) => { // Recibe onClose como prop
  const navigate = useNavigate();
  const [productoDetalles, setProductoDetalles] = useState([]);

  const handleCheckout = () => {
    onClose(); // Cierra el Drawer al hacer clic en "Ir a finalizar pedido"
    navigate("checkout");
  };

  useEffect(() => {
    const obtenerDetallesProductos = async () => {
      try {
        const promises = cartItems.productos.map(async (producto) => {
          const detalleProducto = await getProductoPorId(producto.id);
          return {
            ...detalleProducto,
            tipo: "producto",
            cantidad: producto.cantidad, // Mantener la cantidad del producto
          };
        });
        const productos = await Promise.all(promises);
        return productos;
      } catch (error) {
        console.error("Error al obtener detalles de los productos:", error);
      }
    };

    const obtenerDetallesServicio = async () => {
      try {
        const promises = cartItems.servicios.map(async (servicio) => {
          const detalleServicio = await getServicioPorId(servicio.id);
          let servicioActualizado = {
            ...detalleServicio,
            cantidad: servicio.cantidad,
          };

          if (detalleServicio.stock) {
            if (detalleServicio.duracion === "unico") {
              servicioActualizado = {
                ...servicioActualizado,
                tipo: "servicio",
                diaSeleccionado: servicio.diaSeleccionado,
                horaInicioSeleccionada: servicio.horaInicioSeleccionada,
              };
            } else {
              servicioActualizado = {
                ...servicioActualizado,
                tipo: "servicio",
                cantidadDuracion: servicio.cantidadDuracion,
                diaSeleccionado: servicio.diaSeleccionado,
                horaInicioSeleccionada: servicio.horaInicioSeleccionada,
              };
            }
          } else {
            if (detalleServicio.duracion === "unico") {
              servicioActualizado = {
                ...servicioActualizado,
                tipo: "servicio",
                diaSeleccionado: servicio.diaSeleccionado,
                horaInicioSeleccionada: servicio.horaInicioSeleccionada,
              };
            } else {
              servicioActualizado = {
                ...servicioActualizado,
                tipo: "servicio",
                cantidadDuracion: servicio.cantidadDuracion,
                diaSeleccionado: servicio.diaSeleccionado,
                horaInicioSeleccionada: servicio.horaInicioSeleccionada,
              };
            }
          }

          return servicioActualizado;
        });

        const productos = await Promise.all(promises);
        return productos;
      } catch (error) {
        console.error("Error al obtener detalles de los productos:", error);
      }
    };

    const obtenerDetalles = async () => {
      const productos = await obtenerDetallesProductos();
      const servicios = await obtenerDetallesServicio();
      const detallesCombinados = [...productos, ...servicios];
      setProductoDetalles(detallesCombinados);
    };

    obtenerDetalles();
  }, [cartItems]);

  const getTotalPrice = (productoDetalles) => {
    return productoDetalles.reduce((total, item) => {
      let subtotal = 0;

      if (item.tipo === "producto") {
        subtotal = item.total * item.cantidad;
      } else {
        if (item.stock) {
          if (item.duracion === "unico") {
            subtotal = item.total * item.cantidad * 1;
          } else {
            subtotal = item.total * item.cantidad * item.cantidadDuracion;
          }
        } else {
          if (item.duracion === "unico") {
            subtotal = item.total * 1;
          } else {
            subtotal = item.total * item.cantidadDuracion;
          }
        }
      }
      return total + subtotal;
    }, 0);
  };

  const total =
    Array.isArray(productoDetalles) && productoDetalles.length > 0
      ? getTotalPrice(productoDetalles)
      : 0;

  return (
    <div style={{ padding: "20px" }}>
      <Toaster richColors position="top-center" />
      {productoDetalles && productoDetalles.length > 0 ? (
        <>
          <div style={{ margin: "5px", textAlign: "right" }}>
              
          <Text
              strong
              style={{ display: "block", marginTop: "10px"}}
            >
              Subtotal: 
            </Text>
            <Text
              type="danger"
              strong
              style={{ display: "block", marginTop: "10px", fontSize: "30px"}}
            >
            ${total.toFixed(2)}
            </Text>
            <Button
              style={{ backgroundColor: "black", color: "white" }}
              onClick={handleCheckout}
            >
              Ir a finalizar pedido
            </Button>
          </div>

          {productoDetalles.map((item) => (
            <CartItem key={item.id} item={item} />
          ))}
        </>
      ) : (
        <Empty description="No hay productos en el carrito" />
      )}
    </div>
  );
};

export default CartView;
