// MapGoogle.js
import React, { Fragment, useEffect, useState, useRef } from "react";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";
import { Input } from "antd";
const libraries = ["places"];

const MapGoogle = ({ onMapClick, markerPosition }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyDQfjiw3oAMK7gtBChu4qF9Qq9OdB4XXb4",
    libraries: libraries,
  });

  const mapRef = useRef(null);
  const [mapCenter, setMapCenter] = useState(null);
  const [autocomplete, setAutocomplete] = useState(null);
  const [autocompleteValue, setAutocompleteValue] = useState("");

  useEffect(() => {
    if (mapRef.current && autocomplete) {
      autocomplete.bindTo("bounds", mapRef.current);
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (place.geometry && place.geometry.location) {
          setMapCenter({
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          });
          onMapClick({ latLng: place.geometry.location });
        }
      });
    }
  }, [autocomplete, onMapClick]);

  useEffect(() => {
    // Solo inicializamos el centro del mapa si es nulo (primer render)
    if (!mapCenter && markerPosition) {
      setMapCenter(markerPosition);
    }
  }, [mapCenter, markerPosition]);

  useEffect(() => {
    if (isLoaded) {
      const input = document.getElementById("autocomplete-input");
      const options = {
        types: ["geocode"],
        componentRestrictions: { country: "MX" },
      };
      const autocompleteInstance = new window.google.maps.places.Autocomplete(
        input,
        options
      );
      setAutocomplete(autocompleteInstance);

      autocompleteInstance.addListener("place_changed", () => {
        const place = autocompleteInstance.getPlace();
        if (place.geometry && place.geometry.location) {
          setMapCenter({
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          });

          console.log("desde buscador" + place.geometry.location.lat())
          onMapClick({ latLng: place.geometry.location });
        }
      });
    }
  }, [isLoaded, onMapClick]);

  if (loadError) return "Error al cargar el mapa";
  if (!isLoaded) return "Cargando mapa...";

  return (
    <Fragment>
      <Input
        id="autocomplete-input"
        type="text"
        value={autocompleteValue}
        onChange={(e) => setAutocompleteValue(e.target.value)}
        placeholder="Buscar ubicación..."
      />
      <GoogleMap
        mapContainerStyle={{ height: "400px", width: "100%" }}
        zoom={15}
        center={mapCenter || markerPosition}
        onClick={onMapClick}
        onLoad={(map) => {
          mapRef.current = map;
        }}
      >
        {markerPosition && <MarkerF position={markerPosition} />}
      </GoogleMap>
    </Fragment>
  );
};

export default MapGoogle;
