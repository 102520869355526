import React from "react";
import { Button, Modal, Menu, Dropdown, Avatar, message, Space } from "antd";
import { Link } from "react-router-dom";
import { useUser } from "../../context/UserContext";
import logo from "../../assets/logo.png";
import { useNavigate } from "react-router-dom";
import "./NavbarStyle.scss";
import { DownOutlined } from "@ant-design/icons";

const NavbarSinBuscador = () => {
  const { userData, clearUser, userFavorites, cartItems } = useUser();
  const navigate = useNavigate();


  const handleLogout = () => {
    Modal.confirm({
      title: "Cerrar sesión",
      content: "¿Estás seguro de que deseas cerrar sesión?",
      okText: "Sí",
      okType: "danger",
      cancelText: "Cancelar",
      async onOk() {
        try {
          // Lógica para cerrar sesión
          clearUser();
          message.success("Se ha cerrado la sesión exitosamente");
          navigate("/login");
        } catch (error) {
          message.error("Error al cerrar la sesión");
        }
      },
    });
  };
  const menu = (
    <Menu>
      <Menu.Item key="1">
        <Link to="/perfil">Ir a perfil</Link>
      </Menu.Item>

      {/* Mostrar el botón "Ir a mi tienda" solo si el role es igual a 1 */}
      {userData && userData.role === 1 && (
        <Menu.Item key="3">
          <Link to="/dashboard">Ir a mi panel de vendedor</Link>
        </Menu.Item>
      )}
      {/* Mostrar el botón "Ir a mi panel admin" solo si el role es igual a 2 */}
      {userData && userData.role === 2 && (
        <Menu.Item key="3">
          <Link to="/admin">Ir a mi panel administrador</Link>
        </Menu.Item>
      )}
      <Menu.Item key="2" onClick={handleLogout}>
        Cerrar sesión
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="styled-nav">
      <div className="logo-container">
        <Link to="/" style={{ textDecoration: "none", color: "black" }}>
          <img src={logo} style={{ width: "100px" }} alt="blizu" />
        </Link>
      </div>
    </div>
  );
};

export default NavbarSinBuscador;
