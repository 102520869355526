import { Button, Card, Popconfirm } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
const DireccionItem = ({ direccion, onEdit, onDelete }) => {
  const {
    nombreReceptor,
    alias,
    calle,
    numeroInterior,
    numeroExterior,
    colonia,
    codigoPostal,
    estado,
    municipio,
    referencias,
  } = direccion;

  return (
<Card style={{ marginBottom: "5px", margin: "10px", textAlign: "left" }}>
  <div>
    <strong>Alias:</strong> {alias}
  </div>
  <div>
    <strong>Receptor:</strong> {nombreReceptor}
  </div>
  <div>
    <strong>Dirección:</strong> {calle}, {numeroInterior}, {numeroExterior},{" "}
    {colonia}, {codigoPostal}, {estado}, {municipio}
  </div>
  <div>
    <strong>Referencias:</strong> {referencias}
  </div>
  <div style={{ textAlign: "right", marginTop: "10px" }}>
    <Button type="link" icon={<EditOutlined />} onClick={onEdit}>
      Editar
    </Button>
    <Popconfirm
      title="¿Estás seguro de eliminar esta dirección?"
      onConfirm={onDelete}
      okText="Sí"
      cancelText="No"
    >
      <Button type="text" danger icon={<DeleteOutlined />}>
        Eliminar
      </Button>
    </Popconfirm>
  </div>
</Card>

  );
};

export default DireccionItem;
