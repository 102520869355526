import React from "react";
import { Button, Card, Row, Col } from "antd"; // Importa Row y Col para el diseño responsivo
import { Link } from "react-router-dom";
import cliente from "../../../assets/cliente.png";
import vendedor from "../../../assets/vendedor.png";
import "./Tipo.scss";
const Tipo = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "100vh" }}>
      <Card title="¡Bienvenido a BLIZU!" style={{ textAlign: "center" }}>
        <p>Selecciona tu tipo de cuenta</p>
        <Row gutter={[16, 16]} justify="center"> {/* Utiliza Row y Col para diseño responsivo */}
          <Col xs={24} sm={12} lg={12}> {/* Define el ancho de la columna para diferentes tamaños de pantalla */}
            <Link to="/registrarse/comprador">
              <Card type="primary" style={{ marginBottom: "8px" }}>
                <img src={cliente} alt="Cliente" className="tipo-image" />
              </Card>
              <p>Cliente</p>
            </Link>
          </Col>
          <Col xs={24} sm={12} lg={12}>
            <Link to="/registrarse/vendedor">
              <Card type="primary" style={{ marginBottom: "8px" }}>
                <img src={vendedor} alt="Vendedor" className="tipo-image" />
              </Card>
              <p>Vendedor</p>
            </Link>
          </Col>
        </Row>
        <div style={{ marginBottom: "16px" }}>
          ¿Tienes cuenta? <Link to={`/login`}>Inicia sesión</Link>
        </div>
        <Link to={`/`} style={{ display: "flex", justifyContent: "center" }}>
          <Button type="link">Volver a inicio</Button>
        </Link>
      </Card>
    </div>
  );
};

export default Tipo;
