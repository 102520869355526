import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";

const GraficoVentasPorDiaMesActual = ({ datos }) => {
    const chartRef = useRef(null);
    const [chart, setChart] = useState(null);
  
    useEffect(() => {
        if (!chartRef.current || !datos) return;

        const hoy = new Date();
        const diasDelMes = new Date(hoy.getFullYear(), hoy.getMonth() + 1, 0).getDate();
        const labels = Array.from({ length: diasDelMes }, (_, i) => `${i + 1}/${hoy.getMonth() + 1}`);
        const ventasData = Array.from({ length: diasDelMes }, (_, i) => {
            const diaData = datos[i + 1] || { totalVentas: 0 };
            return diaData.totalVentas;
        });
        const comisionesPlataformaData = Array.from({ length: diasDelMes }, (_, i) => {
            const diaData = datos[i + 1] || { totalComisionesPlataforma: 0 };
            return diaData.totalComisionesPlataforma;
        });


        const ctx = chartRef.current.getContext("2d");
  
        if (!chart) {
            const newChart = new Chart(ctx, {
                type: "line",
                data: {
                    labels: labels,
                    datasets: [
                        {
                            label: "Ventas por Día",
                            data: ventasData,
                            borderColor: "rgba(54, 162, 235, 1)",
                            backgroundColor: "rgba(54, 162, 235, 0.2)",
                            yAxisID: "ventas",
                            pointRadius: 5,
                            pointHoverRadius: 7,
                        },
                        {
                            label: "Comisiones de Plataforma",
                            data: comisionesPlataformaData,
                            borderColor: "rgba(255, 99, 132, 1)",
                            backgroundColor: "rgba(255, 99, 132, 0.2)",
                            yAxisID: "comisiones",
                            pointRadius: 5,
                            pointHoverRadius: 7,
                        },
                    
                    ],
                },
                options: {
                    scales: {
                      
                        ventas: {
                            position: "left",
                            title: {
                                display: true,
                                text: "Ventas",
                            },
                        },
                        comisiones: {
                            position: "right",
                            title: {
                                display: true,
                                text: "Comisiones",
                            },
                            grid: {
                                drawOnChartArea: false,
                            },
                        },
                    },
                },
            });
            setChart(newChart);
        } else {
            chart.data.labels = labels;
            chart.data.datasets[0].data = ventasData;
            chart.data.datasets[1].data = comisionesPlataformaData;
            chart.update();
        }
    }, [datos, chart]);
  
    return <canvas id="ventas-por-dia" ref={chartRef}></canvas>;
};

export default GraficoVentasPorDiaMesActual;
