import React, { useState, useEffect } from "react";
import {
  Card,
  Button,
  message,
  Modal,
  Form,
  Input,
  Row,
  Col,
  Flex,
  Image,
  Typography,
  Empty,
} from "antd";
import dayjs from "dayjs";
import {
  PlusOutlined,
  DeleteOutlined,
  CreditCardOutlined,
} from "@ant-design/icons";
import {
  addCardToCustomer,
  deleteCardFromCustomer,
  getCardToCustomer,
} from "../../../Api/Openpay/OpenPayApi";
import { useUser } from "../../../context/UserContext";
import visa from "../../../assets/logotarjetas/visa.png";
import mastercard from "../../../assets/logotarjetas/mastercard.jpg";
import openpayimg from "../../../assets/openpay.png";
import tarjetasaceptadas from "../../../assets/tarjetasaceptadas.jpg";
import visaymaster from "../../../assets/visa-mastercard.png";
import cardempty from "../../../assets/empty/tarjeta-de-credito.png";
const Cards = () => {
  const [tarjetas, setTarjetas] = useState([]);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const { userData } = useUser();

  const { Title } = Typography;

  // Cargar tarjetas al montar el componente
  useEffect(() => {
    cargarTarjetas();
  }, [userData]);

  const cargarTarjetas = async () => {
    try {
      // Supongamos que tienes el openpayId almacenado en algún lugar
      if (userData) {
        const response = await getCardToCustomer(userData.openpayId);
        setTarjetas(response);
      }
    } catch (error) {
      console.error("Error al cargar tarjetas:", error);
    } finally {
      setLoading(false);
    }
  };

  const mostrarModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const getCardLogo = (brand) => {
    // Lógica para obtener el logo según la marca de la tarjeta
    switch (brand.toLowerCase()) {
      case "visa":
        return visa; // Reemplaza con la ruta de tu logo de Visa
      case "mastercard":
        return mastercard; // Reemplaza con la ruta de tu logo de Mastercard
      default:
        return "ruta_del_logo_generico.png"; // Reemplaza con la ruta de tu logo genérico
    }
  };

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      // Supongamos que tienes el openpayId almacenado en algún lugar

      await addCardToCustomer(values, userData.openpayId);
      message.success("Tarjeta agregada con éxito");
      cargarTarjetas();
      setVisible(false);
    } catch (error) {
      console.error("Error al agregar tarjeta:", error);
      message.error("Error al agregar tarjeta");
    }
  };

  const handleDelete = async (tarjetaId) => {
    Modal.confirm({
      title: "Confirmar Eliminación",
      content: "¿Estás seguro de que quieres eliminar esta tarjeta?",
      okText: "Sí",
      okType: "danger",
      cancelText: "Cancelar",
      async onOk() {
        try {
          // Lógica para eliminar la tarjeta
          await deleteCardFromCustomer(userData.openpayId, tarjetaId);
          setTarjetas((prevTarjetas) =>
            prevTarjetas.filter((tarjeta) => tarjeta.id !== tarjetaId)
          );
          message.success("Tarjeta eliminada con éxito");
        } catch (error) {
          console.error("Error al eliminar la tarjeta:", error);
          message.error("La tarjeta no pudo ser eliminada");
        }
      },
    });
  };

  return (
    <Flex
      vertical
      style={{ textAlign: "center", alignItems: "center", marginTop: "20px" }}
      gap={20}
    >
      <Card style={{ width: "100%", margin: "5px" }}>
        <Title level={2}>Mis tarjetas</Title>

        <Button
          type="primary"
          onClick={mostrarModal}
          style={{ width: "200px", backgroundColor: "#1dbe72" }}
          icon={<PlusOutlined />}
        >
          Agregar tarjeta
        </Button>

        <div style={{ margin: "20px" }}>
          {tarjetas && tarjetas.length > 0 ? (
            <>
              {tarjetas.map((tarjeta) => (
                <Card
                  key={tarjeta.id} // Ajusta según la estructura de tus tarjetas
                  style={{ marginBottom: "16px" }}
                >
                  {/* Mostrar información de la tarjeta */}
                  <Row gutter={[16, 16]} align="middle">
                    <Col>
                      <img
                        src={getCardLogo(tarjeta.brand)}
                        alt={`Logo de ${tarjeta.brand}`}
                        style={{ maxHeight: "100px", width: "auto" }} // Ajusta el ancho a un máximo relativo al contenedor padre y deja que la altura se ajuste automáticamente
                      />
                    </Col>
                    <Col style={{ textAlign: "start" }}>
                      <div>{` ${tarjeta.type}`}</div>
                      <div>{`${tarjeta.card_number.replace(
                        /.(?=.{4})/g,
                        "X"
                      )}`}</div>
                      <div>{`${tarjeta.holder_name}`}</div>
                      <div>{`Vencimiento: ${tarjeta.expiration_month}/${tarjeta.expiration_year}`}</div>
                      <div>{`${tarjeta.bank_name}`}</div>
                      {/* Otros campos según la estructura de tus tarjetas */}
                    </Col>
                    <Col flex="auto" style={{ textAlign: "right" }}>
                      <Button
                        type="danger"
                        icon={<DeleteOutlined />}
                        onClick={() => handleDelete(tarjeta.id)}
                        style={{ color: "red" }}
                      >
                        Eliminar
                      </Button>
                    </Col>
                  </Row>
                </Card>
              ))}
            </>
          ) : (
            <Empty
              image={cardempty}
              description="Aún no se ha guardado ninguna tarjeta"
            />
          )}
        </div>
      </Card>

      <Modal
        title="Agregar tarjeta"
        visible={visible}
        onCancel={handleCancel}
        footer={null}
      >
        <div style={{ textAlign: "center" }}>
          <Image width={150} src={openpayimg} alt="openpay" preview={false} />
          <Image width={150} src={visaymaster} alt="openpay" preview={false} />
          <Form onFinish={handleSubmit} layout="vertical">
            {/* Campos del formulario para la nueva tarjeta */}
            {/* Ajusta según tus necesidades */}
            <Form.Item
              label="Número de tarjeta"
              name="card_number"
              rules={[
                {
                  required: true,
                  message: "Por favor, ingrese el número de tarjeta",
                },
                {
                  pattern: /^[0-9]{16}$/,
                  message: "El número de tarjeta debe tener 16 dígitos",
                },
              ]}
            >
              <Input maxLength={16} />
            </Form.Item>

            <Form.Item
              label="Nombre en la tarjeta"
              name="holder_name"
              rules={[
                {
                  required: true,
                  message: "Por favor, ingrese el nombre en la tarjeta",
                },
                {
                  max: 100,
                  message: "El nombre debe tener menos de 100 caracteres",
                },
              ]}
            >
              <Input maxLength={100} />
            </Form.Item>

            <Form.Item
              label="Mes de expiración"
              name="expiration_month"
              rules={[
                {
                  required: true,
                  message: "Por favor, ingrese el mes de expiración",
                },
                {
                  pattern: /^(0[1-9]|1[0-2])$/,
                  message: "Ingrese un mes válido (01-12)",
                },
              ]}
            >
              <Input maxLength={2} />
            </Form.Item>

            <Form.Item
              label="Año de expiración"
              name="expiration_year"
              rules={[
                {
                  required: true,
                  message: "Por favor, ingrese el año de expiración",
                },
                {
                  pattern: /^[0-9]{2}$/,
                  message: "Ingrese un año válido de dos dígitos (ej. 23)",
                },
                {
                  validator: (_, value) => {
                    const currentYear = dayjs().year() % 100; // Últimos dos dígitos del año actual
                    if (!value || parseInt(value, 10) >= currentYear) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        `Ingrese un año válido (${currentYear} o mayor)`
                      )
                    );
                  },
                },
              ]}
            >
              <Input maxLength={2} />
            </Form.Item>

            <Form.Item
              label="CVV"
              name="cvv2"
              rules={[
                { required: true, message: "Por favor, ingrese el CVV" },
                {
                  pattern: /^[0-9]{3,4}$/,
                  message: "El CVV debe tener 3 o 4 dígitos",
                },
              ]}
            >
              <Input maxLength={4} />
            </Form.Item>

            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              style={{
                width: "100%",
                marginTop: "10px",
                backgroundColor: "#1dbe72",
              }}
            >
              Guardar
            </Button>
          </Form>
          <Image
            src={tarjetasaceptadas}
            alt="Tarjetas Aceptadas"
            preview={false}
            style={{ maxWidth: "100%" }} // Establece un ancho máximo del 100%
          />
        </div>
      </Modal>
    </Flex>
  );
};

export default Cards;
