import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import { obteneServiciosFavoritos } from "../favoritos/favoritosUtils";

export const getServiciosCerca = async (
  ubicacionUsuario,
  userFavorites,
  setServicios
) => {
  try {
    const tiendasCercanas = await obtenerTiendasCercanas(ubicacionUsuario);
    const servicios = [];
    for (const tienda of tiendasCercanas) {
      const serviciosTienda = await obtenerserviciosDeTienda(
        tienda.vendedorUid,
        userFavorites
      );
      servicios.push(...serviciosTienda);
    }

    setServicios(servicios);
  } catch (error) {
    console.error("Error al obtener servicios cercanos:", error.message);
    return [];
  }
};

const obtenerTiendasCercanas = async (ubicacionUsuario) => {
  const tiendasCollection = collection(db, "tiendas");
  const tiendasSnapshot = await getDocs(
    query(tiendasCollection, where("activado", "==", true))
  );

  const tiendasData = [];
  tiendasSnapshot.forEach((doc) => {
    const tienda = doc.data();
    tienda.vendedorUid = doc.id;
    // Calcula la distancia entre la ubicación del usuario y la ubicación de la tienda
    const distancia = calcularDistancia(ubicacionUsuario, tienda.ubicacion);
    // Agrega la distancia a la información de la tienda
    tienda.distancia = distancia;
    tiendasData.push(tienda);
  });

  // Ordena las tiendas por distancia de forma ascendente
  tiendasData.sort((a, b) => a.distancia - b.distancia);

  return tiendasData;
};

const obtenerserviciosDeTienda = async (tiendaId, userFavorites) => {
  const serviciosCollection = collection(db, "servicios");
  const serviciosSnapshot = await getDocs(
    query(serviciosCollection, where("vendedorUid", "==", tiendaId))
  );

  const serviciosData = [];
  serviciosSnapshot.forEach((doc) => {
    const servicio = doc.data();
    servicio.id = doc.id;
    // Verifica si el producto está habilitado
    if (!servicio.pausa) {
      // Si el servicio es de tipo 'renta', también verifica que tenga stock mayor a 0
      if (
        servicio.tipoServicio !== "renta" ||
        (servicio.tipoServicio === "renta" && servicio.stock > 0)
      ) {
        // Comprueba si el servicio está en favoritos
        if (userFavorites && userFavorites.servicios) {
          // Comprobar si el servicio está en la lista de favoritos del usuario
          const isFavorite = userFavorites.servicios.includes(servicio.id);
          servicio.isFavorite = isFavorite;
        } else {
          // Si no hay datos de favoritos, establecer isFavorite como false
          servicio.isFavorite = false;
        }

        // Agrega el servicio a la lista de datos
        serviciosData.push(servicio);
      }
    }
  });

  return serviciosData;
};

// Función para calcular la distancia entre dos ubicaciones geográficas
const calcularDistancia = (ubicacionUsuario, ubicacionTienda) => {
  const lat1 = ubicacionUsuario.latitude;
  const lon1 = ubicacionUsuario.longitude;
  const lat2 = ubicacionTienda.latitud;
  const lon2 = ubicacionTienda.longitud;

  const R = 6371; // Radio de la Tierra en kilómetros
  const dLat = (lat2 - lat1) * (Math.PI / 180);
  const dLon = (lon2 - lon1) * (Math.PI / 180);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * (Math.PI / 180)) *
      Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distancia = R * c; // Distancia en kilómetros
  return distancia;
};
