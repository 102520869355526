import React from "react";
import { Card, Form, Input, Button } from "antd";
import { MailOutlined } from "@ant-design/icons";
import logo from "../../../assets/logo.png";

const RecuperarContraseña = ({ loading, onFinish }) => {
  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "100vh" }}>
      <Card  style={{ maxWidth: "400px", width: "100%" }}>
        <div style={{ textAlign: "center", marginBottom: "20px" }}>
          <img src={logo} style={{ width: "100px" }} alt="Blizu" />
        </div>
        <h2 style={{ textAlign: "center", marginBottom: "20px" }}>Recuperar contraseña</h2>
        <Form name="forgot-password" onFinish={onFinish} layout="vertical">
          <Form.Item
            name="email"
            label="Correo electrónico"
            rules={[
              {
                required: true,
                message: "Por favor ingresa tu correo electrónico",
              },
              {
                type: "email",
                message: "Por favor ingresa un correo electrónico válido",
              },
            ]}
          >
            <Input prefix={<MailOutlined />} placeholder="Correo electrónico" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading} block>
              Enviar correo de recuperación
            </Button>
          </Form.Item>
        </Form>
        <p style={{ textAlign: "center", marginTop: "10px" }}>
          Al ingresar tu correo electrónico, se enviará un correo electrónico a esa dirección si existe una cuenta asociada. Desde allí, podrás recuperar tu contraseña.
        </p>
        <Form.Item style={{ textAlign: "center" }}>
          ¿Volver? <a href="/login">Ir a inicio de sesión</a>
        </Form.Item>
      </Card>
    </div>
  );
};

export default RecuperarContraseña;
