import React, { useState } from "react";
import { Form, Upload, message } from "antd";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";

const PhotoUploader = ({ setImageUrl, imageUrl }) => {
  const [loading, setLoading] = useState(false);

  // Cargar foto
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('Solo puedes subir archivos JPG/PNG');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('La imagen debe ser menor a 2MB');
    }

    getBase64(file, (imageUrl) => {
        setLoading(false);
        setImageUrl(imageUrl);
      });
      
    return false;
  };

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Subir Foto</div>
    </div>
  );

  // Cargar foto
  const normFile = (e) => {
    if (!e || Array.isArray(e)) {
      return e || [];
    }
    const { fileList } = e;
    return fileList || [];
  };
  
  
  
  return (
    <Form.Item
      name="photo"
      valuePropName="fileList"
      getValueFromEvent={(e) => normFile(e, setImageUrl)}
      extra="Sube una foto de perfil"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
      rules={[
        {
          required: true,
          message: "Por favor, sube una foto",
        },
      ]}
    >
      <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        beforeUpload={beforeUpload}
        onChange={handleChange}
        accept="image/*" 
      >
        {imageUrl ? (
          <img
            src={imageUrl}
            alt="avatar"
            style={{
              width: '100%',
            }}
          />
        ) : (
          uploadButton
        )}
      </Upload>
    </Form.Item>
  );
};

export default PhotoUploader;
