import React from "react";
import { Button, Card, Row, Col } from "antd"; // Importa Row y Col de Ant Design
import { Link } from "react-router-dom";
import producto from "../../../assets/categorias/producto.png";
import servicio from "../../../assets/categorias/servicios.png";

const CategoriaAdmin = () => {
  return (
    <div style={{ padding: "20px" }}> {/* Agrega un espacio de relleno alrededor del contenido */}

        <h1 style={{ textAlign: "center" }}>Categorias</h1>
        <p style={{ textAlign: "center" }}>Selecciona el tipo de categoria</p>
        <Row gutter={[16, 16]} justify="center"> {/* Utiliza Row de Ant Design y centra el contenido */}
          <Col xs={24} sm={12} lg={6}>
            <Link to="/admin/categorias/productos">
              <Card hoverable style={{ textAlign: "center" }}>
                <img src={producto} alt="Productos" style={{ width: "50%", margin: "auto" }} />
                <p>Productos</p>
              </Card>
            </Link>
          </Col>
          <Col xs={24} sm={12} lg={6}>
            <Link to="/admin/categorias/servicios">
              <Card hoverable style={{ textAlign: "center" }}>
                <img src={servicio} alt="Servicios" style={{ width: "50%", margin: "auto" }} />
                <p>Servicios</p>
              </Card>
            </Link>
          </Col>
        </Row>

    </div>
  );
};

export default CategoriaAdmin;
