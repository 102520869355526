// categoriaUtils.js

import { collection, query, where, getDocs,doc, getDoc} from "firebase/firestore";
import { db } from "../../firebaseConfig";

export const fetchCategorias = async (setCategorias, fetchProductoData) => {
  try {
    const categoriasCollection = collection(db, "categorias");
    const q = query(categoriasCollection, where("tipo", "==", "producto"));
    const categoriasSnapshot = await getDocs(q);

    const categoriasData = categoriasSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setCategorias(categoriasData);

    // Luego de obtener las categorías, llamar a fetchProductoData
    fetchProductoData(categoriasData);
  } catch (error) {
    console.error("Error al obtener categorías:", error.message);
  }
};

export const obtenerNombreCategoria = async (categoriaId) => {
    try {
      const categoriaRef = doc(db, "categorias", categoriaId);
      const categoriaDoc = await getDoc(categoriaRef);
  
      if (categoriaDoc.exists()) {
        return categoriaDoc.data().nombre;
      } else {
        return "Nombre de categoría no encontrado";
      }
    } catch (error) {
      console.error("Error al obtener el nombre de la categoría:", error.message);
      throw error;
    }
  };