import {
  getDoc,
  doc,
  updateDoc,
  setDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { db } from "../../firebaseConfig";
import { toast } from "sonner";

export const obteneProductosFavoritos = async (userData) => {
  try {
    if (!userData) return [];

    const userFavoritesRef = doc(db, "favoritos", userData.uid);
    const userFavoritesDoc = await getDoc(userFavoritesRef);
    if (userFavoritesDoc.exists()) {
      const productIds = userFavoritesDoc.data().productos || [];
      return productIds;
    }
    return [];
  } catch (error) {
    console.error("Error fetching favorite products:", error.message);
    return [];
  }
};




export const handleFavoriteProductoAction = async (
  productId,
  action,
  userData
) => {
  try {
    // Verifica si el usuario ha iniciado sesión
    if (!userData) {
      toast.error("Inicia sesión para gestionar productos en favoritos.");
      return;
    }

    // Obtiene la lista de productos favoritos del usuario desde la base de datos
    const userFavoritesRef = doc(db, "favoritos", userData.uid);
    const userFavoritesDoc = await getDoc(userFavoritesRef);

    if (userFavoritesDoc.exists()) {
      // Si la lista de favoritos ya existe, verifica si el producto está presente
      const isFavorite = userFavoritesDoc.data().productos.includes(productId);

      if (!isFavorite && action === "add") {
        // Si el producto no está en la lista de favoritos y la acción es "add", agrégalo
        const updatedFavorites = arrayUnion(productId);
        await updateDoc(userFavoritesRef, {
          productos: updatedFavorites,
        });
        toast.success("Producto agregado a favoritos exitosamente.");
      } else if (isFavorite && action === "remove") {
        // Si el producto está en la lista de favoritos y la acción es "remove", elimínalo
        const updatedFavorites = arrayRemove(productId);
        await updateDoc(userFavoritesRef, {
          productos: updatedFavorites,
        });
        toast.success("Producto eliminado de favoritos exitosamente.");
      } else {
        // Maneja otros casos (por ejemplo, acciones inesperadas)
        console.warn(
          "Acción no reconocida o producto ya existe/ausente en favoritos."
        );
      }
    } else {
      // Si la lista de favoritos no existe, créala con el producto
      await setDoc(userFavoritesRef, {
        productos: [productId],
        servicios: [],
      });

      if (action === "add") {
        toast.success("Producto agregado a favoritos exitosamente.");
      }
    }
  } catch (error) {
    console.error(
      "Error al gestionar el producto en favoritos:",
      error.message
    );
  }
};

export const obteneServiciosFavoritos = async (userData) => {
  try {
    if (!userData) return [];
    const userFavoritesRef = doc(db, "favoritos", userData.uid);
    const userFavoritesDoc = await getDoc(userFavoritesRef);
    if (userFavoritesDoc.exists()) {
      const productIds = userFavoritesDoc.data().servicios || [];
      return productIds;
    }
    return [];
  } catch (error) {
    console.error("Error fetching favorite products:", error.message);
    return [];
  }
};

export const handleFavoriteServicioAction = async (
  productId,
  action,
  userData
) => {
  try {
    // Verifica si el usuario ha iniciado sesión
    if (!userData) {
      toast.error("Inicia sesión para gestionar productos en favoritos.");
      return;
    }

    // Obtiene la lista de productos favoritos del usuario desde la base de datos
    const userFavoritesRef = doc(db, "favoritos", userData.uid);
    const userFavoritesDoc = await getDoc(userFavoritesRef);

    if (userFavoritesDoc.exists()) {
      // Si la lista de favoritos ya existe, verifica si el producto está presente
      const isFavorite = userFavoritesDoc.data().servicios.includes(productId);
      if (!isFavorite && action === "add") {
        // Si el producto no está en la lista de favoritos y la acción es "add", agrégalo
        const updatedFavorites = arrayUnion(productId);
        await updateDoc(userFavoritesRef, {
          servicios: updatedFavorites,
        });
        toast.success("Servicio agregado a favoritos exitosamente.");
      } else if (isFavorite && action === "remove") {
        // Si el producto está en la lista de favoritos y la acción es "remove", elimínalo
        const updatedFavorites = arrayRemove(productId);
        await updateDoc(userFavoritesRef, {
          servicios: updatedFavorites,
        });
        toast.success("Servicio eliminado de favoritos exitosamente.");
      } else {
        // Maneja otros casos (por ejemplo, acciones inesperadas)
        console.warn(
          "Acción no reconocida o servicio ya existe/ausente en favoritos."
        );
      }
    } else {
      // Si la lista de favoritos no existe, créala con el producto
      await setDoc(userFavoritesRef, {
        servicios: [productId],
        productos: [],
      });

      if (action === "add") {
        toast.success("Servicio agregado a favoritos exitosamente.");
      }
    }
  } catch (error) {
    console.error(
      "Error al gestionar el servicio en favoritos:",
      error.message
    );
  }
};

export const obtenerFavoritosConDetallesUsuario = async (
  userFavorites,
  setProductsDetails
) => {
  try {
    if (!userFavorites) return;

    const productDetailsArray = [];
    const serviceDetailsArray = [];

    const setFavoriteAndDetails = async () => {
      if (userFavorites.productos) {
        const productsDetails = await obtenerFavoritosProductosConDetalles(userFavorites);
        productDetailsArray.push(...productsDetails);
      }
      if (userFavorites.servicios) {
        const servicesDetails = await obtenerFavoritosServiciosConDetalles(userFavorites);
        serviceDetailsArray.push(...servicesDetails);
      }

      const allDetailsArray = [
        ...productDetailsArray,
        ...serviceDetailsArray,
      ].filter((detail) => detail !== null);
      setProductsDetails(allDetailsArray);
    };

    await setFavoriteAndDetails();
  } catch (error) {
    console.error(
      "Error fetching favorite products and services:",
      error.message
    );
  }
};

export const obtenerFavoritosProductosConDetalles = async (userFavorites) => {
  try {
    if (!userFavorites) return;

    // Fetch product details for each productId in userFavorites
    const productDetailsPromises = userFavorites.productos.map(async (productId) => {
      const productDoc = await getDoc(doc(db, "productos", productId));
      if (productDoc.exists()) {
        const productDetails = productDoc.data();
        productDetails.id = productId;
        // Marcar como favorito si el productId está en userFavorites
        productDetails.isFavorite = true;
        return productDetails;
      }
      return null;
    });

    // Wait for all product details to be fetched
    const productsDetailsArray = await Promise.all(productDetailsPromises);
    return productsDetailsArray.filter((product) => product !== null);
    
  } catch (error) {
    console.error("Error fetching favorite products:", error.message);
  }
};

export const obtenerFavoritosServiciosConDetalles = async (userFavorites) => {
  try {
    if (!userFavorites) return;

    // Fetch service details for each serviceId in userFavorites
    const serviceDetailsPromises = userFavorites.servicios.map(async (serviceId) => {
      const serviceDoc = await getDoc(doc(db, "servicios", serviceId));
      if (serviceDoc.exists()) {
        const serviceData = serviceDoc.data();
        serviceData.id = serviceId;
        serviceData.isFavorite = true; // Marcar como favorito ya que está en userFavorites
        return serviceData;
      }
      return null;
    });

    // Wait for all service details to be fetched
    const servicesDetailsArray = await Promise.all(serviceDetailsPromises);
    return servicesDetailsArray.filter((service) => service !== null);
  } catch (error) {
    console.error("Error fetching favorite services:", error.message);
  }
};
