import React, { useState } from "react";
import { Input, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom"; // Importa Link y useNavigate
import './BuscadorStyle.scss';

const { Search } = Input;

const Buscador = () => {
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate(); // Obtiene la función de navegación

  const handleSearch = () => {
    // Redirige a la URL con el listado de productos y la búsqueda
    navigate(`/buscador?search=${searchText}`);

  
  };

  return (
      <Search
        placeholder="¿Qué producto o servicio deseas?"
        allowClear
        size="middle"
        onSearch={handleSearch}
        onChange={(e) => setSearchText(e.target.value)}
        value={searchText}
        style={{
          width: "400px",
        }}
      />

  );
};

export default Buscador;
