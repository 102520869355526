import React, { useEffect, useState } from "react";
import { Flex, Card, Typography } from "antd";
import { Link } from "react-router-dom";
import { useUser } from "../../context/UserContext";
import { ListaServiciosItemBuscador } from "../ServiciosBuscador/ListaServiciosItemBuscador";
import { getServiciosCerca } from "../../utils/servicios/serviciosCercaUtils";
import { handleFavoriteServicioAction } from "../../utils/favoritos/favoritosUtils";
import { addToCartService } from "../../utils/servicios/serviciosUtils";

const ServiciosCerca = () => {
  const [servicios, setServicios] = useState([]);
  const { userLocation , userData, userFavorites } = useUser();

  useEffect(() => {
    if (userLocation && userFavorites) {
      getServiciosCerca(userLocation,userFavorites , setServicios);
    }
  }, [userLocation , userFavorites]);
  
  const handleFavoriteToggle = async (productId, isFavorite) => {
    // Llama a la función handleFavoriteAction con el ID del producto y la acción determinada
    // Determina la acción según si el producto ya está en favoritos o no
    const action = isFavorite ? "remove" : "add";
    // Llama a la función handleFavoriteAction con el ID del producto y la acción determinada
    handleFavoriteServicioAction(productId, action, userData);
  };

  return (
    <Card style={{ margin: "20px", textAlign: "center" }}>
      <Typography.Title>Servicios cerca de ti</Typography.Title>
      <Flex wrap="wrap" gap="large" justify="center">
        {servicios.slice(0, 5).map((servicio) => (
          <ListaServiciosItemBuscador
            servicio={servicio}
            addToCart={() => addToCartService(servicio,userData)}
            favorite={() =>
              handleFavoriteToggle(servicio.id, servicio.isFavorite, userData)
            }
            isFavorite={servicio.isFavorite}
            key={servicio.id}
          />
        ))}
      </Flex>
    </Card>
  );
};

export default ServiciosCerca;
