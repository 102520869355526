import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Table, Button, Popconfirm, message, Tag } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  PauseCircleOutlined,
} from "@ant-design/icons";
import {
  getFirestore,
  collection,
  getDocs,
  deleteDoc,
  doc,
  updateDoc,
  query,
  where,
  getDoc,
} from "firebase/firestore";

import { useUser } from "../../../context/UserContext";
import { db } from "../../../firebaseConfig";
import { PlusOutlined } from "@ant-design/icons";

const MisServicios = () => {
  const [servicesWithStock, setServicesWithStock] = useState([]);
  const [servicesWithoutStock, setServicesWithoutStock] = useState([]);
  const { userData } = useUser();
  const navigate = useNavigate();



  const getServicios = async () => {
    try {
      if (!userData) {
        // Manejar según tus requisitos si el usuario no está autenticado
        return;
      }
  
      const q = query(
        collection(db, "servicios"),
        where("vendedorUid", "==", userData.uid)
      );
  
      const querySnapshot = await getDocs(q);
  
      let serviciosConStock = [];
      let serviciosSinStock = [];
  
      for (const servicioDoc of querySnapshot.docs) {
        const servicioData = servicioDoc.data();
        const servicioId = servicioDoc.id;
        const servicio = { ...servicioData, key: servicioId }; // Crear objeto de servicio con el ID
        const docRef = doc(db, "categorias", servicio.categoria);
        const docSnap = await getDoc(docRef);
        const categoria = docSnap.data();

        if (categoria) {
          servicio.categoriaNombre = categoria.nombre;

          // Buscar la subcategoría por su ID dentro del array de subcategorías de la categoría
          const subcategoria = categoria.subcategorias.find(
            (sub) => sub.id === servicio.subcategoria
          );

          // Verificar si se encontró la subcategoría y obtener su nombre
          if (subcategoria) {
            const nombreSubcategoria = subcategoria.subcategoria;
            servicio.subcategoriaNombre = nombreSubcategoria;
          } else {
            servicio.subcategoriaNombre = "sin subcategoria";
          }
        } else {
          servicio.categoriaNombre = "sin categoria";
          servicio.subcategoriaNombre = "sin subcategoria";
        }

        // Verificar si el campo servicio.stock está definido
        if ('stock' in servicio) {
          serviciosConStock.push(servicio);
        } else {
          serviciosSinStock.push(servicio);
        }
      }
  
  
      setServicesWithStock(serviciosConStock);
      setServicesWithoutStock(serviciosSinStock);
    } catch (error) {
      console.error("Error al obtener servicios:", error.message);
    }
  };
  
  useEffect(() => {
    getServicios();
  }, [userData]);

  const handleDelete = async (record) => {
    try {
      const db = getFirestore();
      const servicioDocRef = doc(db, "servicios", record.key);
      await deleteDoc(servicioDocRef);
      message.success("Servicio eliminado correctamente");
      getServicios();
    } catch (error) {
      console.error("Error al eliminar el servicio:", error);
    }
  };

  const handleDisabled = async (servicio) => {
    try {
      const db = getFirestore();
      const servicioDocRef = doc(db, "servicios", servicio.key);

      // Actualizar el estado de publicación a true (pausar)
      await updateDoc(servicioDocRef, {
        pausa: !servicio.pausa, // Cambia "deshabilitado" por el nombre de tu columna en Firestore
      });
      getServicios();
      message.success("Servicio actualizado correctamente");
    } catch (error) {
      console.error("Error al editar el servicio:", error.message);
    }
  };

  const handleEdit = async (servicio) => {
    navigate(`/dashboard/editarservicio/${servicio.key}`);
  };

  const columnsStock = [
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
    },

    {
      title: "Imagen",
      dataIndex: "imagen",
      key: "imagen",
      render: (imagen) => (
        <img
          src={Array.isArray(imagen) && imagen.length > 0 ? imagen[0].url : ""}
          alt="Imagen del servicio"
          style={{ width: 50, height: 50 }}
        />
      ),
    },

    {
      title: "Hora de inicio",
      dataIndex: "horaInicio",
      key: "horaInicio",
    },
    {
      title: "Hora de fin",
      dataIndex: "horaFin",
      key: "horaFin",
    },
    {
      title: "Tipo de servicio",
      dataIndex: "tipoServicio",
      key: "tipoServicio",
    },
    {
      title: "Tipo de duración",
      dataIndex: "duracion",
      key: "duracion",
    },
    {
      title: "Tiempo de entrega(horas)",
      dataIndex: "duracionHorasEntrega",
      key: "duracionHorasEntrega",
    },
    {
      title: "Stock",
      dataIndex: "stock",
      key: "stock",
    },
    {
      title: "Servicio a la vez",
      dataIndex: "serviciosALaVez",
      key: "serviciosALaVez",
      render: (serviciosALaVez) => (
        <span>
          {serviciosALaVez ? "Sí" : "No"}
        </span>
      )
    },
    {
      title: "Precio",
      dataIndex: "precio",
      key: "precio",
      render: (precio) => (
        <span>
          {precio.toLocaleString("es-MX", {
            style: "currency",
            currency: "MXN",
          })}
        </span>
      ),
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
    },
    {
      title: "Días disponibles",
      dataIndex: "diasDisponibles",
      key: "diasDisponibles",
      render: (dias) =>
        dias.map((dia) => (
          <Tag color="blue" key={dia} style={{margin: '2px'}}>
            {dia}
          </Tag>
        )),
    },
    {
      title: "Categoria",
      dataIndex: "categoriaNombre",
      key: "categoria",
    },
    {
      title: "Subcategoria",
      dataIndex: "subcategoriaNombre",
      key: "subcategoria",
    },
    {
      title: "Acciones",
      dataIndex: "acciones",
      key: "acciones",
      render: (_, record) => (
        <div style={{ display: "flex", gap: "10px" }}>
          <Button icon={<EditOutlined />} onClick={() => handleEdit(record)}>
            Editar
          </Button>
          <Popconfirm
            title="¿Estás seguro de eliminar este servicio?"
            onConfirm={() => handleDelete(record)}
            okText="Sí"
            cancelText="No"
          >
            <Button
              danger
              icon={<DeleteOutlined />}
              style={{ marginRight: "10px" }}
            >
              Eliminar
            </Button>
          </Popconfirm>

          <Popconfirm
            title={
              record.pausa
                ? "¿Estás seguro de quitar la pausa este servicio?"
                : "¿Estás seguro de pausar este servicio?"
            }
            onConfirm={() => handleDisabled(record)}
            okText="Sí"
            cancelText="No"
          >
            <Button
              style={{ marginRight: "10px" }}
              icon={<PauseCircleOutlined />}
            >
              {record.pausa ? "Quitar pausa" : "Pausar"}
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const columns = [
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
    },

    {
      title: "Imagen",
      dataIndex: "imagen",
      key: "imagen",
      render: (imagen) => (
        <img
          src={Array.isArray(imagen) && imagen.length > 0 ? imagen[0].url : ""}
          alt="Imagen del servicio"
          style={{ width: 50, height: 50 }}
        />
      ),
    },

    {
      title: "Hora de inicio",
      dataIndex: "horaInicio",
      key: "horaInicio",
    },
    {
      title: "Hora de fin",
      dataIndex: "horaFin",
      key: "horaFin",
    },
    {
      title: "Tipo de servicio",
      dataIndex: "tipoServicio",
      key: "tipoServicio",
    },
    {
      title: "Tipo de duración",
      dataIndex: "duracion",
      key: "duracion",
    },
    {
      title: "Tiempo de entrega(horas)",
      dataIndex: "duracionHorasEntrega",
      key: "duracionHorasEntrega",
    },
    {
      title: "Servicio a la vez",
      dataIndex: "serviciosALaVez",
      key: "serviciosALaVez",
      render: (serviciosALaVez) => (
        <span>
          {serviciosALaVez ? "Sí" : "No"}
        </span>
      )
    },
    {
      title: "Precio",
      dataIndex: "precio",
      key: "precio",
      render: (precio) => (
        <span>
          {precio.toLocaleString("es-MX", {
            style: "currency",
            currency: "MXN",
          })}
        </span>
      ),
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
    },
    {
      title: "Días disponibles",
      dataIndex: "diasDisponibles",
      key: "diasDisponibles",
      render: (dias) =>
        dias.map((dia) => (
          <Tag color="blue" key={dia} style={{margin: '2px'}}>
            {dia}
          </Tag>
        )),
    },
    {
      title: "Categoria",
      dataIndex: "categoriaNombre",
      key: "categoria",
    },
    {
      title: "Subcategoria",
      dataIndex: "subcategoriaNombre",
      key: "subcategoria",
    },
    {
      title: "Acciones",
      dataIndex: "acciones",
      key: "acciones",
      render: (_, record) => (
        <div style={{ display: "flex", gap: "10px" }}>
          <Button icon={<EditOutlined />} onClick={() => handleEdit(record)}>
            Editar
          </Button>
          <Popconfirm
            title="¿Estás seguro de eliminar este servicio?"
            onConfirm={() => handleDelete(record)}
            okText="Sí"
            cancelText="No"
          >
            <Button
              danger
              icon={<DeleteOutlined />}
              style={{ marginRight: "10px" }}
            >
              Eliminar
            </Button>
          </Popconfirm>

          <Popconfirm
            title={
              record.pausa
                ? "¿Estás seguro de quitar la pausa este servicio?"
                : "¿Estás seguro de pausar este servicio?"
            }
            onConfirm={() => handleDisabled(record)}
            okText="Sí"
            cancelText="No"
          >
            <Button
              style={{ marginRight: "10px" }}
              icon={<PauseCircleOutlined />}
            >
              {record.pausa ? "Quitar pausa" : "Pausar"}
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];
  return (
    <div>
      <h1>Lista de servicios ofrecidos de renta</h1>
      <Link to="/dashboard/crearservicio">
        <Button
          type="primary"
          style={{ position: "fixed", bottom: 20, right: 20 }}
          icon={<PlusOutlined />}
        >
          Agregar servicio
        </Button>
      </Link>
      <Table dataSource={servicesWithStock} columns={columnsStock} />
      <h1>Lista de servicios ofrecidos de personal</h1>
      <Table dataSource={servicesWithoutStock} columns={columns} />
    </div>
  );
};

export default MisServicios;
