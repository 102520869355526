import React, { useState, useEffect } from "react";
import { collection, query, where, getDocs, onSnapshot } from "firebase/firestore";
import { db } from "../../../firebaseConfig";
import { List, Typography, Button, Spin } from "antd";
import { Link } from "react-router-dom";

const { Title, Text } = Typography;

const PendingMessagesView = () => {
  const [subpedidos, setSubpedidos] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSubpedidos = async () => {
      try {
        // Get all subpedidos
        const subpedidosCollection = collection(db, "subpedidos");
        const unsubscribe = onSnapshot(subpedidosCollection, async (snapshot) => {
          const subpedidosData = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          // Check each subpedido for pending messages
          const pendingSubpedidos = [];
          
          for (const subpedido of subpedidosData) {
            const messagesCollection = collection(db, "subpedidos", subpedido.id, "messages");
            const messagesQuery = query(messagesCollection, where("status", "==", "pending"));
            const messagesSnapshot = await getDocs(messagesQuery);

            if (!messagesSnapshot.empty) {
              pendingSubpedidos.push({
                id: subpedido.id,
                messages: messagesSnapshot.docs.map((doc) => doc.data()),
              });
            }
          }

          setSubpedidos(pendingSubpedidos);
          setLoading(false);
        });

        return () => unsubscribe();
      } catch (error) {
        console.error("Error fetching subpedidos: ", error);
        setLoading(false);
      }
    };

    fetchSubpedidos();
  }, []);

  if (loading) {
    return <Spin />;
  }

  return (
    <div style={{ padding: "20px", maxWidth: "800px", margin: "0 auto" }}>
      <Title level={2}>Subpedidos con mensajes pendientes</Title>
      <List
        dataSource={subpedidos}
        renderItem={(subpedido) => (
          <List.Item
            key={subpedido.id}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px",
              padding: "10px",
              border: "1px solid #d9d9d9",
              borderRadius: "8px",
              backgroundColor: "#fafafa",
            }}
          >
            <div>
              <Text strong>{`Subpedido ID: ${subpedido.id}`}</Text>
              <div>
                <Text type="secondary">Mensajes pendientes</Text>
              </div>
            </div>
            <Link to={`/admin/chat/${subpedido.id}`}>
              <Button type="primary">Ver Mensajes</Button>
            </Link>
          </List.Item>
        )}
      />
    </div>
  );
};

export default PendingMessagesView;
