// firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { getToken, getMessaging } from "firebase/messaging"

const firebaseConfig = {
    apiKey: "AIzaSyCZdmXEtClZuU5nZJaa1nb3cT3C5XfCCIU",
    authDomain: "ecommerce-acf03.firebaseapp.com",
    projectId: "ecommerce-acf03",
    storageBucket: "ecommerce-acf03.appspot.com",
    messagingSenderId: "211023368893",
    appId: "1:211023368893:web:03d08ab7a539351e8d9dd4",
    measurementId: "G-T4VNE7WBVK"
};

const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);
const messaging = getMessaging(firebaseApp);

export { auth, db, createUserWithEmailAndPassword, signInWithEmailAndPassword, messaging };
