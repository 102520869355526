import React, { useEffect, useState } from "react";
import { Table, Button, message, Popconfirm, Input, Flex } from "antd";
import {
  updateDoc,
  doc,
  getFirestore,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { Link } from "react-router-dom";
import { PlusOutlined, FrownOutlined,SmileOutlined  } from "@ant-design/icons";

const AdminsAdmin = ({ activado }) => {
  const [usuarios, setUsuarios] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  // Filtrar categorías según el término de búsqueda
  const filteredUsuarios = usuarios.filter((usuario) =>
    usuario.username.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const fetchUsuarios = async () => {
    try {
      const db = getFirestore();
      const usuariosCollection = collection(db, "roles");
      const querySnapshot = await getDocs(
        query(
          usuariosCollection,
          where("role", "==", 2),
          where("activado", "==", activado)
        )
      );

      const usuariosData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setUsuarios(usuariosData);
    } catch (error) {
      console.error("Error al obtener usuarios:", error.message);
    }
  };

  useEffect(() => {
    fetchUsuarios();
  }, [activado]);

  const handleActivadoChange = async (userId, activado) => {
    try {
      const db = getFirestore();
      const userRef = doc(db, "roles", userId);
      await updateDoc(userRef, { activado: !activado });
      fetchUsuarios();
      message.success("Estado de activación cambiado correctamente");
    } catch (error) {
      console.error("Error al cambiar el estado de activación:", error.message);
      message.error("Error al cambiar el estado de activación");
    }
  };

  const columns = [
    {
      title: "Nombre",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Activado",
      dataIndex: "activado",
      key: "activado",
      render: (activado, record) => (
        <span>
          {activado ? "Activo" : "Inactivo"}
          <Popconfirm
            title={`¿Estás seguro de ${activado ? "desactivar" : "activar"}?`}
            onConfirm={() => handleActivadoChange(record.id, activado)}
            okText="Sí"
            cancelText="No"
          >
            <Button type="link">{activado ? "Desactivar" : "Activar"}</Button>
          </Popconfirm>
        </span>
      ),
    },
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <h1>Administradores</h1>
        <Input.Search
          placeholder="Buscar administrador"
          onSearch={handleSearch}
          style={{ width: 400, marginBottom: 16 }}
        />
        <Flex gap="middle" align="start" horizontal>
          <Link to={`/admin/admins/agregar`}>
            {" "}
            <Button
              type="primary"
              icon={<PlusOutlined />}
              style={{ marginBottom: 16 }}
            >
              Agregar Administrador
            </Button>
          </Link>

          <Link to={`/admin/admins/${activado ? "blacklist" : ""}`}>
            <Button
              type="primary"
              danger={activado}
              icon={activado ? <FrownOutlined /> : <SmileOutlined />}
              style={{ marginBottom: 16 }}
            >
              {activado ? "Ver blacklist" : "Ir a whitelist"}
            </Button>
          </Link>
        </Flex>
      </div>
      <Table
        dataSource={filteredUsuarios}
        columns={columns}
        rowKey="id"
        pagination={{ pageSize: 10 }}
      />
    </div>
  );
};

export default AdminsAdmin;
