import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  doc,
  getDoc,
  collection,
  query,
  updateDoc,
  where,
  getDocs,
  addDoc,
} from "firebase/firestore";
import { db } from "../../../firebaseConfig";
import {
  Card,
  List,
  Button,
  Popconfirm,
  Modal,
  Rate,
  Input,
  message,
  Avatar,
  Badge,
} from "antd";
import ListaItemProductosPedidos from "../../../components/ListaItemProductosPedidos/ListaItemProductosPedidos";
import { refundCharge } from "../../../Api/Openpay/OpenPayApi";
import { useUser } from "../../../context/UserContext";
import { MessageOutlined, CommentOutlined } from "@ant-design/icons";

const DetallesPedido = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { userData } = useUser();
  const [pedido, setPedido] = useState(null);
  const [subpedidos, setSubpedidos] = useState([]);
  const [loading, setLoading] = useState(true);

  const [devolucionHabilitada, setDevolucionHabilitada] = useState(false); // Estado para habilitar el botón de devolución

  const [allDelivered, setAllDelivered] = useState(false);
  const [reviewModalVisible, setReviewModalVisible] = useState(false);
  const [reviewText, setReviewText] = useState("");
  const [reviewRating, setReviewRating] = useState(0);
  const [selectedSubpedido, setSelectedSubpedido] = useState(null);

  const showReviewModal = (subpedido) => {
    setSelectedSubpedido(subpedido); // Guardar el subpedido seleccionado
    setReviewModalVisible(true);
  };

  const irAChat = (subpedido) => {
    navigate("/chat/" + subpedido);
  };

  const getTienda = async (vendedorUid) => {
    try {
      const tiendaDocRef = doc(db, "tiendas", vendedorUid);
      const tiendaDocSnapshot = await getDoc(tiendaDocRef);
      if (tiendaDocSnapshot.exists()) {
        const tiendaData = tiendaDocSnapshot.data();
        return tiendaData;
      }
    } catch (error) {
      console.error("Error al obtener el nombre de la tienda:", error);
    }
    return "Nombre de Tienda no encontrado";
  };

  const handleReviewSubmit = async () => {
    try {
      // Crear una nueva reseña en la colección "reseñasVendedor"
      const reviewData = {
        vendedorUid: subpedidos[0]?.vendedorUid, // Asumiendo que la reseña es para el primer subpedido
        reviewText: reviewText,
        reviewRating: reviewRating,
        userId: userData.uid,
        nameUser: userData.username,
        subpedidoId: selectedSubpedido.id,
        fecha: new Date(),
      };

      await addDoc(collection(db, "reseñasVendedor"), reviewData);

      // Actualizar el subpedido para marcar que ya tiene reseña
      const subpedidoRef = doc(db, "subpedidos", selectedSubpedido.id);
      await updateDoc(subpedidoRef, { tieneReseña: true });

      // Obtener todas las reseñas del vendedor
      const reseñasQuery = query(
        collection(db, "reseñasVendedor"),
        where("vendedorUid", "==", reviewData.vendedorUid)
      );
      const reseñasSnapshot = await getDocs(reseñasQuery);

      // Calcular el nuevo promedio de calificación
      let totalCalificacion = reviewRating;
      let cantidadReseñas = 1; // Contador para incluir la nueva reseña
      reseñasSnapshot.forEach((doc) => {
        const reseña = doc.data();
        totalCalificacion += reseña.reviewRating;
        cantidadReseñas++;
      });
      const nuevoPromedio = totalCalificacion / cantidadReseñas;

      // Actualizar la calificación de la tienda
      const tiendaRef = doc(db, "tiendas", reviewData.vendedorUid);
      await updateDoc(tiendaRef, { calificacion: nuevoPromedio });

      //   console.log("Reseña enviada:", reviewText, "Rating:", reviewRating);
      message.success("Reseña colocada exitosamente, gracias");
      setReviewModalVisible(false);
      setReviewText("");
      setReviewRating(0);
    } catch (error) {
      console.error("Error al enviar la reseña:", error);
    }
  };

  useEffect(() => {
    const fetchPedido = async () => {
      try {
        if (id && userData) {
          const pedidoDocRef = doc(db, "pedidos", id);
          const pedidoDoc = await getDoc(pedidoDocRef);

          if (pedidoDoc.exists()) {
            const pedidoData = pedidoDoc.data();
            pedidoData.meses = parseInt(pedidoData.meses);
            pedidoData.id = pedidoDoc.id;
            setPedido(pedidoData);
            setLoading(true);

            // Validación del usuario
            // if (pedidoData.usuarioUid !== userData && userData.role !=2) {
            //   navigate("/");
            //   return;
            // }

            const subpedidosQuery = query(
              collection(db, "subpedidos"),
              where("pedidoId", "==", id)
            );

            const subpedidosSnapshot = await getDocs(subpedidosQuery);
            const subpedidosData = [];
            for (const doc of subpedidosSnapshot.docs) {
              const subpedido = doc.data();
              const tienda = await getTienda(subpedido.vendedorUid);

              subpedido.id = doc.id;
              subpedido.nombreTienda = tienda.nombre;
              subpedido.fotoTienda = tienda.foto;
              // Obtener productos asociados a este subpedido
              const productosPedidosQuery = query(
                collection(db, "productosPedidos"),
                where("subpedidoId", "==", subpedido.id)
              );
              const productosPedidosSnapshot = await getDocs(
                productosPedidosQuery
              );
              const productosPedidosData = productosPedidosSnapshot.docs.map(
                (doc) => {
                  const data = doc.data();
                  data.id = doc.id; // Asignar el ID del documento al objeto de datos
                  return data;
                }
              );

              // Asignar los productosPedidos al subpedido
              subpedido.productos = productosPedidosData;

              const messagesCollection = collection(
                db,
                "subpedidos",
                subpedido.id,
                "messages"
              );
              const messagesQuery = query(
                messagesCollection,
                where("senderId", "!=", userData.uid),
                where("status", "==", "approved"),
                where("read", "==", false)
              );
              const messagesSnapshot = await getDocs(messagesQuery);
              subpedido.mensajesPendientes = messagesSnapshot.size;

              subpedidosData.push(subpedido);
            }
            setSubpedidos(subpedidosData);
            setLoading(false);

            const allProductsDelivered = subpedidosData.every((subpedido) =>
              subpedido.productos.every((producto) => {
                if (producto.tipo === "producto") {
                  return producto.estatus === "Entrega realizada";
                } else if (producto.tipo === "servicio") {
                  if (producto.tipoServicio === "renta") {
                    return producto.estatus === "Producto de renta recuperado";
                  } else {
                    return producto.estatus === "Entrega realizada";
                  }
                }
                return false;
              })
            );
            setAllDelivered(allProductsDelivered);

            // Verifica si la fecha de creación del pedido es la de hoy para habilitar la devolución
            const fechaCreacion = pedidoData.fechaCreacion.toDate();
            fechaCreacion.setHours(0, 0, 0, 0); // Establece la hora a medianoche

            const fechaHoy = new Date();
            fechaHoy.setHours(0, 0, 0, 0); // Establece la hora a medianoche

            // Verifica si algún producto tiene un estado diferente a "Pendiente de revisión del vendedor"
            const devolucionHabilitada = !subpedidosData.some((subpedido) => {
              return subpedido.productos.some(
                (producto) =>
                  producto.estatus !== "Pendiente de revisión del vendedor"
              );
            });

            setDevolucionHabilitada(
              pedidoData.totalGeneral !== pedidoData.devolucion &&
                fechaCreacion.getTime() === fechaHoy.getTime() &&
                devolucionHabilitada
            );
          } else {
     //       console.log("No se encontró el pedido");
          }
        }
      } catch (error) {
        console.error("Error al obtener los detalles del pedido:", error);
      }
    };

    fetchPedido();
  }, [id, userData]);

  const handleRefund = async () => {
    try {
      // Define los datos de la devolución
      const data = {
        description: "devolución del pedido " + pedido.id,
        amount: pedido.totalGeneral,
      };

      // Realiza la devolución del cargo
      await refundCharge(pedido.uid, data);

      // Actualiza el estado del pedido para reflejar la devolución
      await updateDoc(doc(db, "pedidos", id), {
        estatus: "devolución realizada",
        devolucion: data.amount,
      });

      // Actualiza los subpedidos y los productos para reflejar la devolución
      const updatedSubpedidos = await Promise.all(
        subpedidos.map(async (subpedido) => {
          // Calcula el monto de devolución por producto

          // Actualiza el estado y la devolución de cada producto del subpedido
          const updatedProductos = await Promise.all(
            subpedido.productos.map(async (producto) => {
              const costoDevolucion = calcularMontoArticulo(producto);
              // Actualiza el estado y la devolución del producto
              const productoRef = doc(db, "productosPedidos", producto.id);
              await updateDoc(productoRef, {
                estatus: "Devolución realizada",
                devolucion: costoDevolucion,
              });

              return {
                ...producto,
                estatus: "devolución realizada",
                devolucion: costoDevolucion,
              };
            })
          );

          // Actualiza el estado y la devolución del subpedido
          const subpedidoRef = doc(db, "subpedidos", subpedido.id);
          await updateDoc(subpedidoRef, {
            estatus: "devolución realizada",
            devolucion: subpedido.total,
          });

          return {
            ...subpedido,
            estatus: "devolución realizada",
            devolucion: subpedido.total,
            productos: updatedProductos, // Asigna los productos actualizados al subpedido
          };
        })
      );

      setSubpedidos(updatedSubpedidos);
      setDevolucionHabilitada(false);
      message.success("Cancelación de pedido realizada existosamente");
    } catch (error) {
      message.success(
        "Hubo un error al realizar la cancelación del pedido, intentelo más tarde"
      );
      console.error("Error al realizar la devolución del cargo:", error);
    }
  };

  const calcularMontoArticulo = (producto) => {
    let costoProducto = 0;

    if (producto.tipo === "producto") {
      costoProducto = producto.total * producto.cantidad;
    } else {
      if (producto.stock) {
        if (producto.duracion === "unico") {
          costoProducto = producto.total * 1;
        } else {
          costoProducto =
            producto.total * producto.cantidad * producto.cantidadDuracion;
        }
      } else {
        if (producto.duracion === "unico") {
          costoProducto = producto.total * 1;
        } else {
          costoProducto = producto.total * producto.cantidadDuracion;
        }
      }
    }

    return costoProducto;
  };

  return (
    <>
      <Card className="container" style={{ padding: 50, margin: "10px" }}>
        <h1>Detalles del pedido</h1>
        {/* Renderiza los detalles del pedido */}
        {pedido ? (
          <>
            {/* Renderiza los detalles del pedido */}
            <p>
              <strong>ID del pedido:</strong> {pedido.id}
            </p>
            <p>
              <strong>Dirección de entrega:</strong> {pedido.direccionEntrega}
            </p>
            <p>
              <strong>Fecha de creación:</strong>{" "}
              {pedido.fechaCreacion.toDate().toLocaleString()}
            </p>
            <p>
              <strong>Tarjeta de banco:</strong> {pedido.tarjetaBanco}
            </p>
            <p>
              <strong>Número de tarjeta:</strong> {pedido.tarjetaNumero}
            </p>
            <p>
              <strong>Tipo de tarjeta:</strong> {pedido.tarjetaTipo}
            </p>
            <p>
              <strong>Total general:</strong> $ {pedido.totalGeneral}
            </p>
            {pedido.meses > 1 ? (
              <p>
                <strong>Pago a meses sin intereses:</strong> a {pedido.meses}{" "}
                meses
              </p>
            ) : null}
          </>
        ) : (
          <p>Cargando detalles del pedido...</p>
        )}

        <h2 style={{ marginTop: "15px" }}>Subpedidos</h2>
        {/* Renderiza los subpedidos */}
        {loading ? (
          <p>Cargando subpedidos...</p>
        ) : (
          <>
            {subpedidos.map((subpedido) => (
              <Card key={subpedido.id} style={{ marginBottom: 20 }}>
                {/* Renderiza los detalles del subpedido */}

                <Card>
                  {/* Foto de la tienda a la izquierda */}
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {subpedido.fotoTienda && (
                      <Avatar
                        src={subpedido.fotoTienda}
                        alt={subpedido.nombreTienda}
                        style={{
                          width: "50px",
                          height: "50px",
                          border: "2px solid #000", // Agrega un borde negro de 2px
                          marginRight: "10px",
                        }}
                      />
                    )}
                    <h3 style={{ margin: 0 }}>
                      <strong>{subpedido.nombreTienda}</strong>
                    </h3>
                  </div>

                  <div style={{ flex: 1, marginTop: "10px" }}>
                    <p>
                      <strong>Mensajes con el vendedor</strong>
                    </p>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <Button
                      onClick={() => irAChat(subpedido.id)}
                      icon={<MessageOutlined />}
                    >
                      {subpedido.mensajesPendientes > 0 ? (
                        <span style={{ color: "green" }}>
                          Ver mensajes nuevos:
                          <Badge
                            count={subpedido.mensajesPendientes}
                            style={{
                              marginRight: "8px",
                              backgroundColor: "#f5222d",
                            }} // Añade color rojo al badge
                          />
                        </span>
                      ) : (
                        <span>Enviar mensaje</span>
                      )}
                    </Button>
                    {allDelivered && (
                      <Button
                        type="primary"
                        onClick={() => showReviewModal(subpedido)}
                        icon={<CommentOutlined />}
                        style={{
                          marginLeft: "10px",
                        }} // Añade un margen superior y alinea el botón a la derecha
                      >
                        Dejar una reseña
                      </Button>
                    )}
                  </div>
                </Card>

                <p>Total: ${subpedido.total}</p>
                {/* Muestra un indicador de estado */}
                <div
                  style={{
                    backgroundColor:
                      subpedido.estatus === "pendiente de revisión del vendedor"
                        ? "yellow"
                        : "inherit",
                    padding: "5px",
                    borderRadius: "5px",
                  }}
                ></div>
                {/* Renderiza la lista de productos del subpedido */}
                <h4>Productos</h4>
                <List
                  itemLayout="horizontal"
                  dataSource={subpedido.productos}
                  renderItem={(item) => (
                    <ListaItemProductosPedidos item={item} />
                  )}
                />
              </Card>
            ))}
          </>
        )}

        {devolucionHabilitada ? (
          <Popconfirm
            title="¿Estás seguro de que deseas realizar la cancelación del pedido?"
            onConfirm={handleRefund}
            okText="Sí"
            cancelText="No"
          >
            <Button type="primary">Realizar cancelación del pedido</Button>
          </Popconfirm>
        ) : (
          <Button type="primary" disabled>
            Ya no se puede hacer la cancelación del pedido
          </Button>
        )}
      </Card>
      <Modal
        title="Dejar una reseña"
        visible={reviewModalVisible}
        onCancel={() => setReviewModalVisible(false)}
        onOk={handleReviewSubmit}
      >
        <p>Puntúa al vendedor:</p>
        <Rate
          onChange={(value) => setReviewRating(value)}
          value={reviewRating}
        />
        <p>Escribe tu reseña:</p>
        <Input.TextArea
          rows={4}
          onChange={(e) => setReviewText(e.target.value)}
          value={reviewText}
        />
      </Modal>
    </>
  );
};

export default DetallesPedido;
