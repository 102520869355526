import React from "react";
import { List, Typography } from "antd";
const { Text } = Typography;

const ListaItemProductosPedidos = ({ item }) => {
  return (
    <List.Item key={item.id}   style={{
      padding: 10,
      backgroundColor: item.devolucion > 0 ? "lightgrey" : "white",
    }}>
      {item.tipo === "producto" ? (
        <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
          <div style={{ marginRight: 16 }}></div>
          <div style={{ flex: 1 }}>
            <List.Item.Meta
              title={item.nombre}
              description={`Cantidad: ${item.cantidad}`}
            />
            {/* Contenido específico para productos */}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p>${item.total}</p>
              <Text type="success">
                <strong> ${item.total * item.cantidad}</strong>
              </Text>
            </div>
            <p>Estatus: {item.estatus}</p>
            <p>
              Fecha de entrega:
              {item.entregaprogramada
                ? item.entregaprogramada
                : item.devolucion > 0
                ? "Entrega cancelada"
                : "Aún no se ha asignado una fecha de entrega"}
            </p>
            {/* Agrega más detalles o componentes específicos para productos aquí */}
          </div>
        </div>
      ) : (
        <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
          <div style={{ marginRight: 16 }}></div>
          <div style={{ flex: 1 }}>
            <List.Item.Meta
              title={item.nombre}
              description={item.stock && `Cantidad: ${item.cantidad}`}
            />

            {/* Contenido específico para productos */}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p>${item.total}</p>
              <p>{item.duracion}</p>
              <p>
                <strong>{item.diaSeleccionado}</strong>
              </p>
              {item.duracion !== "unico" && (
                <p>Tiempo: {item.cantidadDuracion}</p>
              )}
              {item.stock ? (
                item.duracion === "unico" ? (
                  <Text type="success">
                    <strong> ${parseFloat(item.total * 1).toFixed(2)}</strong>
                  </Text>
                ) : (
                  <Text type="success">
                    <strong>
                      {parseFloat(
                        item.total * item.cantidad * item.cantidadDuracion
                      ).toFixed(2)}
                    </strong>
                  </Text>
                )
              ) : item.duracion === "unico" ? (
                <Text type="success">
                  <strong> ${parseFloat(item.total * 1).toFixed(2)}</strong>
                </Text>
              ) : (
                <Text type="success">
                  <strong>
                    {parseFloat(item.total * item.cantidadDuracion).toFixed(2)}
                  </strong>
                </Text>
              )}
              
            </div>
            <p>Estatus: {item.estatus}</p>
            <p>
              Fecha de entrega:
              {item.entregaprogramada
                ? item.entregaprogramada
                : item.devolucion > 0
                ? "Entrega cancelada"
                : "Aún no se ha asignado una fecha de entrega"}
            </p>
          </div>
        </div>
      )}
    </List.Item>
  );
};

export default ListaItemProductosPedidos;
