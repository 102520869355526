import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Table, Button, Popconfirm, message} from "antd";
import { DeleteOutlined, EditOutlined,PauseCircleOutlined  } from "@ant-design/icons";
import {
  getFirestore,
  collection,
  getDocs,
  deleteDoc,
  doc,
  updateDoc,
  query,
  where,
  getDoc,
} from "firebase/firestore";

import { useUser } from "../../../context/UserContext";
import { db } from "../../../firebaseConfig";
import { PlusOutlined } from "@ant-design/icons";

const MisProductos = () => {
  const [filteredProducts, setFilteredProducts] = useState([]);
  const { userData } = useUser();
  const navigate = useNavigate();



  const getProductos = async () => {
    try {
      const q = query(
        collection(db, "productos"),
        where("vendedorUid", "==", userData.uid)
      );
  
      const querySnapshot = await getDocs(q);
      const productos = [];
  
      // Recorrer los productos
      for (const docSnap of querySnapshot.docs) {
        const producto = docSnap.data();
        producto.key = docSnap.id;
        productos.push(producto);
  
        // Obtener la referencia del documento de la categoría del producto
        const docRef = doc(db, "categorias", producto.categoria);
        const docSnapCategoria = await getDoc(docRef);
        const categoria = docSnapCategoria.data();
  
        if (categoria) {
          producto.categoriaNombre = categoria.nombre;
  
          // Buscar la subcategoría por su ID dentro del array de subcategorías de la categoría
          const subcategoria = categoria.subcategorias.find(
            (sub) => sub.id === producto.subcategoria
          );
  
          // Verificar si se encontró la subcategoría y obtener su nombre
          if (subcategoria) {
            producto.subcategoriaNombre = subcategoria.subcategoria;
          } else {
            producto.subcategoriaNombre = "sin subcategoria";
          }
        }
      }
      setFilteredProducts(productos);
    } catch (error) {
      console.error("Error al obtener productos:", error.message);
    }
  };

  

  useEffect(() => {
    getProductos();
  }, [getProductos]);
  
  const handleDelete = async (record) => {
    try {
      const db = getFirestore();
      const servicioDocRef = doc(db, "productos", record.key);
      await deleteDoc(servicioDocRef);
      message.success("Producto eliminado correctamente");
      getProductos();
    } catch (error) {
      console.error("Error al eliminar el servicio:", error);
    }
  };

  const handleDisabled = async (producto) => {
    try {
      const db = getFirestore();
      const servicioDocRef = doc(db,  "productos", producto.key);
      // Actualizar el estado de publicación a true (pausar)
      await updateDoc(servicioDocRef, {
        pausa: !producto.pausa, // Cambia "deshabilitado" por el nombre de tu columna en Firestore
      });
      getProductos();
      message.success("Producto actualizado correctamente");
    } catch (error) {
      console.error("Error al editar el servicio:", error.message);
    }
  };

  const handleEdit = async (producto) => {
    navigate(`/dashboard/editarproducto/${producto.key}`);
  };


  const columns = [
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
    },

    {
      title: "Imagen",
      dataIndex: "imagen",
      key: "imagen",
      render: (imagen) => (
        <img
          src={Array.isArray(imagen) && imagen.length > 0 ? imagen[0].url : ""}
          alt="Imagen del servicio"
          style={{ width: 50, height: 50 }}
        />
      ),
    },

    {
      title: "Stock",
      dataIndex: "stock",
      key: "stock",
    },
 
    {
      title: "Precio neto",
      dataIndex: "total",
      key: "total",
      render: (precio) => (
        <span>
          {precio.toLocaleString("es-MX", {
            style: "currency",
            currency: "MXN",
          })}
        </span>
      ),
    },
    {
      title: "Categoria",
      dataIndex: "categoriaNombre",
      key: "categoria",
    },
    {
      title: "Subcategoria",
      dataIndex: "subcategoriaNombre",
      key: "subcategoria",
    },
    {
      title: "Acciones",
      dataIndex: "acciones",
      key: "acciones",
      render: (_, record) => (
        <div style={{ display: "flex", gap: "10px" }}>
          <Button icon={<EditOutlined />} onClick={() => handleEdit(record)}>
            Editar
          </Button>
          <Popconfirm
            title="¿Estás seguro de eliminar este producto?"
            onConfirm={() => handleDelete(record)}
            okText="Sí"
            cancelText="No"
          >
            <Button danger icon={<DeleteOutlined />} style={{ marginRight: "10px" }}>
              Eliminar
            </Button>
          </Popconfirm>
      
          <Popconfirm
            title={"¿Estás seguro?"
            }
            onConfirm={() => handleDisabled(record)}
            okText="Sí"
            cancelText="No"
          >
            <Button style={{ marginRight: "10px" }} icon={<PauseCircleOutlined />}>
              {record.pausa? "Quitar pausa" : "Pausar"}
            </Button>
          </Popconfirm>
        </div>
      ),
      
    },
  ];

  return (
    <div>
      <h1>Lista de productos ofrecidos</h1>
      <Link to="/dashboard/crearproducto">
        <Button
          type="primary"
          style={{ position: "fixed", bottom: 20, right: 20 }}
          icon={<PlusOutlined />}
        >
          Agregar producto
        </Button>
      </Link>
      <Table dataSource={filteredProducts} columns={columns} />
    </div>
  );
};

export default MisProductos;
