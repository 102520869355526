import React from "react";
import { Badge, Button, Drawer, Typography } from "antd";
import CartView from "../screens/Cliente/Cart/CartView";
import { ShoppingCartOutlined } from "@ant-design/icons";

const BtnCarrito = ({ cartItems, disabled, text }) => {
  const [drawerVisible, setDrawerVisible] = React.useState(false);

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const onClose = () => {
    setDrawerVisible(false);
  };

  const productosLength = cartItems && cartItems.productos ? cartItems.productos.length : 0;
  const serviciosLength = cartItems && cartItems.servicios ? cartItems.servicios.length : 0;
  const items = productosLength + serviciosLength;
  

  return (
    <>
      <Badge count={items}>
        <Button
          icon={<ShoppingCartOutlined />}
          onClick={showDrawer}
          disabled={disabled || cartItems.length === 0}
        >
        {text === false ? text : "Ir a ver el carrito"}

        </Button>
      </Badge>
      <Drawer
        title="Carrito de compras"
        placement="right"
        closable={false}
        onClose={onClose}
        open={drawerVisible}
      >
        <CartView cartItems={cartItems} onClose={onClose} /> {/* Pasa la función onClose como prop */}
      </Drawer>
    </>
  );
};

export default BtnCarrito;
