import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";

const GraficoVentasPorMesAnioActual = ({ datos }) => {
    const chartRef = useRef(null);
    const [chart, setChart] = useState(null);
  
    useEffect(() => {
        if (!chartRef.current || !datos) return;

        const meses = [
            "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
            "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
        ];
        const labels = meses;
        const ventasData = meses.map((mes, index) => datos[index + 1] ? datos[index + 1].totalVentas : 0);
        const comisionesPlataformaData = meses.map((mes, index) => datos[index + 1] ? datos[index + 1].totalComisionesPlataforma : 0);
    

        const ctx = chartRef.current.getContext("2d");
  
        if (!chart) {
            const newChart = new Chart(ctx, {
                type: "line",
                data: {
                    labels: labels,
                    datasets: [
                        {
                            label: "Ventas por Mes",
                            data: ventasData,
                            borderColor: "rgba(54, 162, 235, 1)",
                            backgroundColor: "rgba(54, 162, 235, 0.2)",
                            yAxisID: "ventas",
                            pointRadius: 5,
                            pointHoverRadius: 7,
                        },
                        {
                            label: "Comisiones de Plataforma",
                            data: comisionesPlataformaData,
                            borderColor: "rgba(255, 99, 132, 1)",
                            backgroundColor: "rgba(255, 99, 132, 0.2)",
                            yAxisID: "comisiones",
                            pointRadius: 5,
                            pointHoverRadius: 7,
                        },
                       
                    ],
                },
                options: {
                    scales: {
                        ventas: {
                            position: "left",
                            title: {
                                display: true,
                                text: "Ventas",
                            },
                        },
                        comisiones: {
                            position: "right",
                            title: {
                                display: true,
                                text: "Comisiones",
                            },
                            grid: {
                                drawOnChartArea: false,
                            },
                        },
                    },
                },
            });
            setChart(newChart);
        } else {
            chart.data.labels = labels;
            chart.data.datasets[0].data = ventasData;
            chart.data.datasets[1].data = comisionesPlataformaData;
            chart.update();
        }
    }, [datos, chart]);
  
    return <canvas ref={chartRef}></canvas>;
};

export default GraficoVentasPorMesAnioActual;
