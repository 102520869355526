import React, { useState, useEffect } from "react";
import { Layout, Avatar, Typography, Card, Row, Col } from "antd";
import { Outlet, useParams, Link } from "react-router-dom";
import MenuList from "../../components/MenuList/MenuList";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import { useUser } from "../../context/UserContext";
import "./DashboardVendedor.scss";
import logo from "../../assets/logo.png";

const { Sider, Content } = Layout;


const DashboardVendedor = () => {
  const { userData } = useUser();
  const { id } = useParams();
  const [storeDetails, setStoreDetails] = useState({});
  const [estadoDatosPago, setEstadoDatosPago] = useState(null);

  useEffect(() => {
    if (userData && userData.uid) {
      const fetchStoreDetails = async () => {
        try {
          const storeDocRef = doc(db, "tiendas", userData.uid);
          const storeDocSnapshot = await getDoc(storeDocRef);

          if (storeDocSnapshot.exists()) {
            setStoreDetails(storeDocSnapshot.data());
          }
        } catch (error) {
          console.error(
            "Error al obtener detalles de la tienda:",
            error.message
          );
        }
      };

      const fetchEstadoDatosPago = async () => {
        try {
          const docRef = doc(db, "datosPagoVendedor", userData.uid);
          const docSnapshot = await getDoc(docRef);
          if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            setEstadoDatosPago(data.estado || "pendiente");
          } else {
            setEstadoDatosPago("sin datos");
          }
        } catch (error) {
          console.error(
            "Error al obtener el estado de los datos de pago:",
            error
          );
        }
      };

      fetchStoreDetails();
      fetchEstadoDatosPago();
    }
  }, [id, userData]);

  return (
    <div>
      <div className="control-tamano">
        <Row>
        <Col span={4}>
          <Sider
            className="sidebar"
            width={230}
            breakpoint="lg"
            collapsedWidth="80"
            style={{ paddingTop: "20px" }}
          >
           
            <MenuList datosDePago={estadoDatosPago} darkTheme={false}  foto={storeDetails.foto} nombre={storeDetails.nombre}/>
          </Sider>
          </Col>
          <Col span={20}>
      
            <Content
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "16px",
              }}
            >
              <Outlet id={id} />
            </Content>
            </Col>
            </Row>
      </div>

      <div className="movil-version">
        <Card
          title="Panel no compatible con versión móvil"
          style={{ width: 350, textAlign: "center" }}
        >
          <img
            src={logo}
            className="logoNav"
            alt="blizu"
            style={{ width: "100px", marginBottom: "20px" }}
          />
          <p>
            Para poder visualizar el panel de vendedor es necesario ingresar
            desde un dispositivo con una pantalla más grande. ¡Gracias por tu
            comprensión!
          </p>
          <Link to="/">Volver a inicio</Link>
        </Card>
      </div>
    </div>
  );
};

export default DashboardVendedor;
