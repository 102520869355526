import React from "react";
import { Row, Col, Card, Typography } from "antd";
import meses from "../../assets/beneficios/meses-sin-intereses.png";
import tarjeta from "../../assets/beneficios/pagos con tarjeta.png";
import vendedores from "../../assets/beneficios/vendedores-validados.png";
import productos from "../../assets/beneficios/productos-calidad.png";

const Beneficios = () => {
  return (
    <Card style={{margin:"20px"}}>
      <div>
      <Typography.Title
          style={{ textAlign: "center", fontSize: "2.5rem" }} 
          level={2} 
          responsive
        >
          Nuestros beneficios
        </Typography.Title>
        <Row gutter={[16, 16]} justify="space-around" style={{ margin: "20px"}}>
          <Col xs={12} sm={12} md={12} lg={6}>
            <div style={{ textAlign: "center" }}>
              <img src={meses} alt="Beneficio 1" style={{ height: "auto", maxWidth: "100%" }} />
              <p><strong>Meses sin intereses</strong></p>
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={6}>
            <div style={{ textAlign: "center" }}>
              <img src={tarjeta} alt="Beneficio 2" style={{ height: "auto", maxWidth: "100%" }} />
              <p><strong>Pagos con tarjeta de crédito y debito</strong></p>
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={6}>
            <div style={{ textAlign: "center" }}>
              <img src={vendedores} alt="Beneficio 4" style={{ height: "auto", maxWidth: "100%" }} />
              <p><strong>Vendedores validados</strong></p>
            </div>
          </Col>
        </Row>
      </div>
    </Card>
  );
};

export default Beneficios;
