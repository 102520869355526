import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
import { db } from "../../../firebaseConfig";
import { List, Rate, Typography, Card, Image, Row, Col, Flex, Empty } from "antd";
import resenaempty from "../../../assets/empty/clasificacion.png";

const { Title, Paragraph } = Typography;

const ReseñasVendedor = () => {
  const { vendedorUid } = useParams();
  const [reseñas, setReseñas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [promedioRating, setPromedioRating] = useState(0);
  const [tienda, setTienda] = useState(null);

  useEffect(() => {
    const fetchReseñas = async () => {
      try {
        const reseñasQuery = query(
          collection(db, "reseñasVendedor"),
          where("vendedorUid", "==", vendedorUid)
        );

        const reseñasSnapshot = await getDocs(reseñasQuery);
        const reseñasData = reseñasSnapshot.docs.map((doc) => doc.data());

        setReseñas(reseñasData);

        if (reseñasData.length > 0) {
          const sumRatings = reseñasData.reduce(
            (acc, reseña) => acc + reseña.reviewRating,
            0
          );
          const avgRating = sumRatings / reseñasData.length;
          setPromedioRating(avgRating);
        }

        setLoading(false);
      } catch (error) {
        console.error("Error al obtener las reseñas:", error);
      }
    };

    const fetchTienda = async () => {
      try {
        const tiendaDocRef = doc(db, "tiendas", vendedorUid);
        const tiendaDocSnapshot = await getDoc(tiendaDocRef);
        if (tiendaDocSnapshot.exists()) {
          const tiendaData = tiendaDocSnapshot.data();
          setTienda(tiendaData);
        }
      } catch (error) {
        console.error("Error al obtener la tienda:", error);
      }
    };

    fetchReseñas();
    fetchTienda();
  }, [vendedorUid]);

  return (
    <Flex
      vertical
      style={{ textAlign: "center", alignItems: "center", marginTop: "20px" }}
      gap={20}
    >
      {tienda && (
        <Card className="container" style={{ margin: "10px" }}>
          <Row align="middle">
            <Col xs={24} span={6}>
              <Image
                src={tienda.foto}
                alt={tienda.nombre}
                style={{ width: "100px" }}
                preview={false}
              />
            </Col>
            <Col xs={24} span={18}>
              <div>
                <Title level={2}>{tienda.nombre}</Title>
                <Paragraph>{tienda.descripcion}</Paragraph>
              </div>
            </Col>
          </Row>
        </Card>
      )}
      <Card className="container" style={{ margin: "10px" }}>
        <Title className="center-text" level={3}>
          Reseñas de pedidos realizados a esta tienda
        </Title>
        {loading ? (
          <p>Cargando reseñas...</p>
        ) : (
          <>
            <Title level={4}>
              {promedioRating.toFixed(2)}{" "}
              <Rate disabled value={promedioRating} />
            </Title>
            {reseñas && reseñas.length > 0 ? (
              <Row gutter={[16, 16]}>
                {reseñas.map((reseña, index) => (
                  <Col xs={24} sm={12} key={index}>
                    <Card style={{ margin: "10px", padding: "10px" }}>
                      <List.Item.Meta
                        title={
                          <Typography.Text strong>
                            {reseña.nameUser ? reseña.nameUser : "Anónimo"}
                          </Typography.Text>
                        }
                        description={
                          <>
                            <Rate disabled value={reseña.reviewRating} />
                            <Paragraph>{reseña.reviewText}</Paragraph>
                          </>
                        }
                      />
                    </Card>
                  </Col>
                ))}
              </Row>
            ) : (
              <Empty
                image={resenaempty}
                description="Aún no se ha dejado ninguna reseña, ¡Se el primero!"
              />
            )}
          </>
        )}
      </Card>
    </Flex>
  );
};

export default ReseñasVendedor;
