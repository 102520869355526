import React, { useState, useEffect } from "react";
import { HeartOutlined, HeartFilled } from "@ant-design/icons";
import { Card, Typography, Rate, Tag } from "antd";
import { Link } from "react-router-dom";
import "./ListaServicioItem.scss";
import { obtenerNombreCategoria } from "../../utils/categorias/categoriaUtils";
import { useUser } from "../../context/UserContext";
import { toast } from "sonner";

const { Meta } = Card;
const { Text } = Typography;

export const ListaServiciosItemBuscador = ({
  servicio,
  addToCart,
  favorite,
  isFavorite,
}) => {
  const { userData } = useUser();
  const isMesesSinIntereses = servicio.mesesSinIntereses || false;
  const [nombreCategoria, setNombreCategoria] = useState("");
  const [localIsFavorite, setLocalIsFavorite] = useState(isFavorite);
  const [buttonDisabled, setButtonDisabled] = useState(false); // Estado para deshabilitar el botón durante el cooldown

  useEffect(() => {
    obtenerNombreCategoria(servicio.categoria)
      .then((nombre) => {
        setNombreCategoria(nombre);
      })
      .catch((error) => {
        console.error("Error al obtener el nombre de la categoría:", error);
      });
  }, [servicio.categoria]);

  useEffect(() => {
    setLocalIsFavorite(isFavorite); // Actualizar localIsFavorite cuando isFavorite cambie
  }, [isFavorite]);

  const handleToggleFavorite = () => {
    if (!buttonDisabled) {
      // Si el botón no está deshabilitado
      if (!userData) {
        toast.error("Inicia sesión para agregar a favoritos.");
        return;
      }
      favorite(!localIsFavorite); // Cambiar el estado de favorito en el componente padre
      setLocalIsFavorite(!localIsFavorite); // Actualizar el estado local
      setButtonDisabled(true); // Deshabilitar el botón
      setTimeout(() => {
        // Habilitar el botón después de 1 segundo
        setButtonDisabled(false);
      }, 1000);
    }
  };
  return (
    <Card
      style={{ width: 300, borderRadius: 10, height: 450 }}
      cover={
        <div>
          <Link
            key={servicio.id}
            to={`/servicio/${servicio.id}`}
            style={{ position: "relative", display: "inline-block" }}
          >
            <img
              alt="example"
              src={servicio.imagen[0].url}
              style={{
                width: 300,
                height: 200,
                objectFit: "contain",
                padding: 2,
              }}
            />
          </Link>
          <button
            onClick={() => handleToggleFavorite()}
            style={{
              position: "absolute",
              top: 10,
              right: 10,
              border: "none",
              background: "transparent",
              cursor: "pointer",
            }}
            disabled={buttonDisabled} // Deshabilitar el botón si está en cooldown
          >
            {localIsFavorite ? (
              <HeartFilled style={{ color: "red", fontSize: "26px" }} />
            ) : (
              <HeartOutlined style={{ color: "red", fontSize: "26px" }} />
            )}
          </button>
        </div>
      }
    >
      <Meta
        title={
          <div
            style={{
              whiteSpace: "pre-line",
              maxHeight: "3em",
              overflow: "hidden",
            }}
          >
            {servicio.nombre}
          </div>
        }
        description={
          <>
            <div style={{ color: "midnightblue" }}>
              <Tag>{`${servicio.horaInicio} - ${servicio.horaFin}`}</Tag>
            </div>
            <Text style={{ color: "#878787" }}>
              <strong>{nombreCategoria}</strong>
            </Text>{" "}
            <div>
              {servicio.stock && (
                <Tag color="blue">Stock: {servicio.stock}</Tag>
              )}
            </div>
          </>
        }
      />
      <div style={{ marginTop: 10 }}>
        <Rate disabled allowHalf defaultValue={servicio.calificacion || 0} />
      </div>
      {isMesesSinIntereses ? (
        <span style={{ color: "green" }}> (Meses sin intereses)</span>
      ) : (
        <span> </span>
      )}

      <div
        style={{
          position: "absolute",
          bottom: 0,
          left: 0,
          width: "100%",
          backgroundColor: "#1dbf73",
          padding: "10px",
          display: "flex",
          justifyContent: "space-between",
          paddingRight: "20px",
          paddingLeft: "20px",
          borderBottomLeftRadius: 10, // Redondear la esquina inferior izquierda
          borderBottomRightRadius: 10,
        }}
      >
        <Typography.Text
          style={{
            color: isMesesSinIntereses ? "yellow" : "#fff",
            fontWeight: "bolder",
            fontSize: "30px",
          }}
        >
          ${servicio.total}
        </Typography.Text>
      </div>
    </Card>
  );
};
