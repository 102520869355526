import { doc, getDoc, deleteDoc } from 'firebase/firestore';
import { db } from "../../firebaseConfig";
import { getProductoPorId } from '../productos/productoUtils';
import { getServicioPorId } from '../servicios/serviciosUtils';


// Función para obtener el carrito de un usuario
export const getUserCart = async (uid) => {
    const userCartRef = doc(db, "carritos", uid);
    const userCartSnapshot = await getDoc(userCartRef);
    
    return userCartSnapshot.exists() ? userCartSnapshot.data() : null;
  };


// Función para obtener detalles de productos del carrito
export const obtenerDetallesProductos = async (cartItems) => {
    try {
      const promises = cartItems.productos.map(async (producto) => {
        const detalleProducto = await getProductoPorId(producto.id);
        return {
          ...detalleProducto,
          tipo: "producto",
          cantidad: producto.cantidad, // Mantener la cantidad del producto
        };
      });
      const productos = await Promise.all(promises);
      return productos;
    } catch (error) {
      console.error("Error al obtener detalles de los productos:", error);
    }
  };
  
  // Función para obtener detalles de servicios del carrito
  export const obtenerDetallesServicio = async (cartItems) => {
    try {
      const promises = cartItems.servicios.map(async (servicio) => {
        const detalleServicio = await getServicioPorId(servicio.id);
        let servicioActualizado = {
          ...detalleServicio,
          cantidad: servicio.cantidad,
        };
  
        if (detalleServicio.stock) {
          if (detalleServicio.duracion === "unico") {
            servicioActualizado = {
              ...servicioActualizado,
              tipo: "servicio",
              diaSeleccionado: servicio.diaSeleccionado,
              horaInicioSeleccionada: servicio.horaInicioSeleccionada,
            };
          } else {
            servicioActualizado = {
              ...servicioActualizado,
              tipo: "servicio",
              cantidadDuracion: servicio.cantidadDuracion,
              diaSeleccionado: servicio.diaSeleccionado,
              horaInicioSeleccionada: servicio.horaInicioSeleccionada,
            };
          }
        } else {
          if (detalleServicio.duracion === "unico") {
            servicioActualizado = {
              ...servicioActualizado,
              tipo: "servicio",
              diaSeleccionado: servicio.diaSeleccionado,
              horaInicioSeleccionada: servicio.horaInicioSeleccionada,
            };
          } else {
            servicioActualizado = {
              ...servicioActualizado,
              tipo: "servicio",
              cantidadDuracion: servicio.cantidadDuracion,
              diaSeleccionado: servicio.diaSeleccionado,
              horaInicioSeleccionada: servicio.horaInicioSeleccionada,
            };
          }
        }
  
        return servicioActualizado;
      });
  
      const productos = await Promise.all(promises);
      return productos;
    } catch (error) {
      console.error("Error al obtener detalles de los productos:", error);
    }
  };

  // Función para eliminar el carrito de un usuario
export const eliminarCarritoUsuario = async (uid) => {
  try {
      const userCartRef = doc(db, "carritos", uid);
      await deleteDoc(userCartRef);
      console.log("Carrito eliminado correctamente");
  } catch (error) {
      console.error("Error al eliminar el carrito:", error);
      throw new Error("Error al eliminar el carrito");
  }
};
  