import React, { useState, useEffect } from "react";
import {
  Modal,
  Table,
  Form,
  Input,
  Button,
  message,
  Popconfirm,
  Typography,
  Flex,
  Card,
  Empty,
} from "antd";
import {
  getFirestore,
  collection,
  doc,
  setDoc,
  getDoc,
} from "firebase/firestore";
import { useUser } from "../../../context/UserContext";
import MapGoogle from "../../../components/MapGoogle/MapGoogle";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import DireccionItem from "../../../assets/direccionesItem/DireccionesItem";
import ButtonVolverACheckout from "../../../components/ButtonVolverACheckout/ButtonVolverACheckout";
import { useLocation } from "react-router-dom/dist";
import direccionempty from "../../../assets/empty/direccion-de-casa.png";
const DireccionesCliente = () => {
  const location = useLocation();
  const desdeCheckout = location.state ? location.state.desdeCheckout : false;

  const [formAdd] = Form.useForm();
  const [formEdit] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { userData } = useUser();
  const [direcciones, setDirecciones] = useState([]);
  const [modalOpenEdit, setModalOpenEdit] = useState(false);
  const [modalOpenAdd, setModalOpenAdd] = useState(false);
  const [markerPosition, setMarkerPosition] = useState(null);
  const [editDireccionId, setEditDireccionId] = useState(null);
  const [deleteDireccionId, setDeleteDireccionId] = useState(null);

  if (navigator.geolocation && markerPosition === null) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setMarkerPosition({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      },
      (error) => {
        console.error(error.message);
      }
    );
  }

  const fetchDirecciones = async () => {
    try {
      const db = getFirestore();
      const userId = userData.uid;
      const direccionDocRef = doc(db, "direcciones", userId);

      const direccionDoc = await getDoc(direccionDocRef);

      if (direccionDoc.exists()) {
        const direccionesData = direccionDoc.data().direcciones || {};
        const direccionesArray = Object.values(direccionesData).map(
          (direccion, index) => ({
            ...direccion,
            key: index, // Agrega un identificador único basado en el índice del array
          })
        );
        //console.log(direccionesArray);
        setDirecciones(direccionesArray);
      }
    } catch (error) {
      console.error("Error al obtener direcciones:", error.message);
    }
  };

  useEffect(() => {
    fetchDirecciones();
  }, [userData]);

  const handleAddDireccion = () => {
    setModalOpenAdd(true);
    setEditDireccionId(null);
    formAdd.resetFields();
  };

  const handleEditDireccion = (direccionId) => {
    //console.log(direccionId);
    setEditDireccionId(direccionId);
    setModalOpenEdit(true);
    const direccionToEdit = direcciones.find(
      (_, index) => index === direccionId
    );
    formEdit.setFieldsValue(direccionToEdit);
  };

  const editarDireccion = async (direccionIndex, nuevaDireccion) => {
    try {
      const db = getFirestore();
      const userId = userData.uid;

      const direccionesCollection = collection(db, "direcciones");
      const direccionDocRef = doc(direccionesCollection, userId);

      // Clona el array actual de direcciones para no mutar el estado directamente
      const direccionesClone = [...direcciones];

      // Reemplaza la dirección existente con la nueva dirección en el índice dado
      direccionesClone[direccionIndex] = nuevaDireccion;

      // Actualiza el documento con la lista actualizada de direcciones
      await setDoc(direccionDocRef, {
        direcciones: direccionesClone,
      });

      setDirecciones(direccionesClone);
      setModalOpenEdit(false);
      setEditDireccionId(null);
      formEdit.resetFields();
      message.success("Dirección editada exitosamente");
    } catch (error) {
      console.error("Error al editar la dirección:", error.message);
      message.error("Error al editar la dirección");
    } finally {
      setLoading(false);
    }
  };

  // Luego, puedes llamar a esta función en tu onFinish cuando estés editando una dirección
  const handleEditFinish = async (values) => {
    setLoading(true);

    try {
      const nuevaDireccion = {
        ...values,
        coordenadas: markerPosition,
      };

      // Asumiendo que editDireccionId contiene el índice de la dirección que se está editando
      await editarDireccion(editDireccionId, nuevaDireccion);

      formEdit.resetFields();
      setMarkerPosition(null);
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleModalClose = () => {
    setModalOpenAdd(false);
    setModalOpenEdit(false);
    setEditDireccionId(null);
    formAdd.resetFields();
    formEdit.resetFields();
  };

  const handleDeleteDireccion = (direccionId) => {
    handleConfirmDelete(direccionId);
    // Abre un modal de confirmación si lo deseas
    // o puedes eliminar directamente si prefieres
  };

  const handleConfirmDelete = async (key) => {
   // console.log(key);
    try {
      const db = getFirestore();
      const userId = userData.uid;

      const direccionesCollection = collection(db, "direcciones");
      const direccionDocRef = doc(direccionesCollection, userId);

      // Filtra las direcciones para excluir la que se eliminará
      const updatedDirecciones = direcciones.filter(
        (_, index) => index !== key
      );

      // Actualiza el documento con la lista actualizada de direcciones
      await setDoc(direccionDocRef, {
        direcciones: updatedDirecciones,
      });
      fetchDirecciones();
      setDeleteDireccionId(null);
      message.success("Dirección eliminada correctamente");
    } catch (error) {
      console.error("Error al eliminar la dirección:", error.message);
      message.error("Error al eliminar la dirección");
    }
  };

  const onFinish = async (values) => {
    setLoading(true);

    try {
      const db = getFirestore();
      const userId = userData.uid;

      const direccionesCollection = collection(db, "direcciones");
      const direccionDocRef = doc(direccionesCollection, userId);

      if (!values.numeroInterior) {
        values.numeroInterior = "N/A";
      }
      const nuevaDireccion = {
        ...values,
        coordenadas: markerPosition,
      };

      // Agregar la nueva dirección a la lista de direcciones
      setDirecciones((prevDirecciones) => [...prevDirecciones, nuevaDireccion]);

      // Actualizar el documento con la lista actualizada de direcciones
      await setDoc(direccionDocRef, {
        direcciones: [...direcciones, nuevaDireccion],
      });

      formAdd.resetFields();
      setModalOpenAdd(false);
      setLoading(false);
      setMarkerPosition(null);
      message.success("Dirección guardada exitosamente");
    } catch (error) {
      message.error(
        "Error al guardar la dirección. Por favor, inténtalo de nuevo."
      );
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleMapClick = (event) => {
    setMarkerPosition({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
  };

  return (
    <Flex
      vertical
      style={{ textAlign: "center", alignItems: "center", marginTop: "20px" }}
      gap={20}
    >
      <Card style={{ width: "100%", margin: "5px" }}>
        <Typography.Title>Mis direciones</Typography.Title>
        {desdeCheckout && <ButtonVolverACheckout />}
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={handleAddDireccion}
          style={{ marginBottom: 16, backgroundColor: "#1dbe72" }}
        >
          Agregar dirección
        </Button>

        <div style={{ margin: "20px" }}>
          {direcciones && direcciones.length > 0 ? (
            <>
              {direcciones.map((direccion) => (
                <DireccionItem
                  key={direccion.key}
                  direccion={direccion}
                  onEdit={() => handleEditDireccion(direccion.key)}
                  onDelete={() => handleDeleteDireccion(direccion.key)}
                />
              ))}
            </>
          ) : (
            <Empty
              image={direccionempty}
              description="Aún no se ha guardado ninguna dirección  "
            />
          )}
        </div>
      </Card>

      <Modal
        open={modalOpenAdd}
        title={"Guardar dirección"}
        onCancel={handleModalClose}
        footer={null}
      >
        <Form
          form={formAdd}
          name={"guardar-direcciones-modal"}
          onFinish={onFinish}
          layout="vertical"
        >
          <Form.Item
            name="nombreReceptor"
            label="Nombre de la persona que reciba"
            rules={[
              {
                required: true,
                message: "Por favor ingresa el nombre del receptor",
              },
            ]}
          >
            <Input placeholder="Nombre " />
          </Form.Item>
          <Form.Item
            name="alias"
            label="Alias (ej. Casa, oficina, trabajo, etc)"
            rules={[
              {
                required: true,
                message: "Por favor ingresa un alias para la dirección",
              },
            ]}
          >
            <Input placeholder="Alias " />
          </Form.Item>
          <Form.Item
            name="calle"
            label="Calle"
            rules={[
              {
                required: true,
                message: "Por favor ingresa el nombre de la calle",
              },
            ]}
          >
            <Input placeholder="Calle" />
          </Form.Item>
          <Form.Item
            name="numeroExterior"
            label="Numero exterior"
            rules={[
              {
                required: true,
                message: "Por favor ingresa el número exterior",
              },
            ]}
          >
            <Input placeholder="Numero exterior" />
          </Form.Item>
          <Form.Item name="numeroInterior" label="Numero interior (Opcional)">
            <Input placeholder="Numero interior" />
          </Form.Item>

          <Form.Item
            name="colonia"
            label="Colonia"
            rules={[
              {
                required: true,
                message: "Por favor ingresa el nombre de la colonia",
              },
            ]}
          >
            <Input placeholder="Colonia" />
          </Form.Item>
          <Form.Item
            name="codigoPostal"
            label="Código postal"
            rules={[
              { required: true, message: "Por favor ingresa el código postal" },
            ]}
          >
            <Input placeholder="Código Postal" />
          </Form.Item>
          <Form.Item
            name="estado"
            label="Estado"
            rules={[
              {
                required: true,
                message: "Por favor ingresa el nombre del estado",
              },
            ]}
          >
            <Input placeholder="Estado" />
          </Form.Item>
          <Form.Item
            name="municipio"
            label="Municipio"
            rules={[
              {
                required: true,
                message: "Por favor ingresa el nombre del municipio",
              },
            ]}
          >
            <Input placeholder="Municipio" />
          </Form.Item>
          <Form.Item
            name="referencias"
            label="Referencias (ej. porton negro, casa amarilla, etc)"
            rules={[
              { required: true, message: "Por favor ingresa las referencias" },
            ]}
          >
            <Input.TextArea placeholder="Referencias" />
          </Form.Item>
          <label>Selecciona la ubicación en el mapa</label>
          <MapGoogle
            onMapClick={handleMapClick}
            markerPosition={markerPosition}
          />

          <Form.Item>
            <Button type="primary" htmlType="submit"  style={{width: '100%', marginTop:'10px',  backgroundColor: '#1dbe72'}} loading={loading}>
              Guardar
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        open={modalOpenEdit}
        title={"Editar dirección"}
        onCancel={handleModalClose}
        footer={null}
      >
        <Form
          form={formEdit}
          name={"editar-direcciones-modal"}
          onFinish={handleEditFinish}
          layout="vertical"
        >
          <Form.Item name="nombreReceptor"    label="Nombre de la persona que reciba" required>
            <Input placeholder="Nombre" />
          </Form.Item>
          <Form.Item name="alias" label="Alias (ej. Casa, oficina, trabajo, etc)" required>
            <Input placeholder="Alias" />
          </Form.Item>
          <Form.Item name="calle" label="Calle" required>
            <Input placeholder="Calle" />
          </Form.Item>
          <Form.Item name="numeroExterior" label="Numero exterior" required>
            <Input placeholder="Numero exterior" />
          </Form.Item>
          <Form.Item name="numeroInterior" label="Numero interior (Opcional)">
            <Input placeholder="Numero interior" />
          </Form.Item>

          <Form.Item name="colonia" label="Colonia" required>
            <Input placeholder="Colonia" />
          </Form.Item>
          <Form.Item name="codigoPostal" label="Código postal" required>
            <Input placeholder="Código postal" />
          </Form.Item>
          <Form.Item name="estado" label="Estado" required>
            <Input placeholder="Estado" />
          </Form.Item>
          <Form.Item name="municipio" label="Municipio" required>
            <Input placeholder="Municipio" />
          </Form.Item>
          <Form.Item name="referencias" label="Referencias" required>
            <Input.TextArea placeholder="Referencias" />
          </Form.Item>
          <label>Selecciona tu ubicación el el mapa por favor</label>
          <MapGoogle
            onMapClick={handleMapClick}
            markerPosition={markerPosition}
          />

          <Form.Item>
            <Button type="primary" htmlType="submit" style={{width: '100%', marginTop:'10px',  backgroundColor: '#1dbe72'}} loading={loading}>
              Guardar cambios
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Flex>
  );
};

export default DireccionesCliente;
