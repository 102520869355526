// SimpleMap.js
import React, { useEffect, useState } from "react";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";
import { Button } from "antd";

const libraries = ["places"];
const mapContainerStyle = {
  height: "400px",
  width: "100%",
};

const SimpleMap = ({ markerPosition }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyDQfjiw3oAMK7gtBChu4qF9Qq9OdB4XXb4", // Reemplaza con tu clave API de Google Maps
    libraries: libraries,
  });

  const [mapCenter, setMapCenter] = useState(null);

  useEffect(() => {
    if (markerPosition) {
      setMapCenter(markerPosition);
    }
  }, [markerPosition]);

  if (loadError) return "Error al cargar el mapa";
  if (!isLoaded) return "Cargando mapa...";

  return (
    <>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={15}
        center={mapCenter || { lat: -34.397, lng: 150.644 }}
      >
        {markerPosition && <MarkerF position={markerPosition} />}
      </GoogleMap>
      {markerPosition && (
        <div style={{ marginTop: "10px", textAlign: "center" }}>
          <Button
            type="primary"
            onClick={() => {
              window.open(
                `https://www.google.com/maps?q=${markerPosition.lat},${markerPosition.lng}`,
                "_blank"
              );
            }}
          >
            Ver en Google Maps
          </Button>
        </div>
      )}
    </>
  );
};

export default SimpleMap;
