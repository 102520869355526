import React from 'react';
import { Carousel } from 'antd';

const CarouselAntd = ({ images }) => {
  return (
    <div style={{ width: '100%' }}>
   <Carousel autoplay style={{  marginBottom: "20px" }}>
          {images.map((imagen) => (
            <div key={imagen.nombre}>
              <img
                src={imagen.url}
                alt={imagen.nombre}
                style={{ width: "100%", objectFit: "cover" }}
              />
            </div>
          ))}
        </Carousel>
    </div>
   
  );
};

export default CarouselAntd;
