import axios from 'axios';





const openPayRequest = async (endpoint, method, data = {}) => {
  try {
    const response = await axios({
      method: 'POST', // Se cambió la posición de 'POST' para que sea una propiedad del objeto
      url: `https://api.blizu.com.mx/api/openpay/request`,
      data: { // Se cambió 'body' a 'data' para axios
        endpoint: endpoint,
        method: method,
        data: data
      },
    });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};



export const createCustomer = async (customerData) => {
  try {
  const endpoint = '/customers';
  const method = 'POST';
  return openPayRequest(endpoint, method, customerData);
} catch (error) {
  console.error('Error al crear cliente:', error);
  throw error;
}
};

export const createCharge = async (chargeData, customer) => {
  const endpoint = `/customers/${customer}/charges`;
  const method = 'POST';
  return openPayRequest(endpoint, method, chargeData);
};


// Función para devolver un cargo
export const refundCharge = async (orderId, data) => {
  try {
    // Primero, busca la orden por su orderId
    const orderEndpoint = `/charges?order_id=${orderId}`;
    const orderResponse = await openPayRequest(orderEndpoint, 'GET');

    console.log(orderResponse);
    // Verifica si se encontró la orden y se obtiene el chargeId
    if (orderResponse  && orderResponse.length > 0) {
      const chargeId = orderResponse[0].id;

      // Luego, realiza la devolución del cargo
      const refundEndpoint = `/customers/${orderResponse[0].customer_id}/charges/${chargeId}/refund`;
      const method = 'POST';
      return openPayRequest(refundEndpoint, method, data);
    } else {
      throw new Error(`No se encontró la orden con orderId: ${orderId}`);
    }
  } catch (error) {
    console.error('Error al realizar la devolución del cargo:', error);
    throw error;
  }
};


export const addCardToCustomer = async (cardData, customer) => {
  try {
    const endpoint1 = `/tokens`;
    const method = 'POST';
    const token = await openPayRequest(endpoint1, method, cardData);
  
    const data = { token_id: token.id, device_session_id: customer };
    const endpoint2 = `/customers/${customer}/cards`;
    return await openPayRequest(endpoint2, method, data);
  } catch (error) {
    console.error('Error adding card to customer:', error);
    throw error; // Re-lanza el error para que sea manejado en un nivel superior
  }
}



export const getCardToCustomer = async (openpayId) => {
  const endpoint = `/customers/${openpayId}/cards`;
  const method = 'GET';
  return openPayRequest(endpoint, method);
};

export const getCustomerInfo = async (openpayId) => {
  const endpoint = `/customers/${openpayId}`;
  const method = 'GET';
  return openPayRequest(endpoint, method);
};

export const deleteCardFromCustomer = async (openpayId, cardId) => {
  const endpoint = `/customers/${openpayId}/cards/${cardId}`;
  const method = 'DELETE';
  return openPayRequest(endpoint, method);
};
