import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Avatar,
  Breadcrumb,
  Button,
  Card,
  Carousel,
  Col,
  Flex,
  List,
  Row,
  Rate,
  Input,
  Typography,
  Modal,
  InputNumber,
  TimePicker,
  Select,
  DatePicker,
} from "antd";
import { db } from "../../../firebaseConfig";

import {
  doc,
  addDoc,
  collection,
  getDoc,
  getDocs,
  query,
  where,
  updateDoc,
  setDoc,
  arrayUnion,
} from "firebase/firestore";
import { useUser } from "../../../context/UserContext";
import { Toaster, toast } from "sonner";
import {
  HeartOutlined,
  HeartFilled,
  ShoppingCartOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  addToCartServicioDetalle,
  eliminarServicioCarrito,
} from "../../../utils/servicios/serviciosUtils";
import locale from "antd/es/locale/es_ES";
import dayjs from "dayjs";
import "dayjs/locale/es"; // Importa el idioma español de dayjs
dayjs.locale("es");

export default function Servicio() {
  const { id } = useParams();
  const [servicio, setservicio] = useState(null);
  const [cantidad, setCantidad] = useState(1);
  const [calificacion, setCalificacion] = useState(0);
  const [resena, setResena] = useState("");
  const [mostrarFormulario, setMostrarFormulario] = useState(true);
  const listaResenasRef = useRef(null);
  const [reseñas, setReseñas] = useState([]);
  const [calificacionPromedio, setCalificacionPromedio] = useState(0);
  const { userData, userFavorites, cartItems } = useUser();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [horaInicioSeleccionada, setHoraInicioSeleccionada] = useState(null);
  const [diaSeleccionado, setDiaSeleccionado] = useState(undefined);
  const [cantidadDuracion, setCantidadDuracion] = useState(1);
  const [enCarrito, setEncarrito] = useState(false);
  const [tienda, setTienda] = useState(null);

  function disabledDate(current) {
    const today = dayjs().startOf("day"); // Obtener el día actual sin la hora
    const currentDay = dayjs(current).locale("es").format("dddd").toLowerCase();
    // Devuelve true si el día actual no está en la lista de días disponibles o si es anterior a hoy
    return (
      !servicio.diasDisponibles.includes(currentDay) ||
      dayjs(current).isBefore(today, "day") || // Deshabilitar fechas anteriores a hoy, incluyendo hoy mismo
      dayjs(current).isSame(today, "day") // Deshabilitar el día de hoy
    );
  }

  const handleSelectHora = (value) => {
    setHoraInicioSeleccionada(value);
  };

  const handleSelectDia = (value) => {
    setDiaSeleccionado(value);
  };

  const handleRemoveFromCart = () => {
    eliminarServicioCarrito(userData, id); // Llama a la función eliminarCarrito pasando userData como argumento
  };

  const navigate = useNavigate();

  useEffect(() => {
    // Verificar si userFavorites no está vacío y tiene la propiedad productos definida
    if (
      userFavorites &&
      userFavorites.servicios &&
      userFavorites.servicios.length > 0
    ) {
      setIsFavorite(userFavorites.servicios.includes(id));
    }

    if(userData){
      setMostrarFormulario(true);
    }else{
      setMostrarFormulario(false);
      setButtonDisabled(true);
    }

  }, [id, userFavorites]);

  useEffect(() => {
    const getProduct = async () => {
      const docRef = doc(db, "servicios", id);
      const docSnap = await getDoc(docRef);
      const product = docSnap.data();
      product.id = docSnap.id;
      setservicio(product);
      await getTienda(product.vendedorUid);
    };
    const getTienda = async (vendedorUid) => {
      try {
        const docRef = doc(db, "tiendas", vendedorUid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const tiendaData = docSnap.data();
          tiendaData.id = docSnap.id;
          setTienda(tiendaData);
        } else {
          console.error("No se encontró la tienda");
        }
      } catch (error) {
        console.error("Error al obtener la tienda:", error);
      }
    };
    getProduct();
  }, [id]);

  useEffect(() => {
    if (cartItems.servicios && servicio) {
      // Verificar que servicio no sea nulo
      if (cartItems.servicios.length > 0) {
        const servicioEnCarrito = cartItems.servicios.find(
          (item) => item.id === servicio.id
        );
        if (servicioEnCarrito) {
          if (servicio.stock) {
            if (servicio.duracion === "unico") {
              setHoraInicioSeleccionada(
                servicioEnCarrito.horaInicioSeleccionada
              );
              setDiaSeleccionado(servicioEnCarrito.diaSeleccionado);
              setCantidad(servicioEnCarrito.cantidad);
            } else {
            //  console.log(servicioEnCarrito.cantidad);
              setHoraInicioSeleccionada(
                servicioEnCarrito.horaInicioSeleccionada
              );
              setDiaSeleccionado(servicioEnCarrito.diaSeleccionado);
              setCantidadDuracion(servicioEnCarrito.cantidadDuracion);
              setCantidad(servicioEnCarrito.cantidad);
            }
          } else {
            if (servicio.duracion === "unico") {
              setHoraInicioSeleccionada(
                servicioEnCarrito.horaInicioSeleccionada
              );
              setDiaSeleccionado(servicioEnCarrito.diaSeleccionado);
            } else {
              setHoraInicioSeleccionada(
                servicioEnCarrito.horaInicioSeleccionada
              );
              setDiaSeleccionado(servicioEnCarrito.diaSeleccionado);
              setCantidadDuracion(servicioEnCarrito.cantidadDuracion);
            }
          }
          setEncarrito(true);
        } else {
          setDiaSeleccionado(null);
          setEncarrito(false);
        }
      } else {
        setDiaSeleccionado(null);
        setEncarrito(false);
      }
    }
  }, [cartItems, servicio]);

  const handleToggleFavorite = () => {
    if (!buttonDisabled) {
      // Si el botón no está deshabilitado
      setIsFavorite(!isFavorite); // Actualizar el estado local
      setButtonDisabled(true); // Deshabilitar el botón
      setTimeout(() => {
        // Habilitar el botón después de 1 segundo
        setButtonDisabled(false);
      }, 1000);
    }
  };

  const handleModalOk = () => {
    setIsModalVisible(false);
    navigate("/login");
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const handleCalificar = async () => {
    if (mostrarFormulario) {
      const nuevaResena = {
        titulo: "Nueva",
        descripcion: resena,
        calificacion,
        idservicio: id,
        idUser: userData.uid,
        nombre: userData.email,
        vendedorUid: servicio.vendedorUid,
        photo: userData.photo,
      };

      // Guardar reseña en Firebase
      const reseñasCollection = collection(db, "resenas");
      await addDoc(reseñasCollection, nuevaResena);

      // Actualizar el estado de las reseñas
      setMostrarFormulario(false);
      obtenerReseñas();
    }
  };

  const obtenerReseñas = async () => {
    try {
      // Realiza una consulta para obtener solo las reseñas del servicio actual
      const resenasCollection = collection(db, "resenas");
      const querySnapshot = await getDocs(
        query(resenasCollection, where("idservicio", "==", id))
      );

      // Mapea los documentos de reseñas y actualiza el estado
      const resenasData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setReseñas(resenasData);

      // Calcula el promedio de las calificaciones
      const totalCalificaciones = resenasData.reduce(
        (total, reseña) => total + reseña.calificacion,
        0
      );
      const promedio = totalCalificaciones / resenasData.length || 0;
      setCalificacionPromedio(promedio);
    } catch (error) {
      console.error("Error al obtener reseñas:", error);
    }
  };

  useEffect(() => {
    obtenerReseñas();
  }, []);

  return (
    <>
      <Toaster richColors position="top-center" />
      <Flex justify="center" vertical style={{ padding: 10 }}>
        {servicio == null ? null : (
          <>
            <br />
            <Card>
              <Row>
                <Col xs={24} md={8} xl={8}>
                  <Carousel autoplay>
                    {servicio.imagen.map((imagen, index) => (
                      <div className="img-container" key={index}>
                        <img alt="" src={imagen.url} width={200} />
                      </div>
                    ))}
                  </Carousel>
                  <button
                    onClick={() => handleToggleFavorite()}
                    style={{
                      position: "absolute",
                      top: 10,
                      right: 10,
                      border: "none",
                      background: "transparent",
                      cursor: "pointer",
                    }}
                    disabled={buttonDisabled} // Deshabilitar el botón si está en cooldown
                  >
                    {isFavorite ? (
                      <HeartFilled style={{ color: "red", fontSize: "26px" }} />
                    ) : (
                      <HeartOutlined
                        style={{ color: "red", fontSize: "26px" }}
                      />
                    )}
                  </button>
                </Col>
                <Col xs={24} md={16} xl={16}>
                  <Typography.Title>{servicio.nombre}</Typography.Title>
                  <Rate
                    disabled
                    allowHalf
                    defaultValue={calificacionPromedio}
                  />
                  <Row>
                    <Col xs={24}>
                      <Typography.Title level={3}>
                        ${servicio.total} MXN
                      </Typography.Title>
                    </Col>
                    <Col xs={24} md={24} lg={18}>
                      <Flex justify="space-between" align="center">
                        {servicio.stock && (
                          <Col xs={12}>
                            <Typography.Paragraph strong>
                              Disponible(s): {servicio.stock}
                            </Typography.Paragraph>
                          </Col>
                        )}
                        <Col xs={12}>
                          <Typography.Paragraph>horario:</Typography.Paragraph>
                          <Typography.Paragraph strong>
                            {servicio.horaInicio}hrs - {servicio.horaFin}hrs
                          </Typography.Paragraph>
                        </Col>
                      </Flex>
                      <Col>
                        <Typography.Paragraph>Acerca de:</Typography.Paragraph>
                      </Col>
                      <Col>
                        <Typography.Paragraph>
                          {servicio.descripcion}
                        </Typography.Paragraph>
                      </Col>
                    </Col>
                    <Col xs={24} md={24} lg={6}>
                      <Flex
                        vertical
                        justify="space-evenly"
                        align="center"
                        style={{ height: "100%", padding: 10 }}
                      >
                        <div>
                          <Col>
                            <Typography.Paragraph>
                              Duración: {servicio.duracion}
                            </Typography.Paragraph>
                          </Col>
                          {(() => {
                            switch (servicio.duracion) {
                              case "dia":
                                return (
                                  <>
                                    <Col>
                                      <Typography.Paragraph>
                                        Seleccione la hora de entrega:
                                      </Typography.Paragraph>
                                    </Col>
                                    <TimePicker
                                      style={{ width: "100%" }}
                                      showNow={false}
                                      format="HH:mm"
                                      value={
                                        horaInicioSeleccionada
                                          ? dayjs(
                                              horaInicioSeleccionada,
                                              "HH:mm"
                                            )
                                          : null
                                      }
                                      disabledHours={() => {
                                        const horaInicio = parseInt(
                                          servicio.horaInicio.split(":")[0]
                                        );
                                        const horaFin = parseInt(
                                          servicio.horaFin.split(":")[0]
                                        );
                                        const horasDeshabilitadas = [];
                                        for (let i = 0; i < 24; i++) {
                                          if (i < horaInicio || i > horaFin) {
                                            horasDeshabilitadas.push(i);
                                          }
                                        }
                                        return horasDeshabilitadas;
                                      }}
                                      disabledMinutes={(selectedHour) => {
                                        const horaInicio = parseInt(
                                          servicio.horaInicio.split(":")[0]
                                        );
                                        const minutoInicio = parseInt(
                                          servicio.horaInicio.split(":")[1]
                                        );
                                        const horaFin = parseInt(
                                          servicio.horaFin.split(":")[0]
                                        );
                                        const minutoFin = parseInt(
                                          servicio.horaFin.split(":")[1]
                                        );
                                        if (selectedHour === horaFin) {
                                          return [...Array(60).keys()].slice(
                                            minutoFin + 1
                                          );
                                        }
                                        if (selectedHour === horaInicio) {
                                          return [
                                            ...Array(minutoInicio).keys(),
                                          ];
                                        }
                                        return [];
                                      }}
                                      onChange={(time, timeString) =>
                                        handleSelectHora(timeString)
                                      }
                                    />
                                    <Col>
                                      <Typography.Paragraph>
                                        Seleccione el día de inicio:
                                      </Typography.Paragraph>
                                    </Col>
                                    {diaSeleccionado !== undefined && (
                                      <DatePicker
                                        showToday={false}
                                        style={{
                                          width: "100%",
                                          marginBottom: 10,
                                        }}
                                        placeholder="Seleccione el día"
                                        disabledDate={disabledDate}
                                        {...(diaSeleccionado && {
                                          defaultValue: dayjs(diaSeleccionado),
                                        })}
                                        locale={locale}
                                        onChange={(date, dateString) =>
                                          handleSelectDia(dateString)
                                        }
                                      />
                                    )}
                                    <Col>
                                      <Typography.Paragraph>
                                        Seleccione la cantidad de días:
                                      </Typography.Paragraph>
                                    </Col>
                                    <InputNumber
                                      min={1}
                                      max={99}
                                      value={cantidadDuracion}
                                      style={{ width: "100%" }}
                                      onChange={(value) =>
                                        setCantidadDuracion(value)
                                      }
                                    />
                                  </>
                                );
                              case "hora":
                                return (
                                  <>
                                    <Col>
                                      <Typography.Paragraph>
                                        Seleccione la hora de entrega:
                                      </Typography.Paragraph>
                                    </Col>
                                    <TimePicker
                                      showNow={false}
                                      style={{ width: "100%" }}
                                      format="HH:mm"
                                      value={
                                        horaInicioSeleccionada
                                          ? dayjs(
                                              horaInicioSeleccionada,
                                              "HH:mm"
                                            )
                                          : null
                                      }
                                      disabledHours={() => {
                                        const horaInicio = parseInt(
                                          servicio.horaInicio.split(":")[0]
                                        );
                                        const horaFin = parseInt(
                                          servicio.horaFin.split(":")[0]
                                        );
                                        const horasDeshabilitadas = [];
                                        for (let i = 0; i < 24; i++) {
                                          if (i < horaInicio || i > horaFin) {
                                            horasDeshabilitadas.push(i);
                                          }
                                        }
                                        return horasDeshabilitadas;
                                      }}
                                      disabledMinutes={(selectedHour) => {
                                        const horaInicio = parseInt(
                                          servicio.horaInicio.split(":")[0]
                                        );
                                        const minutoInicio = parseInt(
                                          servicio.horaInicio.split(":")[1]
                                        );
                                        const horaFin = parseInt(
                                          servicio.horaFin.split(":")[0]
                                        );
                                        const minutoFin = parseInt(
                                          servicio.horaFin.split(":")[1]
                                        );
                                        if (selectedHour === horaFin) {
                                          return [...Array(60).keys()].slice(
                                            minutoFin + 1
                                          );
                                        }
                                        if (selectedHour === horaInicio) {
                                          return [
                                            ...Array(minutoInicio).keys(),
                                          ];
                                        }
                                        return [];
                                      }}
                                      onChange={(time, timeString) =>
                                        handleSelectHora(timeString)
                                      }
                                    />
                                    <Col>
                                      <Typography.Paragraph>
                                        Seleccione el día de inicio:
                                      </Typography.Paragraph>
                                    </Col>
                                    <DatePicker
                                      style={{
                                        width: "100%",
                                        marginBottom: 10,
                                      }}
                                      placeholder="Seleccione el día"
                                      showToday={false}
                                      disabledDate={disabledDate}
                                      defaultValue={dayjs(diaSeleccionado)}
                                      locale={locale}
                                      onChange={(date, dateString) =>
                                        handleSelectDia(dateString)
                                      }
                                    />
                                    <Col>
                                      <Typography.Paragraph>
                                        Seleccione la cantidad de horas:
                                      </Typography.Paragraph>
                                    </Col>
                                    <InputNumber
                                      min={1}
                                      max={99}
                                      value={cantidadDuracion}
                                      style={{ width: "100%" }}
                                      onChange={(value) =>
                                        setCantidadDuracion(value)
                                      }
                                    />
                                  </>
                                );
                              default:
                                return (
                                  <>
                                    <Col>
                                      <Typography.Paragraph>
                                        Seleccione la hora de entrega:
                                      </Typography.Paragraph>
                                    </Col>
                                    <TimePicker
                                      showNow={false}
                                      style={{ width: "100%" }}
                                      format="HH:mm"
                                      disabledHours={() => {
                                        const horaInicio = parseInt(
                                          servicio.horaInicio.split(":")[0]
                                        );
                                        const horaFin = parseInt(
                                          servicio.horaFin.split(":")[0]
                                        );
                                        const horasDeshabilitadas = [];
                                        for (let i = 0; i < 24; i++) {
                                          if (i < horaInicio || i > horaFin) {
                                            horasDeshabilitadas.push(i);
                                          }
                                        }
                                        return horasDeshabilitadas;
                                      }}
                                      disabledMinutes={(selectedHour) => {
                                        const horaInicio = parseInt(
                                          servicio.horaInicio.split(":")[0]
                                        );
                                        const minutoInicio = parseInt(
                                          servicio.horaInicio.split(":")[1]
                                        );
                                        const horaFin = parseInt(
                                          servicio.horaFin.split(":")[0]
                                        );
                                        const minutoFin = parseInt(
                                          servicio.horaFin.split(":")[1]
                                        );
                                        if (selectedHour === horaFin) {
                                          return [...Array(60).keys()].slice(
                                            minutoFin + 1
                                          );
                                        }
                                        if (selectedHour === horaInicio) {
                                          return [
                                            ...Array(minutoInicio).keys(),
                                          ];
                                        }
                                        return [];
                                      }}
                                      onChange={(time, timeString) =>
                                        handleSelectHora(timeString)
                                      }
                                    />
                                    <Col>
                                      <Typography.Paragraph>
                                        Seleccione el día de inicio:
                                      </Typography.Paragraph>
                                    </Col>
                                    <DatePicker
                                      style={{
                                        width: "100%",
                                        marginBottom: 10,
                                      }}
                                      placeholder="Seleccione el día"
                                      showToday={false}
                                      disabledDate={disabledDate}
                                      locale={locale}
                                      onChange={(date, dateString) =>
                                        handleSelectDia(dateString)
                                      }
                                    />
                                  </>
                                );
                            }
                          })()}

                          {servicio.stock > 0 && (
                            <Col>
                              <Typography.Paragraph>
                                Selecciona la cantidad de unidades:
                              </Typography.Paragraph>
                              <InputNumber
                                min={1}
                                max={servicio.stock}
                                value={cantidad}
                                style={{ width: "100%" }}
                                onChange={(value) => setCantidad(value)}
                              />
                            </Col>
                          )}

                          {enCarrito ? (
                            <>
                              <Col>
                                <Button
                                  type="primary"
                                  style={{
                                    marginTop: 10,
                                    width: "100%",
                                    backgroundColor: "#f3ff10",
                                    color: "#111",
                                  }}
                                  onClick={() =>
                                    addToCartServicioDetalle(
                                      servicio,
                                      userData,
                                      cantidad,
                                      cantidadDuracion,
                                      diaSeleccionado,
                                      horaInicioSeleccionada
                                    )
                                  }
                                  icon={<ShoppingCartOutlined />} // Icono de carrito
                                >
                                  Actualizar servicio del carrito
                                </Button>
                              </Col>
                              <Col>
                                <Button
                                  type="primary"
                                  danger
                                  style={{ marginTop: 10, width: "100%" }}
                                  onClick={handleRemoveFromCart}
                                  icon={<DeleteOutlined />} // Icono de basura
                                >
                                  Eliminar del carrito
                                </Button>
                              </Col>
                            </>
                          ) : (
                            <Button
                              type="primary"
                              style={{
                                marginTop: 10,
                                width: "100%",
                                backgroundColor: "#1dbe72",
                              }}
                              onClick={() =>
                                addToCartServicioDetalle(
                                  servicio,
                                  userData,
                                  cantidad,
                                  cantidadDuracion,
                                  diaSeleccionado,
                                  horaInicioSeleccionada
                                )
                              }
                              icon={<ShoppingCartOutlined />}
                            >
                              Agregar al carrito
                            </Button>
                          )}
                        </div>
                        {tienda ? (
                          <div>
                            <div className="row">
                              <Typography.Title
                                level={5}
                                style={{ marginTop: "20px" }}
                                strong
                              >
                                {tienda.nombre}
                              </Typography.Title>
                            </div>
                            <div className="row">
                              <Link to={`/reseñas/${tienda.id}`}>
                                <div>Ver reseñas</div>
                                <div>
                                  <Rate disabled value={tienda.calificacion} />
                                </div>
                              </Link>
                            </div>
                          </div>
                        ) : (
                          <p>Cargando tienda...</p>
                        )}
                      </Flex>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
            <Card style={{ marginTop: "20px" }}>
              <Flex vertical>
                <Typography.Title level={2}>
                  Reseñas del producto
                </Typography.Title>
                {/* Formulario de calificación y reseña */}
                {mostrarFormulario && (
                  <>
                    <Typography.Title level={5}>
                      ¡Deja tu reseña!
                    </Typography.Title>
                    <Rate
                      value={calificacion}
                      onChange={(value) => setCalificacion(value)}
                    />
                    <Input
                      type="textarea"
                      rows={4}
                      style={{ marginTop: "10px" }}
                      value={resena}
                      onChange={(e) => setResena(e.target.value)}
                    />
                    <Button
                      type="primary"
                      onClick={handleCalificar}
                      style={{ marginTop: "10px", backgroundColor: "#1c1c34" }}
                    >
                      Calificar y dejar reseña
                    </Button>
                  </>
                )}
                {/* Lista de reseñas */}
                <List
                  itemLayout="horizontal"
                  dataSource={reseñas}
                  renderItem={(item, index) => (
                    <List.Item key={index}>
                      <List.Item.Meta
                        avatar={<Avatar src={item.photo} />}
                        title={
                          <>
                            <a href="https://ant.design">{item.nombre}</a>
                            {/* Muestra la calificación como estrellas */}
                            <Rate disabled defaultValue={item.calificacion} />
                          </>
                        }
                        description={item.descripcion}
                      />
                    </List.Item>
                  )}
                  ref={listaResenasRef}
                />
                {/* Muestra la calificación promedio */}
              </Flex>
            </Card>
          </>
        )}
      </Flex>
      <Modal
        title="Autenticación Requerida"
        open={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        okText="Iniciar Sesión"
        cancelText="Cancelar"
      >
        <p>Debes iniciar sesión para agregar servicios al carrito.</p>
      </Modal>
    </>
  );
}
