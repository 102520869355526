import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Form,
  Input,
  Button,
  Upload,
  Switch,
  InputNumber,
  Select,
  TimePicker,
  Checkbox,
  Typography,
  Card,
  message,
} from "antd";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  updateDoc,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import Compressor from "compressorjs";
import { useUser } from "../../../context/UserContext";
import { db } from "../../../firebaseConfig";
const { Option } = Select;
const { RangePicker } = TimePicker;

const EditarServicio = () => {
  const navigate = useNavigate();
  const { Title, Paragraph } = Typography;
  const [loading, setLoading] = useState(false);
  const { userData } = useUser();
  const [imageFileList, setImageFileList] = useState([]);
  const [imageFileListEdit, setImageFileListEdit] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [subcategorias, setSubcategorias] = useState([]);
  const [subcategoria, setSubcategoria] = useState([]);
  const [subtotal, setSubtotal] = useState(0);
  const [comisionPlataforma, setComisionPlataforma] = useState(0);
  const [comision, setComision] = useState(0);
  const [comisionMesesSinIntereses, setComisionMesesSinIntereses] = useState(0);
  const [total, setTotal] = useState(0);
  const { id } = useParams();
  const [form] = Form.useForm();
  const storage = getStorage();
  const [tipoServicio, setTipoServicio] = useState(null);

  const fetchServicio = async (categoriasData) => {
    try {
      const servicioDoc = await getDoc(doc(db, "servicios", id));
      if (servicioDoc.exists()) {
        const servicioData = servicioDoc.data();
        const categoriaSeleccionadaData = categoriasData.find(
          (categoria) => categoria.id === servicioData.categoria
        );

        // Verificar si la categoría seleccionada tiene subcategorías
        if (categoriaSeleccionadaData) {
          setSubcategorias(categoriaSeleccionadaData.subcategorias);

          // Encontrar la subcategoría específica por nombre
          const subcategoriaEncontrada =
            categoriaSeleccionadaData.subcategorias.find(
              (subcategoria) => subcategoria.id === servicioData.subcategoria
            );

          if (subcategoriaEncontrada) {
         //   console.log(subcategoriaEncontrada.subcategoria);
            form.setFieldsValue({
              subcategoria: subcategoriaEncontrada.subcategoria,
            });
            setSubcategoria({
              subcategoriaNombre: subcategoriaEncontrada.subcategoria,
              subcategoriaIndice: subcategoriaEncontrada.id,
            });
          }
        }

        setSubtotal(servicioData.precio);
        form.setFieldsValue({
          nombre: servicioData.nombre.toLowerCase(),
          descripcion: servicioData.descripcion,
          precio: servicioData.precio,
          imagen: servicioData.imagen, // Tomamos solo la primera imagen como ejemplo
          vendedorUid: servicioData.vendedorUid,
          diasDisponibles: servicioData.diasDisponibles,
          tipoServicio: servicioData.tipoServicio,
          horario: [
            moment(servicioData.horaInicio, "HH:mm"),
            moment(servicioData.horaFin, "HH:mm"),
          ],
          mesesSinIntereses: servicioData.mesesSinIntereses,
          comisionMesesSinIntereses: servicioData.comisionMesesSinIntereses,
          comisionPlataforma: servicioData.comisionPlataforma,
          total: servicioData.total,
          categoria: servicioData.categoria,
          duracion: servicioData.duracion,
          duracionHorasEntrega: servicioData.duracionHorasEntrega,
          stock: servicioData.stock,
        });

        if (servicioData.tipoServicio == "renta") {
          setTipoServicio("renta");
        }
        if (servicioData.imagen && servicioData.imagen.length > 0) {
          setImageFileList(servicioData.imagen);
        }
      } else {
        console.error("El servicio no existe");
      }
    } catch (error) {
      console.error("Error al obtener el servicio:", error.message);
    }
  };

  const fetchCategorias = async () => {
    try {
      const categoriasCollection = collection(db, "categorias");
      const q = query(categoriasCollection, where("tipo", "==", "servicio"));
      const categoriasSnapshot = await getDocs(q);

      const categoriasData = categoriasSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCategorias(categoriasData);

      // Luego de obtener las categorías, llamar a fetchServicio
      fetchServicio(categoriasData);
    } catch (error) {
      console.error("Error al obtener categorías:", error.message);
    }
  };

  const handleChangeCategoria = (categoriaId) => {
    form.setFieldsValue({ subcategoria: "" });
    // Obtener las subcategorías de la categoría seleccionada
    const categoriaSeleccionadaData = categorias.find(
      (categoria) => categoria.id === categoriaId
    );
    // Verificar si la categoría seleccionada tiene subcategorías
    if (categoriaSeleccionadaData && categoriaSeleccionadaData.subcategorias) {
      // Add a unique key to each subcategoria object
      const subcategoriasWithKeys = categoriaSeleccionadaData.subcategorias.map(
        (subcategoria, index) => ({
          ...subcategoria,
          id: index.toString(), // or any other unique identifier
        })
      );
      setSubcategorias(subcategoriasWithKeys);
    } else {
      setSubcategorias([]);
    }
  };

  // Función para manejar el cambio en el tipo de servicio
  const handleTipoServicioChange = (value) => {
    setTipoServicio(value);
  };
  useEffect(() => {
    fetchCategorias();
  }, []);

  const handlePrecioChange = (value) => {
    // Calcular el subtotal cuando cambia el precio
    setSubtotal(value);
  };

  useEffect(() => {
    const comisionMesesSinInteresesCalculada =
      subtotal * (comisionMesesSinIntereses / 100);

    const comisionMesesSinInteresesRedondeada =
      comisionMesesSinInteresesCalculada.toFixed(2);

    const comisionPlataformaCalculada = subtotal * (comisionPlataforma / 100);
    const comisionPlataformaRedondeada = comisionPlataformaCalculada.toFixed(2);
    // Calcular el total
    let totalCalculado = 0; // Inicializar totalCalculado

    totalCalculado =
      subtotal +
      parseFloat(comisionPlataformaRedondeada) +
      parseFloat(comisionMesesSinInteresesRedondeada);

    // Calcular el total
    const totalRedondeado = totalCalculado.toFixed(2);

    setTotal(totalRedondeado);
  }, [subtotal, comisionPlataforma, comisionMesesSinIntereses]);

  useEffect(() => {
    const fetchComisiones = async () => {
      try {
        // Obtener comisiones de Firebase
        const comisionesDoc = await getDoc(
          doc(db, "configuraciones", "comisiones")
        );
        const comisionesData = comisionesDoc.data();
        const comisionesTiendaDoc = await getDoc(
          doc(db, "tiendas", userData.uid)
        );
        const comisionesTiendaData = comisionesTiendaDoc.data();
        setComisionMesesSinIntereses(comisionesData.comisionMesesSinIntereses);
        setComisionPlataforma(comisionesTiendaData.comisionPlataforma);
        // Actualizar estados con comisiones obtenidas
        setComision(
          comisionesTiendaData.comisionPlataforma +
            comisionesData.comisionMesesSinIntereses
        );
      } catch (error) {
        console.error("Error al obtener comisiones:", error.message);
      }
    };

    fetchComisiones();
  }, []);

  //Formato para el horario
  const formatTime = (date) => {
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  const onFinish = async (values) => {
    setLoading(true);
    const currentDate = new Date();
    try {
      // Comprimir imágenes antes de subirlas a Firebase Storage
      const compressedImageFiles = await Promise.all(
        imageFileListEdit.map(async (imageFile) => {
          return new Promise((resolve, reject) => {
            new Compressor(imageFile.originFileObj, {
              quality: 0.6,
              success(result) {
                resolve(result);
              },
              error(err) {
                reject(err);
              },
            });
          });
        })
      );

      // Subir imágenes comprimidas a Firebase Storage y obtener las URL
      const imageUrls = await Promise.all(
        compressedImageFiles.map(async (compressedImageFile) => {
          const dateString = currentDate.toISOString().replace(/:/g, "-");
          const storageRef = ref(
            storage,
            `servicio/${userData.uid}_${dateString}_${compressedImageFile.name}`
          );
          await uploadBytes(storageRef, compressedImageFile);
          const downloadURL = await getDownloadURL(storageRef);
          const location = storageRef._location.path_;
          return { url: downloadURL, path: location };
        })
      );

      const mergedImageFileList = [...imageUrls, ...imageFileList.flat()];

      // Extraer solo la hora del horario seleccionado
      const horaInicio = new Date(values.horario[0]);
      const horaFin = new Date(values.horario[1]);
      const mesesSinIntereses = true;
      const tipoServicio = values.tipoServicio ?? "dia"; // Obtener el tipo de servicio seleccionado o por defecto 'dia'
      // Actualizar en Firestore
      const servicioDocRef = doc(db, "servicios", id);

      if (values.subcategoria === subcategoria.subcategoriaNombre) {
        values.subcategoria = subcategoria.subcategoriaIndice;
      }
      const subcategoriaNumero = parseInt(values.subcategoria);
      const servicioData = {
        nombre: values.nombre.toLowerCase(),
        descripcion: values.descripcion,
        precio: values.precio,
        imagen: mergedImageFileList,
        diasDisponibles: values.diasDisponibles,
        tipoServicio: tipoServicio,
        horaInicio: formatTime(horaInicio),
        horaFin: formatTime(horaFin),
        mesesSinIntereses: mesesSinIntereses,
        comisionMesesSinIntereses: comisionMesesSinIntereses,
        comisionPlataforma: comisionPlataforma,
        total: total,
        categoria: values.categoria,
        subcategoria: subcategoriaNumero,
      };

      if (values.serviciosALaVez) {
        servicioData.serviciosALaVez = true;
      } else {
        servicioData.serviciosALaVez = false;
      }

      await updateDoc(servicioDocRef, servicioData);
      message.success("Servicio editado correctamente");
      setLoading(false);
      navigate(`/dashboard/misservicios`); // Redirigir al detalle del nuevo producto
    } catch (error) {
      setLoading(false);
      console.error("Error al actualizar el servicio:", error.message);
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handleImageChange = ({ fileList }) => {
    setImageFileListEdit(fileList);
  };

  const handleDeleteImage = async (index, path) => {
    try {
      const imageRef = ref(storage, path);

      // Eliminar la imagen de Firebase Storage
      await deleteObject(imageRef);

      // Actualizar el servicio en Firestore
      const servicioRef = doc(db, "servicios", id); // Suponiendo que tienes acceso a la variable 'servicioId' que contiene el ID del servicio
      const servicioDoc = await getDoc(servicioRef);
      if (servicioDoc.exists()) {
        const servicioData = servicioDoc.data();
        const nuevasImagenes = servicioData.imagen.filter(
          (imagen, i) => i !== index
        );

        // Actualizar las imágenes en el servicio
        await updateDoc(servicioRef, { imagen: nuevasImagenes });

        // Actualizar el estado local si es necesario
        // setServicio({ ...servicioData, imagen: nuevasImagenes });
      } else {
        console.error("El servicio no existe");
      }

      // Eliminar la imagen de la lista de imágenes
      const updatedImageFileList = [...imageFileList];
      updatedImageFileList.splice(index, 1);
      setImageFileList(updatedImageFileList);
    } catch (error) {
      console.error("Error al eliminar la imagen:", error.message);
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <Card
        style={{ width: "600px", margin: "auto" }}
        bodyStyle={{ padding: "24px" }}
      >
        <h1 style={{ textAlign: "center", marginBottom: "20px" }}>
          Editar servicio
        </h1>
        <Form name="crearServicio" onFinish={onFinish} form={form}>
          <Form.Item
            label="Tipo de Servicio"
            name="tipoServicio"
            rules={[
              {
                required: true,
                message: "Por favor, selecciona el tipo de servicio",
              },
            ]}
          >
            <Select
              placeholder="Selecciona el tipo de servicio"
              onChange={handleTipoServicioChange}
            >
              <Option value="renta">Renta de artículos</Option>
              <Option value="empleado">Servicio realizado por empleados</Option>
            </Select>
          </Form.Item>
          {tipoServicio === "renta" && (
            <div>
              <Form.Item
                label="Stock"
                name="stock"
                rules={[
                  {
                    required: true,
                    type: "number",
                    min: 1,
                    default: 1,
                    message: "Por favor, ingresa el stock disponible",
                  },
                ]}
              >
                <InputNumber min={0} />
              </Form.Item>
            </div>
          )}
          <Form.Item
            label="Tipo de duración"
            name="duracion"
            rules={[
              {
                required: true,
                message:
                  "Por favor, selecciona el tipo de duración del servicio",
              },
            ]}
          >
            <Select placeholder="Selecciona la duración">
              <Option value="dia">Por día</Option>
              <Option value="hora">Por hora</Option>
              <Option value="unico">Servicio único</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Duración de tiempo de entrega y recolección (horas)"
            name="duracionHorasEntrega"
            rules={[
              {
                required: true,
                type: "number",
                min: 1,
                message:
                  "Por favor, ingresa la duración del tiempo estimado que se tarda entregando el servicio",
              },
            ]}
          >
            <InputNumber min={1} />
          </Form.Item>

          <Form.Item
            label="¿Se pueden realizar multiples servicios a la vez?"
            name="serviciosALaVez"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            label="Nombre"
            name="nombre"
            rules={[
              {
                required: true,
                message: "Por favor, ingresa el nombre del servicio",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Descripción"
            name="descripcion"
            rules={[
              {
                required: true,
                message: "Por favor, ingresa la descripción del servicio",
              },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            label="Categoría"
            name="categoria"
            rules={[
              {
                required: true,
                message: "Por favor, selecciona la categoría del producto",
              },
            ]}
          >
            <Select
              onChange={handleChangeCategoria}
              placeholder="Selecciona una categoria"
            >
              {categorias.map((categoria) => (
                <Option key={categoria.id} value={categoria.id}>
                  {categoria.nombre}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Subcategoría"
            name="subcategoria"
            rules={[
              {
                required: true,
                message: "Por favor, selecciona la subcategoría del producto",
              },
            ]}
          >
            <Select placeholder="Selecciona una subcategoria">
              {subcategorias.map((subcategoria) => (
                <Option key={subcategoria.id} value={subcategoria.id}>
                  {subcategoria.subcategoria}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Precio por tipo de servicio"
            name="precio"
            rules={[
              {
                required: true,
                type: "number",
                min: 0,
                message: "Por favor, ingresa un precio válido",
              },
            ]}
          >
            <InputNumber min={0.01} onChange={handlePrecioChange} />
          </Form.Item>

          <Form.Item
            label="Horario"
            name="horario"
            rules={[
              {
                required: true,
                message: "Por favor, selecciona el horario del servicio",
              },
            ]}
          >
            <RangePicker format="HH:mm" />
          </Form.Item>

          <Form.Item
            label="Días Disponibles"
            name="diasDisponibles"
            rules={[
              {
                required: true,
                message: "Por favor, selecciona los días disponibles",
              },
            ]}
          >
            <Checkbox.Group style={{ width: "100%" }}>
              <Checkbox value="lunes">Lunes</Checkbox>
              <Checkbox value="martes">Martes</Checkbox>
              <Checkbox value="miércoles">Miércoles</Checkbox>
              <Checkbox value="jueves">Jueves</Checkbox>
              <Checkbox value="viernes">Viernes</Checkbox>
              <Checkbox value="sábado">Sábado</Checkbox>
              <Checkbox value="domingo">Domingo</Checkbox>
            </Checkbox.Group>
          </Form.Item>

          <Form.Item
            name="imagenes"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload
              beforeUpload={() => false}
              listType="picture"
              accept="image/*"
              maxCount={3}
              onChange={handleImageChange}
            >
              <Button icon={<UploadOutlined />}>Subir Imágenes</Button>
            </Upload>
          </Form.Item>

          {imageFileList &&
            imageFileList.length > 0 &&
            imageFileList.map((image, index) => {
              return (
                <div
                  key={index}
                  style={{ display: "inline-block", marginRight: 10 }}
                >
                  <img
                    src={image.url} // Corrección aquí
                    style={{ maxHeight: 200, margin: 10 }}
                  />
                  <Button
                    icon={<DeleteOutlined />}
                    onClick={() => handleDeleteImage(index, image.path)} // Y corrección aquí
                    danger
                  >
                    Eliminar
                  </Button>
                </div>
              );
            })}

          <Title
            level={3}
            style={{ textAlign: "center", marginBottom: "20px" }}
          >
            Resumen de Costos
          </Title>

          <Paragraph style={{ marginBottom: "16px", textAlign: "right" }}>
            <strong>Subtotal:</strong>
            <br />${subtotal}
          </Paragraph>

          <Paragraph style={{ marginBottom: "16px", textAlign: "right" }}>
            <strong>Comisión de la plataforma:</strong>
            <br />
            {comision}%
          </Paragraph>

          <Paragraph style={{ marginBottom: "16px", textAlign: "right" }}>
            <strong>Total:</strong>
            <br />${total}
          </Paragraph>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              Editar
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default EditarServicio;
