import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebaseConfig";

export const getProductosCerca = async (ubicacionUsuario, userFavorites, setProducts) => {
  try {
    const tiendasCercanas = await obtenerTiendasCercanas(ubicacionUsuario);
    const productos = [];
    let totalProductos = 0; // Variable para rastrear el número total de productos agregados
    
    for (const tienda of tiendasCercanas) {
      const productosTienda = await obtenerProductosDeTienda(tienda.vendedorUid, userFavorites);

      // Agregar productos de la tienda actual al array productos
      for (const producto of productosTienda) {
        // Verificar si ya se han agregado 5 productos
        if (totalProductos >= 5) {
          break; // Salir del bucle si ya hay 5 productos
        }
        
        productos.push(producto);
        totalProductos++; // Incrementar el contador de productos
      }
      
      // Verificar si ya se han agregado 5 productos
      if (totalProductos >= 5) {
        break; // Salir del bucle si ya hay 5 productos
      }
    }

    setProducts(productos);
  } catch (error) {
    console.error("Error al obtener productos cercanos:", error.message);
    return [];
  }
};


const obtenerTiendasCercanas = async (ubicacionUsuario) => {

  const tiendasCollection = collection(db, "tiendas");
  const tiendasSnapshot = await getDocs(
    query(tiendasCollection, where("activado", "==", true))
  );

  const tiendasData = [];
  tiendasSnapshot.forEach((doc) => {
    const tienda = doc.data();
    tienda.vendedorUid = doc.id;
    // Calcula la distancia entre la ubicación del usuario y la ubicación de la tienda
    const distancia = calcularDistancia(ubicacionUsuario, tienda.ubicacion);
    // Agrega la distancia a la información de la tienda
    tienda.distancia = distancia;
    tiendasData.push(tienda);
  });

  // Ordena las tiendas por distancia de forma ascendente
  tiendasData.sort((a, b) => a.distancia - b.distancia);

  return tiendasData;
};

export const obtenerProductosDeTienda = async (tiendaId, userFavorites) => {
  try {
    const productosCollection = collection(db, "productos");
    const productosSnapshot = await getDocs(
      query(productosCollection, where("vendedorUid", "==", tiendaId))
    );

    const productosData = [];

    productosSnapshot.forEach((doc) => {
      const producto = doc.data();
      producto.id = doc.id;

      // Verificar si el producto está habilitado
      if (!producto.pausa && producto.stock > 0) {
        // Comprobar si userFavorites está definido y es un objeto con la propiedad "productos"
        if (userFavorites && userFavorites.productos) {
          // Comprobar si el producto está en la lista de favoritos del usuario
          const isFavorite = userFavorites.productos.includes(producto.id);
          producto.isFavorite = isFavorite;
        } else {
          // Si no hay datos de favoritos, establecer isFavorite como false
          producto.isFavorite = false;
        }
        productosData.push(producto);
      }
    });

    return productosData;
  } catch (error) {
    console.error("Error al obtener productos de la tienda:", error.message);
    return [];
  }
};

  


// Función para calcular la distancia entre dos ubicaciones geográficas
const calcularDistancia = (ubicacionUsuario, ubicacionTienda) => {
  const lat1 = ubicacionUsuario.latitude;
  const lon1 = ubicacionUsuario.longitude;
  const lat2 = ubicacionTienda.latitud;
  const lon2 = ubicacionTienda.longitud;

  const R = 6371; // Radio de la Tierra en kilómetros
  const dLat = (lat2 - lat1) * (Math.PI / 180);
  const dLon = (lon2 - lon1) * (Math.PI / 180);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * (Math.PI / 180)) *
      Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distancia = R * c; // Distancia en kilómetros
  return distancia;
};
