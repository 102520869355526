import React, { useState, useEffect } from "react";
import {
  ShoppingCartOutlined,
  HeartOutlined,
  HeartFilled,
} from "@ant-design/icons";
import { Card, Typography, Rate, Button, Tag, Col, Row } from "antd";
import { Link } from "react-router-dom";
import "./ListaProductoItem.scss";
import { obtenerNombreCategoria } from "../../utils/categorias/categoriaUtils";
import { useUser } from "../../context/UserContext";
import { toast } from "sonner";
import Producto from "../../screens/Cliente/ProductoDetalles/Producto";

const { Meta } = Card;
const { Text } = Typography;

export const ListaProductoItemBuscador = ({
  producto,
  addToCart,
  favorite,
  isFavorite,
}) => {
  const { userData } = useUser();
  const isMesesSinIntereses = producto.mesesSinIntereses || false;
  const [nombreCategoria, setNombreCategoria] = useState("");
  const [localIsFavorite, setLocalIsFavorite] = useState(isFavorite);
  const [buttonDisabled, setButtonDisabled] = useState(false); // Estado para deshabilitar el botón durante el cooldown

  useEffect(() => {
    obtenerNombreCategoria(producto.categoria)
      .then((nombre) => {
        setNombreCategoria(nombre);
      })
      .catch((error) => {
        console.error("Error al obtener el nombre de la categoría:", error);
      });
  }, [producto.categoria]);

  useEffect(() => {
    setLocalIsFavorite(isFavorite); // Actualizar localIsFavorite cuando isFavorite cambie
  }, [isFavorite]);

  const handleToggleFavorite = () => {
    if (!buttonDisabled) {
      if (!userData) {
        toast.error("Inicia sesión para agregar a favoritos.");
        return;
      }

      // Si el botón no está deshabilitado
      favorite(!localIsFavorite); // Cambiar el estado de favorito en el componente padre
      setLocalIsFavorite(!localIsFavorite); // Actualizar el estado local
      setButtonDisabled(true); // Deshabilitar el botón
      setTimeout(() => {
        // Habilitar el botón después de 1 segundo
        setButtonDisabled(false);
      }, 1000);
    }
  };

  return (
    <>
      {producto.tipo === "producto"  ||producto.tipo === undefined  ? (
        <Col>
          {/* Contenido para productos */}
          <Card
            style={{ borderRadius: 10, textAlign: "center", height: "100%", width: "250px" }}
            cover={
              <div style={{ position: "relative", height: 200 }}>
                <Link
                  key={producto.id}
                  to={`/producto/${producto.id}`}
                  style={{ position: "relative", display: "inline-block" }}
                >
                  <img
                    alt="example"
                    src={producto.imagen[0].url}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      padding: 2,
                    }}
                  />
                </Link>
                <button
                  onClick={() => handleToggleFavorite()}
                  style={{
                    position: "absolute",
                    top: 10,
                    right: 10,
                    border: "none",
                    background: "transparent",
                    cursor: "pointer",
                  }}
                  disabled={buttonDisabled}
                >
                  {localIsFavorite ? (
                    <HeartFilled style={{ color: "red", fontSize: "26px" }} />
                  ) : (
                    <HeartOutlined style={{ color: "red", fontSize: "26px" }} />
                  )}
                </button>
              </div>
            }
          >
            <div
              style={{
                height: 180,
                marginBottom: "30px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Meta
                title={
                  <div
                    style={{
                      display: "inline-block", // Para que el div se ajuste al contenido
                      lineHeight: "1.5", // Espaciado entre líneas fijo
                      height: "3em", // Altura fija del contenedor, equivalente a dos líneas de texto
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "normal", // Permitir múltiples líneas de texto
                    }}
                  >
                    {producto.nombre}
                  </div>
                }
                description={
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Tag
                      color="blue"
                      style={{ width: "80px", textAlign: "center" }}
                    >
                      Stock: {producto.stock}
                    </Tag>
                    <Typography.Text style={{ color: "#878787" }}>
                      <strong>{nombreCategoria}</strong>
                    </Typography.Text>
                  </div>
                }
              />
  
              <div >
                <Rate
                  disabled
                  allowHalf
                  defaultValue={producto.calificacion || 0}
                />
              </div>
              {isMesesSinIntereses ? (
                <span style={{ color: "green" }}> (Meses sin intereses)</span>
              ) : (
                <span> </span>
              )}
            </div>
            
  
            <div
              style={{
                position: "absolute",
                bottom: 0,
                left: 0,
                width : "100%",
                backgroundColor: "#1dbf73",
                padding: "10px",
                display: "flex",
                justifyContent: "space-between",
                paddingRight: "10px",
                paddingLeft: "10px",
                borderBottomLeftRadius: 10,
                borderBottomRightRadius: 10,
              }}
            >
              <Typography.Text
                style={{
                  color: isMesesSinIntereses ? "yellow" : "#fff",
                  fontWeight: "bolder",
                  fontSize: "1.4rem",
                }}
              >
                ${producto.total}
              </Typography.Text>
              <Button
                ghost
                onClick={() => addToCart(producto)}
                style={{
                  border: "none",
                  cursor: "pointer",
                  fontWeight: "bolder",
                  transition: "color 0.3s",
                }}
              >
                <ShoppingCartOutlined
                  style={{
                    color: "white",
                    fontSize: "24px",
                    transition: "color 0.3s",
                  }}
                />
              </Button>
            </div>
          </Card>
        </Col>
      ) : (
        <Col>
          <Card
      style={{ width: 250, borderRadius: 10, height: 450 }}
      cover={
        <div>
          <Link
            key={producto.id}
            to={`/servicio/${producto.id}`}
            style={{ position: "relative", display: "inline-block" }}
          >
            <img
              alt="example"
              src={producto.imagen[0].url}
              style={{
                width: 300,
                height: 200,
                objectFit: "contain",
                padding: 2,
              }}
            />
          </Link>
          <button
            onClick={() => handleToggleFavorite()}
            style={{
              position: "absolute",
              top: 10,
              right: 10,
              border: "none",
              background: "transparent",
              cursor: "pointer",
            }}
            disabled={buttonDisabled} // Deshabilitar el botón si está en cooldown
          >
            {localIsFavorite ? (
              <HeartFilled style={{ color: "red", fontSize: "26px" }} />
            ) : (
              <HeartOutlined style={{ color: "red", fontSize: "26px" }} />
            )}
          </button>
        </div>
      }
    >
      <Meta
        title={
          <div
            style={{
              whiteSpace: "pre-line",
              maxHeight: "3em",
              overflow: "hidden",
            }}
          >
            {producto.nombre}
          </div>
        }
        description={
          <>
            <div style={{ color: "midnightblue" }}>
              <Tag>{`${producto.horaInicio} - ${producto.horaFin}`}</Tag>
            </div>
            <Text style={{ color: "#878787" }}>
              <strong>{nombreCategoria}</strong>
            </Text>{" "}
            <div>
              {producto.stock && (
                <Tag color="blue">Stock: {producto.stock}</Tag>
              )}
            </div>
          </>
        }
      />
      <div style={{ marginTop: 10 }}>
        <Rate disabled allowHalf defaultValue={producto.calificacion || 0} />
      </div>
      {isMesesSinIntereses ? (
        <span style={{ color: "green" }}> (Meses sin intereses)</span>
      ) : (
        <span> </span>
      )}

      <div
        style={{
          position: "absolute",
          bottom: 0,
          left: 0,
          width: "100%",
          backgroundColor: "#1dbf73",
          padding: "10px",
          display: "flex",
          justifyContent: "space-between",
          paddingRight: "20px",
          paddingLeft: "20px",
          borderBottomLeftRadius: 10, // Redondear la esquina inferior izquierda
          borderBottomRightRadius: 10,
        }}
      >
        <Typography.Text
          style={{
            color: isMesesSinIntereses ? "yellow" : "#fff",
            fontWeight: "bolder",
            fontSize: "30px",
          }}
        >
          ${producto.total}
        </Typography.Text>
      </div>
    </Card>
        </Col>
      )}
    </>
  );
  
};
