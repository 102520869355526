import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Upload,
  Switch,
  InputNumber,
  Select,
  Typography,
  Card,
  message,
} from "antd";
import Compressor from 'compressorjs';
import { UploadOutlined } from "@ant-design/icons";
import { db } from "../../../firebaseConfig";
import {
  collection,
  doc,
  addDoc,
  getDocs,
  getDoc,
  query,
  where,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useUser } from "../../../context/UserContext";
import { useNavigate } from "react-router-dom";
const { Option } = Select;

const CrearProducto = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { userData } = useUser();
  const [imageFileList, setImageFileList] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [subcategorias, setSubcategorias] = useState([]);
  const [subtotal, setSubtotal] = useState(0);
  const [comisionPlataforma, setComisionPlataforma] = useState(0);
  const [comision, setComision] = useState(0);
  const [comisionMesesSinIntereses, setComisionMesesSinIntereses] = useState(0);
  const [form] = Form.useForm();
  const [total, setTotal] = useState(0);
  const { Title, Paragraph } = Typography;
  const handlePrecioChange = (value) => {
    // Calcular el subtotal cuando cambia el precio
    setSubtotal(value);
  };


  useEffect(() => {
    const comisionMesesSinInteresesCalculada =
      subtotal * (comisionMesesSinIntereses / 100);

    const comisionMesesSinInteresesRedondeada =
      comisionMesesSinInteresesCalculada.toFixed(2);

    const comisionPlataformaCalculada = subtotal * (comisionPlataforma / 100);
    const comisionPlataformaRedondeada = comisionPlataformaCalculada.toFixed(2);
    // Calcular el total
    let totalCalculado = 0; // Inicializar totalCalculado

    totalCalculado =
      subtotal +
      parseFloat(comisionPlataformaRedondeada) +
      parseFloat(comisionMesesSinInteresesRedondeada);

    // Calcular el total
    const totalRedondeado = totalCalculado.toFixed(2);

    setTotal(totalRedondeado);
  }, [subtotal, comisionPlataforma, comisionMesesSinIntereses]);

  const onFinish = async (values) => {
    setLoading(true);
    const storage = getStorage();
    const currentDate = new Date();
    try {
      const compressedImageFiles = await Promise.all(
        imageFileList.map(async (imageFile) => {
          return new Promise((resolve, reject) => {
            new Compressor(imageFile.originFileObj, {
              quality: 0.6,
              success(result) {
                resolve(result);
              },
              error(err) {
                reject(err);
              },
            });
          });
        })
      );
  
      // Subir imágenes comprimidas a Firebase Storage y obtener las URL
      const imageUrls = await Promise.all(
        compressedImageFiles.map(async (compressedImageFile) => {
          const dateString = currentDate.toISOString().replace(/:/g, "-");
          const storageRef = ref(
            storage,
            `servicio/${userData.uid}_${dateString}_${compressedImageFile.name}`
          );
          await uploadBytes(storageRef, compressedImageFile);
          const downloadURL = await getDownloadURL(storageRef);
          const location = storageRef._location.path_;
          return { url: downloadURL, path: location };
        })
      );
      const mesesSinIntereses = true;
      
     // console.log(values.subcategoria);
      const totalNumero = parseFloat(total); 
      const subcategoriaNumero = parseInt(values.subcategoria); 
      // Guardar en Firestore
      const productoCollection = collection(db, "productos");
     await addDoc(productoCollection, {
        descripcion: values.descripcion,
        imagen: imageUrls,
        mesesSinIntereses: mesesSinIntereses,
        nombre: values.nombre.toLowerCase(),
        precio: values.precio,
        total: totalNumero,
        comisionMesesSinIntereses: comisionMesesSinIntereses,
        comisionPlataforma: comisionPlataforma,
        stock: values.stock,
        vendedorUid: userData.uid,
        categoria: values.categoria,
        subcategoria: subcategoriaNumero,
        deshabilitado:false,
        pausa: false,
      });

      setLoading(false);
      message.success('Producto creado correctamente');
      navigate(`/dashboard/misproductos`); // Redirigir al detalle del nuevo producto
    } catch (error) {
      setLoading(false);
      console.error("Error al crear el producto:", error.message);
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handleImageChange = ({ fileList }) => {
    setImageFileList(fileList);
  };

  const handleChangeCategoria = (categoriaId) => {
  //  form.setFieldsValue({ subcategoria: '' });
    // Obtener las subcategorías de la categoría seleccionada
    const categoriaSeleccionadaData = categorias.find(
      (categoria) => categoria.key === categoriaId
    );
    // Verificar si la categoría seleccionada tiene subcategorías
    if (categoriaSeleccionadaData && categoriaSeleccionadaData.subcategorias) {
      // Add a unique key to each subcategoria object
      const subcategoriasWithKeys = categoriaSeleccionadaData.subcategorias.map((subcategoria, index) => ({
        ...subcategoria,
        id: index.toString() // or any other unique identifier
      }));
      setSubcategorias(subcategoriasWithKeys);
    } else {
      setSubcategorias([]);
    }
  };

  useEffect(() => {
    const fetchCategorias = async () => {
      try {
        const categoriasCollection = collection(db, "categorias");
        const q = query(categoriasCollection, where("tipo", "==", "producto"));
        const categoriasSnapshot = await getDocs(q);

        const categoriasData = categoriasSnapshot.docs.map((doc) => ({
          key: doc.id,
          ...doc.data(),
        }));

        setCategorias(categoriasData);
      } catch (error) {
        console.error("Error al obtener categorías:", error.message);
      }
    };

    fetchCategorias();
  }, []);

  useEffect(() => {
    const fetchComisiones = async () => {
      try {
        // Obtener comisiones de Firebase
        const comisionesDoc = await getDoc(
          doc(db, "configuraciones", "comisiones")
        );
        const comisionesData = comisionesDoc.data();
        const comisionesTiendaDoc = await getDoc(
          doc(db, "tiendas", userData.uid)
        );
        const comisionesTiendaData = comisionesTiendaDoc.data();
        setComisionMesesSinIntereses(comisionesData.comisionMesesSinIntereses);
        setComisionPlataforma(comisionesTiendaData.comisionPlataforma);
        // Actualizar estados con comisiones obtenidas
        setComision(
          comisionesTiendaData.comisionPlataforma +
            comisionesData.comisionMesesSinIntereses
        );
      } catch (error) {
        console.error("Error al obtener comisiones:", error.message);
      }
    };

    fetchComisiones();
  }, []);

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <Card
        style={{ width: "600px", margin: "auto" }}
        bodyStyle={{ padding: "24px" }}
      >
        <h1 style={{ textAlign: "center", marginBottom: "20px" }}>
          Crear producto
        </h1>
        <Form name="crearProducto" onFinish={onFinish} form={form}>
          <Form.Item
            label="Nombre"
            name="nombre"
            rules={[
              {
                required: true,
                message: "Por favor, ingresa el nombre del producto",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Categoría"
            name="categoria"
            rules={[
              {
                required: true,
                message: "Por favor, selecciona la categoría del producto",
              },
            ]}
          >
            <Select
              onChange={handleChangeCategoria}
              placeholder="Selecciona una categoria"
            >
              {categorias.map((categoria) => (
                <Option key={categoria.key} value={categoria.key}>
                  {categoria.nombre}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Subcategoría"
            name="subcategoria"
            rules={[
              {
                required: true,
                message: "Por favor, selecciona la subcategoría del producto",
              },
            ]}
          >
            <Select placeholder="Selecciona una subcategoria">
              {subcategorias.map((subcategoria) => (
                <Option key={subcategoria.id} value={subcategoria.id}>
                  {subcategoria.subcategoria}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Descripción"
            name="descripcion"
            rules={[
              {
                required: true,
                message: "Por favor, ingresa la descripción del producto",
              },
            ]}
          >
            <Input.TextArea />
          </Form.Item>

          <Form.Item
            name="imagenes"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload
              beforeUpload={() => false}
              listType="picture"
              accept="image/*"
              maxCount={3}
              onChange={handleImageChange}
            >
              <Button icon={<UploadOutlined />}>Subir Imágenes</Button>
            </Upload>
          </Form.Item>

    

          <Form.Item
            label="Precio bruto"
            name="precio"
            rules={[
              {
                required: true,
                type: "number",
                min: 0.01,
                message: "Por favor, ingresa un precio válido",
              },
            ]}
          >
            <InputNumber min={0.01} onChange={handlePrecioChange} />
          </Form.Item>

          <Form.Item
            label="Cantidad disponible"
            name="stock"
            rules={[
              {
                required: true,
                type: "number",
                min: 0,
                message: "Por favor, ingresa un stock válido",
              },
            ]}
          >
            <InputNumber min={0} />
          </Form.Item>


          <Form.Item>
            <Title
              level={3}
              style={{ textAlign: "center", marginBottom: "20px" }}
            >
              Resumen de Costos
            </Title>

            <Paragraph style={{ marginBottom: "16px", textAlign: "right" }}>
              <strong>Subtotal:</strong>
              <br />${subtotal}
            </Paragraph>

            <Paragraph style={{ marginBottom: "16px", textAlign: "right" }}>
              <strong>Comisión de la plataforma:</strong>
              <br />
              {comision}%
            </Paragraph>

            <Paragraph style={{ marginBottom: "16px", textAlign: "right" }}>
              <strong>Precio neto por articulo:</strong>
              <br />${total}
            </Paragraph>
            <Button type="primary" htmlType="submit" loading={loading}>
              Crear
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default CrearProducto;
