// Importa las librerías necesarias
import React, { useState, useEffect } from "react";
import { collection, query, orderBy, onSnapshot } from "firebase/firestore";
import { db } from "../../../firebaseConfig";
import { Button, List, Card } from "antd";
import { useUser } from "../../../context/UserContext";
import { useNavigate } from "react-router-dom";

// Definición del componente Pedidos
const PedidosAdmin = () => {
  // Estados para los pedidos, pedido seleccionado y estado del modal
  const [orders, setOrders] = useState([]);
  const { userData } = useUser();
  const navigate = useNavigate();

// Obtener pedidos del usuario desde Firestore
useEffect(() => {
  if (userData) {
    const ordersCollection = collection(db, "pedidos");

    // Consulta con ordenamiento por fecha de creación
    const allOrdersQuery = query(ordersCollection, orderBy("fechaCreacion", "desc")); // "desc" para ordenar de forma descendente

    // Escuchar cambios en la colección de pedidos
    const unsubscribe = onSnapshot(allOrdersQuery, (snapshot) => {
      const newOrders = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setOrders(newOrders);
    });

    // Limpiar el listener al desmontar el componente
    return () => unsubscribe();
  }
}, [userData]);


  const irAPedido = (id) => {
    navigate("/pedido/" + id); // Corregido para agregar "/" entre "pedido" e "id"
  };

  // Renderizar el componente
  return (
    <>
      <Card className="container" style={{ padding: 50, margin: "10px" }}>
        <h1>Lista de todas las Transacciones</h1>
        {/* Lista de pedidos con acciones y botones */}
        <List
          itemLayout="horizontal"
          dataSource={orders}
          renderItem={(order) => (
            <List.Item
              actions={[
                  <Button onClick={() => irAPedido(order.id)}>
                    Ver pedido
                  </Button>,
              ]}
            >
              <List.Item.Meta
                title={`Order ID: ${order.id}`}
                description={`$ ${order.totalGeneral}`}
              />
              <div>
                <p>
                  Fecha:{" "}
                  {order.fechaCreacion.toDate().toLocaleDateString()}
                </p>
              </div>
            </List.Item>
          )}
        />
      </Card>
    </>
  );
};

export default PedidosAdmin;
