import {
  getDoc,
  doc,
  updateDoc,
  setDoc,
  arrayUnion,
} from "firebase/firestore";
import { db } from "../../firebaseConfig";
import {toast } from "sonner";


export const addToCart = async (producto, userData) => {

  try {
    if (!userData) {
      toast.error("Inicia sesión para crear un carrito.");
      return;
    }

    // Obtener el carrito del usuario desde la base de datos
    const userCartRef = doc(db, "carritos", userData.uid);
    const userCartDoc = await getDoc(userCartRef);

    if (userCartDoc.exists()) {
      // Si el carrito ya existe, verificar si el producto está presente
      const existingProductIndex = userCartDoc
        .data()
        .productos.findIndex((cartProduct) => cartProduct.id === producto.id);

      if (existingProductIndex !== -1) {
        // Si el producto ya está en el carrito, incrementar la cantidad en uno
        const updatedCartItems = [...userCartDoc.data().productos];
        updatedCartItems[existingProductIndex].cantidad += 1;
        // Actualizar el carrito en la base de datos
        await updateDoc(userCartRef, {
          productos: updatedCartItems,
        });
        toast.success("Producto agregado al carrito");
      } else {
        // Si el producto no está en el carrito, agregar uno nuevo
        const updatedCartItems = arrayUnion({
          id : producto.id,
          cantidad: 1,
        });
        // Actualizar el carrito en la base de datos
        await updateDoc(userCartRef, {
          productos: updatedCartItems, 
        });
        toast.success("Producto agregado al carrito");
      }
    } else {
      // Si el carrito no existe, crear uno nuevo con el producto
      await setDoc(userCartRef, {
        productos: [{id : producto.id, cantidad: 1 }], servicios:[]
      });
      toast.success("Producto agregado al carrito");
    }
  } catch (error) {
    console.error("Error al agregar el producto al carrito:", error.message);
  }
};

export const addToCartProductoDetalle = async (producto, userData, cantidad) => {

  try {
    if (!userData) {
      toast.error("Inicia sesión para crear un carrito.");
      return;
    }

    // Obtener el carrito del usuario desde la base de datos
    const userCartRef = doc(db, "carritos", userData.uid);
    const userCartDoc = await getDoc(userCartRef);

    if (userCartDoc.exists()) {
      // Si el carrito ya existe, verificar si el producto está presente
      const existingProductIndex = userCartDoc
        .data()
        .productos.findIndex((cartProduct) => cartProduct.id === producto.id);

      if (existingProductIndex !== -1) {
        // Si el producto ya está en el carrito, incrementar la cantidad en uno
        const updatedCartItems = [...userCartDoc.data().productos];
        updatedCartItems[existingProductIndex].cantidad += cantidad;
        // Actualizar el carrito en la base de datos
        await updateDoc(userCartRef, {
          productos: updatedCartItems,
        });
      } else {
        // Si el producto no está en el carrito, agregar uno nuevo
        const updatedCartItems = arrayUnion({
          id : producto.id,
          cantidad: cantidad,
        });
        // Actualizar el carrito en la base de datos
        await updateDoc(userCartRef, {
          productos: updatedCartItems,
        });
      }
    } else {
      // Si el carrito no existe, crear uno nuevo con el producto
      await setDoc(userCartRef, {
        productos: [{id : producto.id, cantidad: 1 }],servicios:[]
      });
    }
  } catch (error) {
    console.error("Error al agregar el producto al carrito:", error.message);
  }
};

// Función para obtener los datos de un producto por su ID
export const getProductoPorId = async (id) => {
  try {
    // Obtener el documento del producto con el ID proporcionado
    const infoProductDoc = doc(db, "productos", id);
    const infoProductSnapshot = await getDoc(infoProductDoc);

    // Verificar si el documento existe
    if (infoProductSnapshot.exists()) {
      // Obtener los datos del producto y devolverlos
      const infoProductData = infoProductSnapshot.data();
      return { id: infoProductSnapshot.id, ...infoProductData };
    } else {
      // Si el documento no existe, devolver un objeto vacío
      console.warn("El producto con el ID proporcionado no existe.");
      return null;
    }
  } catch (error) {
    console.error("Error al obtener los datos del producto:", error.message);
    return null;
  }
}