import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  doc,
  getDoc,
  updateDoc,
  Timestamp,
  query,
  collection,
  where,
  getDocs,
} from "firebase/firestore";
import { db } from "../../../firebaseConfig";
import { Card, List, DatePicker, message, Modal, Button } from "antd";
import ListaItemProductosSubpedidos from "../../../components/ListaItemProductosSubpedidos/ListaItemProductosSubpedidos";
import dayjs from "dayjs";
import { refundCharge } from "../../../Api/Openpay/OpenPayApi";
import SimpleMap from "../../../components/MapGoogle/SimpleMap";
import { MessageOutlined } from "@ant-design/icons";

const DetalleSubpedidosVendedor = () => {
  const { id } = useParams();
  const [subpedido, setSubpedido] = useState(null);
  const [producto, setProducto] = useState(null);
  const [pedido, setPedido] = useState(null);
  const [loading, setLoading] = useState(true);
  const [deliveryDate, setDeliveryDate] = useState(dayjs());
  const [modalVisible, setModalVisible] = useState(false);
  const navigate = useNavigate();
  const fetchSubpedido = async () => {
    try {
      const subpedidoDocRef = doc(db, "subpedidos", id);
      const subpedidoDocSnapshot = await getDoc(subpedidoDocRef);

      if (subpedidoDocSnapshot.exists()) {
        const subpedidoData = subpedidoDocSnapshot.data();
        setSubpedido(subpedidoData);
        const productosPedidosQuery = query(
          collection(db, "productosPedidos"),
          where("subpedidoId", "==", id)
        );
        const productosPedidosSnapshot = await getDocs(productosPedidosQuery);
        const productosPedidosData = productosPedidosSnapshot.docs.map(
          (doc) => {
            const data = doc.data();
            data.id = doc.id; // Asignar el ID del documento al objeto de datos
            return data;
          }
        );

        // Asignar los productosPedidos al subpedido
        subpedidoData.productos = productosPedidosData;
        // Obtener el pedido asociado al subpedido
        const pedidoDocRef = doc(db, "pedidos", subpedidoData.pedidoId);
        const pedidoDocSnapshot = await getDoc(pedidoDocRef);

        if (pedidoDocSnapshot.exists()) {
          const pedidoData = pedidoDocSnapshot.data();
          setPedido(pedidoData);
        } else {
       //   console.log("No se encontró el pedido asociado al subpedido");
        }

        setLoading(false);
      } else {
     //   console.log("No se encontró el subpedido");
      }
    } catch (error) {
      console.error("Error al obtener los detalles del subpedido:", error);
    }
  };

  const irAChat= (subpedido) => {
    navigate("/vendedor/chat/"+subpedido );
  };


  useEffect(() => {
    const fetchSubpedido = async () => {
      try {
        const subpedidoDocRef = doc(db, "subpedidos", id);
        const subpedidoDocSnapshot = await getDoc(subpedidoDocRef);

        if (subpedidoDocSnapshot.exists()) {
          const subpedidoData = subpedidoDocSnapshot.data();
          setSubpedido(subpedidoData);
          const productosPedidosQuery = query(
            collection(db, "productosPedidos"),
            where("subpedidoId", "==", id)
          );
          const productosPedidosSnapshot = await getDocs(productosPedidosQuery);
          const productosPedidosData = productosPedidosSnapshot.docs.map(
            (doc) => {
              const data = doc.data();
              data.id = doc.id; // Asignar el ID del documento al objeto de datos
              return data;
            }
          );

          // Asignar los productosPedidos al subpedido
          subpedidoData.productos = productosPedidosData;
          // Obtener el pedido asociado al subpedido
          const pedidoDocRef = doc(db, "pedidos", subpedidoData.pedidoId);
          const pedidoDocSnapshot = await getDoc(pedidoDocRef);

          if (pedidoDocSnapshot.exists()) {
            const pedidoData = pedidoDocSnapshot.data();
            setPedido(pedidoData);
          } else {
        //    console.log("No se encontró el pedido asociado al subpedido");
          }

          setLoading(false);
        } else {
         // console.log("No se encontró el subpedido");
        }
      } catch (error) {
        console.error("Error al obtener los detalles del subpedido:", error);
      }
    };

    fetchSubpedido();
  }, [id]);

  const handleConfirmDelivery = async () => {
    if (deliveryDate) {
      try {
        const parsedDate = dayjs(deliveryDate);
        const jsDate = parsedDate.toDate();

        // Luego, puedes convertir jsDate en un timestamp de Firestore
        const timestampFromDate = Timestamp.fromDate(jsDate);

        // Actualizar la entrega programada y el estado del producto en la base de datos
        await updateDoc(doc(db, "productosPedidos", producto.id), {
          entregaProgramada: timestampFromDate,
          estatus: "Fecha de entrega asignada",
        });

        // Cerrar el modal
        setModalVisible(false);
        fetchSubpedido();
        // Mostrar mensaje de éxito
        message.success("Fecha de entrega asignada correctamente");
      } catch (error) {
        console.error("Error al actualizar la fecha de entrega:", error);
        message.error("Error al asignar la fecha de entrega");
      }
    } else {
      message.error("Por favor, selecciona una fecha de entrega");
    }
  };

  const handleAssignDeliveryDate = (item) => {
    setProducto(item);
    if (item.entregaProgramada) {
      setDeliveryDate(item.entregaProgramada.toDate());
    }

    if (item.tipo === "servicio") {
      // Crear un objeto dayjs a partir de la fecha y hora seleccionadas
      const fechaCompleta = new Date(
        `${item.diaSeleccionado} ${item.horaInicioSeleccionada}`
      );

      // Convertir el objeto Date a un objeto Day.js
      const fechaDayjs = dayjs(fechaCompleta);

      setDeliveryDate(fechaDayjs);
    }
    setModalVisible(true);
  };

  const handleRefund = async (item) => {
    try {
      // Define los datos de la devolución
      const costoDevolucion = calcularMontoArticulo(item);

      const data = {
        description: "devolución del pedido " + pedido.id,
        amount: costoDevolucion,
      };
      await refundCharge(pedido.uid, data);

      // Obtener el valor actual de devolución del pedido
      const pedidoDocRef = doc(db, "pedidos", subpedido.pedidoId);
      const pedidoDoc = await getDoc(pedidoDocRef);
      const pedidoData = pedidoDoc.data();
      const devolucionActualPedido = pedidoData.devolucion || 0;

      // Obtener el valor actual de devolución del subpedido
      const subpedidoDocRef = doc(db, "subpedidos", id);
      const subpedidoDoc = await getDoc(subpedidoDocRef);
      const subpedidoData = subpedidoDoc.data();
      const devolucionActualSubpedido = subpedidoData.devolucion || 0;

      // Calcular la nueva devolución sumando el valor actual y el nuevo costo de devolución
      const nuevaDevolucionPedido = devolucionActualPedido + costoDevolucion;
      const nuevaDevolucionSubpedido =
        devolucionActualSubpedido + costoDevolucion;

      // Actualizar el estado del pedido para reflejar la devolución
      await updateDoc(pedidoDocRef, {
        devolucion: nuevaDevolucionPedido,
      });

      // Actualizar el estado del subpedido para reflejar la devolución
      await updateDoc(subpedidoDocRef, {
        devolucion: nuevaDevolucionSubpedido,
      });

      // Actualiza el estado y la devolución del producto
      const productoRef = doc(db, "productosPedidos", item.id);
      await updateDoc(productoRef, {
        estatus: "Devolución realizada",
        devolucion: costoDevolucion,
      });
      fetchSubpedido();
      // Mostrar mensaje de éxito
      message.success(
        "Cancelación y devolución al cliente realizada correctamente"
      );
      // Muestra un mensaje de éxito
    } catch (error) {
      console.error("Error al realizar la devolución del cargo:", error);
      throw error; // Propagar el error hacia arriba
    }
  };

  const handleEntregado = async (item) => {
    try {
      // Actualiza el estado del pedido para reflejar la devolución
      await updateDoc(doc(db, "productosPedidos", item.id), {
        estatus: "Entrega realizada",
      });
      fetchSubpedido();
      // Mostrar mensaje de éxito
      message.success("Entrega realizada correctamente");
    } catch (error) {
      console.error("Error al realizar la devolución del cargo:", error);
    }
  };

  const handleRecuperado = async (item) => {
    try {
      // Actualiza el estado del pedido para reflejar la devolución
      await updateDoc(doc(db, "productosPedidos", item.id), {
        estatus: "Producto de renta recuperado",
      });
      fetchSubpedido();
      // Mostrar mensaje de éxito
      message.success("recuperación de la renta realizada correctamente");
    } catch (error) {
      console.error("Error al realizar la devolución del cargo:", error);
    }
  };

  const calcularMontoArticulo = (producto) => {
    let costoProducto = 0;

    if (producto.tipo === "producto") {
      costoProducto = producto.total * producto.cantidad;
    } else {
      if (producto.stock) {
        if (producto.duracion === "unico") {
          costoProducto = producto.total * 1;
        } else {
          costoProducto =
            producto.total * producto.cantidad * producto.cantidadDuracion;
        }
      } else {
        if (producto.duracion === "unico") {
          costoProducto = producto.total * 1;
        } else {
          costoProducto = producto.total * producto.cantidadDuracion;
        }
      }
    }

    return costoProducto;
  };

  return (
    <>
      <Card className="container" style={{ padding: 50, margin: "10px" }}>
        <h1>Detalles del subpedido</h1>
        {loading ? (
          <p>Cargando detalles del subpedido...</p>
        ) : (
          <>
            <p>Total del subpedido: ${subpedido.total}</p>
            {pedido && (
              <p>
                Fecha de creación del pedido:{" "}
                {pedido.fechaCreacion.toDate().toLocaleDateString()}
              </p>
            )}
            <h3>
              <strong>Información del cliente:</strong>
            </h3>
            <p>Ubicación de entrega: {pedido.direccionEntrega}</p>

            {pedido && pedido.coordenadas && (
              <SimpleMap
                markerPosition={{
                  lat: pedido.coordenadas.lat,
                  lng: pedido.coordenadas.lng,
                }}
              />
            )}
<Button type="primary" icon={<MessageOutlined />} onClick={() => irAChat(id)}>
  Chat con el cliente
</Button>

            <h4>Productos</h4>
            <List
              itemLayout="horizontal"
              dataSource={subpedido.productos}
              renderItem={(item) => (
                <ListaItemProductosSubpedidos
                  item={item}
                  onAssignDeliveryDate={handleAssignDeliveryDate}
                  entregado={handleEntregado}
                  recuperado={handleRecuperado}
                />
              )}
            />
          </>
        )}
      </Card>
      {/* Modal para asignar fecha de entrega */}
      <Modal
        title="Asignar fecha y hora de entrega"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={[
          <Button key="close" onClick={() => setModalVisible(false)}>
            Cerrar
          </Button>,
          <Button key="cancel" onClick={() => handleRefund(producto)}>
            Cancelar Pedido
          </Button>,
          <Button
            key="confirm"
            type="primary"
            onClick={() => handleConfirmDelivery()}
          >
            Confirmar dia de entrega
          </Button>,
        ]}
      >
        <p>
          Por favor, selecciona la fecha y hora en la que deseas realizar la
          entrega del pedido.
        </p>
        <DatePicker
          showTime
          format="DD/MM/YYYY HH:mm"
          onChange={setDeliveryDate}
          placeholder="Selecciona la fecha y hora"
          defaultValue={dayjs(deliveryDate)}
        />
      </Modal>
    </>
  );
};

export default DetalleSubpedidosVendedor;
