import React, { useState, useEffect } from "react";
import {
  Card,
  Avatar,
  Button,
  Modal,
  Form,
  Input,
  Upload,
  message,
  Flex,
  Row,
  Col,
} from "antd";
import {
  EditOutlined,
  UserOutlined,
  UploadOutlined,
  CreditCardOutlined,
  EnvironmentOutlined,
  ShoppingOutlined,
} from "@ant-design/icons";
import { useUser } from "../../../context/UserContext";
import Navbar from "../../../components/Nav/Navbar";
import HoverableCard from "../../../components/HoverableCard/HoverableCard";
const { Meta } = Card;

const Perfil = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { userData } = useUser();
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState([]);

  useEffect(() => {
    // Referencia a la colección de pedidos
    if(userData){
      setUserInfo(userData);
    }
   
}, [userData]);

  return (
    <Flex vertical>
      <Card style={{ marginBottom: "16px", textAlign: "center" }}>
        <Avatar
          size={100}
          src={userInfo.photo}
          style={{
            marginBottom: "8px",
            border: "2px solid #1890ff",
            borderRadius: "50%",
          }}
        />
        <Meta title={userInfo.username} description={userInfo.email} />
        <Button
          style={{ marginTop: "8px" }}
          icon={<EditOutlined />}
          href="/perfil/editar" // Agrega esta línea para establecer el enlace
        >
          Editar mi perfil
        </Button>
      </Card>

      <Card
        style={{  margin: "auto" }}
        bodyStyle={{ padding: "24px" }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24}>
            <HoverableCard
              title="Mis pedidos"
              description="Mis pedidos realizados."
              icon={
                <ShoppingOutlined
                  style={{ fontSize: "56px", marginRight: "16px" }}
                />
              }
              destinationUrl="/pedidos"
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24}>
            <HoverableCard
              title="Tarjetas"
              description="Tarjetas guardadas en tu cuenta."
              icon={
                <CreditCardOutlined
                  style={{ fontSize: "56px", marginRight: "16px" }}
                />
              }
              destinationUrl="/mistarjetas"
            />
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24}>
            <HoverableCard
              title="Direcciones"
              description="Direcciones guardadas en tu cuenta."
              icon={
                <EnvironmentOutlined
                  style={{ fontSize: "56px", marginRight: "16px" }}
                />
              }
              destinationUrl="/perfil/direcciones"
            />
          </Col>
        </Row>

      </Card>
    </Flex>
  );
};

export default Perfil;
