import React, { useState } from 'react';
import { Card, Row, Col } from 'antd';
import { CreditCardOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const HoverableCard = ({ title, description, icon, destinationUrl }) => {
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleClick = () => {
    // Redireccionar al hacer clic en la tarjeta
    navigate(destinationUrl);
  };

  const cardStyle = {
    marginBottom: '16px',
    cursor: 'pointer',
    background: isHovered ? '#f0f0f0' : 'white', // Cambiar color de fondo al pasar el cursor
  };

  return (
    <Card
      style={cardStyle}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      <Row align="middle">
        <Col>
          {icon}
        </Col>
        <Col>
          <Card.Meta
            title={title}
            description={description}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default HoverableCard;
