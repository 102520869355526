import React, { useState, useEffect } from "react";
import { Table, Button, message, Modal } from "antd";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import { useUser } from "../../../context/UserContext";
import dayjs from "dayjs";

const PagoAVendedores = () => {
  const [productos, setProductos] = useState([]);
  const [loading, setLoading] = useState(true);
  const { userData } = useUser();
  const db = getFirestore();

  useEffect(() => {
    const fetchProductos = async () => {
      setLoading(true);
      try {
        // Consulta para productos de tipo "producto" con estatus "Entrega realizada" y pagadoVendedor no es true
        const productosQuery = query(
          collection(db, "productosPedidos"),
          where("tipo", "==", "producto"),
          where("estatus", "==", "Entrega realizada"),
          where("pagadoVendedor", "==", false)
        );

        // Consulta para servicios con estatus "Entrega realizada", tipoServicio no sea "renta" y pagadoVendedor no es true
        const serviciosQuery = query(
          collection(db, "productosPedidos"),
          where("tipo", "==", "servicio"),
          where("estatus", "==", "Entrega realizada"),
          where("tipoServicio", "not-in", ["renta"]),
          where("pagadoVendedor", "==", false)
        );

        // Consulta para servicios de renta con estatus "Producto de renta recuperado" y pagadoVendedor no es true
        const rentasQuery = query(
          collection(db, "productosPedidos"),
          where("tipo", "==", "servicio"),
          where("tipoServicio", "==", "renta"),
          where("estatus", "==", "Producto de renta recuperado"),
          where("pagadoVendedor", "==", false)
        );

        // Obtener los documentos para cada consulta
        const [productosSnapshot, serviciosSnapshot, rentasSnapshot] =
          await Promise.all([
            getDocs(productosQuery),
            getDocs(serviciosQuery),
            getDocs(rentasQuery),
          ]);

        // Mapear los datos y combinar en un solo arreglo
        const productosData = productosSnapshot.docs.map((doc) => ({
          docid: doc.id,
          ...doc.data(),
          entregaProgramada: doc.data().entregaProgramada.toDate(),
        }));

        const serviciosData = serviciosSnapshot.docs.map((doc) => ({
          docid: doc.id,
          ...doc.data(),
          entregaProgramada: doc.data().entregaProgramada.toDate(),
        }));

        const rentasData = rentasSnapshot.docs.map((doc) => ({
          docid: doc.id,
          ...doc.data(),
          entregaProgramada: doc.data().entregaProgramada.toDate(),
        }));

        const combinedData = [
          ...productosData,
          ...serviciosData,
          ...rentasData,
        ];

        // Ordenar productos por entregaProgramada
        combinedData.sort((a, b) => a.entregaProgramada - b.entregaProgramada);

       // console.log(combinedData);
        setProductos(combinedData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching productosPedidos:", error);
        setLoading(false);
      }
    };

    fetchProductos();
  }, [db]);

  const handlePagoVendedor = async (productoId) => {
    try {
      const productoRef = doc(db, "productosPedidos", productoId);
  
      // Verificar si el documento existe
      const productoDoc = await getDoc(productoRef);
      if (!productoDoc.exists()) {
        throw new Error(`No se encontró el documento con ID: ${productoId}`);
      }
  
      // Actualizar el documento
      await updateDoc(productoRef, { pagadoVendedor: true });
  
      // Eliminar el producto de la lista si se actualizó con éxito
      setProductos((prevProductos) =>
        prevProductos.filter((producto) => producto.docid !== productoId)
      );
  
      message.success("Producto marcado como pagado al vendedor");
    } catch (error) {
      console.error("Error updating producto:", error);
      message.error("Error al marcar el producto como pagado");
    }
  };
  

  const handleVerInformacionPago = async (vendedorUid) => {
    try {
      // Obtener la información de pago del vendedor
      const infoPagoRef = doc(db, "datosPagoVendedor", vendedorUid);
      const infoPagoDoc = await getDoc(infoPagoRef);
      if (!infoPagoDoc.exists()) {
        message.error('Lo sentimos, este vendedor aún no ha cargado sus datos de pago o están pendientes de aprobación');
        return;
      }
  
      // Verificar si el estado del pago del vendedor está aprobado
      const estado = infoPagoDoc.data().estado;
      if (estado !== 'aprobado') {
        message.error('Los datos de pago de este vendedor aún no han sido aprobados');
        return;
      }
  
      // Mostrar la información de pago del vendedor en un modal
      Modal.info({
        title: 'Información de pago del vendedor',
        content: (
          <div>
            <p><strong>Nombre del vendedor:</strong> {infoPagoDoc.data().nombre}</p>
            <p><strong>Clabe o tarjeta:</strong> {infoPagoDoc.data().clabe}</p>
            <p><strong>Banco:</strong> {infoPagoDoc.data().banco}</p>
            {/* Agrega más información de pago aquí si es necesario */}
          </div>
        ),
      });
    } catch (error) {
      console.error("Error al obtener la información de pago del vendedor:", error);
      message.error("Error al obtener la información de pago del vendedor");
    }
  };
  
  
  

  const columns = [
    {
      title: "Nombre del producto",
      dataIndex: "nombre",
      key: "nombre",
    },
    {
      title: "Pago a vendedor",
      dataIndex: "precio",
      key: "precio",
    },
    {
      title: "Pago que hizo el cliente",
      dataIndex: "total",
      key: "total",
    },
    {
      title: "Fecha de entrega",
      dataIndex: "entregaProgramada",
      key: "entregaProgramada",
      render: (text) => dayjs(text).format("DD/MM/YYYY HH:mm"),
    },
    {
      title: "Estatus",
      dataIndex: "estatus",
      key: "estatus",
    },
    {
      title: "Acciones",
      key: "acciones",
      render: (text, record) => (
        <div>
          <Button
            type="primary"
            onClick={() => handlePagoVendedor(record.docid)}
            disabled={record.pagadoVendedor}
          >
            {record.pagadoVendedor ? "Pagado" : "Marcar como pagado"}
          </Button>
          <Button
            style={{ marginLeft: '10px' }}
            onClick={() => handleVerInformacionPago(record.vendedorUid)}
          >
            Ver información de pago
          </Button>
        </div>
      ),
    },
  ];
  
  return (
    <div>
      <h1>Pago a vendedor por producto o servicio</h1>
      <Table
        columns={columns}
        dataSource={productos}
        loading={loading}
        rowKey="id"
      />
    </div>
  );
  

};

export default PagoAVendedores;
