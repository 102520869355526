import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Upload,
  Switch,
  InputNumber,
  Select,
  Typography,
  Card,
} from "antd";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import Compressor from "compressorjs";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  updateDoc,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { useUser } from "../../../context/UserContext";
import { db } from "../../../firebaseConfig";
const { Option } = Select;

const EditarProducto = () => {
  const navigate = useNavigate();
  const { id } = useParams(); // Obtén el ID del producto de los parámetros de la URL
  const [form] = Form.useForm();
  const { Title, Paragraph } = Typography;
  const [loading, setLoading] = useState(false);
  const { userData } = useUser();
  const [imageFileList, setImageFileList] = useState([]);
  const [imageFileListEdit, setImageFileListEdit] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [subcategorias, setSubcategorias] = useState([]);
  const [subcategoria, setSubcategoria] = useState([]);
  const [subtotal, setSubtotal] = useState(0);
  const [comisionPlataforma, setComisionPlataforma] = useState(0);
  const [comision, setComision] = useState(0);
  const [comisionMesesSinIntereses, setComisionMesesSinIntereses] = useState(0);
  const [total, setTotal] = useState(0);
  const storage = getStorage();

  const fetchProductoData = async (categoriasData) => {
    try {
      // Obtener datos del producto existente
      const productoSnapshot = await getDoc(doc(db, "productos", id));

      if (productoSnapshot.exists()) {
        const productoData = productoSnapshot.data();

        const categoriaSeleccionadaData = categoriasData.find(
          (categoria) => categoria.id === productoData.categoria
        );

        // Verificar si la categoría seleccionada tiene subcategorías
        if (categoriaSeleccionadaData) {
          setSubcategorias(categoriaSeleccionadaData.subcategorias);

          // Encontrar la subcategoría específica por nombre
          const subcategoriaEncontrada =
            categoriaSeleccionadaData.subcategorias.find(
              (subcategoria) => subcategoria.id === productoData.subcategoria
            );

          if (subcategoriaEncontrada) {
            form.setFieldsValue({
              subcategoria: subcategoriaEncontrada.subcategoria,
            });
            setSubcategoria({
              subcategoriaNombre: subcategoriaEncontrada.subcategoria,
              subcategoriaIndice: subcategoriaEncontrada.id,
            });
          }
        }
        setSubtotal(productoData.precio);
        const totalNumero = parseFloat(total); 
        // Llenar el formulario con los datos del producto
        form.setFieldsValue({
          nombre: productoData.nombre.toLowerCase(),
          categoria: productoData.categoria,
          descripcion: productoData.descripcion,
          precio: productoData.precio,
          precioCompra: productoData.precioCompra,
          stock: productoData.stock,
          mesesSinIntereses: productoData.mesesSinIntereses,
          comisionMesesSinIntereses: productoData.comisionMesesSinIntereses,
          comisionPlataforma: productoData.comisionPlataforma,
          total: totalNumero,
        });

        // Llenar la lista de imágenes
        if (productoData.imagen && productoData.imagen.length > 0) {
          setImageFileList(productoData.imagen);
        }
      }
    } catch (error) {
      console.error("Error al obtener datos del producto:", error.message);
    }
  };

  const fetchCategorias = async () => {
    try {
      const categoriasCollection = collection(db, "categorias");
      const q = query(categoriasCollection, where("tipo", "==", "producto"));
      const categoriasSnapshot = await getDocs(q);

      const categoriasData = categoriasSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCategorias(categoriasData);

      // Luego de obtener las categorías, llamar a fetchServicio
      fetchProductoData(categoriasData);
    } catch (error) {
      console.error("Error al obtener categorías:", error.message);
    }
  };

  const handleChangeCategoria = (categoriaSeleccionada) => {
    form.setFieldsValue({ subcategoria: "" });
    // Obtener las subcategorías de la categoría seleccionada
    const categoriaSeleccionadaData = categorias.find(
      (categoria) => categoria.id === categoriaSeleccionada
    );
    // Verificar si la categoría seleccionada tiene subcategorías
    setSubcategorias(
      categoriaSeleccionadaData ? categoriaSeleccionadaData.subcategorias : []
    );
  };

  useEffect(() => {
    fetchCategorias();
  }, []);

  
  const onFinish = async (values) => {
    setLoading(true);
    const currentDate = new Date();
    try {
      // Comprimir imágenes antes de subirlas a Firebase Storage
      const compressedImageFiles = await Promise.all(
        imageFileListEdit.map(async (imageFile) => {
          return new Promise((resolve, reject) => {
            new Compressor(imageFile.originFileObj, {
              quality: 0.6,
              success(result) {
                resolve(result);
              },
              error(err) {
                reject(err);
              },
            });
          });
        })
      );

      // Subir imágenes nuevas a Firebase Storage y obtener las URL
      const imageUrls = await Promise.all(
        compressedImageFiles.map(async (compressedImageFile) => {
          const dateString = currentDate.toISOString().replace(/:/g, "-");
          const storageRef = ref(
            storage,
            `producto/${userData.uid}_${dateString}_${compressedImageFile.name}`
          );
          await uploadBytes(storageRef, compressedImageFile);
          const downloadURL = await getDownloadURL(storageRef);
          const location = storageRef._location.path_;
          return { url: downloadURL, path: location };
        })
      );
      const mergedImageFileList = [...imageUrls, ...imageFileList.flat()];
      // Actualizar datos en Firestore
      const productoRef = doc(collection(db, "productos"), id);

      if (values.subcategoria === subcategoria.subcategoriaNombre) {
        values.subcategoria = subcategoria.subcategoriaIndice;
      }

      await updateDoc(productoRef, {
        categoria: values.categoria,
        descripcion: values.descripcion,
        imagen: mergedImageFileList,
        nombre: values.nombre.toLowerCase(),
        precio: values.precio,
        total: values.total,
        stock: values.stock,
        vendedorUid: userData.uid,
        comisionMesesSinIntereses: comisionMesesSinIntereses,
        comisionPlataforma: comisionPlataforma,
        subcategoria: values.subcategoria,
      });

      setLoading(false);
      navigate(`/dashboard/misproductos`); // Redirigir al detalle del producto editado
    } catch (error) {
      setLoading(false);
      console.error("Error al editar el producto:", error.message);
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handleImageChange = ({ fileList }) => {
    setImageFileListEdit(fileList);
  };

  const handleDeleteImage = async (index, path) => {
    try {
      const imageRef = ref(storage, path);

      // Eliminar la imagen de Firebase Storage
      await deleteObject(imageRef);

      // Actualizar el servicio en Firestore
      const servicioRef = doc(db, "productos", id); // Suponiendo que tienes acceso a la variable 'servicioId' que contiene el ID del servicio
      const servicioDoc = await getDoc(servicioRef);
      if (servicioDoc.exists()) {
        const servicioData = servicioDoc.data();
        const nuevasImagenes = servicioData.imagen.filter(
          (imagen, i) => i !== index
        );

        // Actualizar las imágenes en el servicio
        await updateDoc(servicioRef, { imagen: nuevasImagenes });

        // Actualizar el estado local si es necesario
        // setServicio({ ...servicioData, imagen: nuevasImagenes });
      } else {
        console.error("El servicio no existe");
      }

      // Eliminar la imagen de la lista de imágenes
      const updatedImageFileList = [...imageFileList];
      updatedImageFileList.splice(index, 1);
      setImageFileList(updatedImageFileList);
    } catch (error) {
      console.error("Error al eliminar la imagen:", error.message);
    }
  };

  const handlePrecioChange = (value) => {
    // Calcular el subtotal cuando cambia el precio
    setSubtotal(value);
  };

  useEffect(() => {
    const fetchComisiones = async () => {
      try {
        // Obtener comisiones de Firebase
        const comisionesDoc = await getDoc(
          doc(db, "configuraciones", "comisiones")
        );
        const comisionesData = comisionesDoc.data();
        const comisionesTiendaDoc = await getDoc(
          doc(db, "tiendas", userData.uid)
        );
        const comisionesTiendaData = comisionesTiendaDoc.data();
        setComisionMesesSinIntereses(comisionesData.comisionMesesSinIntereses);
        setComisionPlataforma(comisionesTiendaData.comisionPlataforma);
        // Actualizar estados con comisiones obtenidas
        setComision(
          comisionesTiendaData.comisionPlataforma +
            comisionesData.comisionMesesSinIntereses
        );
      } catch (error) {
        console.error("Error al obtener comisiones:", error.message);
      }
    };

    fetchComisiones();
  }, []);

  useEffect(() => {
    const comisionMesesSinInteresesCalculada =
      subtotal * (comisionMesesSinIntereses / 100);

    const comisionMesesSinInteresesRedondeada =
      comisionMesesSinInteresesCalculada.toFixed(2);

    const comisionPlataformaCalculada = subtotal * (comisionPlataforma / 100);
    const comisionPlataformaRedondeada = comisionPlataformaCalculada.toFixed(2);
    // Calcular el total
    let totalCalculado = 0; // Inicializar totalCalculado

    totalCalculado =
      subtotal +
      parseFloat(comisionPlataformaRedondeada) +
      parseFloat(comisionMesesSinInteresesRedondeada);

    // Calcular el total
    const totalRedondeado = totalCalculado.toFixed(2);

    setTotal(totalRedondeado);
  }, [subtotal, comisionPlataforma, comisionMesesSinIntereses]);

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <Card
        style={{ width: "600px", margin: "auto" }}
        bodyStyle={{ padding: "24px" }}
      >
        <h1 style={{ textAlign: "center", marginBottom: "20px" }}>
          Editar producto
        </h1>
        <Form form={form} name="editarProducto" onFinish={onFinish}>
          <Form.Item
            label="Nombre"
            name="nombre"
            rules={[
              {
                required: true,
                message: "Por favor, ingresa el nombre del producto",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Categoría"
            name="categoria"
            rules={[
              {
                required: true,
                message: "Por favor, selecciona la categoría del producto",
              },
            ]}
          >
            <Select
              onChange={handleChangeCategoria}
              placeholder="Selecciona una categoria"
            >
              {categorias.map((categoria) => (
                <Option key={categoria.id} value={categoria.id}>
                  {categoria.nombre}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Subcategoría"
            name="subcategoria"
            rules={[
              {
                required: true,
                message: "Por favor, selecciona la subcategoría del producto",
              },
            ]}
          >
            <Select placeholder="Selecciona una subcategoria">
              {subcategorias.map((subcategoria) => (
                <Option key={subcategoria.id} value={subcategoria.id}>
                  {subcategoria.subcategoria}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Descripción"
            name="descripcion"
            rules={[
              {
                required: true,
                message: "Por favor, ingresa la descripción del producto",
              },
            ]}
          >
            <Input.TextArea />
          </Form.Item>

          <Form.Item
            label="Precio bruto"
            name="precio"
            rules={[
              {
                required: true,
                type: "number",
                min: 0,
                message: "Por favor, ingresa un precio válido",
              },
            ]}
          >
            <InputNumber min={0.01} onChange={handlePrecioChange} />
          </Form.Item>
          <Form.Item
            label="Cantidad disponible"
            name="stock"
            rules={[
              {
                required: true,
                type: "number",
                min: 0,
                message: "Por favor, ingresa un stock válido",
              },
            ]}
          >
            <InputNumber min={0} />
          </Form.Item>


          <Form.Item
            name="imagenes"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload
              beforeUpload={() => false}
              listType="picture"
              maxCount={3}
              onChange={handleImageChange}
            >
              <Button icon={<UploadOutlined />}>Subir Imágenes</Button>
            </Upload>
          </Form.Item>

          {imageFileList &&
            imageFileList.length > 0 &&
            imageFileList.map((image, index) => {
              return (
                <div
                  key={index}
                  style={{ display: "inline-block", marginRight: 10 }}
                >
                  <img
                    src={image.url} // Corrección aquí
                    style={{ maxHeight: 200, margin: 10 }}
                  />
                  <Button
                    icon={<DeleteOutlined />}
                    onClick={() => handleDeleteImage(index, image.path)} // Y corrección aquí
                    danger
                  >
                    Eliminar
                  </Button>
                </div>
              );
            })}
          <Title
            level={3}
            style={{ textAlign: "center", marginBottom: "20px" }}
          >
            Resumen de Costos
          </Title>

          <Paragraph style={{ marginBottom: "16px", textAlign: "right" }}>
            <strong>Subtotal:</strong>
            <br />${subtotal}
          </Paragraph>

          <Paragraph style={{ marginBottom: "16px", textAlign: "right" }}>
            <strong>Comisión de la plataforma:</strong>
            <br />
            {comision}%
          </Paragraph>

          <Paragraph style={{ marginBottom: "16px", textAlign: "right" }}>
            <strong>Precio neto por articulo:</strong>
            <br />${total}
          </Paragraph>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              Guardar Cambios
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default EditarProducto;
